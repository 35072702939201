import { TypedIconProps } from 'typed-design-system/dist/TypedIcon'
import colors from '@/styles/colors'
import { ResourceTag } from '@/models/resource/ResourceTag'
import { FileMetaData, IFileMetaData } from '@/models/resource/FileMetaData'
import TypedResource, {
  TypedResourceType,
  TypedFileResourceType,
  ITypedResource,
} from '@/models/resource/TypedResource'
import { ITypedUser } from '@/models/user'
import { ResourceFilterType } from '@/models/resource/ResourceFilter'

export interface ITypedFileResource extends ITypedResource {
  readonly metadata: IFileMetaData
  readonly signedUrl: string
  readonly highlightCount?: number
}

export class TypedFileResource
  extends TypedResource
  implements ITypedFileResource
{
  type = TypedResourceType.FILE

  constructor(
    readonly resourceId: string,
    readonly backlinks: string[],
    readonly links: string[],
    readonly name: string,
    readonly createdAt: number,
    readonly createdBy: ITypedUser,
    readonly tags: ResourceTag[],
    readonly metadata: FileMetaData,
    readonly signedUrl: string,
    readonly hasWorkspace: boolean,
    readonly highlightCount?: number,
  ) {
    super(
      resourceId,
      backlinks,
      links,
      name,
      createdAt,
      createdBy,
      tags,
      hasWorkspace,
    )
  }

  get canBeDocument() {
    return this.isPdfFile() && !this.hasWorkspace
  }

  static fromJSON(json: ITypedFileResource): TypedFileResource {
    if (json.type !== 'file') {
      throw new Error(
        `the type of FileResource must be file, but given type is ${json.type}`,
      )
    }

    return new TypedFileResource(
      json.resourceId,
      json.backlinks,
      json.links,
      json.name,
      json.createdAt,
      json.createdBy,
      json.tags?.map((tag) => ResourceTag.fromJSON(tag)) || [],
      FileMetaData.fromJSON(json.metadata),
      json.signedUrl,
      json.hasWorkspace,
      json.highlightCount,
    )
  }

  static getFileResourceType = (mimeType: string): TypedFileResourceType => {
    switch (mimeType) {
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return TypedFileResourceType.WORD

      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-powerpoint':
        return TypedFileResourceType.PPT

      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
        return TypedFileResourceType.EXCEL

      case 'application/pdf':
      case 'application/vnd.epapyrus.pdf':
        return TypedFileResourceType.PDF

      case 'application/octet-stream':
      case 'application/x-hwp':
      case 'application/haansofthwp':
      case 'application/vnd.hancom.hwp':
        return TypedFileResourceType.HWP

      case 'image/apng':
      case 'image/avif':
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/webp':
      case 'image/bmp':
      case 'image/tiff':
        return TypedFileResourceType.IMAGE

      default:
        return TypedFileResourceType.NOT_SUPPORTED
    }
  }

  static getFileResourceCurrentLibraryFilterType = (
    mimeType?: string,
  ): ResourceFilterType | 'file' => {
    switch (mimeType) {
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return 'word'

      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-powerpoint':
        return 'ppt'

      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
        return 'excel'

      case 'application/pdf':
      case 'application/vnd.epapyrus.pdf':
        return 'pdf'

      case 'application/octet-stream':
      case 'application/x-hwp':
      case 'application/haansofthwp':
      case 'application/vnd.hancom.hwp':
        return 'hwp'

      case 'image/apng':
      case 'image/avif':
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
      case 'image/svg+xml':
      case 'image/webp':
      case 'image/bmp':
      case 'image/tiff':
        return 'image'

      default:
        return 'file'
    }
  }

  copyWith(
    options?: Partial<Omit<TypedFileResource, 'type'>>,
  ): TypedFileResource {
    return new TypedFileResource(
      options?.resourceId ?? this.resourceId,
      options?.backlinks ?? this.backlinks,
      options?.links ?? this.links,
      options?.name ?? this.name,
      options?.createdAt ?? this.createdAt,
      options?.createdBy ?? this.createdBy,
      options?.tags ?? this.tags,
      options?.metadata ?? this.metadata,
      options?.signedUrl ?? this.signedUrl,
      options?.hasWorkspace ?? this.hasWorkspace,
      options?.highlightCount ?? this.highlightCount,
    )
  }

  toJSON(): ITypedFileResource {
    return {
      ...super.toJSON(),
      metadata: this.metadata.toJSON(),
      signedUrl: this.signedUrl,
      highlightCount: this.highlightCount,
    }
  }

  getFileFormat(): TypedFileResourceType {
    return TypedFileResource.getFileResourceType(this.metadata.contentType)
  }

  getFilePreviewInfo(): {
    bgColor: string
    fontColor: string
    iconName: TypedIconProps['icon']
    typeText: string
  } {
    switch (this.getFileFormat()) {
      case TypedFileResourceType.WORD:
        return {
          iconName: 'word',
          typeText: 'WORD',
          bgColor: colors.formatWord(0.1),
          fontColor: colors.formatWord(),
        }

      case TypedFileResourceType.PPT:
        return {
          iconName: 'ppt',
          typeText: 'PPT',
          bgColor: colors.formatPpt(0.1),
          fontColor: colors.formatPpt(),
        }

      case TypedFileResourceType.EXCEL:
        return {
          iconName: 'excel',
          typeText: 'EXCEL',
          bgColor: colors.formatExcel(0.1),
          fontColor: colors.formatExcel(),
        }

      case TypedFileResourceType.PDF:
        return {
          iconName: 'pdf',
          typeText: 'PDF',
          bgColor: colors.formatPdf(0.1),
          fontColor: colors.formatPdf(),
        }

      case TypedFileResourceType.HWP:
        return {
          iconName: 'hwp',
          typeText: 'HWP',
          bgColor: colors.formatHwp(0.1),
          fontColor: colors.formatHwp(),
        }

      default:
        return {
          iconName: 'file',
          typeText: 'FILE',
          bgColor: colors.gray33(0.1),
          fontColor: colors.gray33(),
        }
    }
  }

  isImageFile() {
    if (
      this.metadata.contentType.match(/^image\/*/) &&
      this.metadata.thumbnailUrl
    )
      return true
    return false
  }

  isPdfFile() {
    const pdfMimeTypes = ['application/pdf', 'application/vnd.epapyrus.pdf']
    if (pdfMimeTypes.includes(this.metadata.contentType)) return true
    return false
  }
}
