import { isNil } from 'lodash-es'
import {
  ITypedPDFSelectionText,
  ITypedSelectionText,
  ITypedURLSelectionText,
  TypedPDFSelectionText,
  TypedSelectionText,
  TypedURLSelectionText,
} from '@/models/selectionText'
import { ITypedRange, TypedRange } from '@/models/range'

export interface ITypedHighlight {
  readonly id: string
  readonly resourceId: string
  readonly text: ITypedSelectionText
  readonly range: ITypedRange
  readonly createdAt: number
}

export const CONVERSION_ERROR = 'Invalid highlight data'

export abstract class TypedHighlight implements ITypedHighlight {
  constructor(
    readonly id: string,
    readonly resourceId: string,
    readonly text: TypedSelectionText,
    readonly range: TypedRange,
    readonly createdAt: number,
  ) {}

  toJSON(): ITypedHighlight {
    return {
      id: this.id,
      resourceId: this.resourceId,
      text: this.text.toJSON(),
      range: this.range.toJSON(),
      createdAt: this.createdAt,
    }
  }
}

export interface ITypedURLHighlight extends ITypedHighlight {
  readonly id: string
  readonly resourceId: string
  readonly text: ITypedURLSelectionText
  readonly range: ITypedRange
  readonly createdAt: number
}

export class TypedURLHighlight
  extends TypedHighlight
  implements ITypedURLHighlight
{
  constructor(
    readonly id: string,
    readonly resourceId: string,
    readonly text: TypedURLSelectionText,
    readonly range: TypedRange,
    readonly createdAt: number,
  ) {
    super(id, resourceId, text, range, createdAt)
  }

  static validateJSON(json: ITypedURLHighlight): json is ITypedURLHighlight {
    return Boolean(
      json?.id &&
        json?.resourceId &&
        !isNil(json?.text?.core) &&
        json?.text?.pageIndex === undefined &&
        Number.isInteger(json?.range?.anchor?.content?.start) &&
        Number.isInteger(json?.range?.anchor?.content?.end) &&
        json?.createdAt,
    )
  }

  static fromJSON(json: ITypedURLHighlight): TypedURLHighlight {
    if (!TypedURLHighlight.validateJSON(json)) {
      throw new Error(CONVERSION_ERROR)
    }

    return new TypedURLHighlight(
      json.id,
      json.resourceId,
      new TypedURLSelectionText(json.text.core),
      new TypedRange(json.range.anchor),
      json.createdAt,
    )
  }
}

export interface ITypedPDFHighlight extends ITypedHighlight {
  readonly id: string
  readonly resourceId: string
  readonly text: ITypedPDFSelectionText
  readonly range: ITypedRange
  readonly createdAt: number
}

export class TypedPDFHighlight
  extends TypedHighlight
  implements ITypedPDFHighlight
{
  constructor(
    readonly id: string,
    readonly resourceId: string,
    readonly text: TypedPDFSelectionText,
    readonly range: TypedRange,
    readonly createdAt: number,
  ) {
    super(id, resourceId, text, range, createdAt)
  }

  static validateJSON(json: ITypedPDFHighlight): json is ITypedPDFHighlight {
    return Boolean(
      json?.id &&
        json?.resourceId &&
        !isNil(json?.text?.core) &&
        typeof json?.text?.pageIndex === 'number' &&
        Number.isInteger(json?.range?.anchor?.content?.start) &&
        Number.isInteger(json?.range?.anchor?.content?.end) &&
        json?.createdAt,
    )
  }

  static fromJSON(json: ITypedPDFHighlight): TypedPDFHighlight {
    if (!TypedPDFHighlight.validateJSON(json)) {
      throw new Error(CONVERSION_ERROR)
    }

    return new TypedPDFHighlight(
      json.id,
      json.resourceId,
      new TypedPDFSelectionText(json.text.core, json.text.pageIndex),
      new TypedRange(json.range.anchor),
      json.createdAt,
    )
  }
}

export function highlightFactory(data: ITypedHighlight) {
  if (isPDFHighlightData(data)) {
    return TypedPDFHighlight.fromJSON(data)
  }
  if (isURLHighlightData(data)) {
    return TypedURLHighlight.fromJSON(data)
  }
  throw new Error('Invalid highlight type')
}

function isPDFHighlightData(data: ITypedHighlight): data is ITypedPDFHighlight {
  return 'pageIndex' in data.text
}
function isURLHighlightData(data: ITypedHighlight): data is ITypedURLHighlight {
  return !('pageIndex' in data.text)
}
