import { SUPPORTED_LANGUAGES } from '@/locale/locale.util'
import { TypedDocumentType } from '@/models/document/TypedDocument'

export interface TypedTemplateDTO {
  id: string
  createdDate: string
  updatedDate: string
  name: string
  position: number
  languageType: 'KO' | 'EN'
  paymentType: 'free' | 'standard'
  active: boolean
  thumbnail: string
  preview: string
  originResourceId: string
  templateGroupId: string
  templateMimeType: TypedDocumentType
  templateResourcesTotal: number
  representResources: { id: string; name: string; resourceMimeType: string }[]
}

export interface ITypedTemplate
  extends Omit<
    TypedTemplateDTO,
    'createdDate' | 'updatedDate' | 'languageType'
  > {
  createdDate: Date
  updatedDate: Date
  languageType: SUPPORTED_LANGUAGES
  copyWith(options?: Partial<ITypedTemplate>): ITypedTemplate
}

export default class TypedTemplate implements ITypedTemplate {
  constructor(
    readonly id: string,
    readonly createdDate: Date,
    readonly updatedDate: Date,
    readonly name: string,
    readonly position: number,
    readonly languageType: SUPPORTED_LANGUAGES,
    readonly paymentType: 'free' | 'standard',
    readonly active: boolean,
    readonly thumbnail: string,
    readonly preview: string,
    readonly originResourceId: string,
    readonly templateGroupId: string,
    readonly templateMimeType: TypedDocumentType,
    readonly templateResourcesTotal: number,
    readonly representResources: any[],
  ) {}

  static fromDTO(dto: TypedTemplateDTO) {
    return TypedTemplate.fromJSON({
      ...dto,
      createdDate: new Date(dto.createdDate),
      updatedDate: new Date(dto.updatedDate),
      languageType: dto.languageType.toLowerCase() as SUPPORTED_LANGUAGES,
    })
  }

  static fromJSON(json: Omit<ITypedTemplate, 'copyWith'>) {
    return new TypedTemplate(
      json.id,
      json.createdDate,
      json.updatedDate,
      json.name,
      json.position,
      json.languageType,
      json.paymentType,
      json.active,
      json.thumbnail,
      json.preview,
      json.originResourceId,
      json.templateGroupId,
      json.templateMimeType,
      json.templateResourcesTotal,
      json.representResources,
    )
  }

  copyWith(options?: Partial<ITypedTemplate>): TypedTemplate {
    return new TypedTemplate(
      options?.id ?? this.id,
      options?.createdDate ?? this.createdDate,
      options?.updatedDate ?? this.updatedDate,
      options?.name ?? this.name,
      options?.position ?? this.position,
      options?.languageType ?? this.languageType,
      options?.paymentType ?? this.paymentType,
      options?.active ?? this.active,
      options?.thumbnail ?? this.thumbnail,
      options?.preview ?? this.preview,
      options?.originResourceId ?? this.originResourceId,
      options?.templateGroupId ?? this.templateGroupId,
      options?.templateMimeType ?? this.templateMimeType,
      options?.templateResourcesTotal ?? this.templateResourcesTotal,
      options?.representResources ?? this.representResources,
    )
  }
}
