import { matchPath, generatePath } from 'react-router-dom'
import { ApphomeViewType } from '@/types/types'
import autoBind from '@/utils/autoBind'
import { TypedDocumentSharedPermission } from '@/models/document/TypedDocument'

export enum TypedPage {
  HOME,
  APP,
  APP_HOME,
  APP_HOME_SEARCH,
  APP_MY_HOME,
  REVIEW,
  DOCUMENT,
  INBOX,
  PAYMENT,
  ICP,
  LOGIN,
  LOGOUT,
  SIGNUP,
  TERMS,
  NOT_FOUND,
  MIGRATION,
  SHARED_DOCUMENT,
  INTEGRATION,
  ACCESS_REQUIRED,
  NOT_INVITED_SPACE,
  NOT_INVITED_PROJECT,
  PAYMENT_COMPLETED,
  TEMPLATES,
}

export enum DocumentPagePanel {
  TASK = 'task',
  REVIEW = 'review',
}

export enum ReviewPageTab {
  'RECEIVE' = 'receive',
  'REQUEST' = 'request',
}

export const pageNameToPathTemplateMap: { [page: string]: string } = {
  [TypedPage.HOME]: '/',
  [TypedPage.APP]: '/app',
  [TypedPage.APP_HOME]: '/app/space/:spaceId',
  [TypedPage.APP_HOME_SEARCH]: '/app/space/:spaceId/search',
  [TypedPage.APP_MY_HOME]: '/app/space/:spaceId/home',
  [TypedPage.DOCUMENT]: '/app/doc/:documentId',
  [TypedPage.REVIEW]: '/review',
  [TypedPage.INBOX]: '/inbox',
  [TypedPage.PAYMENT]: '/payment/:spaceId',
  [TypedPage.ICP]: '/icp',
  [TypedPage.LOGIN]: '/login',
  [TypedPage.LOGOUT]: '/logout',
  [TypedPage.SIGNUP]: '/signup',
  [TypedPage.MIGRATION]: '/migration',
  [TypedPage.TERMS]: '/terms',
  [TypedPage.NOT_FOUND]: '/404',
  [TypedPage.SHARED_DOCUMENT]: '/public/doc/:documentId',
  [TypedPage.INTEGRATION]: '/integrations/:type/callback/space/:spaceId',
  [TypedPage.ACCESS_REQUIRED]: '/access-required',
  [TypedPage.NOT_INVITED_SPACE]: '/not-invited-space',
  [TypedPage.NOT_INVITED_PROJECT]: '/not-invited-project',
  [TypedPage.PAYMENT_COMPLETED]: '/payment-completed/:spaceId',
  [TypedPage.TEMPLATES]: '/app/space/:spaceId/templates',
}

export const pathTemplateToPageNameMap: { [path: string]: TypedPage } = {
  '/': TypedPage.HOME,
  '/app': TypedPage.APP,
  '/app/space/:spaceId': TypedPage.APP_HOME,
  '/app/space/:spaceId/search': TypedPage.APP_HOME_SEARCH,
  '/app/space/:spaceId/home': TypedPage.APP_MY_HOME,
  '/app/doc/:documentId': TypedPage.DOCUMENT,
  '/review': TypedPage.REVIEW,
  '/inbox': TypedPage.INBOX,
  '/payment/:spaceId': TypedPage.PAYMENT,
  '/icp': TypedPage.ICP,
  '/login': TypedPage.LOGIN,
  '/logout': TypedPage.LOGOUT,
  '/signup': TypedPage.SIGNUP,
  '/migration': TypedPage.MIGRATION,
  '/terms': TypedPage.TERMS,
  '/404': TypedPage.NOT_FOUND,
  '/public/doc/:documentId': TypedPage.SHARED_DOCUMENT,
  '/integrations/:type/callback/space/:spaceId': TypedPage.INTEGRATION,
  '/access-required': TypedPage.ACCESS_REQUIRED,
  '/not-invited-space': TypedPage.NOT_INVITED_SPACE,
  '/not-invited-project': TypedPage.NOT_INVITED_PROJECT,
  '/payment-completed/:spaceId': TypedPage.PAYMENT_COMPLETED,
  '/app/space/:spaceId/templates': TypedPage.TEMPLATES,
}

export interface IRouteManager {
  getSpaceIdFromPathname(): string | undefined
  getDocumentIdFromPathname(): string | undefined
  getProjectIdFromPathName(): string | undefined
  getSharedPagePermissionFromPathName():
    | TypedDocumentSharedPermission
    | undefined
  isSpacePage(spaceId?: string): boolean
  isSearchPage(): boolean
  isReviewPage(): boolean
  isInboxPage(): boolean
  isTemplatesPage(): boolean
  isAllProjects(): boolean
  isMyHomePage(): boolean
  isSharedDocumentPage(): boolean
  isDocumentPage(): boolean
  isProjectPage(): boolean
  getQueryParams(): { [key: string]: string | undefined }
  getAnonymousTokenFromQueryString(): string | undefined
  getLoginPagePath(): string
  getSignupPagePath(): string
  getAccessRequiredPath(): string
  getAppPagePath(): string
  getNotInvitedSpacePath(): string
  getNotInvitedProjectPath(): string
}

class RouteManager implements IRouteManager {
  readonly pathname: string
  constructor(pathname: string, readonly search: string) {
    this.pathname = RouteManager.stripBasename(pathname)
  }

  static stripBasename(pathname: string): string {
    const basename = process.env.PUBLIC_PATH

    if (!basename || basename === '/') return pathname

    if (!pathname.toLowerCase().startsWith(basename.toLowerCase())) {
      return pathname
    }

    const nextChar = pathname.charAt(basename.length)
    if (nextChar && nextChar !== '/') {
      return pathname
    }

    return pathname.slice(basename.length) || '/'
  }

  getSpaceIdFromPathname(): string | undefined {
    const typedPage = this.getPageName()

    switch (typedPage) {
      case TypedPage.APP_HOME:
      case TypedPage.APP_HOME_SEARCH:
      case TypedPage.APP_MY_HOME:
      case TypedPage.PAYMENT:
      case TypedPage.PAYMENT_COMPLETED:
      case TypedPage.TEMPLATES:
      case TypedPage.INTEGRATION:
        return this.getSpaceIdFromParams(typedPage)
      case TypedPage.REVIEW:
      case TypedPage.INBOX:
      case TypedPage.DOCUMENT:
      case TypedPage.SHARED_DOCUMENT:
        return this.getSpaceIdFromQueryString()
      default:
        return undefined
    }
  }

  getDocumentIdFromPathname(): string | undefined {
    const typedPage = this.getPageName()

    switch (typedPage) {
      case TypedPage.DOCUMENT:
      case TypedPage.SHARED_DOCUMENT:
        return this.getDocumentIdFromParams(typedPage)
      case TypedPage.APP_HOME:
      case TypedPage.APP_HOME_SEARCH:
      case TypedPage.APP_MY_HOME:
      case TypedPage.PAYMENT:
      case TypedPage.INBOX:
      default:
        return undefined
    }
  }

  getProjectIdFromPathName(): string | undefined {
    const typedPage = this.getPageName()

    switch (typedPage) {
      case TypedPage.APP_HOME:
        return this.getProjectIdFromQueryString()
      default:
        return undefined
    }
  }

  getSharedPagePermissionFromPathName():
    | TypedDocumentSharedPermission
    | undefined {
    const URLQueryString = new URLSearchParams(this.search)
    const permission = URLQueryString.get(
      'mode',
    ) as TypedDocumentSharedPermission

    if (!permission) {
      console.error(`Can't find Permission from queryString in the path.`)
      return undefined
    }

    return permission
  }

  isSpacePage(spaceId?: string) {
    if (this.getPageName() !== TypedPage.APP_HOME) {
      return false
    }

    if (spaceId) {
      return this.getSpaceIdFromPathname() === spaceId
    }

    return true
  }

  isSearchPage() {
    return this.getPageName() === TypedPage.APP_HOME_SEARCH
  }

  isReviewPage() {
    return this.getPageName() === TypedPage.REVIEW
  }

  isInboxPage() {
    return this.getPageName() === TypedPage.INBOX
  }

  isTemplatesPage() {
    return this.getPageName() === TypedPage.TEMPLATES
  }

  isAllProjects() {
    const queryParams = new URLSearchParams(this.search)
    const selectedProject = queryParams.get('project-id')

    return this.isSpacePage() && !selectedProject
  }

  isMyHomePage() {
    return this.getPageName() === TypedPage.APP_MY_HOME
  }

  isSharedDocumentPage() {
    return this.getPageName() === TypedPage.SHARED_DOCUMENT
  }

  isDocumentPage() {
    return this.getPageName() === TypedPage.DOCUMENT
  }

  isProjectPage() {
    return this.getPageName() === TypedPage.APP_HOME
  }

  getQueryParams() {
    const queryParams = new URLSearchParams(this.search)
    return Object.fromEntries<string | undefined>(queryParams.entries())
  }

  getAnonymousTokenFromQueryString() {
    const URLQueryString = new URLSearchParams(this.search)
    const token = URLQueryString.get('anonymous')

    if (!token) {
      console.error(`Can't get an anonymous token from shared document.`)
      return undefined
    }

    return token
  }
  getLoginPagePath() {
    const pathname = generatePath(pageNameToPathTemplateMap[TypedPage.LOGIN])
    return pathname
  }
  getSignupPagePath() {
    const pathname = generatePath(pageNameToPathTemplateMap[TypedPage.SIGNUP])
    return pathname
  }

  getAccessRequiredPath() {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.ACCESS_REQUIRED],
    )
    return pathname
  }

  getNotInvitedSpacePath() {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.NOT_INVITED_SPACE],
    )
    return pathname
  }

  getNotInvitedProjectPath() {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.NOT_INVITED_PROJECT],
    )
    return pathname
  }

  getAppPagePath() {
    const pathname = generatePath(pageNameToPathTemplateMap[TypedPage.APP])
    return pathname
  }

  static getAppPagePath(): string {
    return this.getAppPagePath()
  }

  static getProjectPagePath(
    spaceId: string,
    options: { projectId: string; viewType?: ApphomeViewType },
  ) {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.APP_HOME],
      { spaceId },
    )

    const queryParams = new URLSearchParams()
    queryParams.append('project-id', options.projectId)

    if (options.viewType) {
      queryParams.append('view', options.viewType)
    }
    return `${pathname}?${queryParams.toString()}`
  }

  static getDocumentPagePath(
    spaceId: string,
    documentId: string,
    panel?: DocumentPagePanel,
  ) {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.DOCUMENT],
      { documentId },
    )
    const queryParams = new URLSearchParams()
    queryParams.append('space-id', spaceId)
    if (panel) queryParams.append('panel', panel)

    return `${pathname}?${queryParams.toString()}`
  }

  static getMyHomePagePath(spaceId: string) {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.APP_MY_HOME],
      { spaceId },
    )
    return pathname
  }

  static getPaymentCompletedPath(spaceId: string) {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.PAYMENT_COMPLETED],
      { spaceId },
    )

    return pathname
  }

  static getReviewPagePath(
    spaceId: string,
    tab: ReviewPageTab = ReviewPageTab.RECEIVE,
  ) {
    const pathname = generatePath(pageNameToPathTemplateMap[TypedPage.REVIEW])
    const queryParams = new URLSearchParams()
    queryParams.append('space-id', spaceId)
    queryParams.append('tab', tab)
    return `${pathname}?${queryParams.toString()}`
  }

  static getInboxPagePath(spaceId: string) {
    const pathname = generatePath(pageNameToPathTemplateMap[TypedPage.INBOX])
    const queryParams = new URLSearchParams()
    queryParams.append('space-id', spaceId)

    return `${pathname}?${queryParams.toString()}`
  }

  static getTemplatesPagePath(spaceId: string) {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.TEMPLATES],
      { spaceId },
    )

    return pathname
  }

  static getIcpPagePath() {
    const pathname = generatePath(pageNameToPathTemplateMap[TypedPage.ICP])
    return pathname
  }

  static getPaymentPagePath(spaceId: string, cycle: 'monthly' | 'annual') {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.PAYMENT],
      { spaceId },
    )
    const queryParams = new URLSearchParams()
    queryParams.append('cycle', cycle)

    return `${pathname}?${queryParams.toString()}`
  }

  static getSearchPagePath(spaceId: string, searchWord?: string) {
    const pathname = generatePath(
      pageNameToPathTemplateMap[TypedPage.APP_HOME_SEARCH],
      { spaceId },
    )

    if (searchWord) {
      const queryParams = new URLSearchParams()
      queryParams.append('search-word', searchWord)
      return `${pathname}?${queryParams.toString()}`
    }

    return pathname
  }

  private getProjectIdFromQueryString() {
    const URLQueryString = new URLSearchParams(this.search)
    const spaceId = URLQueryString.get('project-id')

    if (!spaceId) {
      console.error(`Can't find 'space-id' from queryString in the path.`)
      return undefined
    }

    return spaceId
  }

  private getSpaceIdFromQueryString() {
    const URLQueryString = new URLSearchParams(this.search)
    const spaceId = URLQueryString.get('space-id')

    if (!spaceId) {
      console.error(`Can't find 'space-id' from queryString in the path.`)
      return undefined
    }

    return spaceId
  }

  private getSpaceIdFromParams(typedPage: TypedPage) {
    const spaceMatchPath = matchPath<{ spaceId: string }>(this.pathname, {
      path: pageNameToPathTemplateMap[typedPage],
    })

    const spaceId = spaceMatchPath?.params.spaceId
    return spaceId
  }

  private getPageName() {
    const entries = Object.entries(pathTemplateToPageNameMap)
    for (let i = 0; i < entries.length; i += 1) {
      const [pathTemplate, typedPage] = entries[i]
      if (matchPath(this.pathname, { path: pathTemplate })?.isExact) {
        return typedPage
      }
    }
  }

  private getDocumentIdFromParams(typedPage: TypedPage): string | undefined {
    const documentMatchPath = matchPath<{ documentId: string }>(this.pathname, {
      path: pageNameToPathTemplateMap[typedPage],
    })

    const documentId = documentMatchPath?.params.documentId
    return documentId
  }
}

export default autoBind(RouteManager)
