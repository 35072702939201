import colors from '@/styles/colors'

interface Radius {
  upperLeft: number
  upperRight: number
  lowerLeft: number
  lowerRight: number
}

function fillRadiusRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: Partial<Radius>,
  fill: boolean,
  stroke = true,
) {
  const cornerRadius: Radius = {
    upperLeft: radius['upperLeft'] ?? 0,
    upperRight: radius['upperRight'] ?? 0,
    lowerLeft: radius['lowerLeft'] ?? 0,
    lowerRight: radius['lowerRight'] ?? 0,
  }

  ctx.beginPath()
  ctx.moveTo(x + cornerRadius.upperLeft, y)
  ctx.lineTo(x + width - cornerRadius.upperRight, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + cornerRadius.upperRight)
  ctx.lineTo(x + width, y + height - cornerRadius.lowerRight)
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - cornerRadius.lowerRight,
    y + height,
  )
  ctx.lineTo(x + cornerRadius.lowerLeft, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - cornerRadius.lowerLeft)
  ctx.lineTo(x, y + cornerRadius.upperLeft)
  ctx.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y)
  ctx.closePath()
  if (fill) {
    ctx.fill()
  }
  if (stroke) {
    ctx.globalAlpha = 1
    ctx.stroke()
  }
}

function paintBackgroundGrid(ctx: CanvasRenderingContext2D) {
  var bw = 1500000
  var bh = 1500000
  var p = 10

  for (var x = -bw / 2; x <= bw / 2; x += 15) {
    ctx.moveTo(0.5 + x + p, p - bh / 2)
    ctx.lineTo(0.5 + x + p, bh + p)
  }

  for (var x = -bh / 2; x <= bh / 2; x += 15) {
    ctx.moveTo(p - bw / 2, 0.5 + x + p)
    ctx.lineTo(bw + p, 0.5 + x + p)
  }

  ctx.strokeStyle = colors.gray0(0.02)
  ctx.stroke()
}

export default { fillRadiusRect, paintBackgroundGrid }
