import { TypedDocumentType } from '@/models/document/TypedDocument'
import autoBind from '@/utils/autoBind'

class ViewerService {
  getGoogleViewerUrl(docType: TypedDocumentType, id: string, email?: string) {
    const getStringDocType = (docType: TypedDocumentType) => {
      switch (docType) {
        case 'document':
          return 'document'
        case 'spreadsheet':
          return 'spreadsheets'
        case 'presentation':
          return 'presentation'
        default:
          throw Error('Invalid doc type')
      }
    }

    const url = new URL(
      `https://docs.google.com/${getStringDocType(docType)}/d/${id}/edit`,
    )

    url.searchParams.append('rm', 'embedded')
    if (email) url.searchParams.append('authuser', email)

    return url.toString()
  }
}

export default autoBind(ViewerService)
