function nodeTextLength(node) {
  switch (node.nodeType) {
    case Node.ELEMENT_NODE:
    case Node.TEXT_NODE:
      return node.textContent.length
    default:
      return 0
  }
}

function previousSiblingsTextLength(node) {
  let sibling = node.previousSibling
  let length = 0
  while (sibling) {
    length += nodeTextLength(sibling)
    sibling = sibling.previousSibling
  }
  return length
}

class TextPosition {
  constructor(element, offset) {
    if (offset < 0) {
      throw new Error('Offset is invalid')
    }

    this.element = element
    this.offset = offset
  }

  relativeTo(parent) {
    if (!parent.contains(this.element)) {
      throw new Error('Parent is not an ancestor of current element')
    }

    let elem = this.element
    let offset = this.offset
    while (elem !== parent) {
      offset += previousSiblingsTextLength(elem)
      elem = elem.parentElement
    }

    return new TextPosition(elem, offset)
  }

  static fromPoint(node, offset) {
    switch (node.nodeType) {
      case Node.TEXT_NODE: {
        if (offset < 0 || offset > node.data.length) {
          throw new Error('Text node offset is out of range')
        }

        if (!node.parentElement) {
          throw new Error('Text node has no parent')
        }

        const textOffset = previousSiblingsTextLength(node) + offset
        return new TextPosition(node.parentElement, textOffset)
      }

      case Node.ELEMENT_NODE: {
        if (offset < 0 || offset > node.childNodes.length) {
          throw new Error('Child node offset is out of range')
        }

        let textOffset = 0
        for (let i = 0; i < offset; i++) {
          textOffset += nodeTextLength(node.childNodes[i])
        }

        return new TextPosition(node, textOffset)
      }

      default:
        throw new Error('Point is not in an element or text node')
    }
  }
}

export default TextPosition
