import { AxiosInstance } from 'axios'
import { ITypedFolder, TypedFolder } from '@/models/folder'
import autoBind from '@/utils/autoBind'

export interface IFoldersToBeUpdated {
  [folderId: string]: {
    prevFolder?: string
    nextFolder?: string
    movingFolder?: boolean
  }
}

class FolderAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getSpecificFolder(
    folderId: string,
    spaceId?: string,
  ): Promise<TypedFolder> {
    const {
      data,
      config: { headers },
    } = await this.axios({
      method: 'get',
      url: `/api/folders/${folderId}`,
      headers: spaceId ? { 'x-space-id': spaceId } : {},
    })

    const spaceIdFromHeaders = (headers ? headers['x-space-id'] : '') as string

    return TypedFolder.fromJSON(data, spaceIdFromHeaders)
  }

  async getDocumentFolders(documentId: string): Promise<TypedFolder[]> {
    const {
      data,
      config: { headers },
    } = await this.axios({
      method: 'get',
      url: '/api/folders',
      params: {
        documentId,
      },
    })
    const spaceIdFromHeaders = (headers ? headers['x-space-id'] : '') as string

    return (data as ITypedFolder[]).map((folder) =>
      TypedFolder.fromJSON(folder, spaceIdFromHeaders),
    )
  }

  async createFolder(documentId: string): Promise<TypedFolder> {
    const {
      data,
      config: { headers },
    } = await this.axios({
      method: 'post',
      url: '/api/folders',
      data: {
        documentId,
      },
    })
    const spaceIdFromHeaders = (headers ? headers['x-space-id'] : '') as string

    return TypedFolder.fromJSON(data, spaceIdFromHeaders)
  }

  // FIXME: repository 가 적용될 때, folderData가 TypedFolder가 되도록 변경 필요
  async updateFolder(folderId: string, folderData: any): Promise<void> {
    await this.axios({
      method: 'patch',
      url: `/api/folders/${folderId}`,
      data: {
        ...folderData,
      },
    })
  }

  async updateFolders(folderList: IFoldersToBeUpdated): Promise<void> {
    await this.axios({
      method: 'patch',
      url: '/api/folders',
      data: {
        data: folderList,
        re_order: true,
      },
    })
  }

  async deleteFolder(documentId: string, folderId: string): Promise<void> {
    await this.axios({
      method: 'delete',
      url: `/api/docs/${documentId}/folders/${folderId}`,
    })
  }
}

export default autoBind(FolderAPI)
