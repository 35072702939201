export class SpaceError extends Error {
  constructor(readonly name = 'SpaceError', message: string) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class EditorCountQuotaExceededError extends SpaceError {
  constructor() {
    super('EditorCountQuotaExceededError', 'Editor count quota exceeded!')
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class SpaceNotExistError extends SpaceError {
  constructor() {
    super('SpaceNotExistError', 'Space does not exist!')
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class SpaceCantGetUsage extends SpaceError {
  constructor() {
    super('SpaceCantGetUsage', 'Space Cant get Usage!')
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
