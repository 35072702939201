export enum NotificationType {
  TASK_ASSIGNED = 'tasks/assigned',
  REVIEW_ASSIGNED = 'reviews/assigned',
  REVIEW_CHECKED = 'reviews/checked',
  PROJECT_INVITED = 'projects/invited',
  COMMENT_CREATED = 'comments/created',
}

export interface INotification {
  id: string
  type: NotificationType
  checked: boolean
  spaceId: string
}

export abstract class Notification implements INotification {
  id: string
  type: NotificationType
  checked: boolean
  spaceId: string

  constructor(
    id: string,
    type: NotificationType,
    checked: boolean,
    spaceId: string,
  ) {
    this.id = id
    this.type = type
    this.checked = checked
    this.spaceId = spaceId
  }

  abstract copyWith(options?: Partial<INotification>): Notification
}
