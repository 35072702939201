import { AxiosInstance } from 'axios'
import { TypedInbox } from '@/models/inbox'
import {
  ITypedFileResource,
  TypedFileResource,
} from '@/models/resource/TypedFileResource'
import autoBind from '@/utils/autoBind'
import TypedUrlResource, {
  ITypedUrlResource,
} from '@/models/resource/TypedUrlResource'
import { getResourceInstanceByJson } from '@/factories/getResourceInstanceByJson'

export type FileWithMd5Hash = File & { md5Hash: string }

class InboxAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getInboxData(): Promise<TypedInbox> {
    const response = await this.axios({
      method: 'get',
      url: '/api/v2/folders/inbox',
    })

    return TypedInbox.fromJSON({
      ...response.data,
      resourceList: response.data.resourceList.map(
        (resource: ITypedUrlResource | ITypedFileResource) =>
          getResourceInstanceByJson(resource),
      ),
    })
  }

  async addUrlNode(url: string): Promise<TypedUrlResource> {
    const response = await this.axios({
      method: 'post',
      url: '/api/v2/resources/inbox',
      data: {
        type: 'url',
        data: { url },
      },
    })

    return TypedUrlResource.fromJSON(response.data)
  }

  async updateInboxResourceName(resourceId: string, name: string) {
    await this.axios({
      method: 'PATCH',
      url: `/api/v2/resources/inbox/${resourceId}`,
      data: {
        name,
      },
    })
  }

  async addFileNodes(file: FileWithMd5Hash) {
    const form = buildFileFormData({ file })

    const response = await this.axios.post('/api/v2/resources/inbox', form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })

    return TypedFileResource.fromJSON(response.data)
  }
}

export default autoBind(InboxAPI)

const buildFileFormData = (data: { file: File }) => {
  const formData = new FormData()
  formData.append('type', 'file')
  formData.append('file', data.file)
  return formData
}
