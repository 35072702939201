export const formatBytes = (bytes: number): string => {
  let stringValue = ''
  let unit = ''
  if (bytes >= 1024 ** 3) {
    stringValue = (bytes / 1024 ** 3).toFixed(2)
    unit = 'GB'
  } else if (bytes >= 1024 ** 2) {
    stringValue = (bytes / 1024 ** 2).toFixed(2)
    unit = 'MB'
  } else if (bytes >= 1024) {
    stringValue = (bytes / 1024).toFixed(2)
    unit = 'KB'
  } else {
    stringValue = bytes.toFixed(2)
    unit = 'B'
  }

  if (stringValue.endsWith('.00')) {
    stringValue = stringValue.slice(0, -3)
  }

  return `${stringValue}${unit}`
}
