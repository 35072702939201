// 참고 자료: https://engineering.linecorp.com/ko/blog/typescript-enum-tree-shaking/
const USER_EVENT = {
  SIGNUP_COMPLETED: 'user_signup_completed',
  SIGNIN_COMPLETED: 'user_signed_in',
  USER_PROFILE_CLICKED: 'user_profile_clicked',
  PLANS_AND_BILLING_TAB_CLICKED: 'plans_and_billing_tab_clicked',
  MY_ACCOUNT_TAB_CLICKED: 'my_account_tab_clicked',
  USER_LANGUAGE_TAB_CLICKED: 'user_language_tab_clicked',
  UPGRADE_TO_STANDARD_BUTTON_CLICKED: 'upgrade_to_standard_button_clicked',
  CUSTOMER_HELP_CLICKED: 'customer_help_clicked',
} as const

const RESOURCE_EVENT = {
  CREATED: 'resource_created',
  DELETED: 'resource_deleted',
  SPLIT_VIEWER_READ_MODE_TOGGLED: 'split_viewer_readmode_toggled',
  RENAMED: 'resource_renamed',
  MOVED_INBOX_TO_DOCUMENT: 'resource_moved_inbox_to_document',
  LINK: 'resource_link',
  CLIPBOARD_TOOLTIP_CLICKED: 'clipboard_tooltip_clicked',
  CLIPBOARD_PASTE_BUTTON_CLICKED: 'clipboard_paste_button_clicked',
  RESOURCE_DOWNLOADED: 'resource_downloaded',
  RESOURCE_READ_BUTTON_CLICKED: 'resource_read_button_clicked',
  ADD_TO_PROJECT_CLICKED: 'add_to_project_clicked',
} as const

enum RESOURCE_TAG {
  READ = 'read',
  UNREAD = 'unread',
}

const FOLDER_EVENT = {
  CREATED: 'folder_created',
  DELETED: 'folder_deleted',
} as const

const DOCUMENT_EVENT = {
  CREATED: 'document_created',
  DELETED: 'document_deleted',
  DOCUMENT_STATUS_CHANGED: 'document_status_changed',
  DOCUMENT_LIST_ADD_DOCUMENT_BUTTON_CLICKED:
    'document_list_add_document_button_clicked',
  MY_HOME_CREATED_BY_ME_ANCHOR_RESOURCE_CLICKED:
    'my_home_created_by_me_anchor_resource_clicked',
  MY_HOME_RECENTLY_VIEWED_ANCHOR_RESOURCE_CLICKED:
    'my_home_recently_viewed_anchor_resource_clicked',

  DOCUMENT_PAGE_HISTORY_BUTTON_CLICKED: 'document_page_history_button_clicked',
  DOCUMENT_PAGE_HISTORY_ADD_TO_LIBRARY: 'document_page_history_add_to_library',
  DOCUMENT_PAGE_BACKLINK_INFO_CLICKED: 'document_page_backlink_info_clicked',
  DOCUMENT_PAGE_BACKLINK_ADD_TO_LIBRARY:
    'document_page_backlink_add_to_library',
  DOCUMENT_PAGE_BACKLINK_OPEN_RESOURCE: 'document_page_backlink_open_resource',
  DOCUMENT_PAGE_PAGE_VIEWS_CLICKED: 'document_page_page_views_clicked',
  DOCUMENT_PAGE_SHARE_CLICKED: 'document_page_share_clicked',
  DOCUMENT_PAGE_SHARE_INTERNAL_URL_COPY:
    'document_page_share_internal_url_copy',
  DOCUMENT_MOVED_TO_OTHER_PROJECT: 'document_moved_to_other_project',

  ANCHOR_RESOURCE_PREVIEW_VIEWED: 'anchor_resource_preview_viewed',
} as const

const PROJECT_EVENT = {
  CREATED: 'project_created',
  DELETED: 'project_deleted',
  PROJECT_VIEWED: 'project_viewed',
  PROJECT_FAVORITE_CLICKED: 'project_favorite_clicked',
  PROJECT_FAVORITE_ADDED: 'project_favorite_added',
} as const

const HIGHLIGHT_EVENT = {
  CREATED: 'highlight_created',
  DELETED: 'highlight_deleted',
  VIEWED: 'highlight_viewed',
  COPIED: 'highlight_copied',
} as const

const TASK_EVENT = {
  CREATED: 'task_created',
  DOCUMENT_PAGE_TASK_CREATED: 'document_page_task_created',
  MY_HOME_TASK_CREATED: 'my_home_task_created',
  DELETED: 'task_deleted',
  TASK_GO_TO_DOCUMENT_BUTTON_CLICKED: 'task_go_to_document_button_clicked',
  TASK_DONE_STATUS_CHANGED: 'task_done_status_changed',
  TASK_DUE_DATE_ASSIGNED: 'task_due_date_assigned',
  TASK_OWNER_ASSIGNED: 'task_owner_assigned',
  MY_HOME_TASK_ANCHOR_RESOURCE_ASSIGNED:
    'my_home_task_anchor_resource_assigned',
  MY_HOME_TASK_PROJECT_ASSIGNED: 'my_home_task_project_assigned',
  TASK_PUSHED_TO_CALENDAR: 'task_pushed_to_calendar',
} as const

const PAGE_EVENT = {
  MY_HOME_VIEWED: 'my_home_viewed',
  SIGNUP_VIEWED: 'signup_viewed',
  SIGNUP_CLOSED: 'signup_closed',
  SIGNIN_VIEWED: 'signin_viewed',
  SIGNIN_CLOSED: 'signin_closed',
  DOCUMENT_PAGE_VIEWED: 'document_page_viewed',
  DOCUMENT_PAGE_CLOSED: 'document_page_closed',
  APPHOME_INBOX_RESOURCE_VIEWED: 'apphome_inbox_resource_viewed',
  APPHOME_INBOX_RESOURCE_CLOSED: 'apphome_inbox_resource_closed',
  DOCUMENT_PAGE_RESOURCE_VIEWED: 'document_page_resource_viewed',
  DOCUMENT_PAGE_RESOURCE_CLOSED: 'document_page_resource_closed',
  CURRENT_LIBRARY_RECOMMENDED_RESOURCES_SECTION_OPEN:
    'current_library_recommended_resources_section_open',
  CURRENT_LIBRARY_RECOMMENDED_RESOURCES_CLICKED:
    'current_library_recommended_resources_clicked',
  CURRENT_LIBRARY_RECOMMENDED_RESOURCES_ADD_TO_LIBRARY:
    'current_library_recommended_resources_add_to_library',
  CURRENT_LIBRARY_RESOURCE_TYPE_SELECTED:
    'current_library_resource_type_selected',
  CURRENT_LIBRARY_SORTING_SELECTED: 'current_library_sorting_selected',
  SEARCH_TAB_RECOMMENDED_RESOURCES_CLICKED:
    'document_page_search_tab_recommended_resources_clicked',
  SEARCH_TAB_RECOMMENDED_RESOURCES_ADD_TO_LIBRARY:
    'document_page_search_tab_recommended_resources_add_to_library',
} as const

const OTHER_EVENT = {
  EXTENSION_ADD_BUTTON_CLICKED: 'extension_add_button_clicked',
  OPENED_IN_DRIVE_BUTTON_CLICKED: 'opened_in_drive_button_clicked',
  ADD_DOCUMENT_BUTTON_CLICKED: 'add_document_button_clicked',
  ADD_NEW_BUTTON_CLICKED: 'add_new_button_clicked',
  BACKLINK_OTHER_LIBRARY_CLICKED: 'backlink_other_library_clicked',
  RESOURCE_LIBRARY_LOCK_BUTTON_CLICKED: 'resource_library_lock_button_clicked',
  RESOURCE_LIBRARY_UNLOCK_BUTTON_CLICKED:
    'resource_library_unlock_button_clicked',
  EMPTY_FOLDER_ADD_RESOURCE_BUTTON_CLICKED:
    'empty_folder_add_resource_button_clicked',
  LIBRARY_PANEL_BUTTON_CLICKED: 'library_panel_button_clicked',
  DOCUMENT_PAGE_CHANGED: 'document_page_changed',
  WORKSPACE_ADD_DOCUMENT_BUTTON_CLICKED:
    'workspace_add_document_button_clicked',
  SIGN_UP_WITH_GOOGLE_BUTTON_CLICKED: 'sign_up_with_google_button_clicked',
  SIGN_IN_WITH_GOOGLE_BUTTON_CLICKED: 'sign_in_with_google_button_clicked',
} as const

const INBOX_EVENT = {
  INBOX_TAB_CLICKED: 'inbox_tab_clicked',
  INBOX_RESOURCE_PREVIOUS_BUTTON_CLICKED:
    'inbox_resource_previous_button_clicked',
  INBOX_RESOURCE_NEXT_BUTTON_CLICKED: 'inbox_resource_next_button_clicked',
  INBOX_RESOURCE_DELETE_BUTTON_CLICKED: 'inbox_resource_delete_button_clicked',
  INBOX_MOVE_RESOURCE_BUTTON_CLICKED: 'inbox_move_resource_button_clicked',
} as const

const SPACE_EVENT = {
  MY_HOME_TAB_CLICKED: 'my_home_tab_clicked',
  SPACE_CREATED: 'space_created',
  INVITE_MEMBERS_SKIP_BUTTON_CLICKED: 'invite_members_skip_button_clicked',
  INVITE_MEMBERS_BUTTON_CLICKED: 'invite_members_button_clicked',
  ADD_MEMBER_INVITE_BUTTON_CLICKED: 'add_member_invite_button_clicked',
  SPACE_DELETED: 'space_deleted',
  LEAVE_SPACE_BUTTON_CLICKED: 'leave_space_button_clicked',
} as const

const SEARCH_EVENT = {
  SEARCH_RESULT_RETURNED: 'search_result_returned',
  SEARCH_RESULT_CLICKED: 'search_result_clicked',
  SEARCH_GIVE_FEEDBACK_CLICKED: 'search_give_feedback_clicked',
  APP_HOME_SEARCH_DROPDOWN_OPENED: 'app_home_search_dropdown_opened',
  APP_HOME_SEARCH_RESULT_RETURNED: 'app_home_search_result_returned',
  APP_HOME_SEARCH_RESULT_CLICKED: 'app_home_search_result_clicked',
  APP_HOME_SEARCH_RESULT_TAB_CLICKED: 'app_home_search_result_tab_clicked',
  APP_HOME_SEARCH_RESULT_RENAME: 'app_home_search_result_rename',
  APP_HOME_SEARCH_RESULT_DELETE: 'app_home_search_result_delete',
  APP_HOME_SEARCH_RESULT_PREVIEW: 'app_home_search_result_preview',
  APP_HOME_SEARCH_QUERY_SUBMIT: 'app_home_search_query_submit',
  APP_HOME_SEARCH_RECENT_SEARCHES: 'app_home_search_recent_searches',
  APP_HOME_SEARCH_RESULT_FILTERED: 'app_home_search_result_filtered',
  APP_HOME_SEARCH_RESULT_FAVORITE: 'app_home_search_result_favorite',
  APP_HOME_SEARCH_RESULT_DUPLICATE: 'app_home_search_result_duplicate',
  LIBRARY_PANEL_SEARCH_BAR_CLICKED: 'library_panel_search_bar_clicked',
  LIBRARY_PANEL_SEARCH_QUERY_SUBMIT: 'library_panel_search_query_submit',
  LIBRARY_PANEL_SEARCH_RESULT_FILTERED: 'library_panel_search_result_filtered',
}

const NETWORK_EVENT = {
  NETWORK_VIEW_TOGGLED: 'network_view_toggled',
  NETWORK_VIEW_RENDER_START: 'network_view_render_start',
  NETWORK_VIEW_LOADED: 'network_view_loaded',
  NETWORK_VIEW_EXIT: 'network_view_exit',
  NETWORK_NODE_CLICKED: 'network_node_clicked',
  NETWORK_HIDE_PREVIEW: 'network_hide_preview',
  NETWORK_SHOW_PREVIEW: 'network_show_preview',
  NETWORK_GO_TO_DOCUMENT_PAGE: 'network_go_to_document_page',
  NETWORK_SPANNED: 'network_spanned',
}

const HOMEPAGE_EVENT = {
  HOMEPAGE_VIEWED: 'homepage_viewed',
  HOMEPAGE_PRICING_PAGE_VIEWED: 'homepage_pricing_page_viewed',
  HOMEPAGE_LOGIN_PAGE_VIEWED: 'homepage_login_page_viewed',
  HOMEPAGE_SIGNUP_PAGE_VIEWED: 'homepage_signup_page_viewed',
  HOMEPAGE_FEATURE_PAGE_VIEWED: 'homepage_feature_page_viewed',
  HOMEPAGE_CREATOR_PAGE_VIEWED: 'homepage_creator_page_viewed',
  HOMEPAGE_STUDENT_PAGE_VIEWED: 'homepage_student_page_viewed',
  HOMEPAGE_INVESTOR_PAGE_VIEWED: 'homepage_investor_page_viewed',
  HOMEPAGE_FOUNDER_PAGE_VIEWED: 'homepage_founder_page_viewed',
}

const GROUP_EVENT = {
  GROUP_CREATED: 'group_created',
  GROUP_DELETED: 'group_deleted',
}

const PUBLIC_DOCUMENT_EVENT = {
  PUBLIC_DOCUMENT_PAGE_VIEWED: 'public_document_page_viewed',
  PUBLIC_DOCUMENT_PAGE_CLOSED: 'public_document_page_closed',
  PUBLIC_DOCUMENT_RESOURCE_VIEWED: 'public_workspace_resource_viewed',
  PUBLIC_DOCUMENT_PAGE_TRY_TYPED_BUTTON_CLICKED:
    'public_document_page_try_typed_butoon_clicked',
} as const

const TEMPLATE_EVENT = {
  CREATED: 'template_created',
} as const

const IN_TEXT_CITATION_EVENT = {
  CURRENT_LIBRARY_CITATION_LINK_COPY: 'current_library_citation_link_copy',
  CITATION_SPLIT_VIEW_OPEN: 'citation_split_view_open',
} as const

const TYPED_NOTE_EVENT = {
  CREATE_TYPED_NOTE_AS_REFERENCE: 'create_typed_note_as_reference',
  TYPED_NOTE_CITATION_ADDED: 'typed_note_citation_added',
  TYPED_NOTE_CITATION_CLICKED: 'typed_note_citation_clicked',
} as const

const REVIEW_EVENT = {
  REQUEST_REVIEW_BUTTON_CLICKED: 'request_review_button_clicked',
  REQUEST_REVIEW_SENT: 'request_review_sent',
  REVIEW_COMPLETE_BUTTON_CLICKED: 'review_completed',
  REVIEW_CANCELLED_BUTTON_CLICKED: 'review_cancelled',
  REVIEW_REQUEST_PAGE_VIEWED: 'review_request_page_viewed',
  REVIEW_REQUEST_PAGE_SENT_TAB_VIEWED: 'review_request_page_sent_tab_viewed',
  REVIEW_REQUEST_PAGE_RECEIVED_TAB_VIEWED:
    'review_request_page_received_tab_viewed',
  REVIEW_REQUEST_PAGE_RECEIVED_TAB_REVIEW_CLICKED:
    'review_request_page_received_tab_review_clicked',
  REVIEW_REQUEST_PAGE_SENT_TAB_REVIEW_CLICKED:
    'review_request_page_sent_tab_review_clicked',
  REVIEW_REQUEST_PANEL_RECEIVED_TAB_CLICKED:
    'review_request_panel_received_tab_clicked',
  REVIEW_REQUEST_PANEL_SENT_TAB_CLICKED:
    'review_request_panel_sent_tab_clicked',
  REVIEW_REQUEST_PANEL_COMPLETED_FILTER_CLICKED:
    'review_request_panel_completed_filter_clicked',
  REVIEW_REQUEST_PANEL_IN_REVIEW_FILTER_CLICKED:
    'review_request_panel_in_review_filter_clicked',
  REVIEW_REQUEST_PANEL_REVIEW_COMMENTED:
    'review_request_panel_review_commented',
  REVIEW_REQUEST_PANEL_REVIEW_COMMENT_DELETED:
    'review_request_panel_review_comment_deleted',
  REVIEW_REQUEST_PANEL_NEW_REQUEST_CLICKED:
    'review_request_panel_new_request_clicked',
} as const

const NOTIFICATION_EVENT = {
  NOTIFICATION_TAB_CLICKED: 'notification_tab_clicked',
  NOTIFICATION_ASSET_CLICKED: 'notification_asset_clicked',
  NOTIFICATION_REVIEW_ASSIGNED_CLICKED: 'notification_review_assigned_clicked',
  NOTIFICATION_REVIEW_COMPLETED_CLICKED:
    'notification_review_completed_clicked',
} as const

type USER_EVENT = typeof USER_EVENT[keyof typeof USER_EVENT]
type RESOURCE_EVENT = typeof RESOURCE_EVENT[keyof typeof RESOURCE_EVENT]
type FOLDER_EVENT = typeof FOLDER_EVENT[keyof typeof FOLDER_EVENT]
type DOCUMENT_EVENT = typeof DOCUMENT_EVENT[keyof typeof DOCUMENT_EVENT]
type PROJECT_EVENT = typeof PROJECT_EVENT[keyof typeof PROJECT_EVENT]
type HIGHLIGHT_EVENT = typeof HIGHLIGHT_EVENT[keyof typeof HIGHLIGHT_EVENT]
type TASK_EVENT = typeof TASK_EVENT[keyof typeof TASK_EVENT]
type PAGE_EVENT = typeof PAGE_EVENT[keyof typeof PAGE_EVENT]
type OTHER_EVENT = typeof OTHER_EVENT[keyof typeof OTHER_EVENT]
type INBOX_EVENT = typeof INBOX_EVENT[keyof typeof INBOX_EVENT]
type SPACE_EVENT = typeof SPACE_EVENT[keyof typeof SPACE_EVENT]
type SEARCH_EVENT = typeof SEARCH_EVENT[keyof typeof SEARCH_EVENT]
type NETWORK_EVENT = typeof NETWORK_EVENT[keyof typeof NETWORK_EVENT]
type GROUP_EVENT = typeof GROUP_EVENT[keyof typeof GROUP_EVENT]
type PUBLIC_DOCUMENT_EVENT =
  typeof PUBLIC_DOCUMENT_EVENT[keyof typeof PUBLIC_DOCUMENT_EVENT]
type HOMEPAGE_EVENT = typeof HOMEPAGE_EVENT[keyof typeof HOMEPAGE_EVENT]
type TEMPLATE_EVENT = typeof TEMPLATE_EVENT[keyof typeof TEMPLATE_EVENT]
type IN_TEXT_CITATION_EVENT =
  typeof IN_TEXT_CITATION_EVENT[keyof typeof IN_TEXT_CITATION_EVENT]
type REVIEW_EVENT = typeof REVIEW_EVENT[keyof typeof REVIEW_EVENT]
type TYPED_NOTE_EVENT = typeof TYPED_NOTE_EVENT[keyof typeof TYPED_NOTE_EVENT]
type NOTIFICATION_EVENT =
  typeof NOTIFICATION_EVENT[keyof typeof NOTIFICATION_EVENT]

export {
  USER_EVENT,
  RESOURCE_EVENT,
  RESOURCE_TAG,
  FOLDER_EVENT,
  DOCUMENT_EVENT,
  PROJECT_EVENT,
  HIGHLIGHT_EVENT,
  TASK_EVENT,
  PAGE_EVENT,
  OTHER_EVENT,
  INBOX_EVENT,
  SPACE_EVENT,
  SEARCH_EVENT,
  NETWORK_EVENT,
  GROUP_EVENT,
  PUBLIC_DOCUMENT_EVENT,
  HOMEPAGE_EVENT,
  TEMPLATE_EVENT,
  IN_TEXT_CITATION_EVENT,
  REVIEW_EVENT,
  TYPED_NOTE_EVENT,
  NOTIFICATION_EVENT,
}
