import css from '@emotion/css'
import colors from './colors'

export default css`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    font: inherit;
  }

  html,
  body,
  #root {
    height: 100%;
    margin: 0;
    font-family: 'Pretendard', 'Sans-serif';
    font-size: 10px; /* root font size for rem */
  }

  strong {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }

  #root {
    overflow-x: hidden;
  }

  button {
    all: unset;
    border: none;
    font: inherit;
    cursor: pointer;
    box-sizing: border-box;
  }

  button:focus,
  video:focus {
    outline: 0;
  }

  [contenteditable] {
    outline: 0px solid transparent;
    overflow: auto;
    -ms-overflow-style: none;
  }

  [contenteditable]::-webkit-scrollbar {
    display: none;
  }

  /* split.js */
  .gutter.gutter-horizontal {
    flex-shrink: 0;
    background: ${colors.gray77()};
    cursor: col-resize;
  }

  a {
    text-decoration: none;
  }

  hr {
    height: 1px;
    margin: 0;
    border: 0;
    border-top: 1px solid ${colors.gray0()};
  }

  * {
    ::-webkit-scrollbar {
      display: none !important;
    }

    &:hover::-webkit-scrollbar {
      display: block !important;
      width: 6px !important;
      height: 6px !important;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 3px !important;
      background: ${colors.gray77()} !important;
    }
  }
`
