import GroupAPI from '@/api/groupAPI'
import { GroupEvent } from '@/integrations/logging/event/group'
import TypedGroup from '@/models/group'
import GroupRepository from '@/repositories/groupRepository'
import ProjectRepository from '@/repositories/projectRepository'
import store from '@/store'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'

class GroupService {
  constructor(
    private groupRepo: GroupRepository,
    private groupAPI: GroupAPI,
    private projectRepo: ProjectRepository,
    private groupEvent: GroupEvent,
  ) {}

  async createGroup(name: string): Promise<TypedGroup> {
    const newGroup = await this.groupAPI.createGroup(name)
    this.groupRepo.add(newGroup)

    this.groupEvent.createdEvent({
      spaceId: newGroup.spaceId,
      groupId: newGroup.id,
    })

    return newGroup
  }

  async renameGroup(id: string, newName: string): Promise<void> {
    try {
      this.groupRepo.update(id, { name: newName })
      await this.groupAPI.renameGroup(id, newName)
    } catch (error: unknown) {
      sendErrorLog('Error occurred when user tried to rename group', {
        id,
        name: newName,
        error,
      })
      alert('잠시 후 다시 시도하세요.')
    }
  }

  async deleteGroup(id: string): Promise<void> {
    try {
      const groupToBeDeleted = this.groupRepo.find(id)

      if (!groupToBeDeleted) {
        sendErrorLog('No group to be deleted error.', {
          id,
        })
        alert('삭제할 그룹이 없습니다.')
        return
      }

      const projects = this.projectRepo.findAll()
      const projectsInGroup = projects.filter(
        (project) => project.groupId === groupToBeDeleted.id,
      )

      const defaultGroupId = store.getState().group.defaultGroupId

      if (!defaultGroupId) {
        sendErrorLog('No default group', {
          id,
          defaultGroupId,
        })
        alert('기본 그룹을 찾을 수 없습니다.')
        return
      }

      projectsInGroup.forEach((project) =>
        this.projectRepo.update(project.projectId, { groupId: defaultGroupId }),
      )

      this.groupEvent.deletedEvent({
        groupId: id,
      })

      await this.groupAPI.deleteGroup(id)
      this.groupRepo.delete(id)
    } catch (error: unknown) {
      sendErrorLog('Error occurred when user tried to delete group', {
        id,
        error,
      })
      alert('잠시 후 다시 시도하세요.')
    }
  }
}

export default GroupService
