import { NodeObject } from 'react-force-graph-2d'
import { NETWORK_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'
import { INode } from '@/models/network'
import { TypedDocumentResource } from '@/models/resource/TypedDocumentResource'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class NetworkEvent extends TypedEvent {
  networkViewToggled(data: { spaceId: string; projectId?: string }) {
    this.sendEvent(NETWORK_EVENT.NETWORK_VIEW_TOGGLED, data)
  }

  networkViewRenderStart(data: { spaceId: string; projectId?: string }) {
    this.sendEvent(NETWORK_EVENT.NETWORK_VIEW_RENDER_START, data)
  }

  networkViewLoaded(data: {
    spaceId: string
    projectId?: string
    took: number
    numNodes: number
    numDocuments: number
    numLinks: number
    zoomScale?: number
  }) {
    this.sendEvent(NETWORK_EVENT.NETWORK_VIEW_LOADED, data)
  }

  networkViewExit(data: {
    spaceId: string
    projectId?: string
    timeStayed: number
  }) {
    this.sendEvent(NETWORK_EVENT.NETWORK_VIEW_EXIT, data)
  }

  networkNodeClicked(data: {
    spaceId: string
    projectId?: string
    zoomScale: number
    nodeInfo: INode
  }) {
    this.sendEvent(NETWORK_EVENT.NETWORK_VIEW_EXIT, data)
  }

  networkShowPreview(data: {
    spaceId: string
    projectId?: string
    nodeInfo: INode
    zoomScale: number
  }) {
    this.sendEvent(NETWORK_EVENT.NETWORK_SHOW_PREVIEW, data)
  }

  networkHidePreview(data: {
    spaceId: string
    projectId?: string
    nodeInfo?: INode
    zoomScale: number
  }) {
    this.sendEvent(NETWORK_EVENT.NETWORK_HIDE_PREVIEW, data)
  }

  networkGoToDocumentPage(data: {
    spaceId: string
    projectId?: string
    nodeInfo: TypedDocumentResource
    zoomScale: number
  }) {
    this.sendEvent(NETWORK_EVENT.NETWORK_GO_TO_DOCUMENT_PAGE, data)
  }

  networkSpanned(data: {
    spaceId: string
    projectId: string
    zoomScale: number
    from: NodeObject
    to: NodeObject
  }) {
    this.sendEvent(NETWORK_EVENT.NETWORK_SPANNED, data)
  }
}
