import { AxiosInstance } from 'axios'
import { TypedNetwork } from '@/models/network'
import autoBind from '@/utils/autoBind'

class KnAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getAllProjectsNetworkBySpaceId(spaceId: string): Promise<TypedNetwork> {
    const response = await this.axios({
      method: 'get',
      url: `/kn/api/graph/total/space/${spaceId}`,
    })

    return TypedNetwork.fromJSON({ ...response.data })
  }

  async getProjectNetwork(
    projectId: string,
  ): Promise<TypedNetwork | undefined> {
    if (!projectId) return
    const response = await this.axios({
      method: 'get',
      url: `/kn/api/graph/resources/${projectId}`,
    })

    return TypedNetwork.fromJSON({
      ...response.data,
      currentProjectId: projectId,
    })
  }
}

export default autoBind(KnAPI)
