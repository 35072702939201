import { ILoggingClient } from '@/integrations/logging/client'
import store from '@/store'
import RouteManager from '@/utils/routeManager'

export default class TypedEvent {
  constructor(private readonly clients: ILoggingClient[]) {}

  sendEvent(...[eventName, property]: Parameters<ILoggingClient['sendEvent']>) {
    const routeManager = new RouteManager(
      window.location.pathname,
      window.location.search,
    )

    const { data: userData } = store.getState().user

    this.clients.forEach((client) => {
      client.sendEvent(eventName, {
        uid: userData?.uid,
        Email: userData?.email,
        anchorResourceId: routeManager.getDocumentIdFromPathname(),
        projectId: routeManager.getProjectIdFromPathName(),
        spaceId: routeManager.getSpaceIdFromPathname(),
        timestamp: new Date().toISOString(),
        ...property,
      })
    })
  }
}
