import TaskAPI from '@/api/taskAPI'
import TypedTask, { GetTasksFilter, UpdateTaskParams } from '@/models/task'
import autoBind from '@/utils/autoBind'
import { TaskEvent } from '@/integrations/logging/event/task'
import AuthAPI, { googleTaskScope } from '@/api/authAPI'
import Pagination from '@/models/pagination/Pagination'

class TaskService {
  constructor(
    private taskAPI: InstanceType<typeof TaskAPI>,
    private authAPI: InstanceType<typeof AuthAPI>,
    private taskEvent: TaskEvent,
  ) {}

  async updateTask(taskId: string, params: UpdateTaskParams) {
    await this.taskAPI.updateTask(taskId, params)
  }

  async deleteTask(taskId: string) {
    await this.taskAPI.deleteTaskItem(taskId)
  }

  async exportTaskToGoogleCalendar(taskId: string) {
    await this.authAPI.getGoogleAccessToken([googleTaskScope])
    await this.taskAPI.exportToGoogleCalendar(taskId)
  }

  async createTask({
    projectId,
    documentId,
    content,
    assigneeId,
  }: {
    projectId?: string
    documentId?: string
    content: string
    assigneeId?: string
  }) {
    const createdTask = await this.taskAPI.createTaskItem({
      projectId,
      documentId,
      content,
      assigneeId,
    })
    this.taskEvent.createdEvent(
      createdTask.spaceId,
      createdTask.projectId,
      createdTask.documentId,
      createdTask.taskId,
    )
    return createdTask
  }

  async getTasks(filter: GetTasksFilter): Promise<Pagination<TypedTask>> {
    const result = await this.taskAPI.getTasks(filter)
    return result
  }
}

export default autoBind(TaskService)
