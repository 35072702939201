import { AxiosInstance, AxiosResponse } from 'axios'
import autoBind from '@/utils/autoBind'
import { getResourceInstanceByJson } from '@/factories/getResourceInstanceByJson'
import TypedResource, { ITypedResource } from '@/models/resource/TypedResource'
import TypedProject, { ITypedProject } from '@/models/project'
import { ITypedDocument, TypedDocument } from '@/models/document/TypedDocument'
import { getDocumentInstanceByJson } from '@/factories/getDocumentInstanceByJson'
import PaginationWithTotal from '@/models/pagination/PaginationWithTotal'
import { SortFilterType } from '@/models/SortFilter'

interface DocumentSearchResultDto {
  data: {
    readonly documents: ITypedDocument[]
    readonly token: string
    readonly highlights: any[]
    readonly total: number
  }
}

interface ProjectSearchResultDto {
  data: {
    readonly total: number
    readonly projects: ITypedProject[]
    readonly token: string
  }
}

interface ResourceSearchResultDTO {
  data: {
    readonly total: number
    readonly resources: ITypedResource[]
    readonly token: string
  }
}
export interface ProjectSearchParams {
  searchWord: string
  searchTokenForNext?: string
  spaceId: string
}

export interface ResourceSearchParams {
  searchWord: string
  searchTokenForNext?: string
  resourceType?: string | string[]
  createdBy?: string
  orderBy?: SortFilterType
  hasWorkspace?: boolean
}

export interface DocumentSearchParams {
  searchWord: string
  searchTokenForNext?: string
  documentType?: string | string[]
  createdBy?: string
  orderBy?: SortFilterType
}

class SearchAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getDocumentSearchResult({
    searchWord,
    searchTokenForNext,
    documentType,
    createdBy,
    orderBy = 'default',
  }: DocumentSearchParams) {
    const response: AxiosResponse<DocumentSearchResultDto> = await this.axios({
      method: 'get',
      url: `/api/search/document`,
      headers: {
        'search-version': 1,
      },
      params: {
        keyword: searchWord,
        token: searchTokenForNext,
        orderBy,
        documentType,
        createdBy,
      },
    })

    return PaginationWithTotal.fromJSON<TypedDocument>({
      cursor:
        response.data.data.token === '' ? undefined : response.data.data.token,
      total: response.data.data.total,
      data: response.data.data.documents.map(getDocumentInstanceByJson),
    })
  }

  async getProjectSearchResult({
    searchWord,
    searchTokenForNext,
    spaceId,
  }: ProjectSearchParams) {
    const searchParams = new URLSearchParams({
      keyword: searchWord,
    })
    if (searchTokenForNext) searchParams.append('token', searchTokenForNext)

    const response: AxiosResponse<ProjectSearchResultDto> = await this.axios({
      method: 'get',
      headers: {
        'search-version': 1,
      },
      url: `/api/search/project?${searchParams.toString()}`,
    })

    return PaginationWithTotal.fromJSON<TypedProject>({
      cursor:
        response.data.data.token === '' ? undefined : response.data.data.token,
      total: response.data.data.total,
      data: response.data.data.projects.map((project) =>
        TypedProject.fromJSON(project, spaceId),
      ),
    })
  }

  async getResourceSearchResult({
    searchWord,
    searchTokenForNext,
    resourceType,
    createdBy,
    orderBy = 'default',
    hasWorkspace,
  }: ResourceSearchParams) {
    const response: AxiosResponse<ResourceSearchResultDTO> = await this.axios({
      method: 'get',
      url: `/api/search/resource`,
      headers: {
        'search-version': 1,
      },
      params: {
        keyword: searchWord,
        token: searchTokenForNext,
        resourceType,
        createdBy,
        orderBy,
        hasWorkspace,
      },
    })

    return PaginationWithTotal.fromJSON<TypedResource>({
      cursor:
        response.data.data.token === '' ? undefined : response.data.data.token,
      total: response.data.data.total,
      data: response.data.data.resources.map(getResourceInstanceByJson),
    })
  }
}

export default autoBind(SearchAPI)
