import { TypedUser } from '@/models/user'

export type IntegrationType = 'slack'

export interface IntegrationDTO {
  type: IntegrationType
  createdBy: TypedUser
}

export class Integration implements IntegrationDTO {
  constructor(readonly type: IntegrationType, readonly createdBy: TypedUser) {
    this.type = type
    this.createdBy = createdBy
  }

  static fromJSON(json: IntegrationDTO): Integration {
    return new Integration(json.type, TypedUser.fromJSON(json.createdBy))
  }

  toJSON(): IntegrationDTO {
    return {
      type: this.type,
      createdBy: this.createdBy,
    }
  }

  copyWith(options?: Partial<Integration>): Integration {
    return new Integration(
      options?.type ?? this.type,
      options?.createdBy ?? this.createdBy,
    )
  }
}
