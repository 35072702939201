interface RelativeTimesText {
  future: string
  past: string
  s: string
  m: string
  mm: string
  h: string
  hh: string
  d: string
  dd: string
  w: string
  ww: string
  M: string
  MM: string
  y: string
  yy: string
}

const englishRelativeTimesText: RelativeTimesText = {
  future: 'in %s',
  past: '%s ago',
  s: 'a few seconds',
  m: '1 min',
  mm: '%d mins',
  h: '1 hour',
  hh: '%d hours',
  d: '1 day',
  dd: '%d days',
  w: '1 week',
  ww: '%d weeks',
  M: '1 month',
  MM: '%d months',
  y: '1 year',
  yy: '%d years',
}

const koreanRelativeTimesText: RelativeTimesText = {
  future: '%s',
  past: '%s 전',
  s: '방금',
  m: '1분',
  mm: '%d분',
  h: '1시간',
  hh: '%d시간',
  d: '1일',
  dd: '%d일',
  w: '1주',
  ww: '%d주',
  M: '1개월',
  MM: '%d개월',
  y: '1년',
  yy: '%d년',
}

export { englishRelativeTimesText, koreanRelativeTimesText }
