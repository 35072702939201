import { Store } from 'redux'
import _axios from 'axios'
import { QueryClient } from 'react-query'
import DocumentAPI, {
  GoogleDocumentObject,
  GooglePickerResponseObject,
} from '@/api/documentAPI'
import {
  TypedDocument,
  TypedDocumentSharedPermission,
  TypedDocumentType,
} from '@/models/document/TypedDocument'
import autoBind from '@/utils/autoBind'
import { CustomError } from '@/util'
import FolderAPI from '@/api/folderAPI'
import FolderRepository from '@/repositories/folderRepository'
import IntercomManager from '@/integrations/intercom/IntercomManager'
import { DocumentEvent } from '@/integrations/logging/event/document'
import ProjectRepository from '@/repositories/projectRepository'
import ResourceAPI from '@/api/resourceAPI'
import { ResourceEvent } from '@/integrations/logging/event/resource'
import { RootState } from '@/store'
import blurredBg from '@/assets/document/blurred_ws_bg.png'
import { openProject } from '@/slices/common/userActionSlice'
import SpaceRepository from '@/repositories/spaceRepository'
import { TypedResourceType } from '@/models/resource/TypedResource'
import TypedSpace from '@/models/space'
import SpaceService from '@/services/spaceService'
import { openModal } from '@/utils/feedback/feedbackSlice'
import { ModalType } from '@/hooks/feedback/modals'
import { QuotaExcessType } from '@/models/subscription'
import GooglePickerService from '@/services/googlePickerService'
import errors from '@/errors'
import RequestingService from '@/services/requestingService'
import AuthAPI from '@/api/authAPI'
import { queryKeys } from '@/providers/react-query'
import { getDocumentInstanceByJson } from '@/factories/getDocumentInstanceByJson'
import { isDev } from '@/utils/environment'
import TypedEditorDocument from '@/models/document/TypedEditorDocument'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'

export type GooglePickerImportResult =
  | { newDocuments: TypedDocument[]; space: TypedSpace }
  | undefined

class DocumentService {
  constructor(
    private readonly store: Store<RootState>,
    private readonly queryClient: QueryClient,
    private readonly documentAPI: InstanceType<typeof DocumentAPI>,
    private readonly folderAPI: InstanceType<typeof FolderAPI>,
    private readonly resourceAPI: InstanceType<typeof ResourceAPI>,
    private readonly authAPI: InstanceType<typeof AuthAPI>,
    private readonly projectRepo: InstanceType<typeof ProjectRepository>,
    private readonly folderRepo: InstanceType<typeof FolderRepository>,
    private readonly spaceRepo: InstanceType<typeof SpaceRepository>,
    private readonly documentEvent: DocumentEvent,
    private readonly resourceEvent: ResourceEvent,
    private readonly googlePickerAPI: typeof window.google.picker,
    private readonly spaceService: InstanceType<typeof SpaceService>,
    private readonly googlePickerService: InstanceType<
      typeof GooglePickerService
    >,
    private readonly requestingService: InstanceType<typeof RequestingService>,
  ) {}

  async getDocument(documentId: string): Promise<TypedDocument> {
    const document = await this.documentAPI.getDocumentData(documentId)
    return document
  }

  async getBackLinks(documentId: string): Promise<TypedDocument[]> {
    const backlinks = await this.resourceAPI.getBacklinks(documentId)
    return backlinks
  }

  async createDocument({
    title,
    docType,
    projectId,
    createdLocation,
    parentDocument,
    file,
  }: {
    title?: string
    docType?: TypedDocumentType
    projectId: string
    file?: File
    createdLocation: 'workspace' | 'apphome'
    parentDocument?: TypedDocument
  }): Promise<TypedDocument | undefined> {
    const spaceId = this.projectRepo.find(projectId)?.spaceId
    if (!spaceId) {
      return
    }
    if (await this.spaceService.willDocumentCountBeExceeded(spaceId)) {
      this.showQuotaExcessModal(QuotaExcessType.documentCount)
      return
    }

    const {
      user: { data: authState },
    } = this.store.getState()
    const hasRequestingDocumentInSameProject =
      this.requestingService.newDocument
        .getAll()
        .some(
          ({ data: requestingData }) => requestingData.projectId === projectId,
        )
    if (hasRequestingDocumentInSameProject) {
      return
    }

    let resourceSkeletonIds: string[] | undefined = undefined
    let createdDocument: TypedDocument | undefined = undefined
    try {
      if (parentDocument) {
        resourceSkeletonIds = this.requestingService.libraryPanelResources.add([
          {
            folderId: parentDocument.defaultFolderId,
            resourceType: TypedResourceType.DOCUMENT,
          },
        ])
      }

      const newDocumentSkeletonIds = this.requestingService.newDocument.add([
        { projectId },
      ])
      if (file) {
        createdDocument = await this.documentAPI.createFileDocument({
          projectId,
          file,
        })
      } else if (title && docType) {
        createdDocument = await this.documentAPI.createGoogleDocument({
          projectId,
          title,
          docType,
        })
      } else {
        throw Error('should have file or title & docType to create Document')
      }

      this.refetchSpaceUsage(spaceId)
      createdDocument = createdDocument.copyWith({
        // FIXME: 추후에 백엔드에서 넣어주도록 요청
        createdBy: {
          displayName: authState?.userDisplayName ?? '',
          photo: authState?.imageURL ?? '',
          userId: authState?.uid ?? '',
        },
      })
      this.requestingService.newDocument.remove(newDocumentSkeletonIds)

      this.documentEvent.createdEvent({
        anchorResourceId: createdDocument.documentId,
        type: createdDocument.docType,
        addedFrom: 'create_new',
        createdLocation,
        projectId: createdDocument.projectId,
        spaceId:
          this.projectRepo.find(createdDocument.projectId)?.spaceId ?? '',
      })
      IntercomManager.document.sendCreatedEvent()

      // FIXME: 백엔드 팀과 협의해서 link 로직 백엔드로 옮기기
      if (parentDocument) {
        await this.linkResource(createdDocument, parentDocument)

        const updatedFolderData = await this.folderAPI.getSpecificFolder(
          parentDocument.defaultFolderId,
        )
        this.folderRepo.update(updatedFolderData.folderId, updatedFolderData)
      }
    } catch (error) {
      if (_axios.isAxiosError(error) && error.response?.status === 403) {
        throw new errors.api.GdriveInsufficientFilePermissionsError()
      } else {
        throw error
      }
    } finally {
      if (resourceSkeletonIds) {
        this.requestingService.libraryPanelResources.remove(resourceSkeletonIds)
      }
    }

    return createdDocument
  }

  async importFromGoogleDrive(
    projectId: string,
    options: {
      parentDocument?: TypedDocument
      onPickerClose?(res: GooglePickerResponseObject | undefined): void
    } = {},
  ) {
    const spaceId = this.projectRepo.find(projectId)?.spaceId
    if (!spaceId) {
      return
    }
    if (await this.spaceService.willDocumentCountBeExceeded(spaceId)) {
      this.showQuotaExcessModal(QuotaExcessType.documentCount)
      return
    }

    const { parentDocument } = options

    let resourceSkeletonIds: string[] | undefined = undefined
    let documentsFromGoogleDriveSkeletonIds: string[] | undefined = undefined
    try {
      const googlePickerResponse =
        await this.googlePickerService.buildGooglePicker(
          GooglePickerService.uploadFromGoogleDriveMimeTypes.join(','),
        )
      if (options.onPickerClose) {
        options.onPickerClose(googlePickerResponse)
      }

      if (googlePickerResponse) {
        if (
          await this.spaceService.willDocumentCountBeExceeded(
            spaceId,
            googlePickerResponse.docs.length,
          )
        ) {
          this.showQuotaExcessModal(QuotaExcessType.documentCount)
          return
        }

        documentsFromGoogleDriveSkeletonIds =
          this.requestingService.documentsFromGoogleDrive.add(
            googlePickerResponse.docs.map(() => ({ projectId })),
          )

        if (parentDocument) {
          resourceSkeletonIds =
            this.requestingService.libraryPanelResources.add(
              googlePickerResponse.docs.map(() => ({
                folderId: parentDocument.defaultFolderId,
                resourceType: TypedResourceType.DOCUMENT,
              })),
            )
        }

        const result = await this.createDocumentByGooglePicker(
          googlePickerResponse.docs,
          'drive',
          projectId,
          parentDocument,
        )
        this.refetchSpaceUsage(spaceId)

        // FIXME: 백엔드 팀과 협의해서 link 로직 백엔드로 옮기기
        if (parentDocument && result) {
          const updatedFolderData = await this.folderAPI.getSpecificFolder(
            parentDocument.defaultFolderId,
          )
          this.folderRepo.update(updatedFolderData.folderId, updatedFolderData)
        }

        return result
      }
    } finally {
      if (documentsFromGoogleDriveSkeletonIds) {
        this.requestingService.documentsFromGoogleDrive.remove(
          documentsFromGoogleDriveSkeletonIds,
        )
      }

      if (resourceSkeletonIds) {
        this.requestingService.libraryPanelResources.remove(resourceSkeletonIds)
      }
    }
  }

  async importFromLocal(projectId: string, parentDocument?: TypedDocument) {
    const spaceId = this.projectRepo.find(projectId)?.spaceId
    if (!spaceId) {
      return
    }
    if (await this.spaceService.willDocumentCountBeExceeded(spaceId)) {
      this.showQuotaExcessModal(QuotaExcessType.documentCount)
      return
    }

    const space = this.spaceRepo.find(spaceId)
    if (!space) {
      return
    }

    let resourceSkeletonIds: string[] | undefined = undefined
    let newDocumentSkeletonIds: string[] | undefined = undefined
    try {
      if (!space.driveId) return

      const googlePickerRes =
        await this.googlePickerService.buildLocalGooglePicker(
          GooglePickerService.uploadFromLocalMimeTypes.join(','),
          space.driveId,
        )

      if (parentDocument) {
        resourceSkeletonIds = this.requestingService.libraryPanelResources.add([
          {
            folderId: parentDocument.defaultFolderId,
            resourceType: TypedResourceType.DOCUMENT,
          },
        ])
      }

      newDocumentSkeletonIds = this.requestingService.newDocument.add([
        { projectId },
      ])

      const result = await this.createDocumentByGooglePicker(
        googlePickerRes.docs,
        'local',
        projectId,
        parentDocument,
      )
      this.refetchSpaceUsage(spaceId)

      // FIXME: 백엔드 팀과 협의해서 link 로직 백엔드로 옮기기
      if (parentDocument && result) {
        const updatedFolderData = await this.folderAPI.getSpecificFolder(
          parentDocument.defaultFolderId,
        )
        this.folderRepo.update(updatedFolderData.folderId, updatedFolderData)
      }

      return result
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error(e)
      }
    } finally {
      if (resourceSkeletonIds) {
        this.requestingService.libraryPanelResources.remove(resourceSkeletonIds)
      }
      if (newDocumentSkeletonIds) {
        this.requestingService.newDocument.remove(newDocumentSkeletonIds)
      }
    }
  }

  async duplicateDocument(
    spaceId: string,
    docId: string,
    docName: string,
    projectId: string,
    isCopyResource: boolean,
  ): Promise<TypedDocument | undefined> {
    if (await this.spaceService.willDocumentCountBeExceeded(spaceId)) {
      this.showQuotaExcessModal(QuotaExcessType.documentCount)
      return
    }

    const copiedDocument = await this.documentAPI.duplicateDocument(
      spaceId,
      docId,
      docName,
      projectId,
      isCopyResource,
    )
    this.refetchSpaceUsage(spaceId)

    return copiedDocument
  }

  async toggleDocumentFavorite(document: TypedDocument) {
    if (document.favorite) {
      await this.documentAPI.removeDocumentFromFavorites(document.documentId)
    } else {
      await this.documentAPI.addDocumentToFavorites(document.documentId)
    }
  }

  async removeDocumentFavorite(document: TypedDocument) {
    this.documentAPI.removeDocumentFromFavorites(document.documentId)
  }

  async renameDocument(newName: string, docId: string): Promise<boolean> {
    const response = await this.documentAPI.renameDocument(newName, docId)
    return response
  }

  async getSharedDocLink({ docId }: { docId: string }): Promise<string> {
    const shareDocLink = await this.documentAPI.getSharedDocLink(docId)

    let link = shareDocLink.link

    if (isDev()) {
      const sha = window.location.pathname.split('/')[1]
      link = `${shareDocLink.link}?dev=${sha}`
    }

    return link
  }

  async deleteDocument(
    spaceId: string,
    document: TypedDocument,
  ): Promise<void> {
    await this.documentAPI.deleteDocument(document.documentId)
    this.refetchSpaceUsage(spaceId)

    this.documentEvent.deletedEvent({
      documentId: document.documentId,
      createdAt: document.createdAt,
      numOfResources: document.numResources,
    })
  }

  async reorderDocumentsInProject({
    projectId,
    documentId,
    prevDocumentId,
    nextDocumentId,
  }: {
    projectId: string
    documentId: string
    prevDocumentId: string
    nextDocumentId: string
  }): Promise<boolean> {
    const result = await this.documentAPI.updateDocuments([
      {
        projectId,
        documentId,
        prevDocument: prevDocumentId,
        nextDocument: nextDocumentId,
      },
    ])
    return result
  }

  async moveDocumentToOtherProject({
    projectId,
    documentId,
  }: {
    projectId: string
    documentId: string
  }): Promise<boolean> {
    const result = await this.documentAPI.updateDocuments([
      {
        projectId,
        documentId,
      },
    ])
    return result
  }

  async updateDocumentStatus(
    docId: string,
    isCompleted: boolean,
  ): Promise<boolean> {
    const result = await this.documentAPI.updateDocumentStatus(
      docId,
      isCompleted,
    )
    return result
  }

  async getSharedPermission({
    docId,
  }: {
    docId: string
  }): Promise<TypedDocumentSharedPermission> {
    return this.documentAPI.getSharedPermission(docId)
  }

  async changeSharedDocPermission({
    docId,
    permission,
  }: {
    docId: string
    permission: TypedDocumentSharedPermission
  }): Promise<boolean> {
    return this.documentAPI.changeSharedDocPermission(docId, permission)
  }

  async editEditorDocument(newDocument: TypedEditorDocument) {
    try {
      await this.resourceAPI.editTextResource({
        id: newDocument.id,
        title: newDocument.title,
        body: newDocument.origin.body,
      })
    } catch (error) {
      sendErrorLog('Error occurred while editing text resource', {
        error,
        newDocument,
      })
    }
  }

  async patchLastUpdated(docId: string): Promise<void> {
    this.documentAPI.patchLastUpdated(docId)
  }

  private showQuotaExcessModal(quotaExcessType: QuotaExcessType) {
    this.store.dispatch(
      openModal({ name: ModalType.QUOTA_EXCESS, data: quotaExcessType }),
    )
  }

  private async linkResource(
    document: TypedDocument,
    parentDocument: TypedDocument,
  ) {
    await this.resourceAPI.linkResource(
      document.documentId,
      parentDocument.defaultFolderId,
      parentDocument.documentId,
    )
    this.resourceEvent.linkEvent({
      type: 'document',
      resourceId: document.documentId,
      createdAt: document.createdAt,
      docId: parentDocument.documentId,
    })
  }

  private async createDocumentByGooglePicker(
    googleDocObj: GoogleDocumentObject[],
    importLocation: 'drive' | 'local',
    projectId: string,
    parentDocument?: TypedDocument,
  ) {
    const project = this.projectRepo.find(projectId)
    if (!project) {
      return
    }

    const space = this.spaceRepo.find(project.spaceId)
    if (!space) {
      return
    }

    const pickerElements = document.getElementsByClassName('picker-dialog-bg')

    for (let i = 0; i < pickerElements.length; i++) {
      if (pickerElements[i].tagName === 'DIV') {
        const dialogBackground = pickerElements[i] as HTMLDivElement
        dialogBackground.style.background = `no-repeat url('${blurredBg}')`
        dialogBackground.style.backgroundSize = 'cover'
        dialogBackground.style.opacity = '1'
      }
    }

    const newDocuments = await this.documentAPI.createDocumentByGooglePicker(
      googleDocObj,
      importLocation,
      projectId,
    )

    const { data: userData } = this.store.getState().user
    const newDocumentsWithCreatedBy = newDocuments.map((document) =>
      getDocumentInstanceByJson(document).copyWith({
        createdBy: {
          displayName: userData?.userDisplayName ?? '',
          photo: userData?.imageURL ?? '',
          userId: userData?.uid ?? '',
        },
      }),
    )
    // FIXME: google drive에서 도큐먼트를 추가하는 것은 1개밖에 되지 않으므로 forEach를 돌아가며 add를 해줘도 되지만 장기적으론 명확히 해야 함
    // 한개만 추가할 것인지, 여러개를 추가할 수 있도록 할 것인지
    newDocumentsWithCreatedBy.forEach((document) => {
      if ((document as any).exceedFileSizeLimit) {
        alert((document as any).message)
      } else {
        this.documentEvent.createdEvent({
          anchorResourceId: document.documentId,
          type: document.docType,
          addedFrom: importLocation,
          createdLocation: 'apphome',
          projectId: document.projectId,
          spaceId: space.id,
        })
        IntercomManager.document.sendCreatedEvent()
      }
    })

    if (parentDocument) {
      const linkAllDocumentResources = newDocuments.map((newDocument) =>
        this.linkResource(newDocument, parentDocument),
      )
      await Promise.all(linkAllDocumentResources).catch((error) => {
        throw error
      })
    }

    this.store.dispatch(
      openProject({
        openingProjectId: projectId,
        userId: this.store.getState().user.data?.uid ?? '',
      }),
    )

    return { newDocuments: newDocumentsWithCreatedBy, space }
  }

  private refetchSpaceUsage(spaceId: string) {
    return this.queryClient.refetchQueries(
      queryKeys.spaceUsageBySpaceId(spaceId),
      { exact: true },
    )
  }
}

export default autoBind(DocumentService)
