import { Store } from 'redux'
import { RootState } from '@/store'
import { randomStr } from '@/util'
import {
  RequestingType,
  addRequestings,
  removeRequestings,
  clearRequestings,
  RequestingItem,
} from '@/slices/requestingSlice'

export interface IRequestingStorage<T> {
  type: RequestingType
  add: (items: T[]) => string[]
  getAll: () => RequestingItem<T>[]
  remove: (ids: string[]) => void
  clear: () => void
}

class RequestingStorage<T = undefined> implements IRequestingStorage<T> {
  constructor(
    readonly store: Store<RootState>,
    readonly type: RequestingType,
  ) {}

  add = (items: T[]): string[] => {
    const itemsWithId = items.map((item) => ({ id: randomStr(), data: item }))
    this.store.dispatch(addRequestings({ type: this.type, items: itemsWithId }))

    return itemsWithId.map(({ id }) => id)
  }

  getAll = (): RequestingItem<T>[] => {
    const requestings = this.store.getState().requesting[this.type]
    return requestings ?? ([] as RequestingItem<any>[])
  }

  remove = (ids: string[]): void => {
    this.store.dispatch(removeRequestings({ type: this.type, ids }))
  }

  clear = (): void => {
    this.store.dispatch(clearRequestings({ type: this.type }))
  }
}

export default RequestingStorage
