import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loadingDocument: null,
  loadingProject: null,
}

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoadingDocument: (state, action) => {
      state.loadingDocument = action.payload
    },
    setLoadingProject: (state, action) => {
      state.loadingProject = action.payload
    },
  },
})

export const { setLoadingDocument, setLoadingProject } = loadingSlice.actions

export default loadingSlice.reducer
