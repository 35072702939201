import colors from '@/styles/colors'

export default {
  dark5: '0px 1px 5px rgba(0, 0, 0, 0.3)',
  dark10: '0px 5px 10px rgba(0, 0, 0, 0.3)',
  light5: '0px 2px 5px rgba(0, 0, 0, 0.05)',
  light10: '0px 5px 10px rgba(0, 0, 0, 0.05)',
  light25: '0px 10px 25px rgba(0, 0, 0, 0.05)',

  red15: '0px 5px 15px rgba(192, 39, 39, 0.3)',
  yellow15: '0px 5px 15px rgba(180, 114, 0, 0.3)',
  purple15: '0px 5px 15px rgba(75, 80, 161, 0.3)',
  blue15: '0px 5px 15px rgba(9, 108, 170, 0.3)',
  green15: '0px 5px 15px rgba(29, 118, 64, 0.3)',

  gray10: '0px 5px 10px rgba(0, 0, 0, 0.12)',

  insertTop: `inset 0 2px 0 0 ${colors.blue50()}, 0 -2px 0 0 ${colors.blue50()}`,
  insertBottom: `inset 0 -2px 0 0 ${colors.blue50()}, 0 2px 0 0 ${colors.blue50()}`,
}
