import Pagination, { IPagination } from '@/models/pagination/Pagination'

interface IPaginationWithTotal<T> extends IPagination<T> {
  readonly total: number
}

class PaginationWithTotal<T> extends Pagination<T> {
  constructor(
    readonly data: T[],
    readonly total: number,
    // FIXME: 백엔드 통일 작업 끝나면 타입 하나로 통일
    readonly cursor?: string | number,
  ) {
    super(data, cursor)
  }

  static fromJSON<T>(json: IPaginationWithTotal<T>) {
    return new PaginationWithTotal<T>(json.data, json.total, json.cursor)
  }
}

export default PaginationWithTotal
