interface MessageText {
  unsupportedFormatMessage: string
  resourceOnlyplacedInDocumentMessage: string
  successfullyMoved: string
  successfullyLinked: string
  urlHostPolicyWarning: string
  multiSelectToobarMessage: string
  highlightReadModeMessage: string
  DRIVE_ACCESS_DENIED_MESSAGE: string
  INCOGNITO_MODE_DENIED_MESSAGE: string
  SIGN_IN_FROM_EXTENSION: string
  'Read mode': string
  'Start Typing': string
  'Get to work!': string
  'Go to Document': string
  'Open in Drive': string
  Deselect: string
  Delete: string
  Editor: string
  Preview: string
}

const englishMessageText: MessageText = {
  unsupportedFormatMessage: 'This format will soon be supported.',
  resourceOnlyplacedInDocumentMessage:
    'Resources can only be placed in Documents.',
  successfullyMoved: 'Successfully moved',
  successfullyLinked: '👍 Successfully Linked here!',
  urlHostPolicyWarning: `Due to the following URL host policy,
the resource may not be rendered correctly.`,
  multiSelectToobarMessage: 'Multi-select with Ctrl(Cmd)',
  highlightReadModeMessage:
    'Highlights cannot be created or referenced when Read Mode is Off',
  DRIVE_ACCESS_DENIED_MESSAGE:
    'For a seamless Typed experience, we need your permission to access Google Drive.\nPlease allow access to Google Drive.',
  INCOGNITO_MODE_DENIED_MESSAGE:
    'Sign Up and Log In may be blocked due to Chrome’s Incognito Mode.\nPlease allow Typed cookies for Incognito Mode in Chrome Settings or switch out of Incognito Mode for the fix.',
  SIGN_IN_FROM_EXTENSION: 'Sign in to your account to enable Typed extension.',
  'Read mode': 'Read mode',
  'Start Typing': 'Start Typing',
  'Get to work!': 'Get to work!',
  'Go to Document': 'Go to Document',
  'Open in Drive': 'Open in Drive',
  Deselect: 'Deselect',
  Delete: 'Delete',
  Editor: 'Editor',
  Preview: 'Preview',
}

const koreanMessageText: MessageText = {
  unsupportedFormatMessage: '이 포맷은 곧 지원할 예정이예요.',
  resourceOnlyplacedInDocumentMessage: '참고자료는 문서로만 이동할 수 있어요.',
  successfullyMoved: '이동 성공! 🙌 ',
  successfullyLinked: '링크 성공! 🔗 ',
  urlHostPolicyWarning: `URL 호스트 정책으로 인해 정상적으로 출력되지
않을 수 있습니다.`,
  multiSelectToobarMessage: 'Ctrl(Cmd)키를 눌러 다중 선택하세요.',
  highlightReadModeMessage:
    '읽기 모드로 설정해야 하이라이트를 생성하거나 참조할 수 있어요.',
  DRIVE_ACCESS_DENIED_MESSAGE:
    '타입드 사용을 위해서는 구글 드라이브 액세스가 필요합니다.\n구글 드라이브 엑세스를 허용해주세요.',
  INCOGNITO_MODE_DENIED_MESSAGE:
    '크롬 시크릿 창을 사용하면 계정 생성 및 로그인이 제한될 수 있습니다.\n시크릿 창 설정에서 타입드의 쿠키를 허용하거나, 시크릿 창이 아닌 일반 새 창으로 접속하세요.',
  SIGN_IN_FROM_EXTENSION: '익스텐션 사용을 위해서는 로그인이 필요합니다.',
  'Read mode': '읽기 모드',
  'Start Typing': '문서 작성 시작',
  'Get to work!': '작성하러 가기!',
  'Go to Document': '도큐먼트로 이동',
  'Open in Drive': '구글 드라이브에서 열기',
  Deselect: '선택 해제',
  Delete: '삭제',
  Editor: '에디터',
  Preview: '미리보기',
}

export { englishMessageText, koreanMessageText }
