import { Store } from 'redux'
import AuthAPI from '@/api/authAPI'
import UserAPI from '@/api/userAPI'
import { ITypedEventLoggers } from '@/integrations/logging/event/typedEventLoggers'
import { RootState } from '@/store'
import { SESSION_STORAGE_NAMES } from '@/util'
import autoBind from '@/utils/autoBind'
import parseJwt from '@/utils/parseJwt'
import ExtensionService from '@/services/extensionService'
import TrackerService from '@/services/trackerService'
import { login as loginAction } from '@/slices/auth/authActionSlice'
import AuthService from '@/services/authService'

class LoginFlowService {
  constructor(
    readonly store: Store<RootState>,
    readonly authAPI: InstanceType<typeof AuthAPI>,
    readonly userAPI: InstanceType<typeof UserAPI>,
    readonly eventLoggers: ITypedEventLoggers,
    readonly authService: InstanceType<typeof AuthService>,
    readonly extensionService: InstanceType<typeof ExtensionService>,
    readonly trackerService: InstanceType<typeof TrackerService>,
  ) {}

  async typedLogin({
    idToken,
    amplitudeUserInfo,
    intercomUserInfo,
  }: {
    idToken: string
    amplitudeUserInfo?: Record<string, string> | undefined
    intercomUserInfo?: Record<string, string> | undefined
  }): Promise<void> {
    const credential = parseJwt(idToken)

    if (!credential || !credential.email) {
      return
    }

    const wasUserSignedUp = await this.authAPI.checkUserExists(credential.email)

    const userData = await this.authService.loginWithGoogleToken(idToken)

    const userProfile = await this.userAPI.getUserProfile()

    if (userData) {
      this.extensionService.signIn({
        displayName: userData.displayName,
        photoUrl: userData.photoUrl,
        email: userData.email,
        userId: userData.localId,
      })

      const userAuth = {
        uid: userProfile.userId,
        displayName: userData.displayName,
        userDisplayName: userProfile.displayName,
        imageURL: userProfile.photo ?? userData.photoUrl,
        originalImageURL: userData.photoUrl,
        email: userData.email,
      }
      this.store.dispatch(loginAction(userAuth))
      await this.trackerService.setUserInfoForTrackers()

      // 아래는 icp 페이지 데이터라서 처음 가입할때만 들어감
      amplitudeUserInfo &&
        this.eventLoggers.ampClient.setUserProperty(
          userAuth.uid,
          userAuth.email,
          amplitudeUserInfo,
        )
      intercomUserInfo && window.Intercom('update', intercomUserInfo)

      sessionStorage.setItem(
        SESSION_STORAGE_NAMES.IDPIFRAME_INITIALIZATION_FAILED,
        'false',
      )

      if (!wasUserSignedUp) {
        this.eventLoggers.userEvent.signupEvent(new Date())
        window.fbq && window.fbq('trackCustom', 'SignUp')
        window.dataLayer && window.dataLayer.push({ event: 'sign_up' })

        this.eventLoggers.quantcast.sendSignupEvent()
      }

      this.eventLoggers.userEvent.signinEvent()
    }
  }
}

export default autoBind(LoginFlowService)
