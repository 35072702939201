import { NOTIFICATION_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'
import { NotificationType } from '@/models/notification'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class NotificationEvent extends TypedEvent {
  notificationTabClickedEvent() {
    this.sendEvent(NOTIFICATION_EVENT.NOTIFICATION_TAB_CLICKED)
  }

  notificationAssetClickedEvent(type: NotificationType) {
    this.sendEvent(NOTIFICATION_EVENT.NOTIFICATION_ASSET_CLICKED, {
      type,
    })
  }
}
