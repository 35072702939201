import { randomStr } from '@/util'
import { TypedSpaceMemberRole } from '@/models/space'

interface IMemberWhoCreateProject {
  displayName: string
  email: string
  photo: string
}

export interface ITypedProject {
  readonly projectId: string
  readonly userId: string
  readonly createdAt: number
  readonly name: string
  readonly emoji: string
  readonly favorite: boolean
  readonly groupId: string
  readonly documentCnt: number
  readonly description: string
  readonly createdBy: IMemberWhoCreateProject
  readonly isPrivate: boolean
}

export interface IPrivateProjectMember {
  id: string
  displayName: string
  photo: string
  role: `${TypedSpaceMemberRole}`
  email: string
}

export default class TypedProject implements ITypedProject {
  constructor(
    readonly projectId: string,
    readonly userId: string,
    readonly createdAt: number,
    readonly name: string,
    readonly emoji: string,
    readonly spaceId: string,
    readonly favorite: boolean,
    readonly groupId: string,
    readonly documentCnt: number,
    readonly description: string,
    readonly createdBy: IMemberWhoCreateProject,
    readonly isPrivate: boolean,
  ) {}

  get id(): string {
    return this.projectId
  }

  static CREATING_PROJECT = 'CREATING_PROJECT'

  static fromJSON(json: ITypedProject, spaceId: string): TypedProject {
    return new TypedProject(
      json.projectId,
      json.userId,
      json.createdAt,
      json.name,
      json.emoji,
      spaceId,
      json.favorite,
      json.groupId,
      json.documentCnt,
      json.description,
      json.createdBy,
      json.isPrivate,
    )
  }

  static createEmptyProject(): TypedProject {
    const newProject: ITypedProject = {
      projectId: `${TypedProject.CREATING_PROJECT}-${randomStr()}`,
      userId: '',
      name: '',
      createdAt: 0,
      emoji: '',
      favorite: false,
      groupId: '',
      documentCnt: 0,
      description: '',
      createdBy: {
        displayName: '',
        email: '',
        photo: '',
      },
      isPrivate: false,
    }

    return TypedProject.fromJSON(newProject, '')
  }

  static getRandomEmoji(): string {
    const emojis = ['📑', '🗃', '🗄', '📋', '📂', '📔', '📚', '🗂', '🙂', '👋']
    const randomIdx = Math.floor(Math.random() * emojis.length)

    return emojis[randomIdx]
  }

  toJSON(): ITypedProject {
    return {
      projectId: this.projectId,
      userId: this.userId,
      createdAt: this.createdAt,
      name: this.name,
      emoji: this.emoji,
      favorite: this.favorite,
      groupId: this.groupId,
      documentCnt: this.documentCnt,
      description: this.description,
      createdBy: this.createdBy,
      isPrivate: this.isPrivate,
    }
  }

  copyWith(options?: Partial<TypedProject>): TypedProject {
    return new TypedProject(
      options?.projectId ?? this.projectId,
      options?.userId ?? this.userId,
      options?.createdAt ?? this.createdAt,
      options?.name ?? this.name,
      options?.emoji ?? this.emoji,
      options?.spaceId ?? this.spaceId,
      options?.favorite ?? this.favorite,
      options?.groupId ?? this.groupId,
      options?.documentCnt ?? this.documentCnt,
      options?.description ?? this.description,
      options?.createdBy ?? this.createdBy,
      options?.isPrivate ?? this.isPrivate,
    )
  }
}
