import { IGoogleDocumentOrigin } from '@/models/document/TypedGoogleDocument'
import { IFileDocumentOrigin } from '@/models/document/TypedFileDocument'
import { IEditorDocumentOrigin } from '@/models/document/TypedEditorDocument'

export type TypedDocumentType =
  | 'document'
  | 'spreadsheet'
  | 'presentation'
  | 'pdf'
  | 'editor'

export interface TypedDocumentOrigin {
  provider: string
}

export enum TypedDocumentSharedPermission {
  NONE = 'none',
  VIEW = 'view',
  EDIT = 'edit',
}

export interface ITypedDocumentCreatedBy {
  readonly userId: string
  readonly displayName: string
  readonly photo: string
}

export type ITypedDocumentOrigin =
  | IGoogleDocumentOrigin
  | IFileDocumentOrigin
  | IEditorDocumentOrigin

export interface ITypedSharedDocumentLink {
  readonly link: string
  readonly isPublic: boolean
}

export interface ITypedDocument {
  readonly projectId: string
  readonly origin: TypedDocumentOrigin
  readonly documentId: string
  readonly docType: TypedDocumentType
  readonly title: string
  readonly createdBy: ITypedDocumentCreatedBy | undefined
  readonly defaultFolderId: string
  readonly numResources: number
  readonly createdAt: number
  readonly updatedAt: number
  readonly isCompleted: boolean
  readonly favorite: boolean
}

export abstract class TypedDocument implements ITypedDocument {
  constructor(
    readonly projectId: string,
    readonly documentId: string,
    readonly docType: TypedDocumentType,
    readonly title: string,
    readonly createdBy: ITypedDocumentCreatedBy | undefined,
    readonly defaultFolderId: string,
    readonly numResources: number,
    readonly createdAt: number,
    readonly updatedAt: number,
    readonly isCompleted: boolean,
    readonly favorite: boolean,
    readonly origin: TypedDocumentOrigin,
  ) {}

  get id(): string {
    return this.documentId
  }

  toJSON(): ITypedDocument {
    return {
      projectId: this.projectId,
      documentId: this.documentId,
      docType: this.docType,
      title: this.title,
      createdBy: this.createdBy,
      defaultFolderId: this.defaultFolderId,
      numResources: this.numResources,
      createdAt: this.createdAt,
      isCompleted: this.isCompleted,
      favorite: this.favorite,
      updatedAt: this.updatedAt,
      origin: this.origin,
    }
  }

  abstract copyWith(options?: Partial<TypedDocument>): TypedDocument
}
