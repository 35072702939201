import React, { useState } from 'react'
import relativeTime from 'dayjs/plugin/relativeTime'
import updateLocale from 'dayjs/plugin/updateLocale'
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import 'dayjs/locale/en'
import duration from 'dayjs/plugin/duration'

import {
  getLanguage,
  SUPPORTED_LANGUAGES,
  TEXTS_WITH_LOCALE,
} from '@/locale/locale.util'
import LocalStorageManagerFactory from '@/utils/common/localStorageManagerFactory'

dayjs.extend(updateLocale)
dayjs.extend(relativeTime)
dayjs.extend(duration)

export const UserLanguageContext = React.createContext({
  language: getLanguage(),
  isEnglish: getLanguage() === 'en',
  isKorean: getLanguage() === 'ko',
  textWithLocale: TEXTS_WITH_LOCALE[getLanguage()],
  // eslint-disable-next-line unused-imports/no-unused-vars
  changeLanguage: (state: SUPPORTED_LANGUAGES) => {},
})

interface UserLanguageContextProviderProps {
  children: React.ReactNode
}

const UserLanguageLSM = LocalStorageManagerFactory.userLanguage

const UserLanguageContextProvider = ({
  children,
}: UserLanguageContextProviderProps) => {
  const [language, setLanguage] = useState<SUPPORTED_LANGUAGES>(
    () => UserLanguageLSM.getData() ?? getLanguage(),
  )

  const isEnglish = language === 'en'
  const isKorean = language === 'ko'
  const textWithLocale = TEXTS_WITH_LOCALE[language]

  if (!UserLanguageLSM.getData()) {
    UserLanguageLSM.setData(getLanguage())
  }

  const setDayjsLocale = (language: SUPPORTED_LANGUAGES) => {
    dayjs.locale(language)
    dayjs.updateLocale(language, {
      relativeTime: textWithLocale.relativeTimes,
    })
  }

  const changeLanguage = (language: SUPPORTED_LANGUAGES) => {
    UserLanguageLSM.setData(language)
    setLanguage(language)

    setDayjsLocale(language)
  }

  setDayjsLocale(language)

  return (
    <UserLanguageContext.Provider
      value={{ language, isEnglish, isKorean, textWithLocale, changeLanguage }}
    >
      {children}
    </UserLanguageContext.Provider>
  )
}

export default UserLanguageContextProvider
