import { CSSProperties } from 'react'
import { XYCoord } from 'react-dnd'

export const setDragPreviewPosition = (
  currentOffset: XYCoord | null,
): CSSProperties => {
  if (!currentOffset) {
    return {
      opacity: 0,
    }
  }
  const { x, y } = currentOffset
  return {
    transform: `translate(${x}px, ${y}px)`,
    WebkitTransform: `translate(${x}px, ${y}px)`,
  }
}

export const getRandomHex = (): string => {
  // HSL 값 생성 참고: https://www.figma.com/file/cQBefvuZcPlZQsoD44coIY/Typed-Sprint?node-id=19553%3A38988
  function randomInt(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const h = randomInt(0, 360)
  const s = randomInt(70, 85)
  const l = randomInt(60, 75)

  function hslToHex(h: number, s: number, l: number) {
    l /= 100
    const a = (s * Math.min(l, 1 - l)) / 100
    const f = (n: number) => {
      const k = (n + h / 30) % 12
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0') // convert to Hex and prefix "0" if needed
    }
    return `#${f(0)}${f(8)}${f(4)}`
  }

  return hslToHex(h, s, l)
}
