import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { emptyTaskIndex: number } = {
  emptyTaskIndex: -1,
}

export const taskSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    setEmptyTaskIndex: (state, action: PayloadAction<number>) => {
      state.emptyTaskIndex = action.payload
    },
    resetEmptyTaskIndex: (state) => {
      state.emptyTaskIndex = -1
    },
  },
})

export const { setEmptyTaskIndex, resetEmptyTaskIndex } = taskSlice.actions

export default taskSlice.reducer
