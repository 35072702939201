const CSS_SELECTORS = Object.freeze({
  VIEWER_ID: 'viewer', // pdf js 내부의 viewer id와 맞추기 위함
  IFRAME_VIEWER_ID: 'iframe_viewer',
  CLASS_PREFIX: 'highlight-',
  HOVERED_CLASS: 'hovered',
  CLICKED_CLASS: 'clicked',
})

const HIGHLIGHT_CARD_CLICK_EVENT = 'highlightcardclick'

const toolPosition = {
  forward: { top: 10, left: 0 },
  backward: { top: -20, left: -10 },
}

export default {
  CSS_SELECTORS,
  HIGHLIGHT_CARD_CLICK_EVENT,
  toolPosition,
}
