import dayjs from 'dayjs'
import { ITypedSubscriptionPlan } from '@/models/subscription'
import { formatBytes } from '@/utils/unit'

interface ModalText {
  icpModal: {
    next: string
    previous: string
    done: string
    'Product Tour': string
    'Product Tour Description': string
    'Member Management': string
    'Member Management Description': string
    'Create Documents': string
    'Create Documents Description': string
    'Drag & Drop': string
    'Drag & Drop Description': string
    'Spit Viewer': string
    'Spit Viewer Description': string
    highlight: string
    'highlight Description': string
    videoGuide: string
    userGuide: string
    videoGuideUrl: string
    userGuideUrl: string
    'Learn More': string
  }
  deleteModal: {
    'Are you sure': string
    projectDelete: string
    documentDelete: string
    folderDeletModal: string
    spaceMemberRemove1: string
    spaceMemberRemove2: string
    deleteUserHeader: string
    deleteUser1: string
    deleteUser2: string
    deleteUser3: string
    'Email address': string
    'Delete Account': string
    spaceLeave: string
    Cancel: string
    Delete: string
    Remove: string
    'Delete Space': string
    'Leave Space': string
    deleteSpaceHeader: (spaceName: string) => string
    'Space name': string
    deleteSpace1: string
    deleteSpace2: string
    'Delete this group': string
    groupDeleteDescription(groupName: string): string
    'Leave Project Text': string
    Leave: string
  }
  documentCreate: {
    'Start Typing with': string
    Title: string
    Project: string
    'New Project': string
    Cancel: string
    Create: string
    Message: string
    'Create Document': string
  }
  referenceCreate: {
    'Add reference': string
  }
  documentUploadWarning: string
  duplicateDocument: {
    'Duplicate document': string
    'Link All References': string
    Project: string
    Cancel: string
    Duplicate: string
  }
  projectSelect: {
    'Select project': string
    Project: string
    'New Project': string
    Cancel: string
    Next: string
    Confirm: string
  }
  documentPageStatus: {
    'Done Writing': string
    'Done status change': string
  }
  imageEditModal: {
    'Crop your Image': string
    Upload: string
    Cancel: string
    'The Image is too big': string
    'Try Another Image': string
  }
  projectCreate: {
    'Name new project': string
    placeholder: string
    Cancel: string
    Create: string
    'Make private': string
    'Create private Description': string
  }
  projectSettings: {
    Settings: string
    Members: string
    'Project title': string
    'Project description': string
    'Created By': string
    'Danger Zone': string
    'Leave Project': string
    'Delete Project': string
    'Save Changes': string
    Cancel: string
    'Add member': string
    Remove: string
  }
  projectInvite: {
    'Add multiple people with comma or enter': string
    'Add people to': string
    'Skip for now': string
    'No match items': string
    Add: string
  }
  spaceCreate: {
    'Name your space': string
    placeholder: string
    CreateSpace: string
    Cancel: string

    'Invite members': string
    Invite: string
    Skip: string

    storedSafelyMessage: string
    StartTyping: string
    invalidNameError: string
    invalidEmptyNameError: string

    Notice: string
    spaceGuideMessage2: string
  }
  spaceInvite: {
    'Invite people': string
    'Invite members': string
    helperText: string
    Invite: string
    Skip: string
  }
  spaceSettings: {
    Settings: string
    Members: string
    'Plans & Billing': string
    'Space icon': string
    'Upload Image': string
    'Remove Image': string
    'Space name': string
    'Danger Zone': string
    'Add member': string
    'Save Changes': string
    Cancel: string
    Pending: string
    freePlanDescription: string
    proPlanDescription: string
    cancelledPlanDescription: (plan: ITypedSubscriptionPlan) => string
    Storage: string
    Document: string
    Editor: string
    storageQuotaDescription: (current: number, max: number) => string
    appSumoEditorQuotaDescription: (current: number, max: number) => string
    editors: string
    Monthly: string
    Yearly: string
    'per editor/month': string
    'billed annually': string
    'billed monthly': string
    Trial: string
    Pro: string
    Enterprise: string
    'Current Plan': string
    'Upgrade to Pro': string
    'Contact Sales 🤙': string
    'Upgraded to Pro': string
    'Upgraded to Enterprise': string
    '1 document': string
    '1GB of reference storage': string
    'Typed Extension': string
    'Typed Mobile': string
    'Everything in Free, plus': string
    'Unlimited documents': string
    '10GB of storage per editor': string
    'Early access to new features': string
    'Everything in Pro, plus': string
    'Unlimited storage': string
    'Advanced Knowledge Network': string
    'Designated success managers': string
    'Custom Contract': string
    scholarship1: string
    scholarship2: string
    unsubscribeDescription: string
    unsubscribeGuideLeading: string
    unsubscribeGuideHere: string
    unsubscribeGuideTrailing: string
    'If you unsubscribe, you will be downgraded': string
    'Failed to Unsubscribe': string
    unsubscribeErrorDescription1: string
    unsubscribeErrorDescription2: string
    unsubscribeErrorDescription3: string
    'Try Again': string
    Unsubscribe: string
    'Payment method': string
    Update: string
    Integrations: string
    'Slack Guide Description': string
    'Slack Connect': string
    'Slack Disconnect': string
    IntegrationInfoTrailingText: string
    IntegrationInfoMiddleText: string
    IntegrationInfoLeadingText: string
  }
  resourceTag: {
    'mark as read': string
  }
  spaceMigration: {
    title: string
    description: string
    maximumDuration: string
    upgradeComplete: string
    updateCompleteDescription: string
    emailUsTextLeft: string
    emailUsTextRight: string
    upgradeFail: string
    failDescriptionFirstLine: string
    failDescriptionSecondLineLeft: string
    failDescriptionSecondLineRight: string
  }
  groupCreation: {
    'Name new group': string
    placeholder: string
    Cancel: string
    Create: string
  }
  groupRename: {
    'Rename this group': string
    Cancel: string
    Save: string
    Rename: string
  }
  groupDelete: {
    Delete: string
  }
  quotaExcess: {
    'Upgrade your plan': string
    '1docsLimitDesc1': string
    '1docsLimitDesc2': string
    storageLimitDesc1: string
    storageLimitDesc2: string
    moreTemplateDesc1: string
    moreTemplateDesc2: string
  }
  viewModal: {
    pageView: string
    uniqueViewers: string
    lastViewed: string
  }
}

const englishModalText: ModalText = {
  icpModal: {
    previous: 'Previous',
    next: 'Next',
    done: 'Done',
    'Product Tour': 'Product Tour',
    'Product Tour Description': 'Expand your knowledge and connect the docs.',
    'Member Management': 'Member Management',
    'Member Management Description':
      'Invite your team members to a Space and work together in collaboration!',
    'Create Documents': 'Create Documents',
    'Create Documents Description':
      'Start by creating a document - Docs, Slides, Sheets.',
    'Drag & Drop': 'Drag & Drop',
    'Drag & Drop Description':
      'Drag your files from your local file directly into a Document Library.',
    'Spit Viewer': 'Spit Viewer',
    'Spit Viewer Description':
      'View your references on the same page as your document by clicking on them.',
    highlight: 'Highlight',
    'highlight Description': 'Highlight text on your URL and PDF references.',
    videoGuide: 'YouTube Video Tutorials',
    userGuide: 'User Guide Academy',
    videoGuideUrl:
      'https://www.youtube.com/watch?v=LAIuaHIjN2w&list=PL40xZMKHcUHC4tHmEOFxsbnrY-0wpXkxj&ab_channel=Typed',
    userGuideUrl: 'https://company.typed.do/academy',
    'Learn More': 'Learn More',
  },
  deleteModal: {
    'Are you sure': 'Are you sure? 😱',
    projectDelete:
      'If you click "delete", you will permanently lose the project and its documents.',
    documentDelete:
      'If you click "delete", you will permanently lose the document and its resources.',
    folderDeletModal:
      'If you click “delete”, you will permanently lose the folder and the resources within.',
    spaceMemberRemove1: 'Are you sure you want to remove ',
    spaceMemberRemove2: '? You can invite anytime.',
    spaceLeave:
      'Are you sure you want to leave? You can be invited again anytime.',
    Cancel: 'Cancel',
    Delete: 'Delete',
    Remove: 'Remove',
    'Delete Space': 'Delete Space',
    'Leave Space': 'Leave Space',
    deleteSpaceHeader: (spaceName) => `Delete ${spaceName}?`,
    'Space name': 'Space name',
    deleteSpace1: 'You cannot undo this action.',
    deleteSpace2: 'To confirm, please enter the name of the space.',
    'Delete this group': 'Delete this group',
    groupDeleteDescription: (groupName) =>
      `Projects and documents in <b>*${groupName}</b> will move back to the <b>Projects</b> in your sidebar.`,
    deleteUserHeader: 'Permanent Account Deletion',
    deleteUser1: 'All of the Spaces you created will be deleted!',
    deleteUser2: 'You cannot undo this action.',
    deleteUser3: 'To confirm, please enter your email.',
    'Email address': 'email address',
    'Delete Account': 'Delete Account',
    'Leave Project Text':
      'Are you sure you want leave? You can be invited again anytime.',
    Leave: 'Leave',
  },
  documentUploadWarning: 'Only docx, pptx, xlsx can be uploaded',
  documentCreate: {
    'Start Typing with': 'Start Typing with',
    Title: 'Title',
    Project: 'Project',
    'New Project': 'New Project',
    Cancel: 'Cancel',
    Create: 'Create',
    Message: 'Your document will be safely stored in Google Drive',
    'Create Document': 'Create Document',
  },
  referenceCreate: {
    'Add reference': 'Add reference',
  },
  duplicateDocument: {
    'Duplicate document': 'Duplicate document',
    'Link All References': 'Link All References',
    Cancel: 'Cancel',
    Duplicate: 'Duplicate',
    Project: 'Project',
  },
  projectSelect: {
    'Select project': 'Select project',
    Project: 'Project',
    'New Project': 'New Project',
    Cancel: 'Cancel',
    Next: 'Next',
    Confirm: 'Confirm',
  },
  documentPageStatus: {
    'Done Writing': 'Done Writing!',
    'Done status change': 'You have completed the task.',
  },
  imageEditModal: {
    'Crop your Image': 'Crop your Image',
    Upload: 'Upload',
    Cancel: 'Cancel',
    'The Image is too big':
      'The Image is too big.' + '\n' + "Try one that's smaller than 10mb.",
    'Try Another Image': 'Try Another Image',
  },
  projectCreate: {
    'Name new project': 'Name new project',
    placeholder: 'Project title',
    Cancel: 'Cancel',
    Create: 'Create',
    'Make private': 'Make private',
    'Create private Description':
      'when a project is set to private, it can be viewed or joined only by invitation',
  },
  projectSettings: {
    Settings: 'Settings',
    Members: 'Members',
    'Project title': 'Project title',
    'Project description': 'Project description',
    'Created By': 'Created By',
    'Danger Zone': 'Danger Zone',
    'Leave Project': 'Leave Project',
    'Delete Project': 'Delete Project',
    'Save Changes': 'Save Changes',
    Cancel: 'Cancel',
    'Add member': 'Add member',
    Remove: 'Remove',
  },
  projectInvite: {
    'Add multiple people with comma or enter':
      'Add multiple people with comma or enter',
    'Skip for now': 'Skip for now',
    'Add people to': 'Add people to',
    'No match items': 'No matches found',
    Add: 'Add',
  },
  spaceCreate: {
    'Name your space': 'Name your space',
    placeholder: 'Space name',
    CreateSpace: 'Create Space',
    Cancel: 'Cancel',

    'Invite members': 'Invite members',
    Invite: 'Invite',
    Skip: 'Skip',

    storedSafelyMessage:
      'Collaborative documents will be' +
      '\n' +
      'safely stored in Typed Drive.',
    StartTyping: 'Start Typing!',
    invalidNameError:
      'No special characters allowed except hyphen(-) and underscore(_).',
    invalidEmptyNameError: 'You can’t leave this blank.',

    Notice: 'Notice',
    spaceGuideMessage2:
      'Moving documents and resources between Spaces is not possible.',
  },
  spaceInvite: {
    'Invite people': 'Invite people',
    'Invite members': 'Invite members',
    helperText: 'Add multiple people with comma or enter',
    Invite: 'Invite',
    Skip: 'Skip',
  },
  spaceSettings: {
    Settings: 'Settings',
    Members: 'Members',
    'Plans & Billing': 'Plans & Billing',
    'Space icon': 'Space icon',
    'Upload Image': 'Upload Image',
    'Remove Image': 'Remove Image',
    'Space name': 'Space name',
    'Danger Zone': 'Danger Zone',
    'Add member': 'Add member',
    'Save Changes': 'Save Changes',
    Cancel: 'Cancel',
    Pending: 'Pending',
    freePlanDescription:
      'You are currently on Typed Free trial. Upgrade to Typed Pro to unlock the full features!',
    proPlanDescription: `You are currently on Typed Pro plan. Reflecting recent changes in your space, you will be charged accordingly.`,
    cancelledPlanDescription: (plan: ITypedSubscriptionPlan) =>
      `You have cancelled your Pro Plan. You will be reverted to the <b>Free Plan</b> on <b>${dayjs(
        plan.end,
      ).format('MMMM D, YYYY')}.`,
    Storage: 'Storage',
    Document: 'Document',
    Editor: 'Editor',
    storageQuotaDescription: (current: number, max: number) =>
      `${formatBytes(current)} of ${formatBytes(max)} used`,
    appSumoEditorQuotaDescription: (current: number, max: number) =>
      `${current} ${current === 1 ? 'seat' : 'seats'} of ${max} seats used`,
    editors: 'editors',
    Monthly: 'Monthly',
    Yearly: 'Yearly',
    'per editor/month': 'per editor/month',
    'billed annually': 'billed annually',
    'billed monthly': 'billed monthly',
    Trial: 'Trial',
    Pro: 'Pro',
    Enterprise: 'Enterprise',
    'Current Plan': 'Current Plan',
    'Upgrade to Pro': 'Upgrade to Pro',
    'Contact Sales 🤙': 'Contact Sales 🤙',
    'Upgraded to Pro': 'Upgraded to Pro',
    'Upgraded to Enterprise': 'Upgraded to Enterprise',
    '1 document': '1 document',
    '1GB of reference storage': '1GB of reference storage',
    'Typed Extension': 'Typed Extension',
    'Typed Mobile': 'Typed Mobile',
    'Everything in Free, plus': 'Everything in Free, plus',
    'Unlimited documents': 'Unlimited documents',
    '10GB of storage per editor': '10GB of storage per editor',
    'Early access to new features': 'Early access to new features',
    'Everything in Pro, plus': 'Everything in Pro, plus',
    'Unlimited storage': 'Unlimited storage',
    'Advanced Knowledge Network': 'Advanced Knowledge Network',
    'Designated success managers': 'Designated success managers',
    'Custom Contract': 'Custom Contract',
    scholarship1: 'Are you a student? Apply for a Typed license',
    scholarship2: '.',
    unsubscribeDescription:
      'Cancelling your plan will revert your Space will revert to the Free Plan. You will be able to use your Space for the remaining subscription period.',
    unsubscribeGuideLeading:
      'If you want to cancel, please proceed the cancellation ',
    unsubscribeGuideHere: 'here',
    unsubscribeGuideTrailing: '.',
    'If you unsubscribe, you will be downgraded':
      'If you unsubscribe, you will be downgraded to the Free Plan by the end of this subscription period. You can upgrade again anytime.',
    'Failed to Unsubscribe': 'Failed to Unsubscribe',
    unsubscribeErrorDescription1:
      'The server encountered a temporary error and could not complete your request.',
    unsubscribeErrorDescription2:
      'Please try again after a while. If the problem persists, please contact ',
    unsubscribeErrorDescription3: '',
    'Try Again': 'Try Again',
    Unsubscribe: 'Unsubscribe',
    'Payment method': 'Payment method',
    Update: 'Update',
    Integrations: 'Integrations',
    'Slack Guide Description':
      'Connect and receive review request messages in Slack',
    'Slack Connect': 'Connect',
    'Slack Disconnect': 'Disconnect',
    IntegrationInfoLeadingText: 'Connect to',
    IntegrationInfoMiddleText: 'at',
    IntegrationInfoTrailingText: '',
  },
  resourceTag: {
    'mark as read': 'Marked as Read',
  },
  spaceMigration: {
    title: 'We’ve got something special for you. ',
    description:
      'Typed is currently undergoing a system upgrade to better streamline your documents. Please check back soon.',
    maximumDuration: 'Maximum Duration: 30 minutes',
    upgradeComplete: 'Upgrade Complete! ',
    updateCompleteDescription:
      'Due to document permission issues, we have been unable to migrate the following documents. You can bring them back into Typed by importing them.',
    emailUsTextLeft: 'Please email us at ',
    emailUsTextRight: ' regarding any inquiries.',
    upgradeFail: 'Upgrade Fail',
    failDescriptionFirstLine: 'An issue is blocking your upgrading process. ',
    failDescriptionSecondLineLeft: 'Please contact',
    failDescriptionSecondLineRight: 'to solve this issue.',
  },
  groupCreation: {
    'Name new group': 'Name new group',
    placeholder: 'ex: ‘Design team’, ‘marketing team’',
    Create: 'Create',
    Cancel: 'Cancel',
  },
  groupRename: {
    'Rename this group': 'Rename this group',
    Cancel: 'Cancel',
    Save: 'Save',
    Rename: 'Rename',
  },
  groupDelete: {
    Delete: 'Delete',
  },
  quotaExcess: {
    'Upgrade your plan': 'Upgrade your plan',
    '1docsLimitDesc1':
      'Don’t hold yourself back. Unlock limitless documents for limitless knowledge.',
    '1docsLimitDesc2': 'You reached your 1 document limit',
    storageLimitDesc1:
      'Don’t hold yourself back. Unlock limitless storage for limitless knowledge.',
    storageLimitDesc2: 'You reached your 1 GB storage limit',
    moreTemplateDesc1:
      '더 많은 템플릿을 원하시나요? 유료인 템플릿을 보기 위해서는 결제가 필요합니다.',
    moreTemplateDesc2:
      '여러분들을 위해 Team Typed가 준비해놓은, 더욱 유용한 자료들을 활용해보세요!',
  },
  viewModal: {
    pageView: 'Page View',
    uniqueViewers: 'Unique Viewers',
    lastViewed: 'Last viewed by',
  },
}

const koreanModalText: ModalText = {
  icpModal: {
    previous: '이전',
    next: '다음',
    done: '완료',
    'Product Tour': '프로덕트 투어',
    'Product Tour Description': '여러분의 지식을 연결하고 확장할 시간입니다.',
    'Member Management': '멤버 관리',
    'Member Management Description': '멤버를 초대하여 협업을 할 수 있습니다.',
    'Create Documents': '문서 생성',
    'Create Documents Description':
      'Google Docs, Slide, Sheet를 생성할 수 있습니다. 내 컴퓨터와 구글 드라이브에 있는 문서들도 업로드할 수 있습니다.',
    'Drag & Drop': 'Drag & Drop',
    'Drag & Drop Description':
      '내 컴퓨터에 있는 파일을 쉽게 문서의 참고자료로 업로드할 수 있습니다.',
    'Spit Viewer': 'Spit Viewer',
    'Spit Viewer Description':
      '참고자료를 한 화면에서 보면서 문서 작성을 할 수 있습니다.',
    highlight: '하이라이트',
    'highlight Description':
      'URL, PDF 참고자료에 하이라이트 표시를 할 수 있습니다.',
    videoGuide: '영상가이드 보러가기',
    userGuide: '유저가이드 보러가기',
    videoGuideUrl:
      'https://www.youtube.com/watch?v=icZdURAjto0&list=PLO6Ov0YONqdTdBV3nid3PWyVZD9V8YnJL',
    userGuideUrl: ' https://company.typed.do/academy-kr',
    'Learn More': '더 알아보기',
  },
  deleteModal: {
    'Are you sure': '잠시만요! 😱',
    projectDelete:
      "'삭제'를 누르시면 프로젝트와 그 안에 있는 문서는 완전히 사라져요!",
    documentDelete:
      "'삭제'를 누르시면 문서와 그 안에 있는 참고자료는 완전히 사라져요!",
    folderDeletModal:
      "'삭제'를 누르시면 폴더와 그 안에 있는 참고자료는 완전히 사라져요!",
    spaceMemberRemove1: '정말로 ',
    spaceMemberRemove2:
      '님을 삭제하시겠습니까? 언제든 다시 초대할 수 있습니다.',
    spaceLeave:
      '정말로 이 스페이스를 나가시겠습니까? 언제든지 다시 초대될 수 있습니다.',
    Cancel: '취소',
    Delete: '삭제',
    Remove: '삭제',
    'Delete Space': '스페이스 삭제',
    'Leave Space': '스페이스 나가기',
    deleteSpaceHeader: (spaceName) => `${spaceName}을 삭제하시겠습니까?`,
    'Space name': '스페이스 이름',
    deleteSpace1: '이 작업은 취소할 수 없습니다.',
    deleteSpace2: '삭제하려면 스페이스 이름을 입력하세요.',
    'Delete this group': '이 그룹을 삭제하시겠습니까?',
    groupDeleteDescription: (groupName: string) =>
      `<b>*${groupName}</b>의 프로젝트와 도큐먼트가 사이드바의 <b>Projects</b>로 되돌아갑니다.`,
    deleteUserHeader: '계정 영구 삭제',
    deleteUser1: '계정 삭제 시 소유하고 있는 스페이스는 삭제됩니다!',
    deleteUser2: '이 작업은 취소할 수 없으며, 계정 삭제를',
    deleteUser3: '진행하기 위해 이메일 주소를 입력하세요.',
    'Email address': '이메일 주소',
    'Delete Account': '계정 삭제',
    'Leave Project Text':
      '정말로 이 프로젝트를 나가시겠습니까? 언제든지 다시 초대될 수 있습니다.',
    Leave: '나가기',
  },
  documentUploadWarning: 'docx, pptx, xlsx 파일만 업로드 가능해요.',
  documentCreate: {
    'Start Typing with': '문서 작성을 시작해보세요!',
    Title: '제목',
    Project: '프로젝트',
    'New Project': '새 프로젝트',
    Cancel: '취소',
    Create: '생성',
    Message: 'Typer님의 소중한 문서는 구글드라이브에 안전하게 저장돼요!',
    'Create Document': '새 문서 생성',
  },
  referenceCreate: {
    'Add reference': '참고자료 추가',
  },
  duplicateDocument: {
    'Duplicate document': '문서 복제',
    'Link All References': '모든 참고자료 링크하기',
    Cancel: '취소',
    Duplicate: '복제',
    Project: '프로젝트',
  },
  projectSelect: {
    'Select project': '프로젝트 선택',
    Project: '프로젝트',
    'New Project': '새 프로젝트',
    Cancel: '취소',
    Next: '다음',
    Confirm: '확인',
  },
  documentPageStatus: {
    'Done Writing': '문서 작업을 완료했어요!',
    'Done status change': '작업을 완료했습니다.',
  },
  imageEditModal: {
    'Crop your Image': '사진 자르기',
    Upload: '업로드',
    Cancel: '취소',
    'The Image is too big':
      '사진이 너무 커요.' + '\n' + '10mb 이하의 사진을 업로드 해주세요.',
    'Try Another Image': '다른 사진 시도',
  },
  projectCreate: {
    'Name new project': '새 프로젝트',
    placeholder: '프로젝트 타이틀',
    Cancel: '취소',
    Create: '생성',
    'Make private': '비공개로 만들기',
    'Create private Description':
      '프로젝트가 비공개로 설정된 경우 초대를 통해서만 조회 또는 참여할 수 있습니다.',
  },
  projectSettings: {
    Settings: '설정',
    Members: '멤버',
    'Project title': '프로젝트 제목',
    'Project description': '프로젝트 설명',
    'Created By': '생성자',
    'Danger Zone': '주의!',
    'Leave Project': '프로젝트 나가기',
    'Delete Project': '프로젝트 지우기',
    'Save Changes': '변경 사항 저장',
    Cancel: '취소',
    'Add member': '멤버 추가',
    Remove: '삭제',
  },
  projectInvite: {
    'Add multiple people with comma or enter':
      '쉼표나 엔터를 입력하여 여러 사람을 초대하세요.',
    'Skip for now': '건너뛰기',
    'Add people to': '에 사용자 추가',
    'No match items': '일치하는 항목 없음',
    Add: '추가',
  },
  spaceCreate: {
    'Name your space': '스페이스 이름을 입력하세요.',
    placeholder: '스페이스 이름',
    CreateSpace: '스페이스 생성',
    Cancel: '취소',

    'Invite members': '멤버를 초대하세요.',
    Invite: '멤버 초대',
    Skip: '건너뛰기',

    storedSafelyMessage: '팀 문서는 타입드 드라이브에 안전하게 저장됩니다.',
    StartTyping: '시작하기',
    invalidNameError: '‘_’, ‘-’ 외의 특수문자는 사용할 수 없습니다.',
    invalidEmptyNameError: '이 항목은 비워둘 수 없습니다.',

    Notice: '참고사항',
    spaceGuideMessage2:
      '스페이스 간의 정보 이동과 공유는 불가능하니 스페이스 생성 시 유의해주세요.',
  },
  spaceInvite: {
    'Invite people': '멤버를 초대하세요.',
    'Invite members': '멤버를 초대하세요.',
    Invite: '멤버 초대',
    helperText: '쉼표나 엔터를 입력하여 여러 사람을 초대하세요.',
    Skip: '건너뛰기',
  },
  spaceSettings: {
    Settings: '설정',
    Members: '멤버',
    'Plans & Billing': '요금제 & 청구',
    'Space icon': '스페이스 아이콘',
    'Upload Image': '이미지 업로드',
    'Remove Image': '이미지 제거',
    'Space name': '스페이스 이름',
    'Danger Zone': '주의!',
    'Add member': '멤버 추가',
    'Save Changes': '변경 사항 저장',
    Cancel: '취소',
    Pending: '수락 대기',
    freePlanDescription:
      '현재 체험판을 사용하고 있습니다. 모든 기능들을 사용하기 위해 프로 플랜으로 업그레이드하세요!',
    proPlanDescription: `현재 프로 플랜을 사용하고 있습니다. 최종 구독료는 스페이스 내 변동사항을 모두 반영하여 부과됩니다.`,
    cancelledPlanDescription: (plan: ITypedSubscriptionPlan) =>
      `프로 플랜을 취소하셨습니다. <b>${dayjs(plan.end).format(
        'YYYY년 M월 D일',
      )}</b>에 이 스페이스는 <b>무료 플랜</b>으로 전환됩니다.`,
    Storage: '저장용량',
    Document: '문서',
    Editor: '편집자',
    storageQuotaDescription: (current: number, max: number) =>
      `${formatBytes(max)} 중 ${formatBytes(current)} 사용`,
    appSumoEditorQuotaDescription: (current: number, max: number) =>
      `${current} seats of ${max} seats used`,
    editors: '명',
    Monthly: '월간',
    Yearly: '연간',
    'per editor/month': '에디터당/월',
    'billed annually': '매년 청구',
    'billed monthly': '매월 청구',
    'Current Plan': '현재 플랜',
    Trial: '체험판',
    Pro: '프로',
    Enterprise: '기업',
    'Upgrade to Pro': 'Pro로 업그레이드',
    'Contact Sales 🤙': '영업 문의 🤙',
    'Upgraded to Pro': '프로 플랜 구독 중',
    'Upgraded to Enterprise': '기업 플랜 구독 중',
    '1 document': '1개 문서 제한',
    '1GB of reference storage': '참고자료 용량 1GB',
    'Typed Extension': 'Typed 크롬 확장 프로그램',
    'Typed Mobile': 'Typed 모바일 앱',
    'Everything in Free, plus': '무료 플랜 모든 기능 +',
    'Unlimited documents': '무제한 문서',
    '10GB of storage per editor': '참고자료 에디터당 10GB',
    'Early access to new features': '신규 기능 미리보기',
    'Everything in Pro, plus': '프로 플랜 모든 기능 +',
    'Unlimited storage': '무제한 리소스 용량',
    'Advanced Knowledge Network': '고급 지식 네트워크',
    'Designated success managers': '전담 고객 매니저',
    'Custom Contract': '맞춤형 계약',
    scholarship1: '혹시 학생이신가요? 1년 학생용 무료 라이센스는',
    scholarship2: '에서 신청해주세요.',
    unsubscribeDescription:
      '구독을 취소하면 해당 스페이스는 무료 플랜으로 돌아가게 되며, 남은 구독 기간은 정상적으로 사용이 가능합니다.',
    unsubscribeGuideLeading: '',
    unsubscribeGuideHere: '여기서',
    unsubscribeGuideTrailing: ' 구독 취소를 진행해주세요.',
    'If you unsubscribe, you will be downgraded':
      '구독 취소를 진행하시면 무료플랜으로 돌아가게 됩니다. 언제든지 다시 업그레이드 할 수 있습니다.',
    'Failed to Unsubscribe': '구독 취소에 실패했습니다.',
    unsubscribeErrorDescription1:
      '일시적인 서버 문제로 인하여, 구독 취소에 실패했습니다.',
    unsubscribeErrorDescription2:
      '잠시후에 다시 시도해주세요. 문제가 지속되면 ',
    unsubscribeErrorDescription3: '에 연락주세요.',
    'Try Again': '다시 시도',
    Unsubscribe: '구독 취소',
    'Payment method': '결제 방법',
    Update: '업데이트',
    Integrations: '앱',
    'Slack Guide Description':
      '검토 요청 받은 알림을 슬랙에서도 확인할 수 있어요.',
    'Slack Connect': '연동',
    'Slack Disconnect': '연동 해제',
    IntegrationInfoLeadingText: '',
    IntegrationInfoMiddleText: '님이',
    IntegrationInfoTrailingText: '에 연동했어요.',
  },
  resourceTag: {
    'mark as read': '읽음 처리 되었어요!',
  },
  spaceMigration: {
    title: '지금 Typed는 부스터를 다는 중입니다!',
    description:
      'Typed는 더욱 안정적인 서비스와 보안을 위하여 업데이트 중 입니다. 잠시후 업데이트 된 Typed와 함께 더욱더 효율적인 지식관리를 경험해 보세요!',
    maximumDuration: '최대 소요 시간: 30분',
    upgradeComplete: '업그레이드 완료! ',
    updateCompleteDescription:
      '문서 권한 관련 이슈로 인해 몇몇 문서들을 이전하지 못했습니다. 구글 드라이브에서 임포트를 진행해주세요! ',
    emailUsTextLeft: '문제 발생 시 ',
    emailUsTextRight: ' 로 문의 남겨주세요.',
    upgradeFail: '업그레이드 실패',
    failDescriptionFirstLine: '시스템 이슈로 인해 업그레이드가 중단되었습니다.',
    failDescriptionSecondLineLeft: '이슈를 해결하기 위해',
    failDescriptionSecondLineRight: '로 연락주세요.',
  },
  groupCreation: {
    'Name new group': '새 그룹',
    placeholder: '예: ‘디자인 팀’, ‘마케팅 팀’',
    Create: '생성',
    Cancel: '취소',
  },
  groupRename: {
    'Rename this group': '그룹 이름 변경',
    Cancel: '취소',
    Save: '저장',
    Rename: '이름 변경',
  },
  groupDelete: {
    Delete: '삭제',
  },
  quotaExcess: {
    'Upgrade your plan': '플랜을 업그레이드하세요',
    '1docsLimitDesc1':
      '제한 없이 늘어나는 문서만큼, 무한히 확장되는 지식을 경험해보세요!',
    '1docsLimitDesc2': '문서 1개에 도달했습니다.',
    storageLimitDesc1:
      '제한 없이 늘어나는 용량만큼, 무한히 확장되는 지식을 경험해보세요!',
    storageLimitDesc2: '용량 1GB에 도달했습니다.',
    moreTemplateDesc1:
      '더 많은 템플릿을 원하시나요? 유료인 템플릿을 보기 위해서는 결제가 필요합니다.',
    moreTemplateDesc2:
      '여러분들을 위해 Team Typed가 준비해놓은, 더욱 유용한 자료들을 활용해보세요!',
  },
  viewModal: {
    pageView: '조회 수',
    uniqueViewers: '방문자 수',
    lastViewed: '마지막으로 조회한 사람',
  },
}

export { englishModalText, koreanModalText }
