import {
  ITypedDocumentCreatedBy,
  TypedDocument,
} from '@/models/document/TypedDocument'

export type IFileDocumentOrigin = {
  readonly provider: 'typed'
  readonly signedUrl: string
}

class TypedFileDocument extends TypedDocument {
  constructor(
    readonly projectId: string,
    readonly documentId: string,
    readonly docType: 'pdf',
    readonly title: string,
    readonly createdBy: ITypedDocumentCreatedBy | undefined,
    readonly defaultFolderId: string,
    readonly numResources: number,
    readonly createdAt: number,
    readonly updatedAt: number,
    readonly isCompleted: boolean,
    readonly favorite: boolean,
    readonly origin: IFileDocumentOrigin,
  ) {
    super(
      projectId,
      documentId,
      docType,
      title,
      createdBy,
      defaultFolderId,
      numResources,
      createdAt,
      updatedAt,
      isCompleted,
      favorite,
      origin,
    )
  }

  copyWith(options?: Partial<TypedFileDocument>): TypedFileDocument {
    return new TypedFileDocument(
      options?.projectId ?? this.projectId,
      options?.documentId ?? this.documentId,
      options?.docType ?? this.docType,
      options?.title ?? this.title,
      options?.createdBy ?? this.createdBy,
      options?.defaultFolderId ?? this.defaultFolderId,
      options?.numResources ?? this.numResources,
      options?.createdAt ?? this.createdAt,
      options?.updatedAt ?? this.updatedAt,
      options?.isCompleted ?? this.isCompleted,
      options?.favorite ?? this.favorite,
      options?.origin ?? this.origin,
    )
  }
}

export default TypedFileDocument
