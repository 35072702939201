import { createSlice } from '@reduxjs/toolkit'
import ClosedViewerStatus from '@/models/viewerStatus/ClosedViewerStatus'
import ViewerStatus from '@/models/viewerStatus/ViewerStatus'

interface IViewerDataState {
  viewerStatus: ViewerStatus

  isReadModeHtmlMounted: boolean
  highlightIdToScroll: string | null
  targetFolderId: string | null
  isDocPageViewerSwapped: boolean
}

const initialState: IViewerDataState = {
  viewerStatus: new ClosedViewerStatus(),
  isReadModeHtmlMounted: false,
  highlightIdToScroll: null,
  targetFolderId: null,
  isDocPageViewerSwapped: false,
}

export const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  reducers: {
    setViewerStatus: (state, action: { payload: ViewerStatus }) => {
      state.viewerStatus = action.payload
    },
    setIsReadModeHtmlMounted: (state) => {
      state.isReadModeHtmlMounted = true
    },
    resetIsReadModeHtmlMounted: (state) => {
      state.isReadModeHtmlMounted = false
    },
    setHighlightIdToScroll: (state, action) => {
      state.highlightIdToScroll = action.payload
    },
    setTargetFolderId: (state, action) => {
      state.targetFolderId = action.payload
    },
    swapViewer: (state) => {
      state.isDocPageViewerSwapped = !state.isDocPageViewerSwapped
    },
  },
})

export const {
  setViewerStatus,
  setIsReadModeHtmlMounted,
  resetIsReadModeHtmlMounted,
  setHighlightIdToScroll,
  setTargetFolderId,
  swapViewer,
} = viewerSlice.actions

export default viewerSlice.reducer
