interface SpaceText {
  Owner: string
  Editor: string
  Viewer: string
  'Create Space': string
  searchSpace: (spaceName: string | undefined) => string
  numSpaceMemeber: (num: number) => string
}

const englishSpaceText: SpaceText = {
  Owner: 'Owner',
  Editor: 'Editor',
  Viewer: 'Viewer',
  'Create Space': 'Create Space',
  searchSpace: (spaceName: string | undefined) => `Search ${spaceName}`,
  numSpaceMemeber: (num: number) => `${num} members`,
}

const koreanSpaceText: SpaceText = {
  Owner: '소유자',
  Editor: '편집자',
  Viewer: '뷰어',
  'Create Space': '스페이스 생성',
  searchSpace: (spaceName: string | undefined) => `${spaceName} 전체 검색`,
  numSpaceMemeber: (num: number) => `멤버 ${num}명`,
}

export { englishSpaceText, koreanSpaceText }
