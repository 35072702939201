import { getSpecificDOMNode } from '@/utils/common/helper'
import {
  anchorHandler,
  constants,
  HighlightElementManager,
} from '@/utils/highlights'
import {
  IHighlightTool,
  ISelection,
  TOOLTIP_TYPES,
} from '@/utils/viewers/highlightTool.util'
import { TypedHighlight } from '@/models/highlight'

export default class SelectionParser {
  static createDataToHighlight(pageIndex?: number): {
    selection: ISelection | null
    tooltipData: IHighlightTool | null
  } {
    const selection = HighlightElementManager.getRoot().getSelection()
    if (!selection || selection?.isCollapsed) {
      return { selection: null, tooltipData: null }
    }

    if (!SelectionParser.validateSelection(selection)) {
      return {
        selection: null,
        tooltipData: {
          type: TOOLTIP_TYPES.DISABLED,
          highlightId: null,
          position: SelectionParser.getTooltipPosition(selection),
        },
      }
    }

    return {
      selection: {
        text: { core: selection.toString(), pageIndex },
        range: anchorHandler.extractAnchorFromSelection({
          selection,
          pageIndex,
        }) as TypedHighlight['range'],
      },
      tooltipData: {
        type: TOOLTIP_TYPES.ADD,
        highlightId: null,
        position: SelectionParser.getTooltipPosition(selection),
      },
    }
  }

  private static validateSelection(selection: Selection) {
    const selectedCoreText = selection.toString()
    if (!selectedCoreText.trim()) return false

    const viewer = HighlightElementManager.getViewer()
    const isInsideViewer = getSpecificDOMNode(
      selection.getRangeAt(0).commonAncestorContainer,
      (DOMNode: Node) => viewer === DOMNode,
    )
    if (!isInsideViewer) return false

    return true
  }

  private static getTooltipPosition(selection: Selection) {
    const _range = document.createRange()
    _range.setStart(selection.anchorNode!, selection.anchorOffset)
    _range.setEnd(selection.focusNode!, selection.focusOffset)
    const backwards = _range.collapsed

    _range.detach()

    const rects = selection.getRangeAt(0).getClientRects()

    const n = rects.length - 1

    const additionalPos = HighlightElementManager.getTooltipAdditionalPosition()

    if (backwards) {
      return {
        top:
          Math.round(rects[0].top) +
          constants.toolPosition.backward.top +
          additionalPos.top,
        left:
          Math.round(rects[0].left) +
          constants.toolPosition.backward.left +
          additionalPos.left,
      }
    }

    return {
      top:
        Math.round(rects[n].top) +
        constants.toolPosition.forward.top +
        additionalPos.top,
      left:
        Math.round(rects[n].right) +
        constants.toolPosition.forward.left +
        additionalPos.left,
    }
  }
}
