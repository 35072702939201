import { Store } from 'redux'
import { isEmpty } from 'lodash-es'
import ResourceAPI from '@/api/resourceAPI'
import TypedResource, {
  IResourceDuplicateInfo,
  TypedResourceType,
} from '@/models/resource/TypedResource'
import { HighlightableResources } from '@/models/resource/HighlightableResources'
import { TypedFileResource } from '@/models/resource/TypedFileResource'
import FolderRepository from '@/repositories/folderRepository'
import InboxRepository from '@/repositories/inboxRepository'
import { setViewerStatus } from '@/slices/viewers/viewerSlice'
import autoBind from '@/utils/autoBind'
import ResourceRepository from '@/repositories/resourceRepository'
import HighlightAPI from '@/api/highlightAPI'
import FolderAPI from '@/api/folderAPI'
import InboxAPI from '@/api/inboxAPI'
import { ResourceEvent } from '@/integrations/logging/event/resource'
import IntercomManager from '@/integrations/intercom/IntercomManager'
import { INBOX_FOLDER_ID } from '@/models/inbox'
import { gb2byte, MAX_FILE_GB } from '@/util'
import DuplicateService from '@/services/duplicateService'
import { getRemovedDuplicateFiles } from '@/api/helper'
import ClipboardService from '@/services/clipboardService'
import GooglePickerService from '@/services/googlePickerService'
import { AddedFrom } from '@/services/resourceAdderService'
import RequestingService from '@/services/requestingService'
import TypedUrlResource from '@/models/resource/TypedUrlResource'
import InstanceViewerStatus from '@/models/viewerStatus/InstanceViewerStatus'
import ClosedViewerStatus from '@/models/viewerStatus/ClosedViewerStatus'

class InboxService {
  constructor(
    private readonly inboxRepo: InstanceType<typeof InboxRepository>,
    private readonly folderRepo: InstanceType<typeof FolderRepository>,
    private readonly resourceRepo: InstanceType<typeof ResourceRepository>,
    private readonly resourceAPI: InstanceType<typeof ResourceAPI>,
    private readonly highlightAPI: InstanceType<typeof HighlightAPI>,
    private readonly folderAPI: InstanceType<typeof FolderAPI>,
    private readonly inboxAPI: InstanceType<typeof InboxAPI>,
    private readonly store: Store,
    private readonly resourceEvent: ResourceEvent,
    private readonly duplicateService: InstanceType<typeof DuplicateService>,
    private readonly clipboardService: InstanceType<typeof ClipboardService>,
    private readonly googlePickerService: InstanceType<
      typeof GooglePickerService
    >,
    private readonly requestingService: InstanceType<typeof RequestingService>,
  ) {}

  async moveResource(
    resourceToMove: TypedResource,
    targetDocumentId: string,
    targetFolderId: string,
    spaceId: string,
  ): Promise<void> {
    const inbox = this.inboxRepo.find()
    if (!inbox) return
    const resourceIdx = inbox.resourceList.findIndex(
      (resource) => resource.resourceId === resourceToMove.resourceId,
    )
    const nextResource = inbox.resourceList[resourceIdx + 1]
    const prevResource = inbox.resourceList[resourceIdx - 1]

    if (nextResource) {
      this.store.dispatch(
        setViewerStatus(new InstanceViewerStatus(nextResource)),
      )
    } else if (prevResource) {
      this.store.dispatch(
        setViewerStatus(new InstanceViewerStatus(prevResource)),
      )
    } else {
      this.store.dispatch(setViewerStatus(new ClosedViewerStatus()))
    }

    const targetFolder = this.folderRepo.find(targetFolderId)
    if (targetFolder) {
      this.folderRepo.update(targetFolderId, {
        resourceList: [...targetFolder.resourceList, resourceToMove.resourceId],
      })
    }

    this.inboxRepo.update('inbox', {
      resourceList: inbox.resourceList.filter(
        (resource) => resource.resourceId !== resourceToMove.resourceId,
      ),
    })

    await this.moveInboxResourceToSpace(
      resourceToMove,
      targetDocumentId,
      targetFolderId,
      spaceId,
    )
  }

  async moveInboxResourceToSpace(
    resourceToMove: TypedResource,
    targetDocumentId: string,
    targetFolderId: string,
    spaceId: string,
  ): Promise<void> {
    // TODO: 추후 resourceToMove의 타입이 항상 TypedURL, TypedFILE로 instance 올 수 있도록 변경
    let duplicateResource: IResourceDuplicateInfo
    if (resourceToMove.type === 'url') {
      duplicateResource =
        await this.resourceAPI.getSpaceUrlResourceDuplicateInfo(
          (resourceToMove as TypedUrlResource).data.url,
          spaceId,
        )
    } else if (resourceToMove.type === 'file') {
      duplicateResource =
        await this.resourceAPI.getSpaceFileResourceDuplicateInfo(
          (resourceToMove as TypedFileResource).metadata.md5Hash,
          spaceId,
        )
    } else {
      throw new Error('Invalid resource type')
    }

    const updatedResource = await this.resourceAPI.moveResourceFromInbox(
      resourceToMove.resourceId,
      targetFolderId,
      spaceId,
    )

    if (isEmpty(duplicateResource!)) return

    const targetFolderHasDuplicateRsc = duplicateResource!.sourceFolders.find(
      (folder) => folder.folderId === targetFolderId,
    )

    const cachedResource = this.resourceRepo.find(updatedResource.resourceId)
    if (cachedResource) {
      this.resourceRepo.update(updatedResource.resourceId, {
        name: updatedResource.name,
      })

      if ('highlightCount' in cachedResource) {
        const pdfHighlights = await this.highlightAPI.getPDFHighlights(
          updatedResource.resourceId,
        )
        const urlHighlights = await this.highlightAPI.getURLHighlights(
          updatedResource.resourceId,
        )
        this.resourceRepo.update<HighlightableResources>(
          updatedResource.resourceId,
          {
            highlightCount: pdfHighlights.length + urlHighlights.length,
          },
        )
      }
    }

    if (!targetFolderHasDuplicateRsc) {
      const linkedResourceId = duplicateResource!.sourceFolders[0].resourceId
      const linkedDocumentId =
        duplicateResource!.sourceFolders[0].documentData.documentId
      await this.resourceAPI.linkResource(
        linkedResourceId,
        targetFolderId,
        targetDocumentId,
      )

      const updatedLinkedFolderData = await this.folderAPI.getSpecificFolder(
        targetFolderId,
        spaceId,
      )
      this.folderRepo.update(
        updatedLinkedFolderData.folderId,
        updatedLinkedFolderData,
      )

      const sourceResource = this.resourceRepo.find(linkedResourceId)
      if (sourceResource) {
        this.resourceRepo.update(linkedResourceId, {
          backlinks: [...sourceResource!.backlinks, linkedDocumentId],
        })
      }
    }
  }

  async addUrlResource(
    url: string,
    addedFrom: AddedFrom = 'addnew',
  ): Promise<void> {
    const hasDuplicate = await this.duplicateService.addNonDuplicateUrlResource(
      { documentId: 'inbox', folderId: 'inbox' },
      'inbox',
      url,
      '',
    )

    if (hasDuplicate) {
      return
    }

    const inboxResourceSkeletonIds = this.requestingService.inboxResources.add([
      { resourceType: TypedResourceType.URL },
    ])
    try {
      const newResource = await this.inboxAPI.addUrlNode(url)
      const inboxFolderData = await this.inboxAPI.getInboxData()

      this.inboxRepo.update(inboxFolderData.folderId, inboxFolderData)
      this.resourceRepo.add(newResource)

      this.resourceEvent.createdEvent({
        ...newResource,
        addedFrom,
        addedToInbox: true,
        documentId: 'inbox',
      })
      IntercomManager.resource.sendCreatedEvent()
    } finally {
      this.requestingService.inboxResources.remove(inboxResourceSkeletonIds)
    }
  }

  async addFileResources(
    files: File[],
    addedFrom: AddedFrom = 'addnew',
  ): Promise<void> {
    const newResources: TypedFileResource[] = []

    if (files.some((file) => file.size > gb2byte(MAX_FILE_GB))) {
      alert(`Only files under ${MAX_FILE_GB}gb can be uploaded.`)
    }

    const nonDuplicateFiles =
      await this.duplicateService.addNonDuplicateFileResources(
        { documentId: 'inbox', folderId: 'inbox' },
        'inbox',
        files,
        '',
      )

    const uniqFiles = await getRemovedDuplicateFiles(nonDuplicateFiles)

    const inboxResourceSkeletonIds = this.requestingService.inboxResources.add([
      { resourceType: TypedResourceType.FILE },
    ])
    try {
      for (const file of uniqFiles) {
        const newResource = await this.inboxAPI.addFileNodes(file)
        newResources.push(newResource)

        this.resourceEvent.createdEvent({
          documentId: 'inbox',
          ...newResource,
          addedFrom,
          addedToInbox: true,
        })
        IntercomManager.resource.sendCreatedEvent()
      }

      const inbox = this.inboxRepo.find()

      if (!inbox) {
        console.error(`Can't find inbox.`)
      }

      this.resourceRepo.addAll(newResources)
      this.inboxRepo.update(INBOX_FOLDER_ID, {
        resourceList: [...newResources, ...(inbox?.resourceList ?? [])],
      })
    } finally {
      this.requestingService.inboxResources.remove(inboxResourceSkeletonIds)
    }
  }

  async deleteResource(
    resourceToBeDeleted: TypedUrlResource | TypedFileResource,
  ): Promise<void> {
    await this.resourceAPI.deleteResource(
      resourceToBeDeleted.resourceId,
      INBOX_FOLDER_ID,
    )

    const inbox = this.inboxRepo.find()
    if (!inbox) return

    const resourceIdx = inbox.resourceList.findIndex(
      (resource) => resource.resourceId === resourceToBeDeleted.resourceId,
    )

    const nextResourceIdx = resourceIdx + 1
    if (nextResourceIdx === 0) return
    const nextResource = inbox.resourceList[nextResourceIdx]

    const updatedResourceList = inbox.resourceList.filter(
      (resource) => resource.resourceId !== resourceToBeDeleted.resourceId,
    )

    if (nextResource) {
      this.store.dispatch(
        setViewerStatus(new InstanceViewerStatus(nextResource)),
      )
    } else {
      const prevResourceIdx = resourceIdx - 1
      const prevResource = inbox.resourceList[prevResourceIdx]

      if (!prevResource) {
        this.store.dispatch(setViewerStatus(new ClosedViewerStatus()))
      } else {
        this.store.dispatch(
          setViewerStatus(new InstanceViewerStatus(prevResource)),
        )
      }
    }

    this.inboxRepo.update(INBOX_FOLDER_ID, {
      resourceList: updatedResourceList,
    })

    this.resourceEvent.deletedEvent([
      {
        ...resourceToBeDeleted,
        resourceId: resourceToBeDeleted.resourceId,
        folderId: INBOX_FOLDER_ID,
      },
    ])
  }

  async addResourceFromClipboard(): Promise<void> {
    const urlFromClipboard = await this.clipboardService.getUrlFromClipboard()
    const imagesFromClipboard =
      await this.clipboardService.getImagesFromClipboard()

    this.resourceEvent.clipboardPasteButtonClickedEvent()
    this.clipboardService.deleteClipboardData()

    if (urlFromClipboard) {
      this.addUrlResource(urlFromClipboard, 'clipboard')
    } else if (imagesFromClipboard) {
      await this.addFileResources(imagesFromClipboard, 'clipboard')
    }
  }

  async addResourceFromGoogleDrive(): Promise<void> {
    const googlePickerResponse =
      await this.googlePickerService.buildGooglePicker(
        GooglePickerService.fileResourceMimeTypes.join(','),
      )

    if (!googlePickerResponse) {
      return
    }

    googlePickerResponse.docs.map(async (doc) => {
      const fileBuffer =
        await this.googlePickerService.downloadFileFromGoogleDrive(doc.id)
      if (!fileBuffer) return

      const file = new File([fileBuffer], doc.name, { type: doc.mimeType })
      await this.addFileResources([file])
    })
  }
}

export default autoBind(InboxService)
