import { identity } from 'lodash-es'

type LocalStorageManagerOptions<T> = {
  parser?: (rawData: any) => T
}

export default class LocalStorageManager<T> {
  readonly parser: (rawData: any) => T

  constructor(
    readonly key: string,
    { parser = identity }: LocalStorageManagerOptions<T> = {},
  ) {
    this.parser = parser
  }

  getData(): T | undefined {
    const rawData = this.getRawData()
    if (rawData === undefined) {
      return undefined
    }

    return this.parser(rawData)
  }

  setData(data: T) {
    localStorage.setItem(this.key, JSON.stringify(data))
  }

  removeData() {
    localStorage.removeItem(this.key)
  }

  private getRawData(): any | undefined {
    const jsonString = localStorage.getItem(this.key)
    if (!jsonString) {
      return undefined
    }

    try {
      return JSON.parse(jsonString)
    } catch {
      return jsonString
    }
  }
}
