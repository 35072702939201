interface DropDownText {
  addResourceDropdown: {
    'Add New': string
    'Add Resource': string
    'Add URL link': string
    'Add a new file': string
    'Add from Google Drive': string
    'Create a new folder': string
    'Type new Memo': string
    'Typed Note': string
  }
  addDocumentDropdown: {
    'Create a new doc': string
    'Add Document': string
    'Create New': string
    'Open from Local': string
    'Google Drive': string
  }
  dropdownButtonName: {
    Folder: string
    Document: string
    Resource: string
  }
}

const englishDropDownText: DropDownText = {
  addResourceDropdown: {
    'Add New': 'Add New',
    'Add Resource': 'Add Resource',
    'Add URL link': 'URL',
    'Add a new file': 'File',
    'Add from Google Drive': 'Google Drive',
    'Create a new folder': 'Folder',
    'Type new Memo': 'Memo',
    'Typed Note': 'Typed Note',
  },
  addDocumentDropdown: {
    'Create a new doc': 'Create a new doc',
    'Add Document': 'Add Document',
    'Create New': 'Create New',
    'Open from Local': 'Open from Local',
    'Google Drive': 'Google Drive',
  },
  dropdownButtonName: {
    Folder: 'Folder',
    Document: 'Document',
    Resource: 'Resource',
  },
}

const koreanDropDownText: DropDownText = {
  addResourceDropdown: {
    'Add New': '참고자료 추가',
    'Add Resource': '참고자료 추가',
    'Add URL link': 'URL',
    'Add a new file': '첨부파일',
    'Add from Google Drive': '구글 드라이브',
    'Typed Note': '타입드 노트',
    'Create a new folder': '폴더',
    'Type new Memo': '메모',
  },
  addDocumentDropdown: {
    'Create a new doc': '새로운 문서 생성',
    'Add Document': '문서 추가',
    'Create New': '빈 문서 생성',
    'Open from Local': '파일 업로드',
    'Google Drive': '구글 드라이브',
  },
  dropdownButtonName: {
    Folder: '폴더',
    Document: '문서',
    Resource: '참고자료',
  },
}

export { englishDropDownText, koreanDropDownText }
