import { sendErrorLog } from '@/integrations/sentry/sentryLogger'
import {
  ITypedDocument,
  TypedDocumentOrigin,
  TypedDocument,
  TypedDocumentType,
} from '@/models/document/TypedDocument'
import TypedEditorDocument, {
  IEditorDocumentOrigin,
} from '@/models/document/TypedEditorDocument'
import TypedFileDocument, {
  IFileDocumentOrigin,
} from '@/models/document/TypedFileDocument'
import TypedGoogleDocument, {
  GoogleDocumentType,
  IGoogleDocumentOrigin,
} from '@/models/document/TypedGoogleDocument'

export function getDocumentInstanceByJson(json: ITypedDocument): TypedDocument {
  let docType: TypedDocumentType
  switch (json.docType) {
    case 'document':
      docType = 'document'
      break
    case 'spreadsheet':
      docType = 'spreadsheet'
      break
    case 'presentation':
      docType = 'presentation'
      break
    case 'pdf':
      docType = 'pdf'
      break
    case 'editor':
      docType = 'editor'
      break
    default:
      sendErrorLog(`Invalid docType`, { docType: json.docType })
      throw new Error(`Invalid docType: ${json.docType}`)
  }

  if (googleDocOriginGuard(json.origin) && googleDocTypeGuard(docType)) {
    return new TypedGoogleDocument(
      json.projectId,
      json.documentId,
      docType,
      json.title,
      json.createdBy,
      json.defaultFolderId,
      json.numResources,
      json.createdAt,
      json.updatedAt,
      json.isCompleted,
      json.favorite,
      json.origin,
    )
  } else if (docType === 'pdf' && fileDocOriginGuard(json.origin)) {
    return new TypedFileDocument(
      json.projectId,
      json.documentId,
      docType,
      json.title,
      json.createdBy,
      json.defaultFolderId,
      json.numResources,
      json.createdAt,
      json.updatedAt,
      json.isCompleted,
      json.favorite,
      json.origin,
    )
  } else if (editorDocOriginGuard(json.origin) && docType === 'editor') {
    return new TypedEditorDocument(
      json.projectId,
      json.documentId,
      docType,
      json.title,
      json.createdBy,
      json.defaultFolderId,
      json.numResources,
      json.createdAt,
      json.updatedAt,
      json.isCompleted,
      json.favorite,
      json.origin,
    )
  }
  throw Error(`invalid json ${json}`)
}

const googleDocOriginGuard = (
  origin: TypedDocumentOrigin,
): origin is IGoogleDocumentOrigin => {
  return origin.provider === 'google'
}

const fileDocOriginGuard = (
  origin: TypedDocumentOrigin,
): origin is IFileDocumentOrigin => {
  return origin.provider === 'typed' && 'signedUrl' in origin
}

const editorDocOriginGuard = (
  origin: TypedDocumentOrigin,
): origin is IEditorDocumentOrigin => {
  return origin.provider === 'typed' && 'body' in origin
}

const googleDocTypeGuard = (
  docType: TypedDocumentType,
): docType is GoogleDocumentType => {
  return ['document', 'spreadsheet', 'presentation'].includes(docType)
}
