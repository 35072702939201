import { TypedResourceType } from '@/models/resource/TypedResource'
import { Paintable } from '@/models/network'
import ProjectRepository from '@/repositories/projectRepository'
import NodePainter from '@/helpers/network/nodePainter'

interface Neighbor {
  node_id: string
  project_ids: string[]
}

export interface nodePaintParams {
  clickedNodeId: string
  hoverNodeId: string
  highlightNodeIds: string[]
  projectRepository: ProjectRepository
}

export interface INetworkNode {
  readonly currentProjectId: string
  readonly id: string
  readonly node_type: TypedResourceType
  readonly node_weight: number
  readonly metadata: { [key: string]: any }
  readonly title: string
  readonly group: string
  readonly project_ids: string[]
  readonly createdAt?: string
  readonly archived?: boolean
  readonly inbox?: boolean
  readonly neighbors?: Neighbor[]
  readonly x: number
  readonly y: number
}

export class NetworkNode implements INetworkNode, Paintable {
  static DOCUMENT_IMAGE_SIZE = 30
  static DOCUMENT_CLICKED_HIGHLIGH_PADDING = 6
  static CLICKED_HIGHLIGH_DOCUMENT_SIZE =
    this.DOCUMENT_IMAGE_SIZE + 2 * this.DOCUMENT_CLICKED_HIGHLIGH_PADDING
  static DOCUMENT_FONT_SIZE = 12
  static DOCUMENT_TITLE_NUMBER_OF_LINES = 2
  static DOCUMENT_TEXT_MAX_WIDTH = 122
  static DOCUMENT_OTHER_PROJECT_INFO_FONT_SIZE = 12

  static RESOURCE_HEIGHT = 23
  static RESOURCE_TEXT_MAX_WIDTH = 122
  static RESOURCE_ICON_SIZE = 19
  static RESOURCE_CARD_PADDING = 2
  static RESOURCE_FONT_SIZE = 10

  constructor(
    readonly currentProjectId: string,
    readonly id: string,
    readonly node_type: TypedResourceType,
    readonly node_weight: number,
    readonly metadata: { [key: string]: any },
    readonly title: string,
    readonly group: string,
    readonly project_ids: string[],
    readonly createdAt?: string,
    readonly archived?: boolean,
    readonly inbox?: boolean,
    readonly neighbors?: Neighbor[],
  ) {}

  x = 0
  y = 0
  nodePainter = new NodePainter(this)

  static fromJson(json: INetworkNode) {
    return new NetworkNode(
      json.currentProjectId ?? 'all',
      json.id,
      json.node_type,
      json.node_weight,
      json.metadata,
      json.title,
      json.group,
      json.project_ids,
      json.createdAt,
      json.archived,
      json.inbox,
      json.neighbors,
    )
  }

  paint(ctx: CanvasRenderingContext2D, params: nodePaintParams) {
    this.nodePainter.paint(ctx, params)
  }

  paintClickableNodeArea(ctx: CanvasRenderingContext2D, color: string) {
    this.nodePainter.paintClickableNodeArea(ctx, color)
  }
}
