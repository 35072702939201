import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/types/dist/severity'

export function sendDebugLog(
  message: string,
  extraData?: Record<string, unknown>,
) {
  sendSentryLogger(message, Severity.Debug, extraData)
}

export function sendErrorLog(
  message: string,
  extraData?: Record<string, unknown>,
) {
  sendSentryLogger(message, Severity.Error, extraData)
}

export function sendErrorTrace(error: Error) {
  sendSentryLogger(error)
}

function sendSentryLogger(
  sources: string | Error,
  level: Severity = Severity.Error,
  extra?: Record<string, unknown>,
) {
  if (typeof sources === 'string') {
    Sentry.captureMessage(formatLoggingMessage(sources, level), {
      level,
      /**
       * @description extra 데이터를 기반으로 해서 검색을 할 수 없음
       * @see https://docs.sentry.io/product/sentry-basics/enrich-data/#types-of-data
       */
      extra,
    })
  }
  if (sources instanceof Error) {
    sources.message = formatLoggingMessage(sources.message, level)
    Sentry.captureException(sources)
  }
}

function formatLoggingMessage(target: string, level: Severity) {
  /**
   * message 의 format 예시
   * [ERROR] 새로운 게시물을 작성하는데 실패하였습니다.
   */
  return `[${level.toUpperCase()}] ${target}`
}
