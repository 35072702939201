import { IScriptsLoadingState } from '@/setup'
import { appendNoScript, loadScriptWithRetry } from '@/utils/loadScript'

export const facebookPixelSetup = (
  scriptsLoadingState: IScriptsLoadingState,
) => {
  const id = process.env.FACEBOOK_PIXEL_ID
  const f = window

  if (!f.fbq) {
    f.fbq ??= function (...args) {
      n.callMethod ? n.callMethod(...args) : n.queue?.push(args)
    }

    const n = f.fbq
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = '2.0'
    n.queue = []
    loadScriptWithRetry('https://connect.facebook.net/en_US/fbevents.js').then(
      () => {
        scriptsLoadingState.isFacebookPixelLoaded = true
      },
    )

    const imgElement = document.createElement('img')
    imgElement.height = 1
    imgElement.width = 1
    imgElement.src = `https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1`
    imgElement.style.display = 'none'
    appendNoScript(imgElement, document.head)
  }

  f.fbq('init', id)
  f.fbq('track', 'PageView')
}
