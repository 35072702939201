import { IScriptsLoadingState } from '@/setup'
import { loadScriptWithRetry } from '@/utils/loadScript'

export const quantcastSetup = async (
  scriptsLoadingState: IScriptsLoadingState,
) => {
  window._qevents ??= []

  const src = `${
    window.location.protocol === 'https:' ? 'https://secure' : 'http://edge'
  }.quantserve.com/quant.js`

  loadScriptWithRetry(src).then(() => {
    scriptsLoadingState.isQuantcastLoaded = true
  })
}
