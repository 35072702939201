import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IAppSumoParams {
  email?: string
  plan?: string
  uuid?: string
}

export enum AppSumoSpaceActivationState {
  none,
  creating,
  created,
}

interface IAppSumoState {
  appSumoParams?: IAppSumoParams
  activationState: AppSumoSpaceActivationState
  createdAppSumoSpaceId?: string
}

const initialState: IAppSumoState = {
  appSumoParams: undefined,
  activationState: AppSumoSpaceActivationState.none,
  createdAppSumoSpaceId: undefined,
}

export const appSumoSlice = createSlice({
  name: 'appSumo',
  initialState,
  reducers: {
    setAppSumoParams: (state, action: PayloadAction<IAppSumoParams>) => {
      state.appSumoParams = action.payload
    },
    clearAppSumoParams: (state) => {
      state.appSumoParams = undefined
    },
    clearAppSumoActivationState: (state) => {
      state.activationState = AppSumoSpaceActivationState.none
      state.createdAppSumoSpaceId = undefined
    },
    setAppSumoActivationState: (
      state,
      action: PayloadAction<
        Pick<IAppSumoState, 'activationState' | 'createdAppSumoSpaceId'>
      >,
    ) => {
      state.activationState = action.payload.activationState
      state.createdAppSumoSpaceId = action.payload.createdAppSumoSpaceId
    },
  },
})

export const {
  setAppSumoParams,
  clearAppSumoParams,
  setAppSumoActivationState,
} = appSumoSlice.actions

export default appSumoSlice.reducer
