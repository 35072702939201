import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModalType, ToastType } from '@/hooks/feedback/modals'

type FeedbackState = {
  [name in ModalType | ToastType]?: { isOpen: boolean; data: any }
}

const initialState: FeedbackState = {}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    openModal: (
      state,
      action: PayloadAction<{ name: ModalType | ToastType; data?: any }>,
    ) => {
      const { name, data } = action.payload
      state[name] = { isOpen: true, data }
    },
    closeModal: (
      state,
      action: PayloadAction<{ name: ModalType | ToastType; data?: any }>,
    ) => {
      const { name } = action.payload
      state[name] = { isOpen: false, data: undefined }
    },
    setModalData: (
      state,
      action: PayloadAction<{ name: ModalType | ToastType; data?: any }>,
    ) => {
      const { name, data } = action.payload
      state[name] = {
        isOpen: state[name]?.isOpen ?? false,
        data: { ...(state[name]?.data ?? {}), ...data },
      }
    },
  },
})

export const { openModal, closeModal, setModalData } = feedbackSlice.actions

export default feedbackSlice.reducer
