import dayjs from 'dayjs'
import { AxiosInstance } from 'axios'
import TypedTask, {
  GetTasksFilter,
  ITaskResponse,
  UpdateTaskParams,
} from '@/models/task'
import autoBind from '@/utils/autoBind'
import Pagination from '@/models/pagination/Pagination'

type PaginatedTasksDTO = { cursor?: number; data: ITaskResponse[] }

class TaskAPI {
  constructor(readonly axios: AxiosInstance) {}

  async createTaskItem({
    projectId,
    documentId,
    content,
    assigneeId,
  }: {
    projectId?: string
    documentId?: string
    content: string
    assigneeId?: string
  }): Promise<TypedTask> {
    const { data } = await this.axios.post<ITaskResponse>('/api/tasks', {
      projectId,
      documentId,
      content,
      assigneeId,
    })

    return TypedTask.fromResponseJSON(data)
  }

  async updateTask(itemId: string, taskData: UpdateTaskParams) {
    const updateData: {
      content?: string
      isCompleted?: boolean
      assigneeId?: string | null
      dueDate?: string | null
      documentId?: string | null
      projectId?: string | null
    } = {
      content: taskData.content,
      isCompleted: taskData.isCompleted,
    }

    if (taskData.assignee === null) {
      updateData.assigneeId = null
    } else if (taskData.assignee) {
      updateData.assigneeId = taskData.assignee.assigneeId
    }

    if (taskData.dueDate === null) {
      updateData.dueDate = null
    } else if (taskData.dueDate) {
      updateData.dueDate = dayjs(taskData.dueDate).format('YYYY-MM-DD')
    }

    if (taskData.document === null) {
      updateData.documentId = null
    } else if (taskData.document) {
      updateData.documentId = taskData.document.documentId
    }

    if (taskData.project === null) {
      updateData.projectId = null
    } else if (taskData.project) {
      updateData.projectId = taskData.project.projectId
    }

    await this.axios({
      method: 'patch',
      url: `/api/tasks/${itemId}`,
      data: updateData,
    })
  }

  async deleteTaskItem(itemId: string) {
    await this.axios({
      method: 'delete',
      url: `/api/tasks/${itemId}`,
    })
  }

  async exportToGoogleCalendar(itemId: string) {
    await this.axios({
      method: 'post',
      url: `/api/tasks/${itemId}/export`,
    })
  }

  async getTasks({
    spaceId,
    projectId,
    assigneeId,
    documentId,
    cursor,
    limit,
    isCompleted,
  }: GetTasksFilter) {
    const { data } = await this.axios.get<PaginatedTasksDTO>('/api/tasks', {
      params: {
        workspaceId: spaceId,
        projectId,
        assigneeId,
        documentId,
        cursor,
        limit,
        isCompleted,
      },
    })

    return Pagination.fromJSON<TypedTask>({
      data: data.data.map(TypedTask.fromResponseJSON),
      cursor: data.cursor,
    })
  }
}

export default autoBind(TaskAPI)
