import React, { ReactEventHandler } from 'react'
import styled from '@emotion/styled'
import colors from '@/styles/colors'

interface FlatButtonProps {
  children: React.ReactNode
  className: string
  onClick: ReactEventHandler
  [key: string]: unknown
}

const FlatButton = (
  { children, className, onClick, ...props }: FlatButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => (
  <FlatButtonContainer
    ref={ref}
    className={className}
    onClick={onClick}
    {...props}
  >
    {children}
  </FlatButtonContainer>
)

const FlatButtonContainer = styled.button`
  position: relative;
  border-radius: 5px;
  padding: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: 'background' 0.2s;

  &:hover {
    background: ${colors.darker4()};
  }
`

export default React.forwardRef(FlatButton)
