interface PaymentPageText {
  Back: string
  'Upgrade to Pro': string
  'Order summary': string
  'Space name': string
  Editors: string
  'Billing cycle': string
  Annual: string
  Monthly: string
  editors: string
  months: string
  'Have a promotion code?': string
  'Promotion Code': string
  Apply: string
  'This code isn’t valid.': string
  Total: string
  'Payment details': string
  Email: string
  'Card Number': string
  Expiration: string
  CVC: string
  Upgrade: string
  PurchaseSuccessfulTitle: string
  Description: string
}

const englishPaymentPageText: PaymentPageText = {
  Back: 'Back',
  'Upgrade to Pro': 'Upgrade to Pro',
  'Order summary': 'Order summary',
  'Space name': 'Space name',
  Editors: 'Editors',
  'Billing cycle': 'Billing cycle',
  Annual: 'Annual',
  Monthly: 'Monthly',
  editors: 'editors',
  months: 'months',
  'Have a promotion code?': 'Have a promotion code?',
  'Promotion Code': 'Promotion Code',
  Apply: 'Apply',
  'This code isn’t valid.': 'This code isn’t valid.',
  Total: 'Total',
  'Payment details': 'Payment details',
  Email: 'Email',
  'Card Number': 'Card Number',
  Expiration: 'Expiration',
  CVC: 'CVC',
  Upgrade: 'Upgrade',
  PurchaseSuccessfulTitle: 'Your purchase was successful.',
  Description:
    'Now enjoy limitless documents and storage for limitless knowledge.',
}

const koreanPaymentPageText: PaymentPageText = {
  Back: '뒤로',
  'Upgrade to Pro': '프로 요금제로 업그레이드',
  'Order summary': '결제 내역',
  'Space name': '스페이스명',
  Editors: '편집자',
  'Billing cycle': '청구 주기',
  Annual: '매년',
  Monthly: '매월',
  editors: '명의 편집자',
  months: '개월',
  'Have a promotion code?': '쿠폰코드가 있으신가요?',
  'Promotion Code': '프로모션 코드',
  Apply: '적용',
  'This code isn’t valid.': '유효하지 않은 쿠폰입니다.',
  Total: '합계',
  'Payment details': '결제 정보',
  Email: '이메일',
  'Card Number': '카드 번호',
  Expiration: '만료일',
  CVC: 'CVC',
  Upgrade: '업그레이드',
  PurchaseSuccessfulTitle: '성공적으로 결제되었습니다.',
  Description:
    '이제, 제한 없이 늘어나는 문서와 용량 만큼, 무한히 확장되는 지식을 경험해보세요!',
}

export { englishPaymentPageText, koreanPaymentPageText }
