import { QueryClient } from 'react-query'
import { TypedHighlight } from '@/models/highlight'
import { queryKeys } from '@/providers/react-query'
import IBaseRepository from '@/repositories/types/baseRepository'
import { implementError } from '@/utils/repositoryUtils'

export default class HighlightRepository
  implements IBaseRepository<TypedHighlight>
{
  constructor(readonly store: QueryClient) {}

  add<T extends TypedHighlight>(item: T): void {
    this.store.setQueryData<T[]>(
      queryKeys.highlightsByResourceId(item.resourceId),
      (oldData) => {
        if (!oldData) return [item]

        return this.exist(item.id) ? oldData : [...oldData, item]
      },
    )
  }
  addAll(): void {
    throw new Error(implementError)
  }
  find<T extends TypedHighlight>(id: string): T | undefined {
    const allHighlights = this.store
      .getQueriesData<T[]>(queryKeys.allHighlights())
      .reduce<T[]>((acc, [, highlights]) => {
        return [...acc, ...highlights]
      }, [])
    return allHighlights.find((highlight) => highlight.id === id)
  }
  exist(id: string): boolean {
    return Boolean(this.find(id))
  }
  update(): boolean {
    throw new Error(implementError)
  }
  delete<T extends TypedHighlight>(id: string): boolean {
    const highlightToDelete = this.find(id)
    if (!highlightToDelete) return false

    let deleted = false

    this.store.setQueryData<T[]>(
      queryKeys.highlightsByResourceId(highlightToDelete?.resourceId),
      (oldData) => {
        if (!oldData) return []
        deleted = true
        return oldData.filter(
          (highlight) => highlight.id !== highlightToDelete.id,
        )
      },
    )

    return deleted
  }
  clear(): void {
    this.store.removeQueries(queryKeys.allHighlights())
  }
}
