import React from 'react'
import { Global } from '@emotion/core'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import 'react-datepicker/dist/react-datepicker.css'
import { ToastContainer } from 'react-toastify'
import packageJson from '../package.json'
import ErrorFallback from './components/blocks/ErrorFallback'
import appSettings from '@/setup'

import ReactQueryProvider from '@/providers/react-query/ReactQueryProvider'
import UserLanguageContextProvider from '@/providers/LocaleContext'
import reactToastifyOverrideStyle from '@/styles/reactToastifyOverrideStyle'
import appStyles from '@/styles/appStyles'
import store from '@/store'
import AppSettingsContextProvider from '@/providers/AppSettingsContext'
import TdsHighlightTool from '@/components/blocks/TdsHighlightTool'

const persistor = persistStore(store)

appSettings.onLoad = async () => {
  const { default: App } = await import('@/App')

  ReactDOM.render(
    <SentryErrorBoundary
      fallback={({ error, componentStack }) => (
        <ErrorFallback error={error} stack={(componentStack ?? '').trim()} />
      )}
    >
      <ToastContainer
        limit={1}
        hideProgressBar={true}
        autoClose={3000}
        pauseOnHover={false}
        pauseOnFocusLoss={false}
        position='bottom-center'
        enableMultiContainer
        containerId='successToast'
      />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <DndProvider backend={HTML5Backend}>
            <UserLanguageContextProvider>
              <ReactQueryProvider>
                <AppSettingsContextProvider appSettings={appSettings}>
                  <Router basename={process.env.PUBLIC_PATH}>
                    <App />
                    <Global styles={reactToastifyOverrideStyle} />
                    <Global styles={appStyles} />
                    <TdsHighlightTool />
                  </Router>
                </AppSettingsContextProvider>
              </ReactQueryProvider>
            </UserLanguageContextProvider>
          </DndProvider>
        </PersistGate>
      </Provider>
    </SentryErrorBoundary>,
    document.getElementById('root'),
  )
}

if (process.env.NODE_ENV !== 'development') {
  // 정렬 맞추시면 안됩니다. 이대로 두어야 글자가 올바르게 유지됩니다.
  // eslint-disable-next-line
  console.log(
    `    ████████╗██╗   ██╗██████╗ ███████╗██████╗ 
    ╚══██╔══╝╚██╗ ██╔╝██╔══██╗██╔════╝██╔══██╗
       ██║    ╚████╔╝ ██████╔╝█████╗  ██║  ██║
       ██║     ╚██╔╝  ██╔═══╝ ██╔══╝  ██║  ██║
       ██║      ██║   ██║     ███████╗██████╔╝
       ╚═╝      ╚═╝   ╚═╝     ╚══════╝╚═════╝ 
    `,
    `v${packageJson.version}`,
  )

  // eslint-disable-next-line
  console.log(
    '%c[구인] 👩🏻‍💻 Typed를 같이 만들어나갈 개발자를 구합니다! https://bit.ly/3Cbnsng',
    'padding: 4px; background-color: rgb(29,29,28); color: rgb(255, 255, 255); font-size: 24px; border: 1px solid rgb(255, 255, 255); width: 100%',
  )
}
