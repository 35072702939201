import {
  INotification,
  Notification,
  NotificationType,
} from '@/models/notification'
import { ITypedUser } from '@/models/user'

export interface IProjectInvitedNotification extends INotification {
  projectId: string
  projectEmoji: string
  projectTitle: string
  inviter: Omit<ITypedUser, 'userId'>
  createdAt: Date
}

class ProjectInvitedNotification
  extends Notification
  implements IProjectInvitedNotification
{
  constructor(
    readonly id: string,
    readonly type: NotificationType,
    readonly checked: boolean,
    readonly spaceId: string,
    readonly projectId: string,
    readonly projectEmoji: string,
    readonly projectTitle: string,
    readonly inviter: Omit<ITypedUser, 'userId'>,
    readonly createdAt: Date,
  ) {
    super(id, type, checked, spaceId)
  }

  static fromJSON(
    json: IProjectInvitedNotification,
  ): ProjectInvitedNotification {
    return new ProjectInvitedNotification(
      json.id,
      json.type,
      json.checked,
      json.spaceId,
      json.projectId,
      json.projectEmoji,
      json.projectTitle,
      json.inviter,
      new Date(json.createdAt),
    )
  }

  copyWith(
    options?: Partial<IProjectInvitedNotification>,
  ): ProjectInvitedNotification {
    return new ProjectInvitedNotification(
      options?.id ?? this.id,
      options?.type ?? this.type,
      options?.checked ?? this.checked,
      options?.spaceId ?? this.spaceId,
      options?.projectId ?? this.projectId,
      options?.projectEmoji ?? this.projectEmoji,
      options?.projectTitle ?? this.projectTitle,
      options?.inviter ?? this.inviter,
      options?.createdAt ?? this.createdAt,
    )
  }
}

export default ProjectInvitedNotification
