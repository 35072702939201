import { FeaturePropsProvider } from '@/utils/featureProps/featurePropsProvider'
import hackleConfigFeaturePropsProvider from '@/utils/featureProps/hackleFeaturePropsProvider'
import mockFeaturePropsProvider from '@/utils/featureProps/mockFeaturePropsProvider'
import LocalStorageManagerFactory from '@/utils/common/localStorageManagerFactory'

type Feature = {
  providerKey: string
  name: string
  description: string
  type: 'boolean'
  owner: string
  squad: string | null
}

export const Features: { [key: string]: Feature } = {
  IS_TEST_FEATURE: {
    providerKey: 'is_test_feature',
    name: 'Test Feature',
    description: 'Feature Props 테스트를 위해 있는 예제입니다',
    type: 'boolean',
    owner: 'json',
    squad: null,
  },
  IS_TDS_HIGHLIGHT_TOOL_USED: {
    providerKey: 'is_tds_highlight_tool_used',
    name: 'TDS Highlight',
    description: 'TDS(Typed Design System) Highlight 여부',
    type: 'boolean',
    owner: 'kong',
    squad: null,
  },
  IS_TYPED_NOTE_USED: {
    providerKey: 'is_typed_note_used',
    name: 'Typed Note',
    description: 'Typed Note 기능 사용 여부',
    type: 'boolean',
    owner: 'beanie',
    squad: 'X',
  },
  IS_PDF_COORDINATE_CITATION_USED: {
    providerKey: 'is_pdf_coordinate_citation_used',
    name: 'PDF Coordinate Citation',
    description: 'PDF Coordinate Citation 기능 사용 여부',
    type: 'boolean',
    owner: 'beanie',
    squad: 'X',
  },
  IS_GOOGLE_DOCS_CITATION_USED: {
    providerKey: 'is_google_docs_citation_used',
    name: 'Google Docs Citation',
    description: 'Google Docs Citation 기능 사용 여부',
    type: 'boolean',
    owner: 'beanie',
    squad: 'X',
  },
  IS_REVIEW_COMMENT_USED: {
    providerKey: 'is_review_comment_used',
    name: 'Review Comment',
    description: 'Review Comment 기능 사용 여부',
    type: 'boolean',
    owner: 'junho',
    squad: 'Core',
  },
  IS_PAGE_FOUNDATION_USED: {
    providerKey: 'is_page_foundation_used',
    name: 'Page Foundation',
    description: 'Page 중심문서 작업',
    type: 'boolean',
    owner: 'sanghyun',
    squad: 'X',
  },
}

class FeatureProps {
  private localCache = LocalStorageManagerFactory.featureProps

  constructor(private provider: FeaturePropsProvider) {}

  get identifier(): string {
    return this.provider.identifier
  }

  set identifier(identifier: string) {
    this.provider.identifier = identifier
  }

  isTestFeature(): boolean {
    return this.getBooleanValue(Features.IS_TEST_FEATURE, false)
  }

  isTdsHighlightToolUsed(): boolean {
    return this.getBooleanValue(Features.IS_TDS_HIGHLIGHT_TOOL_USED, false)
  }

  isTypedNoteUsed(): boolean {
    return this.getBooleanValue(Features.IS_TYPED_NOTE_USED, false)
  }

  isGoogleDocsCitationUsed(): boolean {
    return this.getBooleanValue(Features.IS_GOOGLE_DOCS_CITATION_USED, false)
  }

  isPdfCoordinateCitationUsed(): boolean {
    return this.getBooleanValue(Features.IS_PDF_COORDINATE_CITATION_USED, false)
  }

  isPageFoundationUsed(): boolean {
    return this.getBooleanValue(Features.IS_PAGE_FOUNDATION_USED, false)
  }

  isReviewCommentUsed(): boolean {
    return this.getBooleanValue(Features.IS_REVIEW_COMMENT_USED, false)
  }

  getValue(feature: Feature): boolean {
    switch (feature.type) {
      case 'boolean':
        return this.getBooleanValue(feature, false)
    }
  }

  setValue(key: Feature, value: boolean): void {
    const localCacheData = this.localCache.getData() || {}
    localCacheData[key.providerKey] = value
    this.localCache.setData(localCacheData)
  }

  resetValue(key: Feature) {
    const localCacheData = this.localCache.getData() || {}
    delete localCacheData[key.providerKey]
    this.localCache.setData(localCacheData)
  }

  resetValues() {
    this.localCache.setData({})
  }

  private getBooleanValue(key: Feature, defaultValue: boolean): boolean {
    const providerKey = key.providerKey

    return (
      this.localCache.getData()?.[providerKey] ??
      this.provider.getBooleanValue(providerKey) ??
      defaultValue
    )
  }
}

// TODO: Separate to a module
const isTest = process.env.NODE_ENV === 'test'
const featurePropsProvider = !isTest
  ? hackleConfigFeaturePropsProvider
  : mockFeaturePropsProvider

const featureProps = new FeatureProps(featurePropsProvider)

export default featureProps
