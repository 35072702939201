import { AxiosInstance, AxiosResponse } from 'axios'
import autoBind from '@/utils/autoBind'
import {
  IPageViewUserResultResponse,
  PageViewUserResult,
} from '@/models/PageViewUserResult'

export interface IPageViewCount {
  userTotal: number
  countTotal: number
}

class LogAPI {
  constructor(readonly axios: AxiosInstance) {}

  loggingResourceViewEvent(resourceId: string): void {
    this.axios({
      method: 'post',
      url: `/api/log/views/resource/${resourceId}`,
    })
  }

  async getPageViewCount(resourceId: string): Promise<IPageViewCount> {
    const response: AxiosResponse<{ data: IPageViewCount }> = await this.axios({
      method: 'get',
      url: `/api/log/views/resource/${resourceId}/count`,
    })

    return response.data.data
  }

  async getPageViewUser(resourceId: string): Promise<PageViewUserResult[]> {
    const response: AxiosResponse<{ data: IPageViewUserResultResponse[] }> =
      await this.axios({
        method: 'get',
        url: `/api/log/views/resource/${resourceId}/last-viewed`,
      })

    return response.data.data.map((data: IPageViewUserResultResponse) =>
      PageViewUserResult.fromResponseJSON(data),
    )
  }
}

export default autoBind(LogAPI)
