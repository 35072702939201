import { loadStripe, Stripe } from '@stripe/stripe-js'
import {
  SubscriptionMetadataSet,
  SubscriptionType,
} from '@/models/subscription'

interface ISetupStripe {
  stripePromise: Promise<Stripe | null>
  subscriptionMetadataSet: SubscriptionMetadataSet
  productIdTypedProMonthly: string
  productIdTypedProAnnual: string
}

export default class SetupStripe implements ISetupStripe {
  stripePromise: Promise<Stripe | null>
  subscriptionMetadataSet: SubscriptionMetadataSet
  productIdTypedProMonthly: string
  productIdTypedProAnnual: string

  constructor() {
    const priceIdTypedProAnnual = process.env.PRICE_ID_TYPED_PRO_ANNUAL
    const priceIdTypedProAnnualDiffProduct =
      process.env.PRICE_ID_TYPED_PRO_ANNUAL_DIFF_PRODUCT
    const priceIdTypedProMonthly = process.env.PRICE_ID_TYPED_PRO_MONTHLY
    const priceIdPrevTypedProMonthly =
      process.env.PRICE_ID_PREV_TYPED_PRO_MONTHLY

    this.productIdTypedProMonthly =
      process.env.PRODUCT_ID_TYPED_PRO_MONTHLY ?? ''
    this.productIdTypedProAnnual = process.env.PRODUCT_ID_TYPED_PRO_ANNUAL ?? ''

    const subscriptionMetadataSet: SubscriptionMetadataSet = {}
    if (!priceIdTypedProAnnual) {
      console.error('Invalid priceIdTypedProAnnual')
    } else {
      subscriptionMetadataSet[SubscriptionType.typedProAnnual] = {
        priceId: priceIdTypedProAnnual,
        pricePerEditorPerMonthAsDollar: 8,
      }
    }

    if (!priceIdTypedProAnnualDiffProduct) {
      console.error('Invalid priceIdTypedProAnnualDiffProduct')
    } else {
      subscriptionMetadataSet[SubscriptionType.typedProAnnualDiffProduct] = {
        priceId: priceIdTypedProAnnualDiffProduct,
        pricePerEditorPerMonthAsDollar: 8,
      }
    }

    if (!priceIdTypedProMonthly) {
      console.error('Invalid priceIdTypedProMonthly')
    } else {
      subscriptionMetadataSet[SubscriptionType.typedProMonthly] = {
        priceId: priceIdTypedProMonthly,
        pricePerEditorPerMonthAsDollar: 10,
      }
    }

    if (!priceIdPrevTypedProMonthly) {
      console.error('Invalid priceIdPrevTypedProMonthly')
    } else {
      subscriptionMetadataSet[SubscriptionType.prevTypedProMonthly] = {
        priceId: priceIdPrevTypedProMonthly,
        pricePerEditorPerMonthAsDollar: 4.2,
      }
    }
    subscriptionMetadataSet[SubscriptionType.appsumoTier1] = {
      priceId: 'typed_tier1',
      pricePerEditorPerMonthAsDollar: 0,
    }
    subscriptionMetadataSet[SubscriptionType.appsumoTier2] = {
      priceId: 'typed_tier2',
      pricePerEditorPerMonthAsDollar: 0,
    }
    subscriptionMetadataSet[SubscriptionType.appsumoTier3] = {
      priceId: 'typed_tier3',
      pricePerEditorPerMonthAsDollar: 0,
    }
    subscriptionMetadataSet[SubscriptionType.appsumoTier4] = {
      priceId: 'typed_tier4',
      pricePerEditorPerMonthAsDollar: 0,
    }
    this.stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY ?? '')
    this.subscriptionMetadataSet = subscriptionMetadataSet
  }
}
