import { Store } from 'redux'
import { RootState } from '@/store'
import { extractUrl } from '@/util'
import autoBind from '@/utils/autoBind'
import ResourceRepository from '@/repositories/resourceRepository'
import { setViewerStatus } from '@/slices/viewers/viewerSlice'
import InstanceViewerStatus from '@/models/viewerStatus/InstanceViewerStatus'

const IN_TEXT_CITATION_HTML_STRING_LENGTH = 8

// TODO: focusActionAsync.js 타도
// TODO: focusActions.util.js 타도
class ClipboardService {
  constructor(
    readonly store: Store<RootState>,
    private readonly resourceRepo: InstanceType<typeof ResourceRepository>,
  ) {}

  async getUrlFromClipboard() {
    const clipboardTextData = await navigator.clipboard.readText()
    const purifiedUrl = extractUrl(clipboardTextData) as string | undefined
    if (purifiedUrl && purifiedUrl.length > 0) {
      return purifiedUrl
    }
  }

  async getImagesFromClipboard() {
    try {
      const clipboardData = await navigator.clipboard.read()

      const imageFiles = (
        await Promise.all(
          clipboardData.map(async (clipboardItem) => {
            for (const type of clipboardItem.types) {
              if (type.match('image')) {
                const ext = type.split('/')[1]
                const imageBlob = await clipboardItem.getType(type)

                const imageFile = new File([imageBlob], `image.${ext}`, {
                  type,
                })
                const dataTransfer = new DataTransfer()
                dataTransfer.items.add(imageFile)
                return Array.from(dataTransfer.files)
              }
            }
          }),
        )
      )
        .flat()
        .filter(Boolean) as File[]

      return imageFiles
    } catch (err) {
      return
    }
  }

  async checkClipboardDataAndOpenViewer(): Promise<void> {
    try {
      const clipboardContents = await navigator.clipboard.read()
      for (const item of clipboardContents) {
        const blob = await item.getType('text/html')
        blob.text().then((html: string) => {
          this.checkAndOpenCitation(html)
        })
      }
    } catch (error) {}
  }

  async deleteClipboardData() {
    await navigator.clipboard
      .writeText('')
      .catch((error) => console.error(error))
  }

  private makeHtmlTagList(htmlString: string) {
    const tagList = htmlString.match(/<[^]+?>/g)
    return tagList
  }

  private isIncludeIntextCitation = (html: string) => {
    return (
      html.includes('a href=') && !html.includes('typed-split-viewer-resource')
    )
  }

  private isOnlyIncludeIntextCitation = (html: string) => {
    return (
      this.isIncludeIntextCitation(html) &&
      this.makeHtmlTagList(html)?.length === IN_TEXT_CITATION_HTML_STRING_LENGTH
    )
  }

  private getResourceIdFromResourceLink = (resourceLink: string) => {
    // resourceLink.split('/')[5] 형태 : {documentId}?space-id={spaceId}
    if (resourceLink.split('/')[5]) {
      const resourceId = resourceLink.split('/')[5].split('?')[0]
      return resourceId
    }
  }

  private checkAndOpenCitation = (html: string) => {
    let capturedId = ''
    const aTagSplitString = html.split('a href=')[1]
    if (this.isOnlyIncludeIntextCitation(html) && aTagSplitString) {
      const resourceLink = aTagSplitString.split('"')[1]
      // resourceLink 형태 : https://typed.do/app/doc/{documentId}?space-id={spaceId}
      const resourceId = this.getResourceIdFromResourceLink(resourceLink)
      if (resourceId) {
        if (resourceId !== capturedId) {
          capturedId = resourceId
          const capturedDocument = this.resourceRepo.find(resourceId)
          if (capturedDocument) {
            this.store.dispatch(
              setViewerStatus(new InstanceViewerStatus(capturedDocument)),
            )
            navigator.clipboard.writeText('')
          }
          capturedId = ''
        }
      }
    }
  }
}

export default autoBind(ClipboardService)
