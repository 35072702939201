import { NodeObject } from 'react-force-graph-2d'
import { Paintable } from '@/models/network'
import { NetworkNode } from '@/models/networkNode'
import LinkPainter from '@/helpers/network/linkPainter'

export interface ILink {
  readonly source: string | (Required<NodeObject> & NetworkNode)
  readonly target: string | (Required<NodeObject> & NetworkNode)
  readonly linkPainter: LinkPainter
}

export class NetworkLink implements Paintable {
  constructor(
    readonly source: string | (Required<NodeObject> & NetworkNode),
    readonly target: string | (Required<NodeObject> & NetworkNode),
  ) {}
  linkPainter = new LinkPainter(this)

  paint(ctx: CanvasRenderingContext2D, clickedNodeId: string): void {
    this.linkPainter.paint(ctx, clickedNodeId)
  }

  static fromJson(json: ILink) {
    return new NetworkLink(json.source, json.target)
  }
}
