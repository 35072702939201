import { init as initSentry } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Event } from '@sentry/types/dist/event'
import { IScriptsLoadingState } from '@/setup'

import { maskingEmail, maskingUserName } from '@/utils/maskingPI'

export const sentrySetup = (scriptsLoadingState: IScriptsLoadingState) => {
  const TARGET_ENV = process.env.TARGET_ENV

  if (
    TARGET_ENV === 'dev' ||
    TARGET_ENV === 'staging' ||
    TARGET_ENV === 'production'
  ) {
    const getReleaseVersion = () => {
      const regexp = /refs[/]tags[/](.+)/g
      const match = regexp.exec(process.env.RELEASE_VERSION as string)
      if (!match) return process.env.RELEASE_VERSION
      return match[1]
    }

    initSentry({
      dsn: process.env.SENTRY_DSN,
      autoSessionTracking: true,
      environment: TARGET_ENV,
      release: getReleaseVersion(),
      integrations: [new BrowserTracing()],
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      beforeSend: (event: Event) => {
        /**
         * 개인을 식별할 수 있는 개인 정보와 같은 경우는 외부의 플랫폼에 전송되지 말아야 한다.
         * @see https://docs.sentry.io/platforms/javascript/guides/react/data-management/sensitive-data/
         */
        if (event.user?.email) {
          event.user.email = maskingEmail(event.user.email)
        }
        if (event.user?.username) {
          event.user.username = maskingUserName(event.user.username)
        }

        return event
      },

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 0.1,
    })

    scriptsLoadingState.isSentryLoaded = true
  }
}
