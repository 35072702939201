export interface IStripePromotionCode {
  id: string
  object: 'promotion_code'
  code: string
  coupon: IStripeCoupon
  active: boolean
}

export class StripePromotionCode implements IStripePromotionCode {
  constructor(
    readonly id: string,
    readonly object: 'promotion_code',
    readonly code: string,
    readonly coupon: StripeCoupon,
    readonly active: boolean,
  ) {}

  static fromJson(json: IStripePromotionCode) {
    return new StripePromotionCode(
      json.id,
      json.object,
      json.code,
      StripeCoupon.fromJson(json.coupon),
      json.active,
    )
  }
}

export interface IStripeCoupon {
  id: string
  object: 'coupon'
  amount_off: number | null
  currency: string
  duration: 'once' | 'repeating' | 'forever'
  duration_in_months: number | null
  livemode: boolean
  max_redemptions: number | null
  name: string
  percent_off: number | null
  times_redeemed: number
  valid: boolean
}

export class StripeCoupon implements IStripeCoupon {
  constructor(
    readonly id: string,
    readonly object: 'coupon',
    readonly amount_off: number | null,
    readonly currency: string,
    readonly duration: 'once' | 'repeating' | 'forever',
    readonly duration_in_months: number | null,
    readonly livemode: boolean,
    readonly max_redemptions: number | null,
    readonly name: string,
    readonly percent_off: number | null,
    readonly times_redeemed: number,
    readonly valid: boolean,
  ) {}

  static fromJson(json: IStripeCoupon) {
    return new StripeCoupon(
      json.id,
      json.object,
      json.amount_off,
      json.currency,
      json.duration,
      json.duration_in_months,
      json.livemode,
      json.max_redemptions,
      json.name,
      json.percent_off,
      json.times_redeemed,
      json.valid,
    )
  }

  getDiscountAmount(priceInDollar: number) {
    if (this.percent_off) {
      return (priceInDollar * this.percent_off) / 100
    }

    if (this.amount_off) {
      return this.amount_off / 100
    }

    return 0
  }
}
