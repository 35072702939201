import {
  configureScope as configureSentryScope,
  setUser as setSentryUser,
} from '@sentry/react'
import { Store } from 'redux'
import { ITypedEventLoggers } from '@/integrations/logging/event/typedEventLoggers'
import { RootState } from '@/store'
import IntercomManager from '@/integrations/intercom/IntercomManager'
import UserAPI from '@/api/userAPI'
import autoBind from '@/utils/autoBind'
import AuthService from '@/services/authService'
import featureProps from '@/utils/featureProps/featureProps'

class TrackerService {
  constructor(
    private readonly store: Store<RootState>,
    private readonly userAPI: InstanceType<typeof UserAPI>,
    private readonly eventLoggers: ITypedEventLoggers,
    private readonly authService: AuthService,
  ) {}

  releaseTracker() {
    configureSentryScope((scope) => scope.setUser(null))
    this.eventLoggers.ampClient.setUserProperty(null)
    this.eventLoggers.typedLoggingClient.setUserProperty(null)
  }

  async setUserInfoForTrackers() {
    const isLoggedIn = this.authService.isLoggedIn()
    if (!isLoggedIn) {
      return this.releaseTracker()
    }

    const uid = this.store.getState().user.data?.uid
    if (uid) {
      featureProps.identifier = uid
    }

    const email = this.store.getState().user.data?.email
    if (email) {
      const { userId: uid, displayName } = await this.userAPI.getUserProfile()

      setSentryUser({ id: uid, email, username: displayName })
      this.eventLoggers.ampClient.setUserProperty(uid, email)
      this.eventLoggers.typedLoggingClient.setUserProperty(uid, email)
      this.eventLoggers.quantcast.setUserProperty(null, email)
      IntercomManager.updateUserInfo({
        email,
        userId: uid,
        name: displayName,
      })
    }
  }
}

export default autoBind(TrackerService)
