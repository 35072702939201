import AuthAPI, { googleDriveScope } from '@/api/authAPI'
import { GooglePickerResponseObject } from '@/api/documentAPI'
import { CustomError } from '@/util'
import autoBind from '@/utils/autoBind'

class GooglePickerService {
  static openXmlFormats = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ]
  static msFormats = [
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
  ]
  static haansoftFormats = [
    'application/haansoftdocx',
    'application/haansoftpptx',
    'application/haansoftxlsx',
  ]
  static googleFormats = [
    'application/vnd.google-apps.document',
    'application/vnd.google-apps.presentation',
    'application/vnd.google-apps.spreadsheet',
  ]
  static uploadFromGoogleDriveMimeTypes = [
    ...GooglePickerService.openXmlFormats,
    ...GooglePickerService.msFormats,
    ...GooglePickerService.googleFormats,
    'application/vnd.oasis.opendocument.text',
    'application/pdf',
  ]
  static uploadFromLocalMimeTypes = [
    ...GooglePickerService.openXmlFormats,
    ...GooglePickerService.msFormats,
    ...GooglePickerService.haansoftFormats,
    'application/pdf',
  ]
  static imageFileMimeTypes = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/bmp',
    'image/svg+xml',
    'image/tiff',
    'image/apng',
    'image/avif',
    'image/webp',
  ]
  static nonGoogleDocumentFileMimeTypes = [
    ...GooglePickerService.imageFileMimeTypes,
    ...GooglePickerService.haansoftFormats,
  ]
  static fileResourceMimeTypes = [
    ...GooglePickerService.uploadFromLocalMimeTypes,
    ...GooglePickerService.imageFileMimeTypes,
  ]

  static formatsCanBeGoogleDocs = [
    ...this.openXmlFormats,
    ...this.msFormats,
    ...this.googleFormats,
  ]

  constructor(
    private readonly authAPI: InstanceType<typeof AuthAPI>,
    private readonly googlePickerAPI: typeof window.google.picker,
  ) {}

  async buildGooglePicker(
    mimeTypes: string,
  ): Promise<GooglePickerResponseObject | undefined> {
    try {
      const googleAccessToken = await this.authAPI.getGoogleAccessToken([
        googleDriveScope,
      ])

      const view = new this.googlePickerAPI.DocsView(
        this.googlePickerAPI.ViewId.DOCS,
      )
        .setIncludeFolders(true)
        // .setOwnedByMe(true)
        .setMimeTypes(mimeTypes)

      const folderView = new this.googlePickerAPI.DocsView(
        this.googlePickerAPI.ViewId.DOCS,
      )
        .setIncludeFolders(true)
        .setEnableDrives(true)
        .setSelectFolderEnabled(false)
        .setMimeTypes(mimeTypes)

      const result = await new Promise<GooglePickerResponseObject>(
        (resolve, reject) => {
          const picker = new this.googlePickerAPI.PickerBuilder()
            .addView(view)
            .addView(folderView)
            .enableFeature(this.googlePickerAPI.Feature.SUPPORT_TEAM_DRIVES)
            .enableFeature(this.googlePickerAPI.Feature.MULTISELECT_ENABLED)
            .setOAuthToken(googleAccessToken)
            .setMaxItems(10)
            .setCallback((data) => {
              if (data.action === 'picked') {
                resolve(data)
              } else if (data.action === 'cancel') {
                reject()
              }
            })
            .build()

          picker.setVisible(true)
        },
      )
      return result
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error(e)
      }
    }
  }

  async downloadFileFromGoogleDrive(
    fileId: string,
  ): Promise<ArrayBuffer | undefined> {
    try {
      const googleAccessToken = await this.authAPI.getGoogleAccessToken([
        googleDriveScope,
      ])

      return new Promise<ArrayBuffer>((resolve, reject) => {
        fetch(
          'https://www.googleapis.com/drive/v3/files/' + fileId + '?alt=media',
          {
            method: 'GET',
            headers: {
              Authorization: 'Bearer ' + googleAccessToken,
            },
          },
        )
          .then((response) => {
            resolve(response.arrayBuffer())
          })
          .catch((e) => {
            reject(e)
          })
      })
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error(e)
      }
    }
  }

  async buildLocalGooglePicker(mimeTypes: string, spaceDriveId: string) {
    const googleAccessToken = await this.authAPI.getGoogleAccessToken([
      googleDriveScope,
    ])
    const result = await new Promise<GooglePickerResponseObject>(
      (resolve, reject) => {
        const view = new this.googlePickerAPI.DocsUploadView().setParent(
          spaceDriveId,
        )

        const picker = new this.googlePickerAPI.PickerBuilder()
          .addView((view as any).setMimeTypes(mimeTypes))
          .setOAuthToken(googleAccessToken)
          .setCallback(async (data) => {
            if (data.action === 'picked') {
              resolve(data)
            } else if (data.action === 'cancel') {
              reject()
            }
          })
          .build()
        picker.setVisible(true)
      },
    )

    return result
  }
}

export default autoBind(GooglePickerService)
