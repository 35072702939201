interface PanelText {
  'Other resources': string
  Inbox: string
  Search: string
  'My Documents': string
  'This library is empty': string
  'Add your first resource': string
  'Current library': string
  'All resources': string
  'All Groups': string
}

const englishPanelText: PanelText = {
  'Other resources': 'All resources',
  Inbox: 'Inbox',
  Search: 'Search',
  'My Documents': 'My Documents',
  'This library is empty': 'This library is empty.',
  'Add your first resource': 'Add your first resource!',
  'Current library': 'Current library',
  'All resources': 'All resources',
  'All Groups': 'All Groups',
}

const koreanPanelText: PanelText = {
  'Other resources': '모든 참고자료',
  Inbox: '인박스',
  Search: '검색',
  'My Documents': '내 문서',
  'This library is empty': '이 라이브러리는 비어있어요.',
  'Add your first resource': '첫 참고자료를 추가해보세요!',
  'Current library': '현재 라이브러리',
  'All resources': '모든 참고자료',
  'All Groups': '모든 그룹',
}

export { englishPanelText, koreanPanelText }
