import { loadScriptWithRetry } from '@/utils/loadScript'

export type CredentialResponse = Parameters<
  NonNullable<
    Parameters<typeof window.google.accounts.id.initialize>[0]['callback']
  >
>[0]

class GoogleIdentityService {
  private static isLoading = false
  private static isLoaded = false
  static onIdTokenChange?: (res: CredentialResponse) => Promise<void>

  static async init(cliendId: string) {
    if (GoogleIdentityService.isLoaded) {
      throw new Error('GoogleIdentityService is already initialized.')
    }

    if (GoogleIdentityService.isLoading) {
      return
    }
    GoogleIdentityService.isLoading = true

    await loadScriptWithRetry('https://accounts.google.com/gsi/client')
    window.google.accounts.id.initialize({
      client_id: cliendId,
      callback: (credentialResponse) => {
        if (GoogleIdentityService.onIdTokenChange) {
          GoogleIdentityService.onIdTokenChange(credentialResponse)
        }
      },
      auto_select: true,
    })

    GoogleIdentityService.isLoading = false
    GoogleIdentityService.isLoaded = true
  }

  static prompt() {
    window.google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        // try next provider if OneTap is not displayed or skipped
      }
    })
  }

  static signOut() {
    window.google.accounts.id.disableAutoSelect()
  }

  static renderSignInButton(
    ...params: Parameters<typeof window.google.accounts.id.renderButton>
  ) {
    window.google.accounts.id.renderButton.apply(null, params)
  }

  static waitIdTokenChange() {
    return new Promise<CredentialResponse>((resolve) => {
      const _onIdTokenChange = GoogleIdentityService.onIdTokenChange
      if (_onIdTokenChange) {
        GoogleIdentityService.onIdTokenChange = async (res) => {
          await _onIdTokenChange(res)

          GoogleIdentityService.onIdTokenChange = _onIdTokenChange
          resolve(res)
        }
      }
    })
  }
}

export default GoogleIdentityService
