import { documentEvents } from '@/integrations/intercom/event/document'
import { pageEvents } from '@/integrations/intercom/event/page'
import { resourceEvents } from '@/integrations/intercom/event/resource'

class IntercomManager {
  static initialize(appId: string) {
    window.Intercom('boot', { app_id: appId })
  }

  static updateUserInfo(userInfo: {
    email?: string
    name?: string
    userId?: string
  }) {
    window.Intercom('update', {
      email: userInfo.email,
      name: userInfo.name,
      user_id: userInfo.userId,
    })
  }

  static resource = resourceEvents
  static document = documentEvents
  static page = pageEvents
}

export default IntercomManager
