import { NetworkLink } from '@/models/networkLink'
import colors from '@/styles/colors'

export default class LinkPainter {
  constructor(readonly networkLink: NetworkLink) {}

  paint(ctx: CanvasRenderingContext2D, clickedNodeId: string): void {
    if (
      typeof this.networkLink.source === 'string' ||
      typeof this.networkLink.target === 'string'
    )
      return
    const start = this.networkLink.source
    const end = this.networkLink.target

    const strokeStyle =
      !clickedNodeId ||
      (clickedNodeId !== this.networkLink.source.id &&
        clickedNodeId !== this.networkLink.target.id)
        ? colors.gray77()
        : colors.blue50()

    ctx.beginPath()
    ctx.moveTo(start.x, start.y)
    ctx.lineTo(end.x, end.y)
    ctx.strokeStyle = strokeStyle
    ctx.stroke()
  }
}
