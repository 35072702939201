import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import LocalStorageManagerFactory from '@/utils/common/localStorageManagerFactory'

const groupOpenStatusLSM = LocalStorageManagerFactory.groupOpenStatus

const initialState: {
  defaultGroupId?: string
  openStatus: { [groupId: string]: boolean }
} = {
  defaultGroupId: undefined,
  openStatus: { ...(groupOpenStatusLSM.getData() ?? {}) },
}

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setDefaultGroupId: (state, action: PayloadAction<string>) => {
      state.defaultGroupId = action.payload
    },
    toggleGroup: (state, action: PayloadAction<string>) => {
      const groupId = action.payload
      const groupOpenStatus = state.openStatus[groupId] ?? true
      state.openStatus[groupId] = !groupOpenStatus

      const prevOpenStatus = groupOpenStatusLSM.getData() ?? {}
      groupOpenStatusLSM.setData({
        ...prevOpenStatus,
        [`${groupId}`]: !groupOpenStatus,
      })
    },
  },
})

export const { setDefaultGroupId, toggleGroup } = groupSlice.actions

export default groupSlice.reducer
