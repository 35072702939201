import React from 'react'
import { IAppSettings } from '@/setup'

export const AppSettingsContext = React.createContext<IAppSettings>(
  {} as IAppSettings,
)

interface AppSettingsContextProviderProps {
  appSettings: IAppSettings
  children: React.ReactNode
}

const AppSettingsContextProvider = ({
  appSettings,
  children,
}: AppSettingsContextProviderProps) => {
  return (
    <AppSettingsContext.Provider value={appSettings}>
      {children}
    </AppSettingsContext.Provider>
  )
}

export default AppSettingsContextProvider
