import {
  INotification,
  Notification,
  NotificationType,
} from '@/models/notification'
import { TypedDocumentType } from '@/models/document/TypedDocument'
import { ITypedUser } from '@/models/user'

export interface IReviewAssignedNotification extends INotification {
  documentId: string
  documentType: TypedDocumentType
  documentTitle: string
  reviewMessage: string
  assignor: Omit<ITypedUser, 'userId'>
  createdAt: Date
}

class ReviewAssignedNotification
  extends Notification
  implements IReviewAssignedNotification
{
  constructor(
    readonly id: string,
    readonly type: NotificationType,
    readonly checked: boolean,
    readonly spaceId: string,
    readonly documentId: string,
    readonly documentType: TypedDocumentType,
    readonly documentTitle: string,
    readonly reviewMessage: string,
    readonly assignor: Omit<ITypedUser, 'userId'>,
    readonly createdAt: Date,
  ) {
    super(id, type, checked, spaceId)
  }

  static fromJSON(
    json: IReviewAssignedNotification,
  ): ReviewAssignedNotification {
    return new ReviewAssignedNotification(
      json.id,
      json.type,
      json.checked,
      json.spaceId,
      json.documentId,
      json.documentType,
      json.documentTitle,
      json.reviewMessage,
      json.assignor,
      new Date(json.createdAt),
    )
  }

  copyWith(
    options?: Partial<IReviewAssignedNotification>,
  ): ReviewAssignedNotification {
    return new ReviewAssignedNotification(
      options?.id ?? this.id,
      options?.type ?? this.type,
      options?.checked ?? this.checked,
      options?.spaceId ?? this.spaceId,
      options?.documentId ?? this.documentId,
      options?.documentType ?? this.documentType,
      options?.documentTitle ?? this.documentTitle,
      options?.reviewMessage ?? this.reviewMessage,
      options?.assignor ?? this.assignor,
      options?.createdAt ?? this.createdAt,
    )
  }
}

export default ReviewAssignedNotification
