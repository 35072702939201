interface LanguageText {
  Korean: string
  English: string
}

const englishLanguageText: LanguageText = {
  Korean: 'Korean',
  English: 'English',
}

const koreanLanguageText: LanguageText = {
  Korean: '한국어',
  English: '영어',
}

export { englishLanguageText, koreanLanguageText }
