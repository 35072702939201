import { SESSION_STORAGE_NAMES } from '@/util'
import { loadScriptWithRetry } from '@/utils/loadScript'

export const gApiSetup = () => {
  return new Promise<void>((resolve) => {
    const initClient = async () => {
      try {
        await window.gapi.client.init({
          apiKey: process.env.GOOGLE_DRIVE_API_KEY,
          clientId: process.env.GOOGLE_DRIVE_CLIENT_ID,
          discoveryDocs: [
            'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
          ],
        })

        sessionStorage.setItem(
          SESSION_STORAGE_NAMES.IDPIFRAME_INITIALIZATION_FAILED,
          'false',
        )

        resolve()
      } catch (e: any) {
        // Incognito 모드—에서 gapi.client.init() 실행시 'idpiframe_initialization_failed' 에러 반환
        if (e.error === SESSION_STORAGE_NAMES.IDPIFRAME_INITIALIZATION_FAILED) {
          sessionStorage.setItem(
            SESSION_STORAGE_NAMES.IDPIFRAME_INITIALIZATION_FAILED,
            'true',
          )
        }

        console.error(e)
      }
    }

    loadScriptWithRetry('https://apis.google.com/js/api.js').then(() => {
      window.gapi.load('client:auth2', initClient)
      window.gapi.load('picker', () => {})
    })
  })
}
