import dayjs from 'dayjs'
import { colors } from 'typed-design-system'

export const MESSAGES = Object.freeze({
  COMMON_ERROR: 'An error has occurred. Please try again later.',
  DOCUMENT_RENAME_ERROR: 'Document rename failed',
})

// 현재 사용되는 모든 코드를 넣어놨습니다. 리팩토링은 차후 예정.
// (https://en.wikipedia.org/wiki/List_of_HTTP_status_codes)
export const HTTP_STATUS_CODES = Object.freeze({
  OK: 200,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
})

export const SESSION_STORAGE_NAMES = Object.freeze({
  IDPIFRAME_INITIALIZATION_FAILED: 'idpiframe_initialization_failed',
})

export class CustomError extends Error {
  constructor(message, name) {
    super(message)
    this.name = name ?? this.constructor.name
  }
}

const isSupportedCopyToClipboardFromMimeType = (mimeType) => {
  const notSupportedImageMimeTypeList = [
    'image/avif',
    'image/bmp',
    'image/tiff',
  ]
  return notSupportedImageMimeTypeList.includes(mimeType) ? null : mimeType
}

export const randomStr = () => Math.random().toString(36).substring(7)
export const contentType = (data) => {
  const result = data?.metadata?.contentType
  return { value: typeof result === 'string' ? result : undefined }
}
export const readableCreatedAt = (isKorean, createdAt) => {
  if (isKorean) {
    return dayjs(createdAt).format('YYYY.M.D')
  }
  return dayjs(createdAt).format('MMM D, YYYY')
}

export const isImageMimeType = (mimeType) => {
  const filterImageMimeTypeList = [
    'image/apng',
    'image/avif',
    'image/gif',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/webp',
    'image/bmp',
    'image/tiff',
  ]
  const filterMimeType = filterImageMimeTypeList.includes(mimeType)
    ? mimeType
    : null
  return isSupportedCopyToClipboardFromMimeType(filterMimeType)
}

export const getFileInfo = (type) => {
  switch (type) {
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/msword':
      return {
        iconName: 'word',
        typeText: 'WORD',
        bgColor: colors.formatWord(0.1),
        fontColor: colors.formatWord(),
      }
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
      return {
        iconName: 'ppt',
        typeText: 'PPT',
        bgColor: colors.formatPpt(0.1),
        fontColor: colors.formatPpt(),
      }
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel':
      return {
        iconName: 'excel',
        typeText: 'EXCEL',
        bgColor: colors.formatExcel(0.1),
        fontColor: colors.formatExcel(),
      }
    case 'application/pdf':
    case 'application/vnd.epapyrus.pdf':
      return {
        iconName: 'pdf',
        typeText: 'PDF',
        bgColor: colors.formatPdf(0.1),
        fontColor: colors.formatPdf(),
      }
    case 'application/octet-stream':
    case 'application/x-hwp':
    case 'application/haansofthwp':
    case 'application/vnd.hancom.hwp':
      return {
        iconName: 'hwp',
        typeText: 'HWP',
        bgColor: colors.formatHwp(0.1),
        fontColor: colors.formatHwp(),
      }
    case isImageMimeType(type):
      return {
        iconName: 'img',
        typeText: 'IMG',
        bgColor: colors.formatImg(),
        fontColor: colors.formatImg(),
      }
    default:
      return {
        iconName: 'file',
        typeText: 'FILE',
        bgColor: colors.gray33(0.1),
        fontColor: colors.gray33(),
      }
  }
}

export const getDocumentPageHistory = (
  fullHistory = [],
  curDocument,
  historyOffset,
) => {
  const currentContextHistory = fullHistory.filter(
    (prevDocId) => prevDocId !== curDocument.documentId,
  )
  return Array.from(new Set(currentContextHistory)).slice(0, historyOffset)
}

export const isInboxId = (id) => id === 'inbox'
export const extractUrl = (text) => {
  // eslint-disable-next-line
  const regex =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return text.match(regex)?.shift()
}
export const isWhiteSpace = (str) => str.trim() === ''

export const getNumResources = (isKorean, num) => {
  if (isKorean) {
    if (num > 99) {
      return '자료 99+ 개'
    }
    return `자료 ${num}개`
  }

  return `${num > 99 ? '99+' : num} ${num > 1 ? 'files' : 'file'}`
}
const MAC_OS = /mac/i
const osMatches = (os) => new RegExp(os).test(navigator.userAgent)
export const isMacOS = osMatches(MAC_OS)
export const isControlOrCmdKeyPressing = (e) =>
  (isMacOS && e.metaKey) || (!isMacOS && e.ctrlKey)

// https://stackoverflow.com/a/18405800
export const getFormatString = (string, ...args) => {
  return string.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] !== 'undefined' ? args[number] : match
  })
}

// 특정 Chrome Extension 설치 여부만 체크할 때 가장 간단한 방법
// (https://stackoverflow.com/a/21043701)
export const isExtensionInstalled = async (extensionId, webAccessibleImage) => {
  try {
    const img = new Image()
    img.src = `chrome-extension://${extensionId}/${webAccessibleImage}`
    await img.decode()

    return true
  } catch {
    return false
  }
}

export const MAX_FILE_GB = 1
export const gb2byte = (gb) => gb * 1024 * 1024 * 1024

export const EVENT_KEY = Object.freeze({
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  META: 'Meta',
  CONTROL: 'Control',
})

export const isValidTheme = (themeObj) => {
  if (!themeObj) throw new Error('Theme object is invalid')
  return themeObj
}

export const deepEqual = (original, target) => {
  const isReferenceType = (obj) => typeof obj === 'object' && obj !== null

  if (!isReferenceType(original) || !isReferenceType(target)) {
    return original === target
  }

  const keysOfOriginal = Object.keys(original)
  const keysOfTarget = Object.keys(target)

  if (keysOfOriginal.length !== keysOfTarget.length) {
    return false
  }

  for (const originKey of keysOfOriginal) {
    if (!(originKey in target)) {
      return false
    }

    if (isReferenceType(original[originKey])) {
      if (!deepEqual(original[originKey], target[originKey])) {
        return false
      }
    } else {
      if (original[originKey] !== target[originKey]) {
        return false
      }
    }
  }

  return true
}
