import { Store } from 'redux'
import { QueryClient } from 'react-query'
import SpaceAPI from '@/api/spaceAPI'
import { SpaceEvent } from '@/integrations/logging/event/space'
import TypedSpace, {
  SpaceInvitationInfo,
  TypedSpaceMemberRole,
} from '@/models/space'
import queryKeys from '@/providers/react-query/queryKeys'
import { QuotaExcessType } from '@/models/subscription'
import { RootState } from '@/store'
import { openModal } from '@/utils/feedback/feedbackSlice'
import { ModalType } from '@/hooks/feedback/modals'
import {
  EditorCountQuotaExceededError,
  SpaceCantGetUsage,
  SpaceNotExistError,
} from '@/errors/space'
import { flushDocumentPageHistory } from '@/slices/common/userActionSlice'
import SpaceRepository from '@/repositories/spaceRepository'
import { getRandomHex } from '@/utils/styleUtils'

export default class SpaceService {
  constructor(
    private queryClient: QueryClient,
    private store: Store<RootState>,
    private spaceRepo: SpaceRepository,
    private spaceAPI: InstanceType<typeof SpaceAPI>,
    private spaceEvent: SpaceEvent,
  ) {}

  async createSpace(name: string): Promise<TypedSpace> {
    const hex = getRandomHex()
    const newSpace = await this.spaceAPI.createSpace(name.trim(), hex)

    this.spaceEvent.spaceCreated(newSpace.id)
    this.spaceRepo.add(newSpace)
    return newSpace
  }

  async clearSpace(userId: string | undefined): Promise<void> {
    this.queryClient.removeQueries(queryKeys.allAppHomeProjectSearchResults())
    this.queryClient.removeQueries(queryKeys.allAppHomeDocumentSearchResults())
    this.store.dispatch(flushDocumentPageHistory({ userId: userId ?? '' }))
    this.queryClient.removeQueries(queryKeys.allReviews(), { exact: false })
  }

  async willDocumentCountBeExceeded(
    spaceId: string,
    docCountForAdd = 1,
  ): Promise<boolean> {
    const spacePlan = this.spaceRepo.find(spaceId)?.plan
    if (!spacePlan) {
      return true
    }

    const {
      doc: { current: currentDocumentCount },
    } = await this.spaceAPI.getUsage(spaceId)
    const documentCountQuota = spacePlan.getDocumentCountQuota()

    return currentDocumentCount + docCountForAdd > documentCountQuota
  }

  async willStorageQuotaBeExceeded(
    spaceId: string,
    totalFilesSize: number,
  ): Promise<boolean> {
    try {
      const {
        storage: { current, max },
      } = await this.spaceAPI.getUsage(spaceId)

      return current + totalFilesSize > max
    } catch (e) {
      throw new SpaceCantGetUsage()
    }
  }

  async willEditorCountBeExceeded(
    spaceId: string,
    newEditorCount: number,
  ): Promise<boolean> {
    const spacePlan = this.spaceRepo.find(spaceId)?.plan
    if (!spacePlan?.isAppSumo) {
      return false
    }

    const members = await this.spaceAPI.getMembersBySpaceId(spaceId)
    const editors = members.filter(
      ({ role }) => role !== TypedSpaceMemberRole.VIEWER,
    )
    return editors.length + newEditorCount > spacePlan.getEditorCountQuota()
  }

  async inviteUsers(
    spaceId: string,
    invitations: SpaceInvitationInfo[],
  ): Promise<void> {
    if (!this.spaceRepo.find(spaceId)) {
      throw new SpaceNotExistError()
    }

    const editorInvitationCount = invitations.filter(
      ({ role }) => role === TypedSpaceMemberRole.EDITOR,
    ).length
    const willEditorCountBeExceeded = await this.willEditorCountBeExceeded(
      spaceId,
      editorInvitationCount,
    )

    if (willEditorCountBeExceeded) {
      this.store.dispatch(
        openModal({
          name: ModalType.QUOTA_EXCESS,
          data: QuotaExcessType.editorCount,
        }),
      )
      throw new EditorCountQuotaExceededError()
    }

    await this.spaceAPI.inviteUsers(spaceId, invitations)
    await this.queryClient.refetchQueries(
      queryKeys.spaceMembersBySpaceId(spaceId),
      { exact: true },
    )
  }
}
