interface DocumentPageText {
  'Open in Drive': string
  'Add to current library': string
  'Already added': string
  History: string
  Backlink: string
  'Copy Google Link': string
  'Add to Favorites': string
  'Added to Favorites': string
  'Remove from Favorites': string
  'Mark as Done': string
  'Done writing': string
  Done: string
  'Open from the right': string
  'Open from the left': string
}

const englishDocumentPageText: DocumentPageText = {
  'Open in Drive': 'Open in Drive',
  'Add to current library': 'Add to current library',
  'Already added': 'Already added',
  History: 'History',
  Backlink: 'Backlink',
  'Copy Google Link': 'Copy Google Link',
  'Add to Favorites': 'Add to Favorites',
  'Added to Favorites': 'Added to Favorites',
  'Remove from Favorites': 'Remove from Favorites',
  'Mark as Done': 'Mark as Done',
  'Done writing': 'Done writing',
  Done: 'Done',
  'Open from the right': 'Open from the right',
  'Open from the left': 'Open from the left',
}

const koreanDocumentPageText: DocumentPageText = {
  'Open in Drive': '구글 드라이브에서 열기',
  'Add to current library': '라이브러리에 추가',
  'Already added': '이미 추가된 문서',
  History: '방문 기록',
  Backlink: '백링크',
  'Copy Google Link': '구글 링크 복사',
  'Add to Favorites': '즐겨찾기에 추가',
  'Added to Favorites': '즐겨찾기에 추가했습니다.',
  'Remove from Favorites': '즐겨찾기 취소',
  'Mark as Done': '완료로 표시',
  'Done writing': '작업을 완료했습니다.',
  Done: '완료된 작업',
  'Open from the right': '오른쪽에서 열기',
  'Open from the left': '왼쪽에서 열기',
}

export { englishDocumentPageText, koreanDocumentPageText }
