const isLocal = () => {
  return process.env.TARGET_ENV === 'local'
}
const isDev = () => {
  return process.env.TARGET_ENV === 'dev'
}
const isProduction = () => {
  return process.env.TARGET_ENV === 'production'
}
const isTest = () => {
  return process.env.NODE_ENV === 'test'
}
export { isLocal, isDev, isProduction, isTest }
