import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IPanelStatus {
  libraryPanel: {
    documentPage: {
      isLocked: boolean
      isHovered: boolean
    }
    allResources: {
      isOpen: boolean
      isResourceHoveredAtFirstTime: boolean
      isAllResourcesPanelHovered: boolean
    }
    currentLibrary: {
      isOpen: boolean
    }
    inboxLibrary: {
      isOpen: boolean
    }
    searchLibrary: {
      isOpen: boolean
    }
  }
}

const initialState: IPanelStatus = {
  libraryPanel: {
    documentPage: {
      isLocked: true,
      isHovered: false,
    },
    allResources: {
      isOpen: false,
      isResourceHoveredAtFirstTime: false,
      isAllResourcesPanelHovered: true,
    },
    currentLibrary: {
      isOpen: true,
    },
    inboxLibrary: {
      isOpen: false,
    },
    searchLibrary: {
      isOpen: false,
    },
  },
}

export const panelOpenStatusSlice = createSlice({
  name: 'panelStatus',
  initialState,
  reducers: {
    setDocumentPageLibraryLockState: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.libraryPanel.documentPage.isLocked = action.payload
    },
    setDocumentPageLibraryHoverState: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.libraryPanel.documentPage.isHovered = action.payload
    },
    setAllResourcesLibraryOpenState: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.libraryPanel.allResources.isOpen = action.payload
    },
    setCurrentLibraryOpenState: (state, action: PayloadAction<boolean>) => {
      state.libraryPanel.currentLibrary.isOpen = action.payload
    },
    setInboxLibraryOpenState: (state, action: PayloadAction<boolean>) => {
      state.libraryPanel.inboxLibrary.isOpen = action.payload
    },
    setSearchLibraryOpenState: (state, action: PayloadAction<boolean>) => {
      state.libraryPanel.searchLibrary.isOpen = action.payload
    },
    setHoverResourceInAllResourceLibraryPanel: (state) => {
      state.libraryPanel.allResources.isResourceHoveredAtFirstTime =
        !state.libraryPanel.allResources.isAllResourcesPanelHovered &&
        !state.libraryPanel.allResources.isResourceHoveredAtFirstTime
      state.libraryPanel.allResources.isAllResourcesPanelHovered = true
    },
    setLeaveAllResourcesLibraryPanel: (state) => {
      state.libraryPanel.allResources.isResourceHoveredAtFirstTime = false
      state.libraryPanel.allResources.isAllResourcesPanelHovered = false
    },
  },
})

export const {
  setDocumentPageLibraryLockState,
  setDocumentPageLibraryHoverState,
  setAllResourcesLibraryOpenState,
  setCurrentLibraryOpenState,
  setInboxLibraryOpenState,
  setSearchLibraryOpenState,
  setHoverResourceInAllResourceLibraryPanel,
  setLeaveAllResourcesLibraryPanel,
} = panelOpenStatusSlice.actions

export default panelOpenStatusSlice.reducer
