import { ITypedAPIs } from '@/api'
import { ITypedAxiosGroup } from '@/axios'
import { SubscriptionMetadataSet } from '@/models/subscription'
import FolderAPI from '@/api/folderAPI'
import TaskAPI from '@/api/taskAPI'
import ProjectAPI from '@/api/projectAPI'
import DocumentAPI from '@/api/documentAPI'
import ResourceAPI from '@/api/resourceAPI'
import HighlightAPI from '@/api/highlightAPI'
import SubscriptionAPI from '@/api/subscriptionAPI'
import InboxAPI from '@/api/inboxAPI'
import SpaceAPI from '@/api/spaceAPI'
import UserAPI from '@/api/userAPI'
import SearchAPI from '@/api/searchAPI'
import KnAPI from '@/api/knAPI'
import AuthAPI from '@/api/authAPI'
import MigrationAPI from '@/api/migrationAPI'
import LogAPI from '@/api/logAPI'
import GroupAPI from '@/api/groupAPI'
import FavoriteAPI from '@/api/favoriteAPI'
import RecommendAPI from '@/api/recommendAPI'
import TemplateAPI from '@/api/templateAPI'
import { ReviewAPI } from '@/api/reviewAPI'
import IntegrationAPI from '@/api/integrationAPI'
import NotificationAPI from '@/api/notificationAPI'
import citationAPI from '@/api/citationAPI'

export default class TypedAPIs implements ITypedAPIs {
  project: InstanceType<typeof ProjectAPI>
  document: InstanceType<typeof DocumentAPI>
  folder: InstanceType<typeof FolderAPI>
  task: InstanceType<typeof TaskAPI>
  resource: InstanceType<typeof ResourceAPI>
  highlight: InstanceType<typeof HighlightAPI>
  subscription: InstanceType<typeof SubscriptionAPI>
  inbox: InstanceType<typeof InboxAPI>
  space: InstanceType<typeof SpaceAPI>
  user: InstanceType<typeof UserAPI>
  search: InstanceType<typeof SearchAPI>
  kn: InstanceType<typeof KnAPI>
  auth: InstanceType<typeof AuthAPI>
  migration: InstanceType<typeof MigrationAPI>
  group: InstanceType<typeof GroupAPI>
  log: InstanceType<typeof LogAPI>
  recommend: InstanceType<typeof RecommendAPI>
  favorite: InstanceType<typeof FavoriteAPI>
  template: InstanceType<typeof TemplateAPI>
  review: InstanceType<typeof ReviewAPI>
  integration: InstanceType<typeof IntegrationAPI>
  notification: InstanceType<typeof NotificationAPI>
  citation: InstanceType<typeof citationAPI>

  constructor(
    readonly axiosGroup: ITypedAxiosGroup,
    readonly subscriptionMetadataSet: SubscriptionMetadataSet,
  ) {
    this.project = new ProjectAPI(axiosGroup.typedAxios)
    this.document = new DocumentAPI(axiosGroup.typedAxios)
    this.folder = new FolderAPI(axiosGroup.typedAxios)
    this.task = new TaskAPI(axiosGroup.typedAxios)
    this.resource = new ResourceAPI(axiosGroup.typedAxios)
    this.highlight = new HighlightAPI(axiosGroup.typedAxios)
    this.subscription = new SubscriptionAPI(axiosGroup.paymentAxios)
    this.inbox = new InboxAPI(axiosGroup.typedAxios)
    this.space = new SpaceAPI(
      axiosGroup.typedAxios,
      axiosGroup.globalAxios,
      subscriptionMetadataSet,
    )
    this.user = new UserAPI(axiosGroup.typedAxios)
    this.search = new SearchAPI(axiosGroup.typedAxios)
    this.kn = new KnAPI(axiosGroup.typedAxios)
    this.auth = new AuthAPI(axiosGroup.typedAxios)
    this.migration = new MigrationAPI(axiosGroup.typedAxios)
    this.group = new GroupAPI(axiosGroup.typedAxios)
    this.log = new LogAPI(axiosGroup.typedAxios)
    this.favorite = new FavoriteAPI(axiosGroup.typedAxios)
    this.recommend = new RecommendAPI(axiosGroup.typedAxios)
    this.template = new TemplateAPI(axiosGroup.typedAxios)
    this.review = new ReviewAPI(axiosGroup.typedAxios)
    this.integration = new IntegrationAPI(axiosGroup.typedAxios)
    this.notification = new NotificationAPI(axiosGroup.typedAxios)
    this.citation = new citationAPI(axiosGroup.typedAxios)
  }
}
