import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  originalMD5Hash: '',
}

export const clipboardSlice = createSlice({
  name: 'clipboard',
  initialState,
  reducers: {
    setOriginalMD5Hash: (state, action) => {
      const { md5Hash } = action.payload
      state.originalMD5Hash = md5Hash
    },
    clearOriginalMD5Hash: (state) => {
      state.originalMD5Hash = ''
    },
  },
})

export const { setOriginalMD5Hash, clearOriginalMD5Hash } =
  clipboardSlice.actions

export default clipboardSlice.reducer
