interface KnowldgeNetwork {
  graphHeader: {
    'Network mode': string
  }
}

const englishKnowldgeNetworkText: KnowldgeNetwork = {
  graphHeader: {
    'Network mode': 'Network mode',
  },
}

const koreanKnowldgeNetworkText: KnowldgeNetwork = {
  graphHeader: {
    'Network mode': '네트워크 모드',
  },
}

export { englishKnowldgeNetworkText, koreanKnowldgeNetworkText }
