interface ProfileText {
  'My Account': string
  Language: string
  'Log out': string
  'Change the language used in the user interface.': string
  'Profile Image': string
  'Upload Image': string
  'Remove Image': string
  'Display name': string
  'Full name': string
  'Email address': string
  'Crop your Image': string
  Upload: string
  'You cant leave this blank': string
  'Save Changes': string
  'If you delete account': string
  'If you want to proceed': string
}

const englishProfileText: ProfileText = {
  'My Account': 'My Account',
  Language: 'Language',
  'Log out': 'Log out',
  'Change the language used in the user interface.':
    'Change the language used in the user interface.',
  'Profile Image': 'Profile Image',
  'Upload Image': 'Upload Image',
  'Remove Image': 'Remove Image',
  'Display name': 'Display Name',
  'Full name': 'Full name',
  'Email address': 'Email address',
  'Crop your Image': 'Crop your Image',
  Upload: 'Upload',
  'You cant leave this blank': "You can't leave this blank.",
  'Save Changes': 'Save Changes',
  'If you delete account':
    'If you delete account, all of your documents and resources in Typed will be deleted.',
  'If you want to proceed':
    'If you want to proceed, please complete an account deletion form ',
}

const koreanProfileText: ProfileText = {
  'My Account': '계정 설정',
  Language: '언어',
  'Log out': '로그아웃',
  'Change the language used in the user interface.':
    'Typed에서 사용하는 언어를 변경하세요.',
  'Profile Image': '프로필 사진',
  'Upload Image': '사진 업로드',
  'Remove Image': '사진 삭제',
  'Display name': '표시 이름',
  'Full name': '전체 이름',
  'Email address': '이메일 주소',
  'Crop your Image': '사진 자르기',
  Upload: '업로드',
  'You cant leave this blank': '이 항목은 비워둘 수 없습니다.',
  'Save Changes': '변경 사항 저장',
  'If you delete account': `계정을 삭제하면 타입드 내 모든 도큐먼트와 리소스를 잃게 돼요. 그래도 계정 삭제를 원하신다면, `,
  'If you want to proceed': '로 계정 삭제를 요청해주세요.',
}

export { englishProfileText, koreanProfileText }
