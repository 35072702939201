import { FOLDER_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

const DEFAULT_FOLDER = 1

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class FolderEvent extends TypedEvent {
  createdEvent(data: any) {
    this.sendEvent(FOLDER_EVENT.CREATED, {
      anchorResourceId: data.documentId,
    })
  }

  deletedEvent(data: any) {
    this.sendEvent(FOLDER_EVENT.DELETED, {
      anchorResourceId: data.documentId,
      numOfDocumentFolders: data.numOfDocumentFolders - DEFAULT_FOLDER,
      numOfFolderResources: data.numOfFolderResources,
    })
  }
}
