import { appendNoScript, loadScriptWithRetry } from '@/utils/loadScript'
import { IScriptsLoadingState } from '@/setup'

export const googleIntegrationSetup = (
  scriptsLoadingState: IScriptsLoadingState,
) => {
  window.dataLayer ??= []
  window.gtag ??= (...args) => {
    window.dataLayer?.push(args)
  }
  const gtag = window.gtag

  const googleAnalyticsId = process.env.GOOGLE_ANALYTICS_ID
  if (googleAnalyticsId) {
    loadScriptWithRetry(
      `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`,
    ).then(() => {
      gtag('js', new Date())
      gtag('config', googleAnalyticsId)
      scriptsLoadingState.isGoogleAnalyticsLoaded = true
    })
  }

  const googleAdsId = process.env.GOOGLE_ADS_ID
  if (googleAdsId) {
    loadScriptWithRetry(
      `https://www.googletagmanager.com/gtag/js?id=${googleAdsId}`,
    ).then(() => {
      gtag('js', new Date())
      gtag('config', googleAdsId)
      scriptsLoadingState.isGoogleAdsLoaded = true
    })
  }

  const googleAdsConversionEventId = process.env.GOOGLE_ADS_CONVERSION_EVENT_ID
  if (googleAdsConversionEventId) {
    gtag('event', 'conversion', {
      send_to: googleAdsConversionEventId,
    })
    scriptsLoadingState.isGoogleAdsConversionEventLoaded = true
  }

  const gtmId = process.env.GTM_ID
  if (gtmId) {
    window.dataLayer?.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
    loadScriptWithRetry(
      `https://www.googletagmanager.com/gtm.js?id=${gtmId}`,
    ).then(() => {
      scriptsLoadingState.isGoogleTagManagerLoaded = true
    })

    const iframeElement = document.createElement('iframe')
    iframeElement.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`
    iframeElement.height = '0'
    iframeElement.width = '0'
    iframeElement.style.display = 'none'
    iframeElement.style.visibility = 'hidden'
    appendNoScript(iframeElement)
  }

  const gtmIdForCXCircle = process.env.GTM_ID_FOR_CX_CIRCLE
  if (gtmIdForCXCircle) {
    window.dataLayer?.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
    loadScriptWithRetry(
      `https://www.googletagmanager.com/gtm.js?id=${gtmIdForCXCircle}`,
    ).then(() => {
      scriptsLoadingState.isGoogleTagManagerLoaded = true
    })

    const iframeElement = document.createElement('iframe')
    iframeElement.src = `https://www.googletagmanager.com/ns.html?id=${gtmIdForCXCircle}`
    iframeElement.height = '0'
    iframeElement.width = '0'
    iframeElement.style.display = 'none'
    iframeElement.style.visibility = 'hidden'
    appendNoScript(iframeElement)
  }
}
