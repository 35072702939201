import Pagination, { IPagination } from '@/models/pagination/Pagination'

interface IPaginationWithPaginationWithRestCount<T> extends IPagination<T> {
  readonly restCount: number
}

class PaginationWithRestCount<T> extends Pagination<T> {
  constructor(
    readonly data: T[],
    readonly restCount: number,
    // FIXME: 백엔드 통일 작업 끝나면 타입 하나로 통일
    readonly cursor?: string | number,
  ) {
    super(data, cursor)
  }

  static fromJSON<T>(json: IPaginationWithPaginationWithRestCount<T>) {
    return new PaginationWithRestCount<T>(
      json.data,
      json.restCount,
      json.cursor,
    )
  }
}

export default PaginationWithRestCount
