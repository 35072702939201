import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  folderIdExtensionResourceAdded: null,
}

export const extensionSlice = createSlice({
  name: 'extension',
  initialState,
  reducers: {
    setFolderResourcesAddedByExtension: (state, action) => {
      const { folderId } = action.payload
      state.folderIdExtensionResourceAdded = folderId
    },
    resetFolderResourcesAddedByExtension: (state) => {
      state.folderIdExtensionResourceAdded = null
    },
  },
})

export const {
  setFolderResourcesAddedByExtension,
  resetFolderResourcesAddedByExtension,
} = extensionSlice.actions

export default extensionSlice.reducer
