import { Store } from 'redux'
import { isNil } from 'lodash-es'
import {
  ITypedHighlight,
  TypedHighlight,
  TypedPDFHighlight,
  TypedURLHighlight,
} from '@/models/highlight'
import HighlightRepository from '@/repositories/highlightRepository'
import ResourceRepository from '@/repositories/resourceRepository'
import {
  setActiveHighlightId,
  setSelectionData,
  setToolData,
} from '@/slices/highlights/highlightsSlice'
import { setHighlightIdToScroll } from '@/slices/viewers/viewerSlice'
import errors from '@/errors'
import { HighlightableResources } from '@/models/resource/HighlightableResources'
import {
  constants,
  HighlightElementManager,
  HighlightInteractor,
} from '@/utils/highlights'
import SelectionParser from '@/utils/highlights/selectionParser'
import autoBind from '@/utils/autoBind'
import HighlightAPI from '@/api/highlightAPI'

class HighlightService {
  constructor(
    private highlightRepo: InstanceType<typeof HighlightRepository>,
    private resourceRepo: InstanceType<typeof ResourceRepository>,
    private highlightAPI: InstanceType<typeof HighlightAPI>,
    private store: Store,
  ) {}

  async add<T extends HighlightableResources>(
    selectionData: Partial<ITypedHighlight>,
    resourceId: string,
  ): Promise<TypedHighlight> {
    const newHighlight = await this.highlightAPI.addHighlight(
      resourceId,
      selectionData,
    )

    if (!newHighlight) {
      throw new errors.api.RequestFailError()
    }

    this.store.dispatch(setActiveHighlightId(null))
    this.store.dispatch(setHighlightIdToScroll(null))
    this.highlightRepo.add(newHighlight)

    const resourceToUpdate = this.resourceRepo.find<T>(resourceId)

    if (isNil(resourceToUpdate?.highlightCount)) {
      throw new errors.highlight.InvalidCountError()
    }

    const newHighlightCount = (resourceToUpdate?.highlightCount ?? 0) + 1
    this.resourceRepo.update<T>(resourceId, {
      highlightCount: newHighlightCount,
    } as Partial<T>)

    return newHighlight
  }

  async delete<T extends HighlightableResources>(id: string): Promise<void> {
    const isSuccess = await this.highlightAPI.deleteHighlight(id)
    if (!isSuccess) {
      throw new errors.api.RequestFailError()
    }

    const highlightToDelete = this.highlightRepo.find(id)
    if (!highlightToDelete) {
      throw new errors.highlight.NotExistError()
    }

    this.highlightRepo.delete(id)

    const resourceToUpdate = this.resourceRepo.find<T>(
      highlightToDelete.resourceId,
    )

    if (isNil(resourceToUpdate?.highlightCount)) {
      throw new errors.highlight.InvalidCountError()
    }

    const newHighlightCount = (resourceToUpdate?.highlightCount || 0) - 1

    this.resourceRepo.update<T>(highlightToDelete.resourceId, {
      highlightCount: newHighlightCount,
    } as Partial<T>)
  }

  async fetchURLHighlights(resourceId: string): Promise<TypedURLHighlight[]> {
    return await this.highlightAPI.getURLHighlights(resourceId)
  }

  async fetchPDFHighlights(resourceId: string): Promise<TypedPDFHighlight[]> {
    return await this.highlightAPI.getPDFHighlights(resourceId)
  }

  scrollToHighlight(id: string): void {
    const highlight = this.highlightRepo.find(id)
    const resource = this.resourceRepo.find<HighlightableResources>(
      highlight?.resourceId || '',
    )

    if (resource?.type === 'url') {
      HighlightInteractor.scrollToURLHighlight(id)
    } else if (resource?.type === 'file') {
      HighlightInteractor.scrollToPDFHighlight(id)
    }

    this.store.dispatch(setHighlightIdToScroll(null))
  }

  openTooltip(pageIndex?: number): void {
    const { selection, tooltipData } =
      SelectionParser.createDataToHighlight(pageIndex)

    this.store.dispatch(setSelectionData(selection))
    this.store.dispatch(setToolData(tooltipData))
  }

  closeTooltip(id: string): void {
    HighlightElementManager.getOne(id).forEach((element) =>
      element.classList.remove(constants.CSS_SELECTORS.CLICKED_CLASS),
    )

    this.store.dispatch(setToolData(null))
  }

  activateHighlightCard(id: string): void {
    const highlight = this.highlightRepo.find(id)

    this.store.dispatch(setActiveHighlightId(highlight?.id || null))
  }

  getVisiblePageIndices(): number[] {
    const scrollBox = HighlightElementManager.getViewer()?.parentElement

    if (!scrollBox) return []

    const visiblePageNodes = Array.from(
      scrollBox.ownerDocument.querySelectorAll<HTMLElement>('.page'),
    ).filter((node) => {
      const childElem = node.firstElementChild
      if (!childElem) return false
      return !childElem.classList.contains('loadingIcon')
    })

    const visibleArea = [
      scrollBox.scrollTop,
      scrollBox.scrollTop + scrollBox.clientHeight,
    ]

    const newVisiblePageIndices = visiblePageNodes
      .filter((pageNode) => {
        const [areaTop, areaBottom] = visibleArea
        const pageNodeTop = pageNode.offsetTop
        const pageNodeBottom = pageNode.offsetTop + pageNode.clientHeight

        const isPageTopVisible =
          pageNodeTop >= areaTop && pageNodeTop <= areaBottom
        const isPageBottomVisible =
          pageNodeBottom >= areaTop && pageNodeBottom <= areaBottom
        const isPageBiggerThanVisibleArea =
          pageNodeTop <= areaTop && pageNodeBottom >= areaBottom

        const isPageInVisibleArea =
          isPageTopVisible || isPageBottomVisible || isPageBiggerThanVisibleArea

        return isPageInVisibleArea
      })
      .map((node) => Number(node.dataset.pageNumber) - 1)

    return newVisiblePageIndices
  }

  getOffset(el: Element) {
    const rect = el.getBoundingClientRect()
    return {
      left: rect.left,
      top: rect.top,
    }
  }

  getVisiblePageTop(visiblePageIndices: number[]): number[] {
    const scrollBox = HighlightElementManager.getViewer()?.parentElement

    if (!scrollBox) return []

    const visiblePageNodes = Array.from(
      scrollBox.ownerDocument.querySelectorAll<HTMLElement>('.page'),
    ).filter((node) => {
      const childElem = node.firstElementChild
      if (!childElem) return false
      return !childElem.classList.contains('loadingIcon')
    })

    const newVisiblePageTop = visiblePageNodes
      .filter((pageNode) =>
        visiblePageIndices.includes(Number(pageNode.dataset.pageNumber) - 1),
      )
      .map((pageNode) => this.getOffset(pageNode).top)

    return newVisiblePageTop
  }
}

export default autoBind(HighlightService)
