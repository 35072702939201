import dayjs from 'dayjs'
import { isNil } from 'lodash-es'
import { ITypedDocument, TypedDocument } from '@/models/document/TypedDocument'
import TypedProject, { ITypedProject } from '@/models/project'
import { getDocumentInstanceByJson } from '@/factories/getDocumentInstanceByJson'

// FIXME: 백엔드 서클에 itemData 바꿔달라고 요청후 response가 바뀌면 ITaskResponse 없애서 적용

export interface ITaskAssignee {
  assigneeId: string
  displayName: string
  photoUrl: string
}

export interface ITaskResponse {
  readonly id: string
  readonly content: string
  readonly isCompleted: boolean
  readonly assignee: ITaskAssignee | null
  readonly dueDate: string | null // YYYY-MM-DD
  readonly createdAt: number
  readonly updatedAt: number
  readonly document?: ITypedDocument
  readonly project?: ITypedProject
  readonly workspaceId: string
}

export interface ITypedTask {
  readonly taskId: string
  readonly document?: ITypedDocument | null
  readonly project?: ITypedProject | null
  readonly content: string
  readonly isCompleted: boolean
  readonly assignee: ITaskAssignee | null
  readonly dueDate: Date | null
  readonly createdAt: number
  readonly updatedAt: number
  readonly spaceId: string

  readonly documentId: string
  readonly projectId: string
}

export class EmptyTypedTask implements ITypedTask {
  readonly taskId: ''
  readonly document: undefined
  readonly project: undefined
  readonly documentId: 'no-document'
  readonly projectId: 'no-project'
  readonly content: ''
  readonly isCompleted: false
  readonly assignee: null
  readonly dueDate: null
  readonly createdAt: 0
  readonly updatedAt: 0
  readonly spaceId: ''
}

export default class TypedTask implements ITypedTask {
  constructor(
    readonly taskId: string,
    readonly content: string,
    readonly isCompleted: boolean,
    readonly assignee: ITaskAssignee | null,
    readonly dueDate: Date | null,
    readonly createdAt: number,
    readonly updatedAt: number,
    readonly spaceId: string,
    readonly document?: TypedDocument | null,
    readonly project?: TypedProject | null,
  ) {}

  static NO_DOCUMENT = 'no-document'
  static NO_PROJECT = 'no-project'

  get documentId() {
    return this.document?.documentId ?? TypedTask.NO_DOCUMENT
  }

  get projectId() {
    return this.project?.projectId ?? TypedTask.NO_PROJECT
  }

  static fromResponseJSON = (json: ITaskResponse): TypedTask => {
    if (!this.checkResponseJsonValid(json)) {
      throw new Error(`json is not ITaskResponse`)
    }

    return new TypedTask(
      json.id,
      json.content,
      json.isCompleted,
      json.assignee,
      json.dueDate === null ? null : new Date(json.dueDate),
      json.createdAt,
      json.updatedAt,
      json.workspaceId,
      json.document ? getDocumentInstanceByJson(json.document) : undefined,
      json.project
        ? TypedProject.fromJSON(json.project, json.workspaceId)
        : undefined,
    )
  }

  static getTempTask = (json: { content: string; spaceId: string }) => {
    const _getRandomNumber = (maxValue: number) => {
      return Math.floor(Math.random() * maxValue)
    }

    const _getRandomId = (length = 20) => {
      let result = ''
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(_getRandomNumber(charactersLength))
      }

      return result
    }

    return new TypedTask(
      _getRandomId(),
      json.content,
      false,
      null,
      null,
      Date.now(),
      Date.now(),
      json.spaceId,
    )
  }

  static checkResponseJsonValid = (json: ITaskResponse): boolean => {
    const value = !(
      !json ||
      !json.id ||
      isNil(json.content) ||
      isNil(json.isCompleted) ||
      !json.createdAt ||
      !json.updatedAt
    )

    return value
  }

  copyWith(options?: Partial<Omit<TypedTask, 'type'>>): TypedTask {
    return new TypedTask(
      options?.taskId ?? this.taskId,
      options?.content ?? this.content,
      options?.isCompleted ?? this.isCompleted,
      options?.assignee === null ? null : options?.assignee ?? this.assignee,
      options?.dueDate === null ? null : options?.dueDate ?? this.dueDate,
      options?.createdAt ?? this.createdAt,
      options?.updatedAt ?? this.updatedAt,
      options?.spaceId ?? this.spaceId,
      options?.document === null ? null : options?.document ?? this.document,
      options?.project === null ? null : options?.project ?? this.project,
    )
  }

  toJSON(): ITaskResponse {
    return {
      id: this.taskId,
      content: this.content,
      isCompleted: this.isCompleted,
      assignee: this.assignee,
      dueDate: this.dueDate ? dayjs(this.dueDate).format('YYYY-MM-DD') : null,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      workspaceId: this.spaceId,
      document: this.document?.toJSON(),
      project: this.project?.toJSON(),
    }
  }
}

export type UpdateTaskParams = Pick<
  Partial<TypedTask>,
  'content' | 'isCompleted' | 'assignee' | 'dueDate' | 'project' | 'document'
>

export type GetTasksFilter = {
  spaceId?: string
  projectId?: string
  assigneeId?: string
  documentId?: string
  cursor?: number
  limit?: number
  isCompleted?: boolean
}

export type TaskLocation = 'myHome' | 'document' | 'projectSelected'
