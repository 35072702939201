interface AccessPageText {
  'You need access': string
  'Request access, or switch to an account with access.': string
  'Go back': string
  'Go to home': string
  space: {
    'not invited space': string
  }
  project: {
    'not inivited project': string
  }
}

const englishAccessPageText: AccessPageText = {
  'You need access': 'You need access',
  'Request access, or switch to an account with access.':
    'Request access, or switch to an account with access.',
  'Go back': 'Go back',
  'Go to home': 'Go to home',
  space: {
    'not invited space': 'Oops, you are not invited to this space.',
  },
  project: {
    'not inivited project': 'Oops, you are not invited to this project.',
  },
}

const koreanAccessPageText: AccessPageText = {
  'You need access': '엑세스가 필요합니다',
  'Request access, or switch to an account with access.':
    '엑세스를 요청하거나 엑세스 권한이 있는 계정으로 변경해주세요.',
  'Go back': '돌아가기',
  'Go to home': '홈으로 이동',
  space: {
    'not invited space': '이 스페이스에 초대되어있지 않습니다.',
  },
  project: {
    'not inivited project': '이 프로젝트에 초대되어있지 않습니다.',
  },
}

export { englishAccessPageText, koreanAccessPageText }
