import { mutex } from '@/utils/common/mutex'
import { ProjectEvent } from '@/integrations/logging/event/project'
import ProjectRepository from '@/repositories/projectRepository'
import TypedProject from '@/models/project'
import autoBind from '@/utils/autoBind'
import ProjectAPI from '@/api/projectAPI'
import FavoriteRepository from '@/repositories/favoriteRepository'

type RequiredProjectId = Pick<TypedProject, 'projectId'> & Partial<TypedProject>
class ProjectService {
  constructor(
    private projectRepo: ProjectRepository,
    private favoriteRepo: FavoriteRepository,
    private projectEvent: ProjectEvent,
    private projectAPI: InstanceType<typeof ProjectAPI>,
  ) {}

  async createProject(
    title: string,
    spaceId: string,
    groupId: string,
    isPrivate: boolean,
  ): Promise<TypedProject> {
    const newProject = await this.projectAPI.createProject(
      title,
      spaceId,
      groupId,
      isPrivate,
    )
    this.projectRepo.addToByGroupKey(newProject)
    this.projectEvent.createdEvent({ projectId: newProject.projectId, spaceId })
    return newProject
  }

  async updateProject(willBeUpdatedProject: RequiredProjectId): Promise<void> {
    this.projectAPI.updateProjects([willBeUpdatedProject])
  }

  async deleteProject(projectId: string, groupId: string): Promise<void> {
    if (mutex.isLocked()) {
      return
    }
    await mutex.runExclusive(async () => {
      try {
        this.projectRepo.delete(projectId)
        this.projectRepo.deleteFromByGroupKey(projectId, groupId)
        await this.projectAPI.deleteProject(projectId)
      } catch (error) {
        console.error('Cannot delete project')
        console.error(error)
      }
    })
  }

  async toggleFavorite(projectId: string): Promise<void> {
    const project = this.projectRepo.find(projectId)
    if (!project) {
      return
    }

    const favoriteUpdatedProject = project.copyWith({
      favorite: !project.favorite,
    })

    try {
      this.projectRepo.update(project.projectId, favoriteUpdatedProject)

      if (project.favorite) {
        this.favoriteRepo.delete(project.projectId, project.spaceId)
        await this.projectAPI.removeProjectFromFavorites(project.projectId)
      } else {
        this.favoriteRepo.add(project, project.spaceId)
        await this.projectAPI.addProjectToFavorites(project.projectId)
        this.projectEvent.projectFavoriteAdded(
          project.spaceId,
          project.projectId,
        )
      }
    } catch (err) {
      this.projectRepo.update(project.projectId, project)

      if (project.favorite) {
        this.favoriteRepo.add(project, project.spaceId)
      } else {
        this.favoriteRepo.delete(project.projectId, project.spaceId)
      }
    }
  }

  async addMemberToPrivateProject(
    projectId: string,
    userIds: string[],
  ): Promise<void> {
    await this.projectAPI.addMembersToPrivateProject(projectId, userIds)
  }

  async removeMembersFromPrivateProject(
    projectId: string,
    userIds: string[],
  ): Promise<void> {
    await this.projectAPI.removeMembersFromPrivateProject(projectId, userIds)
  }
}

export default autoBind(ProjectService)
