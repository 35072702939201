import { AxiosInstance } from 'axios'
import TypedSpace, {
  ISpaceUsage,
  ITypedSpace,
  ITypedSpaceMember,
  SpaceInvitationInfo,
  TypedSpaceMember,
} from '@/models/space'
import autoBind from '@/utils/autoBind'
import { SubscriptionMetadataSet } from '@/models/subscription'

class SpaceAPI {
  constructor(
    readonly axios: AxiosInstance,
    readonly globalAxios: AxiosInstance,
    readonly subscriptionMetadataSet: SubscriptionMetadataSet,
  ) {}

  async createSpace(name: string, hex: string) {
    const { data } = await this.axios({
      method: 'post',
      url: '/api/spaces',
      data: { name, hex },
    })

    return TypedSpace.fromJSON(data, this.subscriptionMetadataSet)
  }

  async getSpaces(): Promise<TypedSpace[]> {
    const { data } = await this.axios({
      method: 'get',
      url: '/api/spaces',
    })

    const spaces = (data.spaces as ITypedSpace[]).map((json) =>
      TypedSpace.fromJSON(json, this.subscriptionMetadataSet),
    )

    return [...spaces]
  }

  async inviteUsers(
    spaceId: string,
    invitations: SpaceInvitationInfo[],
  ): Promise<void> {
    await this.axios({
      method: 'post',
      url: `/api/spaces/${spaceId}/invite`,
      data: { invitedUsers: invitations },
    })
  }

  async grantPermissions(
    spaceId: string,
    invitations: SpaceInvitationInfo[],
  ): Promise<void> {
    await this.axios({
      method: 'post',
      url: `/api/spaces/${spaceId}/permissions`,
      data: { members: invitations },
    })
  }

  async deleteSpaceMember(spaceId: string, memberId: string): Promise<void> {
    await this.axios.delete(`/api/spaces/${spaceId}/members/${memberId}`)
  }

  async deleteSpace(spaceId: string): Promise<void> {
    await this.axios.delete(`/api/spaces/${spaceId}`)
  }

  async getMembersBySpaceId(spaceId: string): Promise<TypedSpaceMember[]> {
    const { data } = await this.axios({
      method: 'get',
      url: `/api/spaces/${spaceId}/members`,
    })

    return (data as ITypedSpaceMember[]).map((json) =>
      TypedSpaceMember.fromJSON(json),
    )
  }

  async updateSpaceName(spaceId: string, name: string): Promise<TypedSpace> {
    const form = buildNameFormData({ name })

    const { data } = await this.axios.patch(`/api/spaces/${spaceId}`, form, {
      headers: {
        'content-type': 'multipart/form-data',
        'x-space-id': spaceId,
      },
    })

    return TypedSpace.fromJSON(data, this.subscriptionMetadataSet)
  }

  async updateSpaceImage(spaceId: string, photo: File): Promise<TypedSpace> {
    const form = buildSpaceImageFormData({ photo })

    const { data } = await this.axios.patch(`/api/spaces/${spaceId}`, form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })

    return TypedSpace.fromJSON(data, this.subscriptionMetadataSet)
  }

  async removeSpaceImage(spaceId: string): Promise<void> {
    await this.axios.delete(`/api/spaces/${spaceId}/photo`)
  }

  async leaveSpace(spaceId: string): Promise<void> {
    await this.axios.post(`/api/spaces/${spaceId}/leave`)
  }

  async removeSpaceMemberInPending(
    spaceId: string,
    spaceMemberEmail: string,
  ): Promise<void> {
    await this.axios.delete(`/api/spaces/${spaceId}/invite`, {
      params: { email: spaceMemberEmail },
    })
  }

  async shouldActivateAppSumoSpace(email: string): Promise<boolean> {
    const { appSumoActivationURL } = process.env.firebase

    if (!appSumoActivationURL || typeof appSumoActivationURL !== 'string') {
      return false
    }

    const { data } = await this.globalAxios({
      method: 'get',
      url: appSumoActivationURL,
      params: { email },
    })

    return Boolean(data)
  }

  async activateAppSumoSpace(email: string): Promise<string> {
    const { appSumoActivationURL } = process.env.firebase

    if (!appSumoActivationURL || typeof appSumoActivationURL !== 'string') {
      return ''
    }

    const { data } = await this.globalAxios({
      method: 'post',
      url: appSumoActivationURL,
      data: { email },
    })

    return data.id
  }

  async getUsage(spaceId: string): Promise<ISpaceUsage> {
    const { data } = await this.axios.get<ISpaceUsage>('/api/spaces/usage', {
      headers: { 'x-space-id': spaceId },
    })

    return data
  }
}

export default autoBind(SpaceAPI)

const buildNameFormData = (data: { name: string }) => {
  const form = new FormData()
  form.append('name', data.name)
  return form
}

const buildSpaceImageFormData = (data: { photo: File }) => {
  const form = new FormData()
  form.append('photo', data.photo)
  return form
}
