import { Store } from 'redux'
import { setToolData } from '@/slices/highlights/highlightsSlice'
import { constants, HighlightInteractor } from '@/utils/highlights'
import { TOOLTIP_TYPES } from '@/utils/viewers/highlightTool.util'

export default class HighlightElementManager {
  static create(body: HTMLElement, highlightId: string): HTMLElement {
    const highlightElement = body.ownerDocument?.createElement('mark')

    const highlightClassSelector =
      constants.CSS_SELECTORS.CLASS_PREFIX + highlightId

    highlightElement?.classList.add(highlightClassSelector)

    return highlightElement
  }

  static getOne(highlightId: string): HTMLElement[] {
    return Array.from(
      this.getRoot().querySelectorAll<HTMLElement>(
        `#${constants.CSS_SELECTORS.VIEWER_ID} .${constants.CSS_SELECTORS.CLASS_PREFIX}${highlightId}`,
      ),
    )
  }

  static getAll(): HTMLElement[] {
    return Array.from(
      this.getRoot().querySelectorAll<HTMLElement>(
        `#${constants.CSS_SELECTORS.VIEWER_ID} [class^="${constants.CSS_SELECTORS.CLASS_PREFIX}"]`,
      ),
    )
  }

  static getRoot(): HTMLDocument {
    const pdfViewerIframe = document.getElementById(
      constants.CSS_SELECTORS.IFRAME_VIEWER_ID,
    )

    if (pdfViewerIframe instanceof HTMLIFrameElement) {
      const iframeDocument = pdfViewerIframe.contentDocument
      if (!iframeDocument) {
        throw new Error('iframe document not exists')
      }

      return iframeDocument
    }

    return document
  }

  static getViewer(): HTMLElement | null {
    const viewer = this.getRoot().getElementById(
      constants.CSS_SELECTORS.VIEWER_ID,
    )

    return viewer ?? null
  }

  // 좌우 패딩없는 순수 pdf viewer
  static getPureViewer(viewer: HTMLElement): Element | null {
    if (viewer) {
      const pureViewer = viewer
        .querySelector('.page')
        ?.querySelector('.canvasWrapper')
      return pureViewer ?? null
    }
    return null
  }

  static getRenderBoundary(pageIndex?: number): HTMLElement | null {
    return pageIndex === undefined
      ? this.getViewer()
      : this.getRoot().querySelector(
          `.page[data-page-number="${(pageIndex || 0) + 1}"]`,
        )
  }

  static getCurrentPageIndex(): number {
    return (
      Number(
        this.getRoot().querySelector<HTMLInputElement>('#pageNumber')?.value ??
          '1',
      ) - 1
    )
  }

  static getTooltipAdditionalPosition(): { top: number; left: number } {
    const iframeViewer = document.getElementById(
      constants.CSS_SELECTORS.IFRAME_VIEWER_ID,
    )

    if (iframeViewer) {
      const iframePosition = iframeViewer.getBoundingClientRect()
      return { top: iframePosition.top, left: iframePosition.left }
    }

    return { top: 0, left: 0 }
  }

  static attachEventListener(
    store: Store,
    element: HTMLElement,
    id: string,
  ): void {
    element.onmouseenter = HighlightInteractor.onMouseEnter(id)

    element.onmouseleave = HighlightInteractor.onMouseLeave(id)

    element.onclick = HighlightInteractor.onClick(
      id,
      (top: number, right: number) => {
        const additionalPos = this.getTooltipAdditionalPosition()
        store.dispatch(
          setToolData({
            type: TOOLTIP_TYPES.DELETE,
            highlightId: id,
            position: {
              top:
                Math.round(top) +
                constants.toolPosition.forward.top +
                additionalPos.top,
              left:
                Math.round(right) +
                constants.toolPosition.forward.left +
                additionalPos.left,
            },
          }),
        )
      },
    )
  }
}
