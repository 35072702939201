import { createSlice } from '@reduxjs/toolkit'
import { ITypedDocument } from '@/models/document/TypedDocument'

const initialState: {
  splitViewed: string
  expandedResource: any
  expandedBacklinkDocument: ITypedDocument | null
  isDragging: boolean
  openedPopoverReferenceId: ''
} = {
  splitViewed: '',
  expandedResource: null,
  expandedBacklinkDocument: null,
  isDragging: false,
  openedPopoverReferenceId: '',
}

export const resourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {
    setResourceForSplitView: (state, action) => {
      state.splitViewed = action.payload
    },
    expandResource: (state, action) => {
      state.expandedResource = action.payload
    },
    expandBacklinkDocument: (state, action) => {
      state.expandedBacklinkDocument = action.payload
    },
    toggleIsDragging: (state) => {
      state.isDragging = !state.isDragging
    },
    setOpenedPopoverReferenceId: (state, action) => {
      state.openedPopoverReferenceId = action.payload
    },
  },
})

export const {
  setResourceForSplitView,
  expandResource,
  expandBacklinkDocument,
  toggleIsDragging,
  setOpenedPopoverReferenceId,
} = resourceSlice.actions

export default resourceSlice.reducer
