import { QueryClient } from 'react-query'
import { differenceBy } from 'lodash-es'
import { TypedFolder } from '@/models/folder'
import IBaseRepository from '@/repositories/types/baseRepository'
import { queryKeys } from '@/providers/react-query'

export default class FolderRepository implements IBaseRepository<TypedFolder> {
  constructor(readonly queryClient: QueryClient) {}

  add(item: TypedFolder): void {
    this.addFoldersAsUniq(item.documentId, [item])
  }

  addAll(items: TypedFolder[]): void {
    const foldersByDocumentId = items.reduce<{
      [documentId: string]: TypedFolder[]
    }>((foldersByDocumentId, item) => {
      foldersByDocumentId[item.documentId] ??= []
      foldersByDocumentId[item.documentId].push(item)
      return foldersByDocumentId
    }, {})

    Object.entries(foldersByDocumentId).forEach(([documentId, folders]) => {
      this.addFoldersAsUniq(documentId, folders)
    })
  }

  replace(documentId: string, items: TypedFolder[]): void {
    this.queryClient.setQueryData<TypedFolder[]>(
      queryKeys.foldersByDocumentId(documentId),
      items,
    )
  }

  find(id: string): TypedFolder | undefined {
    return this.findAll().find((folder) => folder.folderId === id)
  }

  findAll(documentId?: string): TypedFolder[] {
    const queriesData = this.queryClient.getQueriesData<TypedFolder[]>(
      documentId
        ? queryKeys.foldersByDocumentId(documentId)
        : queryKeys.allFolders(),
    )

    return queriesData.reduce<TypedFolder[]>(
      (allFolders, [, folders]) => [...allFolders, ...(folders ?? [])],
      [],
    )
  }

  exist(id: string): boolean {
    return Boolean(this.find(id))
  }

  update(id: string, item: Partial<TypedFolder>): boolean {
    const folder = this.find(id)

    if (!folder) {
      return false
    }

    this.queryClient.setQueryData<TypedFolder[]>(
      queryKeys.foldersByDocumentId(folder.documentId),
      (prevData) =>
        prevData!.map((eachPrevData) =>
          eachPrevData.folderId === folder.folderId
            ? folder.copyWith(item)
            : eachPrevData,
        ),
    )

    return true
  }

  delete(id: string): boolean {
    const folder = this.find(id)

    if (!folder) {
      return false
    }

    this.queryClient.setQueryData<TypedFolder[]>(
      queryKeys.foldersByDocumentId(folder.documentId),
      (prevData) =>
        prevData!.filter(({ folderId }) => folderId !== folder.folderId),
    )

    return true
  }

  clear(): void {
    this.queryClient.removeQueries(queryKeys.allFolders())
  }

  private addFoldersAsUniq(documentId: string, items: TypedFolder[]) {
    this.queryClient.setQueryData<TypedFolder[]>(
      queryKeys.foldersByDocumentId(documentId),
      (prevData) => {
        if (!prevData) {
          return [...items]
        }

        const dataNotExists = differenceBy(prevData, items, 'folderId')
        return [...items, ...(dataNotExists ?? [])]
      },
    )
  }
}
