interface SidenavText {
  Tutorial: string
  manageMembers: string
}

const englishSidenavText: SidenavText = {
  Tutorial: 'Tutorial',
  manageMembers: 'Manage members',
}

const koreanSidenavText: SidenavText = {
  Tutorial: '튜토리얼',
  manageMembers: '멤버 관리',
}

export { englishSidenavText, koreanSidenavText }
