import { RESOURCE_EVENT, RESOURCE_TAG } from '@/integrations/logging/enum'
import { getLifeSpan } from '@/integrations/logging/utils'
import TypedEvent from '@/integrations/logging/event/typedEvent'
import { ILoggingClient } from '@/integrations/logging/client'
import { TypedFileResource } from '@/models/resource/TypedFileResource'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export interface IResourceEvent {
  resourceDownloadedEvent: (data: TypedFileResource) => void
}

export class ResourceEvent extends TypedEvent implements IResourceEvent {
  constructor(clients: ILoggingClient[]) {
    super(clients)
  }

  async createdEvent(data: any) {
    this.sendEvent(RESOURCE_EVENT.CREATED, {
      resourceId: data.resourceId,
      type: data.type,
      addedFrom: data.addedFrom,
      addedToInbox: data.addedToInbox,
      anchorResourceId: data.documentId,
    })
  }

  async deletedEvent(data: Array<any>) {
    for (const resource of data) {
      this.sendEvent(RESOURCE_EVENT.DELETED, {
        resourceId: resource.resourceId,
        type: resource.type,
        lifeSpan: getLifeSpan(resource.createdAt),
        anchorResourceId: resource.documentId,
      })
    }
  }

  readModeToggledEvent(data: any) {
    this.sendEvent(RESOURCE_EVENT.SPLIT_VIEWER_READ_MODE_TOGGLED, {
      resourceId: data.resourceId,
      isReadModeActivation: data.isReadModeActivation,
    })
  }

  movedInboxToDocumentEvent(data: any) {
    this.sendEvent(RESOURCE_EVENT.MOVED_INBOX_TO_DOCUMENT, {
      resourceId: data.resourceId,
      resourceLifeSpan: getLifeSpan(data.resourceCreatedAt),
      anchorResourceId: data.documentId,
      anchorResourceLifeSpan:
        getLifeSpan(data?.documentCreatedAt) || 'not check',
      movedLocation: data.movedLocation,
    })
  }

  linkEvent(data: any) {
    this.sendEvent(RESOURCE_EVENT.LINK, {
      resourceId: data.resourceId,
      type: data.type,
      backlinkCount: data.backlinks?.length,
      resourceLifeSpan: getLifeSpan(data?.createdAt),
      anchorResourceId: data.docId,
    })
  }

  clipboardToolTipClickedEvent() {
    this.sendEvent(RESOURCE_EVENT.CLIPBOARD_TOOLTIP_CLICKED)
  }

  clipboardPasteButtonClickedEvent() {
    this.sendEvent(RESOURCE_EVENT.CLIPBOARD_PASTE_BUTTON_CLICKED)
  }

  resourceDownloadedEvent(data: any) {
    this.sendEvent(RESOURCE_EVENT.RESOURCE_DOWNLOADED, {
      resourceId: data.resourceId,
      lifeSpan: getLifeSpan(data.createdAt),
      anchorResourceId: data.documentId,
    })
  }

  resourceTagEvent(
    event: RESOURCE_EVENT,
    resourceId: string,
    status: RESOURCE_TAG,
  ) {
    this.sendEvent(event, {
      resourceId,
      status,
    })
  }
}
