import axios, { AxiosInstance } from 'axios'
import { ITypedAxiosGroup } from '@/axios'
export default class TypedAxiosGroup implements ITypedAxiosGroup {
  typedAxios: AxiosInstance
  globalAxios: AxiosInstance
  loggingAxios: AxiosInstance
  paymentAxios: AxiosInstance
  constructor() {
    const defaultConfig = {
      headers: {
        'content-type': 'application/json',
      },
    }
    this.typedAxios = axios.create({
      ...defaultConfig,
      baseURL: process.env.firebase.apiServerURL,
    })

    this.loggingAxios = axios.create({
      ...defaultConfig,
      baseURL: process.env.firebase.apiServerURL,
    })

    this.globalAxios = axios.create(defaultConfig)

    this.paymentAxios = axios.create({
      ...defaultConfig,
      baseURL: process.env.firebase.paymentServerURL,
    })
  }
}
