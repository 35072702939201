import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore/lite'

const isTest = process.env.NODE_ENV === 'test'
const config = isTest ? JSON.parse(process.env.firebase) : process.env.firebase

const firebaseApp = initializeApp(config.client)

const db = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp)

// TODO: Apply Firebase Local Emulator Suite
// TODO: https://firebase.google.com/docs/emulator-suite
if (isTest || location.hostname === 'localhost') {
  connectFirestoreEmulator(db, 'localhost', 9000)

  if (!process.env.EXTENSION_MODE) {
    connectAuthEmulator(auth, 'http://localhost:9099', {
      disableWarnings: true,
    })
  }
}

export { firebaseApp, db }
