import { TASK_EVENT } from '@/integrations/logging/enum'
import { getLifeSpan } from '@/integrations/logging/utils'
import TypedEvent from '@/integrations/logging/event/typedEvent'
import { TaskLocation } from '@/models/task'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class TaskEvent extends TypedEvent {
  createdEvent(
    spaceId: string,
    projectId: string,
    documentId: string,
    taskId: string,
  ) {
    this.sendEvent(TASK_EVENT.CREATED, {
      spaceId,
      projectId,
      anchorResourceId: documentId,
      taskId,
    })
  }

  deletedEvent(
    spaceId: string,
    projectId: string,
    documentId: string,
    taskId: string,
    createdAt: number,
  ) {
    this.sendEvent(TASK_EVENT.DELETED, {
      spaceId,
      projectId,
      anchorResourceId: documentId,
      taskId,
      lifeSpan: getLifeSpan(createdAt),
    })
  }

  taskDoneStatusChanged(
    spaceId: string,
    projectId: string,
    documentId: string,
    taskId: string,
    isCompleted: boolean,
    clickLocation: TaskLocation,
  ) {
    this.sendEvent(TASK_EVENT.TASK_DONE_STATUS_CHANGED, {
      spaceId,
      projectId,
      anchorResourceId: documentId,
      taskId,
      isCompleted,
      clickLocation,
    })
  }

  taskDueDateAssigned(
    spaceId: string,
    projectId: string,
    documentId: string,
    taskId: string,
    location: TaskLocation,
    nextDueDate: Date,
  ) {
    this.sendEvent(TASK_EVENT.TASK_DUE_DATE_ASSIGNED, {
      spaceId,
      projectId,
      anchorResourceId: documentId,
      taskId,
      location,
      nextDueDate,
    })
  }

  taskOwnerAssigned(
    spaceId: string,
    projectId: string,
    documentId: string,
    taskId: string,
    location: TaskLocation,
    owner: string,
  ) {
    this.sendEvent(TASK_EVENT.TASK_OWNER_ASSIGNED, {
      spaceId,
      projectId,
      anchorResourceId: documentId,
      taskId,
      location,
      owner,
    })
  }
}
