import { TypedFileResource } from '@/models/resource/TypedFileResource'
import TypedUrlResource from '@/models/resource/TypedUrlResource'

export const INBOX_FOLDER_ID = 'inbox'
export const INBOX_NAME = 'Inbox'
export interface IInbox {
  folderId: typeof INBOX_FOLDER_ID
  name: typeof INBOX_NAME
  createdAt: number
  updatedAt: number
  resourceList: (TypedUrlResource | TypedFileResource)[]
}

export class TypedInbox implements IInbox {
  constructor(
    readonly folderId: typeof INBOX_FOLDER_ID,
    readonly name: typeof INBOX_NAME,
    readonly createdAt: number,
    readonly updatedAt: number,
    readonly resourceList: (TypedUrlResource | TypedFileResource)[],
  ) {
    this.folderId = INBOX_FOLDER_ID
    this.name = INBOX_NAME
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.resourceList = resourceList
  }

  static fromJSON(json: IInbox): TypedInbox {
    return new TypedInbox(
      json.folderId,
      json.name,
      json.createdAt,
      json.updatedAt,
      json.resourceList,
    )
  }

  copyWith(options?: Partial<TypedInbox>): TypedInbox {
    return new TypedInbox(
      options?.folderId ?? this.folderId,
      options?.name ?? this.name,
      options?.createdAt ?? this.createdAt,
      options?.updatedAt ?? this.updatedAt,
      options?.resourceList ?? this.resourceList,
    )
  }
}
