import { cloneDeep, curry, filter, flow, keyBy, uniqBy } from 'lodash-es'
import { ILink, NetworkLink } from '@/models/networkLink'
import { NetworkNode } from '@/models/networkNode'
import { TypedResourceType } from '@/models/resource/TypedResource'

const isEdgeHaveExistNode = curry(
  (nodesKeyById: Record<string, NetworkNode>, edge: any) =>
    !!nodesKeyById[edge?.source] && !!nodesKeyById[edge?.target],
)

export interface Paintable {
  paint(ctx: CanvasRenderingContext2D, params?: any): void
}
interface Neighbor {
  node_id: string
  project_ids: string[]
}
export interface INode {
  readonly id: string
  readonly node_type: TypedResourceType
  readonly node_weight: number
  readonly neighbors?: Neighbor[]
  readonly metadata: { [key: string]: any }
  readonly title: string
  readonly group: string
  readonly project_ids: string[]
  readonly createdAt?: string
  readonly archived?: boolean
  readonly inbox?: boolean
}
export interface ITypedNetwork {
  readonly currentProjectId: string
  readonly nodes: NetworkNode[]
  readonly edges: ILink[]
}

export class TypedNetwork implements ITypedNetwork {
  private _documentNodes?: NetworkNode[]
  private _resourceNodes?: NetworkNode[]

  readonly currentProjectId: string
  readonly documentsinOtherProject: NetworkNode[]
  constructor(
    readonly nodes: NetworkNode[],
    readonly edges: NetworkLink[],
    currentProjectId: string,
  ) {
    this.nodes = flow([
      function excludeDuplicateNetworkNodes(nodes: NetworkNode[]) {
        return uniqBy(nodes, 'id')
      },
      function filterIndexTrue(nodes: NetworkNode[]) {
        return filter(nodes, (node) => node.inbox === false)
      },
    ])(nodes)

    this.edges = flow([
      (edges: NetworkLink[]) => {
        return filter(edges, isEdgeHaveExistNode(keyBy(this.nodes, 'id')))
      },
      cloneDeep,
    ])(edges)
    this.currentProjectId = currentProjectId ?? 'all'

    this.documentsinOtherProject = nodes.filter(
      (node) => !node.project_ids.includes(currentProjectId ?? ''),
    )
  }

  static fromJSON(json: ITypedNetwork): TypedNetwork {
    const nodes = json.nodes.map((node) =>
      NetworkNode.fromJson({
        ...node,
        currentProjectId: json.currentProjectId,
      }),
    )
    const links = json.edges.map(NetworkLink.fromJson)
    return new TypedNetwork(nodes, links, json.currentProjectId)
  }

  get documentNodes() {
    this._documentNodes ??= this.nodes.filter(
      (node) =>
        node.node_type === TypedResourceType.DOCUMENT &&
        (node.project_ids.includes(this.currentProjectId) ||
          this.currentProjectId === 'all'),
    )
    return this._documentNodes
  }

  getHighlightNodesByClickedNodeId(nodeId?: string) {
    if (!nodeId) return []
    const node = this.nodes.find((node: any) => node.id === nodeId)

    return node?.neighbors?.map((node: any) => node.node_id) ?? []
  }
}
