import {
  INotification,
  Notification,
  NotificationType,
} from '@/models/notification'
import { TypedDocumentType } from '@/models/document/TypedDocument'
import { ITypedUser } from '@/models/user'
import { ReviewPurposeType } from '@/models/review'

export interface ICommentCreatedNotification extends INotification {
  documentId: string
  documentType: TypedDocumentType
  documentTitle: string
  commentContent: string
  reviewId: string
  reviewPurpose: ReviewPurposeType
  assignor: Omit<ITypedUser, 'userId'>
  createdAt: Date
}

class CommentCreatedNotification
  extends Notification
  implements ICommentCreatedNotification
{
  constructor(
    readonly id: string,
    readonly type: NotificationType,
    readonly checked: boolean,
    readonly spaceId: string,

    readonly documentId: string,
    readonly documentType: TypedDocumentType,
    readonly documentTitle: string,
    readonly commentContent: string,

    readonly reviewId: string,
    readonly reviewPurpose: ReviewPurposeType,
    readonly assignor: Omit<ITypedUser, 'userId'>,
    readonly createdAt: Date,
  ) {
    super(id, type, checked, spaceId)
  }

  static fromJSON(
    json: ICommentCreatedNotification,
  ): CommentCreatedNotification {
    return new CommentCreatedNotification(
      json.id,
      json.type,
      json.checked,
      json.spaceId,
      json.documentId,
      json.documentType,
      json.documentTitle,
      json.commentContent,
      json.reviewId,
      json.reviewPurpose,
      json.assignor,
      new Date(json.createdAt),
    )
  }

  copyWith(
    options?: Partial<ICommentCreatedNotification>,
  ): CommentCreatedNotification {
    return new CommentCreatedNotification(
      options?.id ?? this.id,
      options?.type ?? this.type,
      options?.checked ?? this.checked,
      options?.spaceId ?? this.spaceId,
      options?.documentId ?? this.documentId,
      options?.documentType ?? this.documentType,
      options?.documentTitle ?? this.documentTitle,
      options?.commentContent ?? this.commentContent,
      options?.reviewId ?? this.reviewId,
      options?.reviewPurpose ?? this.reviewPurpose,
      options?.assignor ?? this.assignor,
      options?.createdAt ?? this.createdAt,
    )
  }
}

export default CommentCreatedNotification
