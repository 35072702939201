import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import TypedResource from '@/models/resource/TypedResource'
import TypedProject from '@/models/project'
import { TypedDocument } from '@/models/document/TypedDocument'
import { ResourceFilterType } from '@/models/resource/ResourceFilter'
import { SortFilterType } from '@/models/SortFilter'

export const sortResourcesByTime = <
  T extends TypedResource | TypedProject | TypedDocument,
>(
  selectedSortFilter: SortFilterType,
  resources: T[],
) => {
  switch (selectedSortFilter) {
    case 'latest':
      return [...resources].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
    case 'last':
      return [...resources].sort((a, b) =>
        a.createdAt >= b.createdAt ? 1 : -1,
      )
    default:
      return resources
  }
}

const initialState: {
  searchWord: string
  selectedResourceFilter?: ResourceFilterType
  selectedOwnerUserId?: string
  selectedSortFilter: SortFilterType
} = {
  searchWord: '',
  selectedResourceFilter: undefined,
  selectedOwnerUserId: undefined,
  selectedSortFilter: 'default',
}

export const documentPageSearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setDocumentPageSearchWord: (state, action: PayloadAction<string>) => {
      state.searchWord = action.payload
    },
    setDocumentPageResourceFilterType: (
      state,
      action: PayloadAction<ResourceFilterType | undefined>,
    ) => {
      state.selectedResourceFilter = action.payload
    },
    setDocumentPageSelectedOwnerUserId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selectedOwnerUserId = action.payload
    },
    setDocumentPageSelectedSortFilter: (
      state,
      action: PayloadAction<SortFilterType>,
    ) => {
      state.selectedSortFilter = action.payload
    },
    resetDocumentPageSearchStore: () => initialState,
  },
})

export const {
  setDocumentPageSearchWord,
  resetDocumentPageSearchStore,
  setDocumentPageResourceFilterType,
  setDocumentPageSelectedOwnerUserId,
  setDocumentPageSelectedSortFilter,
} = documentPageSearchSlice.actions

export default documentPageSearchSlice.reducer
