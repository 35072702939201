import {
  englishReviewText,
  koreanReviewText,
} from '@/locale/translate/translate.review'
import {
  englishProjectText,
  koreanProjectText,
} from '@/locale/translate/translate.project'
import {
  englishDocumentText,
  koreanDocumentText,
} from '@/locale/translate/translate.document'
import {
  englishMessageText,
  koreanMessageText,
} from '@/locale/translate/translate.message'
import {
  englishHomeViewText,
  koreanHomeviewText,
} from '@/locale/translate/translate.homeview'
import {
  englishTaskText,
  koreanTaskText,
} from '@/locale/translate/translate.task'
import {
  englishSidenavText,
  koreanSidenavText,
} from '@/locale/translate/translate.sidenav'
import {
  englishAppHomeText,
  koreanAppHomeText,
} from '@/locale/translate/translate.apphome'
import {
  englishResourceText,
  koreanResourceText,
} from '@/locale/translate/translate.resource'
import {
  englishModalText,
  koreanModalText,
} from '@/locale/translate/translate.modal'
import {
  englishDropDownText,
  koreanDropDownText,
} from '@/locale/translate/translate.dropdown'
import {
  englishExtensionText,
  koreanExtensionText,
} from '@/locale/translate/translate.extension'
import {
  englishFolderText,
  koreanFolderText,
} from '@/locale/translate/translate.folder'
import {
  englishPanelText,
  koreanPanelText,
} from '@/locale/translate/translate.panel'
import {
  englishDuplicateText,
  koreanDuplicateText,
} from '@/locale/translate/translate.duplicate'
import {
  englishDocumentPageText,
  koreanDocumentPageText,
} from '@/locale/translate/translate.documentPage'
import {
  englishProfileText,
  koreanProfileText,
} from '@/locale/translate/translate.profile'
import {
  englishSpaceText,
  koreanSpaceText,
} from '@/locale/translate/translate.space'
import {
  englishInboxText,
  koreanInboxText,
} from '@/locale/translate/translate.inbox'
import {
  englishViewerText,
  koreanViewerText,
} from '@/locale/translate/translate.viewer'
import {
  englishSearchText,
  koreanSearchText,
} from '@/locale/translate/translate.search'
import {
  englishTooltipText,
  koreanTooltipText,
} from '@/locale/translate/translate.tooltip'
import {
  englishKnowldgeNetworkText,
  koreanKnowldgeNetworkText,
} from '@/locale/translate/translate.kn'
import {
  englishPaymentPageText,
  koreanPaymentPageText,
} from '@/locale/translate/translate.paymentPage'
import {
  englishMyHomeText,
  koreanMyHomeText,
} from '@/locale/translate/translate.myhome'
import {
  englishAuthText,
  koreanAuthText,
} from '@/locale/translate/translate.auth'
import {
  englishLanguageText,
  koreanLanguageText,
} from '@/locale/translate/translate.language'
import {
  englishTemplateText,
  koreanTemplateText,
} from '@/locale/translate/translate.template'
import {
  englishCitationText,
  koreanCitationText,
} from '@/locale/translate/translate.citation'
import {
  englishAccessPageText,
  koreanAccessPageText,
} from '@/locale/translate/translate.access'
import {
  englishNotificationText,
  koreanNotificationText,
} from '@/locale/translate/translate.notification'
import {
  englishRelativeTimesText,
  koreanRelativeTimesText,
} from '@/locale/translate/translate.relativeTime'
import {
  englishCustomerHelpText,
  koreanCustomerHelpText,
} from '@/locale/translate/translate.customerHelp'

const SUPPORTED_LANGUAGES = ['ko', 'en']
export type SUPPORTED_LANGUAGES = 'ko' | 'en'
const DEFAULT_LANGUAGE = 'en'

// https://stackoverflow.com/a/25603630
export const getBrowserLanguage = () => {
  return navigator.languages
    ? navigator.languages[0]
    : navigator.language || navigator.userLanguage
}

export const getLanguage = () => {
  let language = getBrowserLanguage()?.substring(0, 2)

  if (!SUPPORTED_LANGUAGES.includes(language)) {
    language = DEFAULT_LANGUAGE
  }

  return language as SUPPORTED_LANGUAGES
}

export const TEXTS_WITH_LOCALE = {
  en: {
    ...englishHomeViewText,
    task: {
      ...englishTaskText,
    },
    sideNav: {
      ...englishSidenavText,
    },
    appHome: {
      ...englishAppHomeText,
    },
    resource: {
      ...englishResourceText,
    },
    review: {
      ...englishReviewText,
    },
    inbox: {
      ...englishInboxText,
    },
    folder: {
      ...englishFolderText,
    },
    document: {
      ...englishDocumentText,
    },
    project: {
      ...englishProjectText,
    },
    modal: {
      ...englishModalText,
    },
    message: {
      ...englishMessageText,
    },
    panel: {
      ...englishPanelText,
    },
    dropdown: {
      ...englishDropDownText,
    },
    extension: {
      ...englishExtensionText,
    },
    duplicate: {
      ...englishDuplicateText,
    },
    documentPage: {
      ...englishDocumentPageText,
    },
    profile: {
      ...englishProfileText,
    },
    space: {
      ...englishSpaceText,
    },
    viewer: {
      ...englishViewerText,
    },
    search: {
      ...englishSearchText,
    },
    tooltip: {
      ...englishTooltipText,
    },
    kn: {
      ...englishKnowldgeNetworkText,
    },
    paymentPage: {
      ...englishPaymentPageText,
    },
    myhome: {
      ...englishMyHomeText,
    },
    auth: {
      ...englishAuthText,
    },
    language: {
      ...englishLanguageText,
    },
    template: {
      ...englishTemplateText,
    },
    citation: {
      ...englishCitationText,
    },
    accessPage: {
      ...englishAccessPageText,
    },
    notification: {
      ...englishNotificationText,
    },
    relativeTimes: {
      ...englishRelativeTimesText,
    },
    cutomerHelp: {
      ...englishCustomerHelpText,
    },
  },
  ko: {
    ...koreanHomeviewText,
    task: {
      ...koreanTaskText,
    },
    sideNav: {
      ...koreanSidenavText,
    },
    appHome: {
      ...koreanAppHomeText,
    },
    resource: {
      ...koreanResourceText,
    },
    review: {
      ...koreanReviewText,
    },
    inbox: {
      ...koreanInboxText,
    },
    folder: {
      ...koreanFolderText,
    },
    document: {
      ...koreanDocumentText,
    },
    project: {
      ...koreanProjectText,
    },
    modal: {
      ...koreanModalText,
    },
    message: {
      ...koreanMessageText,
    },
    panel: {
      ...koreanPanelText,
    },
    dropdown: {
      ...koreanDropDownText,
    },
    extension: {
      ...koreanExtensionText,
    },
    duplicate: {
      ...koreanDuplicateText,
    },
    documentPage: {
      ...koreanDocumentPageText,
    },
    profile: {
      ...koreanProfileText,
    },
    space: {
      ...koreanSpaceText,
    },
    viewer: {
      ...koreanViewerText,
    },
    search: {
      ...koreanSearchText,
    },
    tooltip: {
      ...koreanTooltipText,
    },
    kn: {
      ...koreanKnowldgeNetworkText,
    },
    paymentPage: {
      ...koreanPaymentPageText,
    },
    myhome: {
      ...koreanMyHomeText,
    },
    auth: {
      ...koreanAuthText,
    },
    language: {
      ...koreanLanguageText,
    },
    template: {
      ...koreanTemplateText,
    },
    citation: {
      ...koreanCitationText,
    },
    accessPage: {
      ...koreanAccessPageText,
    },
    notification: {
      ...koreanNotificationText,
    },
    relativeTimes: {
      ...koreanRelativeTimesText,
    },
    cutomerHelp: {
      ...koreanCustomerHelpText,
    },
  },
}
