import { ITypedUser, TypedUser } from '@/models/user'

export interface IPageViewUserResultResponse {
  readonly id: string
  readonly count: number
  readonly createdDate: string
  readonly updatedDate: string
  readonly user: {
    profile: ITypedUser
  }
}

export interface IPageViewUserResult {
  readonly id: string
  readonly count: number
  readonly createdDate: string
  readonly updatedDate: string
  readonly userProfile: ITypedUser
}

export class PageViewUserResult implements IPageViewUserResult {
  constructor(
    readonly id: string,
    readonly count: number,
    readonly createdDate: string,
    readonly updatedDate: string,
    readonly userProfile: TypedUser,
  ) {}

  static fromJSON(json: IPageViewUserResult): PageViewUserResult {
    return new PageViewUserResult(
      json.id,
      json.count,
      json.createdDate,
      json.updatedDate,
      json.userProfile,
    )
  }

  static fromResponseJSON(
    json: IPageViewUserResultResponse,
  ): PageViewUserResult {
    return new PageViewUserResult(
      json.id,
      json.count,
      json.createdDate,
      json.updatedDate,
      TypedUser.fromJSON(json.user.profile),
    )
  }

  toJSON(): IPageViewUserResult {
    return {
      id: this.id,
      count: this.count,
      createdDate: this.createdDate,
      updatedDate: this.updatedDate,
      userProfile: this.userProfile,
    }
  }

  copyWith(options?: Partial<PageViewUserResult>): PageViewUserResult {
    return new PageViewUserResult(
      options?.id ?? this.id,
      options?.count ?? this.count,
      options?.createdDate ?? this.createdDate,
      options?.updatedDate ?? this.updatedDate,
      options?.userProfile ?? this.userProfile,
    )
  }
}
