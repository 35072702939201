import { combineReducers } from 'redux'
import { createMigrate, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import feedbackReducer from '@/utils/feedback/feedbackSlice'
import authReducer from '@/slices/auth/authActionSlice'
import resourceReducer from '@/slices/resources/resourceSlice'
import documentReducer from '@/slices/documents/documentSlice'
import viewerReducer from '@/slices/viewers/viewerSlice'
import panelStatusReducer from '@/slices/viewers/panelStatusSlice'
import extensionReducer from '@/slices/common/extensionSlice'
import bulkActionReducer from '@/slices/bulkActions/bulkActionSlice'
import duplicateResourceReducer from '@/slices/common/duplicateResourceSlice'
import focusActionReducer from '@/slices/focusActions/focusActionSlice'
import userActionSlice, {
  initialState as userActionInitialState,
} from '@/slices/common/userActionSlice'
import loadingReducer from '@/slices/common/loadingSlice'
import highlightsReducer from '@/slices/highlights/highlightsSlice'
import clipboardSlice from '@/slices/clipboardSlice'
import spaceSlice from '@/slices/spaceSlice'
import documentPageSearchSlice from '@/slices/search/documentPageSearchSlice'
import appHomeSearchSlice from '@/slices/search/appHomeSearchSlice'
import taskSlice from '@/slices/taskSlice'
import dndSlice from '@/slices/dndSlice'
import groupSlice from '@/slices/groupSlice'
import appSumoSlice from '@/slices/appSumoSlice'
import myHomeSlice from '@/slices/myHomeSlice'
import requestingSlice from '@/slices/requestingSlice'
import citationsSlice from '@/slices/citations/citationsSlice'
import currentLibrarySlice from '@/slices/currentLibrarySlice'

const reducers = combineReducers({
  user: authReducer,
  resource: resourceReducer,
  document: documentReducer,
  viewer: persistReducer(
    {
      key: 'viewer',
      storage,
      whitelist: ['isDocPageViewerSwapped'],
    },
    viewerReducer,
  ),
  panelStatus: panelStatusReducer,
  bulkAction: bulkActionReducer,
  duplicateResource: duplicateResourceReducer,
  focusAction: focusActionReducer,
  extension: extensionReducer,
  userAction: userActionSlice,
  loading: loadingReducer,
  highlights: highlightsReducer,
  feedback: feedbackReducer,
  clipboard: clipboardSlice,
  space: spaceSlice,
  documentPageSearch: documentPageSearchSlice,
  appHomeSearch: appHomeSearchSlice,
  currentLibrary: currentLibrarySlice,
  task: taskSlice,
  dnd: dndSlice,
  group: groupSlice,
  appSumo: appSumoSlice,
  myHome: myHomeSlice,
  requesting: requestingSlice,
  citations: citationsSlice,
})

const persistedReducer = persistReducer(
  {
    key: 'persist-info',
    storage,
    whitelist: ['user', 'userAction', 'space'],
    version: 1,
    migrate: createMigrate({
      0: (state: any) => {
        const userAction = {
          ...(state.userAction ?? userActionInitialState),
          documentPageHistory: {
            ...(state.userAction?.workspaceHistory ?? {}),
          },
        }
        delete userAction.workspaceHistory

        return { ...state, userAction }
      },
      1: (state: any) => {
        const {
          uid,
          userDisplayName,
          displayName,
          imageURL,
          originalImageURL,
          email,
        } = state.user.auth

        if (
          !uid ||
          !userDisplayName ||
          !displayName ||
          !imageURL ||
          !originalImageURL ||
          !email
        ) {
          return { ...state, user: { data: undefined } }
        }

        return {
          ...state,
          user: {
            data: {
              uid,
              userDisplayName,
              displayName,
              imageURL,
              originalImageURL,
              email,
            },
          },
        }
      },
    }),
  },
  reducers,
)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    // FIXME: 리쿼리 적용 후 아래 코드 한줄 지워야합니다.
    immutableCheck: false,
  }),
  devTools: { trace: true },
})

export default store

export type RootState = ReturnType<typeof store.getState>
