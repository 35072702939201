import TypedEvent from '@/integrations/logging/event/typedEvent'
import { SPACE_EVENT } from '@/integrations/logging/enum'
import { AssignableRole } from '@/models/space'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class SpaceEvent extends TypedEvent {
  myHomeTabClicked(spaceId: string) {
    this.sendEvent(SPACE_EVENT.MY_HOME_TAB_CLICKED, { spaceId })
  }

  spaceCreated(spaceId: string) {
    this.sendEvent(SPACE_EVENT.SPACE_CREATED, { spaceId })
  }

  inviteMembersSkipButtonClicked(spaceId: string) {
    this.sendEvent(SPACE_EVENT.INVITE_MEMBERS_SKIP_BUTTON_CLICKED, { spaceId })
  }

  inviteMembersButtonClicked(
    spaceId: string,
    role: AssignableRole,
    num: number,
  ) {
    this.sendEvent(SPACE_EVENT.INVITE_MEMBERS_BUTTON_CLICKED, {
      spaceId,
      role,
      num,
    })
  }

  addMemberInviteButtonClicked(
    spaceId: string,
    role: AssignableRole,
    num: number,
  ) {
    this.sendEvent(SPACE_EVENT.ADD_MEMBER_INVITE_BUTTON_CLICKED, {
      spaceId,
      role,
      num,
    })
  }

  deleteSpaceButtonClicked(spaceId: string, numOfTeamMembers: number) {
    this.sendEvent(SPACE_EVENT.SPACE_DELETED, {
      spaceId,
      numOfTeamMembers,
    })
  }

  leaveSpaceButtonClicked(spaceId: string, numOfTeamMembers: number) {
    this.sendEvent(SPACE_EVENT.LEAVE_SPACE_BUTTON_CLICKED, {
      spaceId,
      numOfTeamMembers,
    })
  }
}
