import IntegrationAPI from '@/api/integrationAPI'
import { Integration, IntegrationType } from '@/models/integration'
import autoBind from '@/utils/autoBind'

class SlackIntegrationService {
  constructor(
    private readonly integrationAPI: InstanceType<typeof IntegrationAPI>,
  ) {}

  async sendCode(code: string, spaceId: string): Promise<Integration> {
    const redirect_uri = this.getRedirectUri(spaceId)

    const result = await this.integrationAPI.sendOAuthCode({
      type: 'slack',
      code,
      redirect_uri,
    })
    return result
  }

  async getIntegrations(): Promise<Integration[]> {
    const result = await this.integrationAPI.getIntegrations() // FIXME Integration이 추가되면 integration서비스로 분리

    return result
  }

  async deleteIntegration({
    type,
  }: {
    type: IntegrationType
  }): Promise<boolean> {
    const result = await this.integrationAPI.deleteIntegration({ type })

    return result
  }

  getConnectUrl(spaceId: string) {
    const scope = this.getSlackAppScopeWithEncode()
    const clientId = this.SlackAppSetting.client_id
    const redirect_uri = this.getRedirectUri(spaceId)

    const targetUrl = `${this.SlackAppSetting.connectSlackUrl}?client_id=${clientId}&scope=${scope}&redirect_uri=${redirect_uri}&state=`
    return targetUrl
  }

  private getRedirectUri(spaceId: string) {
    const redirect_uri = `${window.location.origin}${this.SlackAppSetting.redirect_uri}/space/${spaceId}`
    return redirect_uri
  }

  private getSlackAppScopeWithEncode() {
    return encodeURIComponent(String(this.SlackAppSetting.scopes))
  }

  private readonly SlackAppSetting = {
    connectSlackUrl: 'https://slack.com/oauth/v2/authorize',
    client_id: process.env.SLACK_CLIENT_ID!,
    scopes: [
      'chat:write',
      'users.profile:read',
      'users:read',
      'users:read.email',
    ],
    redirect_uri: '/integrations/slack/callback',
  }
}

export default autoBind(SlackIntegrationService)
