import { TypedDocument } from '@/models/document/TypedDocument'
import TypedResource from '@/models/resource/TypedResource'

export default abstract class ViewerStatus<
  T extends TypedDocument | TypedResource | undefined =
    | TypedDocument
    | TypedResource
    | undefined,
> {
  readonly data: T
  readonly isOpen: boolean
  readonly viewerType: 'new_typed_note' | 'new_text' | 'instance' | 'closed'
}
