import { createSlice } from '@reduxjs/toolkit'
import { toggleItem } from '@/utils/bulkActions/bulkAction.util'
import { ITypedResource } from '@/models/resource/TypedResource'

export type BulkSelectedItem = ITypedResource & {
  folderId: string
  documentId: string
}

const initialState = { selectedItems: [] } as {
  selectedItems: BulkSelectedItem[]
}

export const bulkActionSlice = createSlice({
  name: 'bulkAction',
  initialState,
  reducers: {
    toggleSelectItem: (state, action) => {
      state.selectedItems = toggleItem(state.selectedItems, action.payload)
    },
    flushSelectedItems: (state) => {
      state.selectedItems = []
    },
  },
})

export const { toggleSelectItem, flushSelectedItems } = bulkActionSlice.actions

export default bulkActionSlice.reducer
