export default {
  gray0: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  gray7: (opacity = 1) => `rgba(18, 18, 17, ${opacity})`,
  gray11: (opacity = 1) => `rgba(29, 29, 27, ${opacity})`,
  gray16: (opacity = 1) => `rgba(41, 41, 41, ${opacity})`,
  gray20: (opacity = 1) => `rgba(51, 51, 51, ${opacity})`,
  gray27: (opacity = 1) => `rgba(68, 68, 68, ${opacity})`,
  gray33: (opacity = 1) => `rgba(85, 85, 85, ${opacity})`,
  gray40: (opacity = 1) => `rgba(102, 102, 102, ${opacity})`,
  gray50: (opacity = 1) => `rgba(128, 128, 128, ${opacity})`,
  gray60: (opacity = 1) => `rgba(153, 153, 153, ${opacity})`,
  gray68: (opacity = 1) => `rgba(173, 173, 173, ${opacity})`,
  gray77: (opacity = 1) => `rgba(196, 196, 196, ${opacity})`,
  gray85: (opacity = 1) => `rgba(217, 217, 217, ${opacity})`,
  gray90: (opacity = 1) => `rgba(229, 229, 229, ${opacity})`,
  gray94: (opacity = 1) => `rgba(240, 240, 240, ${opacity})`,
  gray97: (opacity = 1) => `rgba(247, 247, 247, ${opacity})`,
  gray100: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
  light25: function () {
    return this.gray0(0.05)
  },
  lighter4: function () {
    return this.gray100(0.04)
  },
  lighter8: function () {
    // hover dark2
    return this.gray100(0.08)
  },
  lighter12: function () {
    return this.gray100(0.12)
  },
  lighter16: function () {
    // hover dark
    return this.gray100(0.16)
  },
  darker4: function () {
    // hover light2
    return this.gray0(0.04)
  },
  darker6: function () {
    return this.gray0(0.06)
  },
  darker8: function () {
    return this.gray0(0.08)
  },
  darker12: function () {
    return this.gray0(0.12)
  },
  darker16: function () {
    return this.gray0(0.16)
  },

  blue50: (opacity = 1) => `rgba(56, 165, 225, ${opacity})`, // active dark
  blue60: (opacity = 1) => `rgba(9, 142, 208, ${opacity})`, // active light
  blue80: (opacity = 1) => `rgba(9, 108, 170, ${opacity})`,
  green50: (opacity = 1) => `rgba(71, 187, 117, ${opacity})`, //success dark
  green60: (opacity = 1) => `rgba(41, 157, 88, ${opacity})`, //success light
  green80: (opacity = 1) => `rgba(29, 118, 64, ${opacity})`,
  yellow50: (opacity = 1) => `rgba(243, 165, 8, ${opacity})`, //warning dark
  yellow60: (opacity = 1) => `rgba(231, 150, 0, ${opacity})`, //warning light
  yellow80: (opacity = 1) => `rgba(180, 114, 0, ${opacity})`,
  red50: (opacity = 1) => `rgba(249, 68, 68, ${opacity})`, //error dark
  red60: (opacity = 1) => `rgba(237, 42, 46, ${opacity})`, //error light
  red80: (opacity = 1) => `rgba(192, 39, 39, ${opacity})`,
  purple50: (opacity = 1) => `rgba(120, 129, 245, ${opacity})`, //etc dark
  purple60: (opacity = 1) => `rgba(101, 109, 231, ${opacity})`, //etc light
  purple80: (opacity = 1) => `rgba(75, 80, 161, ${opacity})`,
  highlight: (opacity = 1) => `rgba(255, 199, 0, ${opacity})`,
  backlink: (opacity = 1) => `rgba(98, 255, 189, ${opacity})`,
  formatDoc: (opacity = 1) => `rgba(38, 132, 252, ${opacity})`,
  formatSheets: (opacity = 1) => `rgba(65, 164, 81, ${opacity})`,
  formatSlides: (opacity = 1) => `rgba(244, 180, 0, ${opacity})`,
  formatWord: (opacity = 1) => `rgba(49, 116, 196, ${opacity})`,
  formatExcel: (opacity = 1) => `rgba(33, 163, 102, ${opacity})`,
  formatPpt: (opacity = 1) => `rgba(209, 87, 35, ${opacity})`,
  formatPdf: (opacity = 1) => `rgba(190, 55, 81, ${opacity})`,
  formatUrl: (opacity = 1) => `rgba(107, 136, 158, ${opacity})`,
  formatNote: (opacity = 1) => `rgba(90, 90, 90, ${opacity})`,
  formatImg: (opacity = 1) => `rgba(108, 122, 195, ${opacity})`,
  formatHwp: (opacity = 1) => `rgba(32, 157, 223, ${opacity})`,
}
