import { isNil } from 'lodash-es'
import { getSpecificDOMNode } from '../common/helper'
import { constants, HighlightElementManager } from '@/utils/highlights'
import errors from '@/errors'

const { HOVERED_CLASS, CLICKED_CLASS } = constants.CSS_SELECTORS

export default class HighlightInteractor {
  static scrollToURLHighlight(highlightId: string) {
    if (!highlightId) return

    const viewer = HighlightElementManager.getViewer()
    if (!viewer) return

    const highlightElement = HighlightElementManager.getOne(highlightId).shift()
    if (!highlightElement) {
      throw new errors.highlight.NotFoundError()
    }

    const offsetTop = highlightElement.offsetTop - viewer.clientHeight / 2
    viewer.scrollTo(0, offsetTop)
  }

  static scrollToPDFHighlight(highlightId: string) {
    if (!highlightId) return

    const scrollBox = HighlightElementManager.getViewer()?.parentElement
    if (!scrollBox) return

    const highlightElement = HighlightElementManager.getOne(highlightId).shift()
    const highlightOffsetTop = highlightElement?.parentElement?.offsetTop
    const PDFPageOffsetTop = getSpecificDOMNode(
      highlightElement,
      (DOMNode: HTMLElement) => DOMNode?.classList.contains('page'),
    )?.offsetTop

    if (
      !highlightElement ||
      isNil(highlightOffsetTop) ||
      isNil(PDFPageOffsetTop)
    ) {
      throw new errors.highlight.NotFoundError()
    }

    const offsetTop =
      highlightOffsetTop +
      PDFPageOffsetTop -
      scrollBox.clientHeight / 2 -
      highlightElement.clientHeight

    scrollBox.scrollTo(0, offsetTop)
  }

  static onClick(
    highlightId: string,
    callback: (top: number, right: number) => void,
  ) {
    return (e: MouseEvent) => {
      e.stopPropagation()
      const highlightedNodes = HighlightElementManager.getOne(highlightId)

      highlightedNodes.forEach((node) => {
        node.classList.remove(HOVERED_CLASS)
        node.classList.add(CLICKED_CLASS)
      })

      const lastNode = highlightedNodes[highlightedNodes.length - 1]
      const { top, right } = lastNode.getBoundingClientRect()

      callback(top, right)
    }
  }

  static onMouseEnter(highlightId: string) {
    return function (e: MouseEvent) {
      e.stopPropagation()

      const highlightedNodes = HighlightElementManager.getOne(highlightId)
      const isClicked = highlightedNodes.some((node) =>
        node.classList.contains(CLICKED_CLASS),
      )
      if (isClicked) return

      highlightedNodes.forEach((node) => {
        node.classList.add(HOVERED_CLASS)
      })
    }
  }

  static onMouseLeave(highlightId: string) {
    return (e: MouseEvent) => {
      e.stopPropagation()

      const highlightedNodes = HighlightElementManager.getOne(highlightId)
      const isClicked = highlightedNodes.some((node) =>
        node.classList.contains(CLICKED_CLASS),
      )
      if (isClicked) return

      highlightedNodes.forEach((node) => {
        node.classList.remove(HOVERED_CLASS)
      })
    }
  }
}
