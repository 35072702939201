import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type MyHomeDocumentsState = {
  cursor: number | undefined
  isFetching: boolean
}

interface IMyHomeState {
  [spaceId: string]: {
    documentsViewedByMe: MyHomeDocumentsState
    documentsCreatedByMe: MyHomeDocumentsState
  }
}

export const getEmptyMyHomeDocumentsState = () => ({
  cursor: new Date().getTime(),
  isFetching: false,
})

const initialState: IMyHomeState = {}

export const myHomeSlice = createSlice({
  name: 'myHome',
  initialState,
  reducers: {
    setDocumentsViewedByMeCursor: (
      state,
      action: PayloadAction<{ spaceId: string; cursor: number | undefined }>,
    ) => {
      const { spaceId, cursor } = action.payload
      state[spaceId] ??= {
        documentsCreatedByMe: getEmptyMyHomeDocumentsState(),
        documentsViewedByMe: getEmptyMyHomeDocumentsState(),
      }
      state[spaceId].documentsViewedByMe.cursor = cursor
    },
    setDocumentsViewedByMeIsFetching: (
      state,
      action: PayloadAction<{ spaceId: string; isFetching: boolean }>,
    ) => {
      const { spaceId, isFetching } = action.payload
      state[spaceId] ??= {
        documentsCreatedByMe: getEmptyMyHomeDocumentsState(),
        documentsViewedByMe: getEmptyMyHomeDocumentsState(),
      }
      state[spaceId].documentsViewedByMe.isFetching = isFetching
    },
    setDocumentsCreatedByMeCursor: (
      state,
      action: PayloadAction<{ spaceId: string; cursor: number | undefined }>,
    ) => {
      const { spaceId, cursor } = action.payload
      state[spaceId] ??= {
        documentsCreatedByMe: getEmptyMyHomeDocumentsState(),
        documentsViewedByMe: getEmptyMyHomeDocumentsState(),
      }
      state[spaceId].documentsCreatedByMe.cursor = cursor
    },
    setDocumentsCreatedByMeIsFetching: (
      state,
      action: PayloadAction<{ spaceId: string; isFetching: boolean }>,
    ) => {
      const { spaceId, isFetching } = action.payload
      state[spaceId] ??= {
        documentsCreatedByMe: getEmptyMyHomeDocumentsState(),
        documentsViewedByMe: getEmptyMyHomeDocumentsState(),
      }
      state[spaceId].documentsCreatedByMe.isFetching = isFetching
    },
  },
})

export const {
  setDocumentsViewedByMeCursor,
  setDocumentsViewedByMeIsFetching,
  setDocumentsCreatedByMeCursor,
  setDocumentsCreatedByMeIsFetching,
} = myHomeSlice.actions

export default myHomeSlice.reducer
