import errors from '@/errors'
import autoBind from '@/utils/autoBind'
import CitationRepository from '@/repositories/citationRepository'
import CitationAPI from '@/api/citationAPI'
import TypedCitation, {
  ITypedCitationCoordData,
  ITypedCitation,
} from '@/models/citation'
import { ITypedSelectionText } from '@/models/selectionText'
import { ITypedRange } from '@/models/range'

class CitationService {
  constructor(
    private citationRepo: InstanceType<typeof CitationRepository>,
    private citationAPI: InstanceType<typeof CitationAPI>,
  ) {}
  async addCoordinateCitation({
    citerResourceId,
    citedResourceId,
    type,
    citationPosition,
  }: {
    citerResourceId: string
    citedResourceId: string
    type: string
    citationPosition: ITypedCitationCoordData
  }): Promise<TypedCitation> {
    const newCitation = await this.citationAPI.addCoordinateCitation({
      citerResourceId,
      citedResourceId,
      type,
      citationPosition,
    })

    if (!newCitation) {
      throw new errors.api.RequestFailError()
    }
    this.citationRepo.add(newCitation)

    return newCitation
  }

  async addDragCitation({
    citerResourceId,
    citedResourceId,
    type,
    citationSelectionText,
    citationTextRange,
  }: {
    citerResourceId: string
    citedResourceId: string
    type: string
    citationSelectionText: ITypedSelectionText
    citationTextRange: ITypedRange
  }): Promise<TypedCitation> {
    const newCitation = await this.citationAPI.addDragCitation({
      citerResourceId,
      citedResourceId,
      type,
      citationSelectionText,
      citationTextRange,
    })

    if (!newCitation) {
      throw new errors.api.RequestFailError()
    }
    this.citationRepo.add(newCitation)

    return newCitation
  }

  async getCitations(citerResourceId: string): Promise<TypedCitation[]> {
    const citations = await this.citationAPI.getCitations(citerResourceId)

    if (!citations) {
      throw new errors.api.RequestFailError()
    }
    citations.forEach((citation) => {
      this.citationRepo.add(citation)
    })

    return citations
  }

  async delete(citation: ITypedCitation): Promise<void> {
    const isSuccess = await this.citationAPI.deleteCitation(citation.id)
    if (!isSuccess) {
      throw new errors.api.RequestFailError()
    }

    this.citationRepo.delete(citation.id, citation.citerResourceId)
  }
}

export default autoBind(CitationService)
