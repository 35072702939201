export interface ITypedGroup {
  id: string
  name: string
  spaceId: string
}

export default class TypedGroup implements ITypedGroup {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly spaceId: string,
  ) {}

  static fromJSON(json: ITypedGroup) {
    return new TypedGroup(json.id, json.name, json.spaceId)
  }

  copyWith(options?: Partial<TypedGroup>): TypedGroup {
    return new TypedGroup(
      options?.id ?? this.id,
      options?.name ?? this.name,
      options?.spaceId ?? this.spaceId,
    )
  }
}
