export interface IResourceTag {
  readonly id: string
  readonly name: string
  readonly createdAt: string // ISO string
  readonly updatedAt: string // ISO string
}

export class ResourceTag implements IResourceTag {
  constructor(
    readonly id: string,
    readonly name: string,
    readonly createdAt: string,
    readonly updatedAt: string,
  ) {}

  copyWith(parts: Partial<Omit<ResourceTag, 'id'>>) {
    return new ResourceTag(
      this.id,
      parts.name ?? this.name,
      parts.createdAt ?? this.createdAt,
      parts.updatedAt ?? this.updatedAt,
    )
  }

  toJSON(): IResourceTag {
    return {
      id: this.id,
      name: this.name,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    }
  }
  static fromJSON(json: IResourceTag): ResourceTag {
    return new ResourceTag(json.id, json.name, json.createdAt, json.updatedAt)
  }
}
