import { getLanguage } from '@/locale/locale.util'
import LocalStorageManagerFactory from '@/utils/common/localStorageManagerFactory'

export default abstract class DisplayError extends Error {
  constructor(
    name: string,
    readonly koMessage: string,
    readonly enMessage: string,
  ) {
    super(name)
    Object.setPrototypeOf(this, new.target.prototype)
  }

  get displayMessage() {
    const UserLanguageLSM = LocalStorageManagerFactory.userLanguage
    const language = UserLanguageLSM.getData() ?? getLanguage()
    return language === 'ko' ? this.koMessage : this.enMessage
  }
}
