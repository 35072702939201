interface CustomerHelpText {
  'Customer Help': string
  'Typed Academy': string
  typedAcademyUrl: string
  typedSupportAskUrl: string
  typedSalesAskUrl: string
  'Submit feedback': string
  'Contact support': string
  'Contact sales': string
}

const englishCustomerHelpText: CustomerHelpText = {
  'Customer Help': 'Customer Help',
  'Typed Academy': 'Typed Academy',
  typedAcademyUrl: 'https://company.typed.do/academy',
  typedSupportAskUrl: 'https://form.typeform.com/to/IcCt6wGA',
  typedSalesAskUrl:
    'https://calendly.com/business-canvas/letmeintroducetyped-en',
  'Submit feedback': 'Submit feedback',
  'Contact support': 'Contact support',
  'Contact sales': 'Contact sales',
}

const koreanCustomerHelpText: CustomerHelpText = {
  'Customer Help': '도움말',
  'Typed Academy': '사용자 가이드',
  typedAcademyUrl: 'https://company.typed.do/academy-kr',
  typedSupportAskUrl: 'https://form.typeform.com/to/n4JWyIbz',
  typedSalesAskUrl: 'https://calendly.com/business-canvas/letmeintroducetyped',
  'Submit feedback': '기능 제안하기',
  'Contact support': '문의하기',
  'Contact sales': '도입 문의하기',
}

export { englishCustomerHelpText, koreanCustomerHelpText }
