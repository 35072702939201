import { AxiosInstance } from 'axios'
import autoBind from '@/utils/autoBind'
import TypedCitation, { ITypedCitationCoordData } from '@/models/citation'
import { ITypedSelectionText } from '@/models/selectionText'
import { ITypedRange } from '@/models/range'
class citationAPI {
  constructor(readonly axios: AxiosInstance) {}

  async addCoordinateCitation({
    citerResourceId,
    citedResourceId,
    type,
    citationPosition,
  }: {
    citerResourceId: string
    citedResourceId: string
    type: string
    citationPosition: ITypedCitationCoordData
  }): Promise<TypedCitation> {
    const data = citationPosition
    const response = await this.axios({
      method: 'post',
      url: '/api/citation',
      data: {
        citerResourceId: citerResourceId,
        citedResourceId: citedResourceId,
        type,
        data,
      },
    })

    return TypedCitation.fromJSON(response.data)
  }

  async addDragCitation({
    citerResourceId,
    citedResourceId,
    type,
    citationSelectionText,
    citationTextRange,
  }: {
    citerResourceId: string
    citedResourceId: string
    type: string
    citationSelectionText: ITypedSelectionText
    citationTextRange: ITypedRange
  }): Promise<TypedCitation> {
    const data = {
      text: citationSelectionText,
      range: citationTextRange,
    }
    const response = await this.axios({
      method: 'post',
      url: '/api/citation',
      data: {
        citerResourceId,
        citedResourceId,
        type,
        data,
      },
    })

    return TypedCitation.fromJSON(response.data)
  }

  async getCitations(citerResourceId: string): Promise<TypedCitation[]> {
    const response = await this.axios({
      method: 'get',
      url: '/api/citation',
      params: {
        citerResourceId,
      },
    })
    return response.data.map(TypedCitation.fromJSON)
  }

  async deleteCitation(citationId: string): Promise<boolean> {
    const pathToRequest = `/api/citation/${citationId}`
    const result = await this.axios.delete(pathToRequest)
    return result.status === 200
  }
}

export default autoBind(citationAPI)
