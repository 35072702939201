import { QueryClient } from 'react-query'
import TypedResource from '@/models/resource/TypedResource'
import { queryKeys } from '@/providers/react-query'

export default class RecommendRepository {
  constructor(readonly store: QueryClient) {}

  add(item: TypedResource, documentId: string) {
    this.store.setQueryData<TypedResource[]>(
      queryKeys.recommendResourcesByDocumentId(documentId),
      (prevData) => [...(prevData ?? []), item],
    )
  }

  addAll(items: TypedResource[], documentId: string) {
    this.store.setQueryData<TypedResource[]>(
      queryKeys.recommendResourcesByDocumentId(documentId),
      (prevData) => [...(prevData ?? []), ...items],
    )
  }

  find(id: string): TypedResource | undefined {
    const allRecommendResources = this.store
      .getQueriesData<TypedResource[]>(queryKeys.allRecommendResources())
      .reduce<TypedResource[]>((acc, [, resources]) => {
        return [...acc, ...resources]
      }, [])

    return allRecommendResources.find((resource) => resource.resourceId === id)
  }

  exist(id: string): boolean {
    return Boolean(this.find(id))
  }

  delete(id: string, documentId: string): boolean {
    const resourceToBeDeleted = this.find(id)

    if (!resourceToBeDeleted) {
      return false
    }

    this.store.setQueryData<TypedResource[]>(
      queryKeys.recommendResourcesByDocumentId(documentId),
      (oldData) => {
        if (!oldData) return []
        return oldData.filter(
          ({ resourceId }) => resourceId !== resourceToBeDeleted.resourceId,
        )
      },
    )

    return true
  }

  clear(): void {
    this.store.removeQueries(queryKeys.allRecommendResources())
  }
}
