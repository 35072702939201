interface InboxText {
  toolbarButton: {
    prev: string
    next: string
    'mark as read': string
    'mark as unread': string
    delete: string
    move: string
  }
  emptyInboxMainMessage: string
  emptyInboxSubMessage: string
}

const englishInboxText: InboxText = {
  toolbarButton: {
    prev: 'Prev',
    next: 'Next',
    'mark as read': 'Mark as Read',
    'mark as unread': 'Mark as Unread',
    delete: 'Delete',
    move: 'Move',
  },
  emptyInboxMainMessage: 'Your Inbox is empty!',
  emptyInboxSubMessage: 'Add Your Resources Here.',
}

const koreanInboxText: InboxText = {
  toolbarButton: {
    prev: '이전',
    next: '다음',
    'mark as read': '읽음',
    'mark as unread': '읽지 않음',
    delete: '삭제',
    move: '이동',
  },
  emptyInboxMainMessage: '인박스가 비어있습니다!',
  emptyInboxSubMessage: '관심있는 자료를 추가해보세요.',
}

export { englishInboxText, koreanInboxText }
