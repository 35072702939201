export interface ITypedUser {
  readonly userId: string
  readonly displayName: string
  readonly photo: string
}

export class TypedUser implements ITypedUser {
  constructor(
    readonly userId: string,
    readonly displayName: string,
    readonly photo: string,
  ) {}

  static fromJSON(json: ITypedUser): TypedUser {
    return new TypedUser(json.userId, json.displayName, json.photo)
  }
}
