import { PROJECT_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class ProjectEvent extends TypedEvent {
  createdEvent(data: { projectId: string; spaceId: string }) {
    this.sendEvent(PROJECT_EVENT.CREATED, data)
  }

  projectViewed(spaceId: string, projectId: string) {
    this.sendEvent(PROJECT_EVENT.PROJECT_VIEWED, {
      spaceId,
      projectId,
    })
  }

  projectFavoriteClicked(
    spaceId: string,
    projectId: string,
    clickLocation: 'sidebar' | 'myHome',
  ) {
    this.sendEvent(PROJECT_EVENT.PROJECT_FAVORITE_CLICKED, {
      spaceId,
      projectId,
      clickLocation,
    })
  }

  projectFavoriteAdded(spaceId: string, projectId: string) {
    this.sendEvent(PROJECT_EVENT.PROJECT_FAVORITE_ADDED, {
      spaceId,
      projectId,
    })
  }
}
