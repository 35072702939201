interface DuplicateText {
  'This resource already exists': string
  'resources already exist': string
  'Move here': string
  Cancel: string
  Move: string
  'Duplicate document': string
}

const englishDuplicateText: DuplicateText = {
  'This resource already exists': 'This resource already exists',
  'resources already exist': 'resources already exist',
  'Move here': 'Move here?',
  Cancel: 'Cancel',
  Move: 'Move',
  'Duplicate document': 'Duplicate',
}

const koreanDuplicateText: DuplicateText = {
  'This resource already exists': '이미 추가된 참고자료예요!',
  'resources already exist': '개의 참고 자료가 이미 존재해요',
  'Move here': '여기로 이동할까요?',
  Cancel: '취소',
  Move: '이동',
  'Duplicate document': '문서 복제',
}

export { englishDuplicateText, koreanDuplicateText }
