import { EVENT_KEY, isMacOS } from '@/util'

export const findCallBack = (selectedItem) => (itemToCompare) =>
  selectedItem.resourceId === itemToCompare.resourceId &&
  selectedItem.folderId === itemToCompare.folderId

export const removeAtIndex = (arr, idx) => {
  const copy = [...arr]
  copy.splice(idx, 1)
  return copy
}

export const toggleItem = (arr, selectedItem) => {
  const idx = arr.findIndex(findCallBack(selectedItem))
  if (idx === -1) return [...arr, selectedItem]
  return removeAtIndex(arr, idx)
}

export const isMultiSelecting = (event) =>
  (isMacOS && event.metaKey) || (!isMacOS && event.ctrlKey)

const keyMatches = (e, key) => e.key === key
export const isControlOrCmdKey = (e) =>
  (isMacOS && keyMatches(e, EVENT_KEY.META)) ||
  (!isMacOS && keyMatches(e, EVENT_KEY.CONTROL))

export const removeDuplicates = (selectedItems) => {
  const visited = {}
  return selectedItems.reduce((acc, cur) => {
    if (cur.resourceId in visited) return acc
    visited[cur.resourceId] = true
    return [...acc, cur]
  }, [])
}

export const maintainRelativeOrder = (targetFolder, selectedItems) => {
  const orderedSelectedItems = []
  targetFolder.resourceList.forEach((resourceId) => {
    const itemFound = selectedItems.find(
      (item) => item.resourceId === resourceId,
    )
    if (itemFound) orderedSelectedItems.push(itemFound)
  })

  const selectedItemsFromExternalFolders = selectedItems.filter(
    (item) =>
      !orderedSelectedItems.find(
        (itemWithinTargetFolder) =>
          itemWithinTargetFolder.resourceId === item.resourceId,
      ),
  )

  return removeDuplicates(selectedItemsFromExternalFolders).concat(
    orderedSelectedItems,
  )
}

export const insertResourcesInRange = ({
  pos,
  targetFolder,
  selectedItems,
}) => {
  let left = pos - 1
  let right = pos

  let resourceList = [...targetFolder.resourceList]
  const len = resourceList.length
  const selectedItemIdList = selectedItems.map((item) => item.resourceId)

  while (left >= 0) {
    const resourceSelected = selectedItems.find(
      (item) => item.resourceId === resourceList[left],
    )
    if (!resourceSelected) {
      break
    }
    left--
  }

  while (right < len) {
    const resourceSelected = selectedItems.find(
      (item) => item.resourceId === resourceList[right],
    )
    if (!resourceSelected) {
      break
    }
    right++
  }

  const resourceListWithoutSelectedItems = resourceList.filter(
    (rsc) => !selectedItems.find((item) => item.resourceId === rsc),
  )

  if (left === -1 && right === len) {
    resourceList = selectedItemIdList
  } else if (left === -1) {
    resourceList = selectedItemIdList.concat(resourceListWithoutSelectedItems)
  } else if (right === len) {
    resourceList = resourceListWithoutSelectedItems.concat(selectedItemIdList)
  } else {
    const leftBoundary = resourceListWithoutSelectedItems.indexOf(
      resourceList[left],
    )
    const rightBoundary = resourceListWithoutSelectedItems.indexOf(
      resourceList[right],
    )

    resourceList = resourceListWithoutSelectedItems
      .slice(0, leftBoundary + 1)
      .concat(selectedItemIdList)
      .concat(resourceListWithoutSelectedItems.slice(rightBoundary))
  }
  return resourceList
}
