import { isNil } from 'lodash-es'

export const CONVERSION_ERROR = 'Invalid selection text data'

export interface ITypedSelectionText {
  readonly core: string
  readonly pageIndex?: number
}

export interface ITypedURLSelectionText extends ITypedSelectionText {
  readonly core: string
}

export abstract class TypedSelectionText implements ITypedSelectionText {
  constructor(readonly core: string, readonly pageIndex?: number) {}

  toJSON(): ITypedSelectionText {
    return { core: this.core, pageIndex: this.pageIndex }
  }
}

export class TypedURLSelectionText
  extends TypedSelectionText
  implements ITypedURLSelectionText
{
  constructor(readonly core: string) {
    super(core)
  }

  static fromJSON(json: ITypedURLSelectionText) {
    if (!this.validateJSON(json)) {
      throw new Error(CONVERSION_ERROR)
    }
    return new TypedURLSelectionText(json.core)
  }

  static validateJSON(
    json: ITypedURLSelectionText,
  ): json is ITypedURLSelectionText {
    return Boolean(!isNil(json?.core) && typeof json?.pageIndex === 'undefined')
  }

  toJSON(): ITypedURLSelectionText {
    return { core: this.core }
  }
}

export interface ITypedPDFSelectionText extends ITypedSelectionText {
  readonly core: string
  readonly pageIndex: number
}

export class TypedPDFSelectionText
  extends TypedSelectionText
  implements ITypedPDFSelectionText
{
  constructor(readonly core: string, readonly pageIndex: number) {
    super(core, pageIndex)
  }

  static fromJSON(json: ITypedPDFSelectionText) {
    if (!this.validateJSON(json)) {
      throw new Error(CONVERSION_ERROR)
    }

    return new TypedPDFSelectionText(json.core, json.pageIndex)
  }

  static validateJSON(json: ITypedPDFSelectionText): boolean {
    return Boolean(!isNil(json?.core) && typeof json?.pageIndex === 'number')
  }

  toJSON(): ITypedPDFSelectionText {
    return { core: this.core, pageIndex: this.pageIndex }
  }
}
