import { USER_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class UserEvent extends TypedEvent {
  signupEvent(createdAt: Date) {
    this.sendEvent(USER_EVENT.SIGNUP_COMPLETED, { createdAt })
  }
  signinEvent() {
    this.sendEvent(USER_EVENT.SIGNIN_COMPLETED)
  }
  userProfileClickedEvent() {
    this.sendEvent(USER_EVENT.USER_PROFILE_CLICKED)
  }

  upgradeToStandardButtonClickedEvent(clickLocation: string) {
    this.sendEvent(USER_EVENT.UPGRADE_TO_STANDARD_BUTTON_CLICKED, {
      clickLocation,
    })
  }
}
