import { createSlice } from '@reduxjs/toolkit'
import { TypedFolder } from '@/models/folder'

interface FocusActionSlice {
  focusedItem: TypedFolder | null
  mouseClickCoords: { x: number; y: number } | null
}

const initialState: FocusActionSlice = {
  focusedItem: null,
  mouseClickCoords: null,
}

export const focusActionSlice = createSlice({
  name: 'focusAction',
  initialState,
  reducers: {
    toggleFocusedItem: (state, action) => {
      const { item, clientX, clientY } = action.payload

      const shouldFocusOut =
        state.focusedItem && state.focusedItem.folderId === item.folderId

      if (shouldFocusOut) {
        state.focusedItem = null
        state.mouseClickCoords = null
        return
      }

      state.focusedItem = item
      state.mouseClickCoords = { x: clientX, y: clientY }
    },
    removeFocusedItem: (state) => {
      state.focusedItem = null
      state.mouseClickCoords = null
    },
    setMouseClickCoords: (state, action) => {
      state.mouseClickCoords = action.payload
    },
  },
})

export const { toggleFocusedItem, removeFocusedItem, setMouseClickCoords } =
  focusActionSlice.actions

export default focusActionSlice.reducer
