import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  closeModal as _closeModal,
  openModal as _openModal,
  setModalData as _setModalData,
} from '@/utils/feedback/feedbackSlice'
import { RootState } from '@/store'
import TypedSpace, { TypedSpaceMember } from '@/models/space'
import { QuotaExcessType } from '@/models/subscription'
import { TypedDocument } from '@/models/document/TypedDocument'
import { ITypedTemplate } from '@/models/template'
import { TypedUser } from '@/models/user'
import TypedProject from '@/models/project'
import TypedGroup from '@/models/group'

export enum ModalType {
  DOCUMENT_CREATION = 'documentCreation',
  DOCUMENT_DUPLICATE = 'documentDuplicate',
  PROJECT_CREATION = 'projectCreation',
  PROJECT_INVITE_MEMBERS = 'projectInviteMembers',
  PROJECT_SELECTION_OF_LOCAL_DOC = 'projectSelectionOfLocalDoc',
  PROJECT_SELECTION_OF_GDRIVE_DOC = 'projectSelectionOfGdriveDoc',
  PROJECT_SETTINGS = 'projectSettings',
  PROJECT_DELETE = 'projectDelete',
  PROJECT_REMOVE_MEMBER = 'projectRemoveMember',
  PROJECT_LEAVE = 'projectLeave',
  SPACE_CREATION = 'spaceCreation',
  SPACE_SETTINGS = 'spaceSettings',
  SPACE_DELETION = 'spaceDeletion',
  SPACE_INVITE_MEMBERS = 'spaceInviteMembers',
  SPACE_REMOVE_MEMBER = 'spaceRemoveMember',
  SPACE_LEAVE = 'spaceLeave',
  DELETE_DIALOG = 'deleteDialog',
  PERSONAL_SPACE_MIGRATION_COMPLETE = 'personalSpaceMigrationComplete',
  GROUP_CREATION = 'groupCreation',
  GROUP_DELETION = 'groupDeletion',
  GROUP_RENAME = 'groupRename',
  QUOTA_EXCESS = 'quotaExcess',
  PUBLIC_TEMPLATE_AD = 'publicTemplateAd',
  TEMPLATE_PREVIEW = 'templatePreview',
  USER_DELETION = 'userDeletion',
  USER_PROFILE = 'userProfile',
  REVIEW_REQUEST = 'reviewRequest',
}

export enum ToastType {
  APPHOME_SPLIT_VIEWER_GET_TO_WORK = 'appHomeSplitViewerGetToWork',
  DOCUMENT_UPLOAD_WARNING = 'documentUploadWarning',
  SUCCESSFULLY_INVITED = 'spaceMemberSuccessfullyInvited',
}

export enum ProjectSettingTabView {
  PROJECT_SETTING_TAB = 'projectSettingTab',
  PROJECT_MEMBERS_TAB = 'projectMembersTab',
}

const useModal = <D>(name: ModalType | ToastType) => {
  const dispatch = useDispatch()
  const modalStatus = useSelector((state: RootState) => state.feedback)

  const data = modalStatus[name]?.data as D | undefined
  const isOpen = modalStatus[name]?.isOpen ?? false

  const openModal = useCallback(
    (data?: D) => {
      dispatch(_openModal({ name, data }))
    },
    [dispatch, name],
  )

  const closeModal = useCallback(() => {
    dispatch(_closeModal({ name }))
  }, [dispatch, name])
  const setData = useCallback(
    (data?: Partial<D>) => dispatch(_setModalData({ name, data })),
    [dispatch, name],
  )

  return {
    data,
    isOpen,
    openModal,
    closeModal,
    setData,
  }
}

export default {
  // Modal
  useProjectCreateModal: () => useModal<TypedGroup>(ModalType.PROJECT_CREATION),
  useProjectInviteMembersModal: () =>
    useModal<TypedProject>(ModalType.PROJECT_INVITE_MEMBERS),
  useDocumentCreationModal: () => useModal(ModalType.DOCUMENT_CREATION),
  useDocumentDuplicateModal: () =>
    useModal<TypedDocument>(ModalType.DOCUMENT_DUPLICATE),
  useProjectSelectionOfLocalDoc: () =>
    useModal(ModalType.PROJECT_SELECTION_OF_LOCAL_DOC),
  useProjectSelectionOfGdrivDoc: () =>
    useModal(ModalType.PROJECT_SELECTION_OF_GDRIVE_DOC),
  useProjectDeleteModal: () => useModal(ModalType.PROJECT_DELETE),
  useProjectRemoveMemberModal: () =>
    useModal<TypedSpaceMember>(ModalType.PROJECT_REMOVE_MEMBER),
  useProjectLeaveModal: () => useModal(ModalType.PROJECT_LEAVE),
  useSpaceCreationModal: () => useModal(ModalType.SPACE_CREATION),
  useSpaceSettingsModal: () =>
    useModal<{ space: TypedSpace; tabIndex: number }>(ModalType.SPACE_SETTINGS),
  useSpaceDeletionModal: () => useModal<TypedSpace>(ModalType.SPACE_DELETION),
  useSpaceInviteMembersModal: () => useModal(ModalType.SPACE_INVITE_MEMBERS),
  useSpaceRemoveMemberModal: () =>
    useModal<TypedSpaceMember>(ModalType.SPACE_REMOVE_MEMBER),
  useSpaceLeaveModal: () => useModal(ModalType.SPACE_LEAVE),
  useProjectSettingsModal: () =>
    useModal<{
      project: TypedProject
      tabView: ProjectSettingTabView
      isOpenedByInvite: boolean
    }>(ModalType.PROJECT_SETTINGS),
  useDeleteDialog: () => useModal(ModalType.DELETE_DIALOG),
  usePersonalSpaceMigrationCompleteModal: () =>
    useModal(ModalType.PERSONAL_SPACE_MIGRATION_COMPLETE),
  useGroupCreationModal: () => useModal(ModalType.GROUP_CREATION),
  useGroupDeletionModal: () => useModal(ModalType.GROUP_DELETION),
  useGroupRenameModal: () => useModal(ModalType.GROUP_RENAME),
  useQuotaExcessModal: () => useModal<QuotaExcessType>(ModalType.QUOTA_EXCESS),
  usePublicTemplateAdModal: () => useModal(ModalType.PUBLIC_TEMPLATE_AD),
  useTemplatePreviewModal: () =>
    useModal<
      Pick<ITypedTemplate, 'id' | 'name' | 'preview' | 'templateMimeType'> & {
        isLocked: boolean
      }
    >(ModalType.TEMPLATE_PREVIEW),
  useUserDeletionModal: () => useModal<TypedUser>(ModalType.USER_DELETION),
  useUserProfileModal: () => useModal(ModalType.USER_PROFILE),
  useReviewRequestModal: () => useModal(ModalType.REVIEW_REQUEST),

  // Toast
  useAppHomeGetToWorkToast: () =>
    useModal(ToastType.APPHOME_SPLIT_VIEWER_GET_TO_WORK),
  useDocumentUploadWarningToast: () =>
    useModal(ToastType.DOCUMENT_UPLOAD_WARNING),
}
