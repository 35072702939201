import { Stripe } from '@stripe/stripe-js'
import autoBind from '@/utils/autoBind'
import SubscriptionAPI from '@/api/subscriptionAPI'

class SubscriptionService {
  constructor(
    private readonly stripePromise: Promise<Stripe | null>,
    private readonly subscriptionAPI: InstanceType<typeof SubscriptionAPI>,
  ) {}

  async openStripeCustomerPortal() {
    const customerPortalUrl =
      await this.subscriptionAPI.createCustomerPortalSession(
        window.location.href,
      )
    window.location.href = customerPortalUrl
  }

  async getPaymentMethod(spaceId: string) {
    return this.subscriptionAPI.getPaymentMethod(spaceId)
  }
}

export default autoBind(SubscriptionService)
