export const CONVERSION_ERROR = 'Invalid typed range data'

export interface ITypedRange {
  anchor: { content: { start: number; end: number } }
}

export class TypedRange implements ITypedRange {
  constructor(readonly anchor: ITypedRange['anchor']) {}

  static fromJSON(json: ITypedRange): TypedRange {
    if (!this.validateJSON(json)) {
      throw new Error(CONVERSION_ERROR)
    }
    return new TypedRange(json.anchor)
  }

  static validateJSON(json: ITypedRange): json is ITypedRange {
    return Boolean(
      Number.isInteger(json?.anchor?.content?.start) &&
        Number.isInteger(json?.anchor?.content?.end),
    )
  }

  toJSON(): ITypedRange {
    return {
      anchor: this.anchor,
    }
  }
}
