class HighlightError extends Error {
  constructor(readonly name = 'HighlightError', message: string) {
    super(message)
  }
}

class InvalidCountError extends HighlightError {
  constructor() {
    super('InvalidCountError', 'Resource has invalid highlight count!')
  }
}

class NotExistError extends HighlightError {
  constructor() {
    super('NotExistError', 'Highlight data does not exist!')
  }
}

class NotFoundError extends HighlightError {
  constructor() {
    super('NotFoundError', 'Cannot find highlight node!')
  }
}

export default {
  HighlightError,
  InvalidCountError,
  NotExistError,
  NotFoundError,
}
