import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { personalSpaceId?: string; lastVisitedSpaceId?: string } =
  {
    personalSpaceId: undefined,
    lastVisitedSpaceId: undefined,
  }

export const spaceSlice = createSlice({
  name: 'space',
  initialState,
  reducers: {
    setPersonalSpaceId: (state, action: PayloadAction<string>) => {
      state.personalSpaceId = action.payload
    },
    setLastVisitedSpaceId: (state, action: PayloadAction<string>) => {
      state.lastVisitedSpaceId = action.payload
    },
  },
})

export const { setPersonalSpaceId, setLastVisitedSpaceId } = spaceSlice.actions

export default spaceSlice.reducer
