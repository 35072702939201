import { AxiosInstance } from 'axios'
import autoBind from '@/utils/autoBind'
import { AuthData } from '@/models/auth'

export const googleDriveScope = 'https://www.googleapis.com/auth/drive'
export const googleTaskScope = 'https://www.googleapis.com/auth/tasks'

class AuthAPI {
  constructor(readonly axios: AxiosInstance) {}

  async signInWithGoogleToken(typedToken: string): Promise<AuthData> {
    const result = await this.axios.post<AuthData>('/api/auth/signin', {
      token: typedToken,
    })

    return result.data
  }

  async refreshToken(refreshToken: string) {
    const { data } = await this.axios.post<{
      idToken: string
      refreshToken: string
      expiresAt: number
    }>('/api/auth/token', {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    })

    return data
  }

  async checkUserExists(email: string): Promise<boolean> {
    const result = await this.axios({
      method: 'get',
      url: `/api/auth/exists/?email=${email}`,
    })

    return result.data.exists
  }

  async deleteUser() {
    await this.axios({
      method: 'delete',
      url: '/api/auth/account',
    })
  }

  async getGoogleAccessToken(scopes: string[]) {
    const { data } = await this.axios.get<{ access_token: string }>(
      '/api/authorize/access-token',
      { params: { scope: scopes } },
    )
    return data.access_token
  }
}

export default autoBind(AuthAPI)
