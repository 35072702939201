import { Store } from 'redux'
import errors from '@/errors'
import { TypedHighlight } from '@/models/highlight'
import autoBind from '@/utils/autoBind'
import {
  anchorHandler,
  constants,
  HighlightElementManager,
} from '@/utils/highlights'
import NodeParser from '@/utils/highlights/nodeParser'

class HighlightDOMService {
  constructor(private readonly store: Store) {}

  renderHighlights(highlights: TypedHighlight[]): void {
    try {
      const renderedHighlights = HighlightElementManager.getAll().reduce<{
        [key: string]: boolean
      }>((acc, highlight) => {
        const highlightClassSelector =
          Array.from(highlight.classList).find((selector) =>
            new RegExp(/^highlight-/).test(selector),
          ) ?? ''
        const highlightId = highlightClassSelector?.replace(
          constants.CSS_SELECTORS.CLASS_PREFIX,
          '',
        )
        acc[highlightId] = true
        return acc
      }, {})

      highlights.forEach((highlight) => {
        if (renderedHighlights[highlight.id]) return
        this.renderHighlight(highlight)
      })
    } catch (error: any) {
      if (error.name !== new errors.textRange.OffsetExceedError().name) {
        console.error(error)
      }
      throw error
    }
  }

  removeHighlight(id: string): void {
    const viewer = HighlightElementManager.getViewer()
    if (!viewer) return

    HighlightElementManager.getOne(id).forEach((node) => {
      while (node.firstChild) {
        node.parentNode?.insertBefore(node.firstChild, node)
      }
      node.remove()
    })
  }

  private renderHighlight(highlightData: TypedHighlight): void {
    if (!highlightData) {
      throw new Error('Invalid Selection')
    }

    const { anchor } = highlightData.range
    if (!anchor) {
      throw new Error('Invalid Anchor')
    }

    const range = anchorHandler.anchorToRange({
      anchor,
      rootElement: HighlightElementManager.getRenderBoundary(
        highlightData.text.pageIndex,
      ),
    })

    NodeParser.rangeToTextNodes(range).map<HTMLElement>((nodes) => {
      const highlightEl = HighlightElementManager.create(
        document.body,
        highlightData.id,
      )
      HighlightElementManager.attachEventListener(
        this.store,
        highlightEl,
        highlightData.id,
      )

      nodes?.[0].parentNode?.replaceChild(highlightEl, nodes[0])
      nodes?.forEach((node) => highlightEl.appendChild(node))

      return highlightEl
    })
  }
}

export default autoBind(HighlightDOMService)
