import { AxiosInstance } from 'axios'
import TypedSpaceMigrationResult, {
  ITypedSpaceMigrationResult,
} from '@/models/spaceMigrationResult'
import autoBind from '@/utils/autoBind'

class MigrationAPI {
  constructor(readonly axios: AxiosInstance) {}

  async migratePersonalSpace(
    googleAccessToken: string,
  ): Promise<ITypedSpaceMigrationResult> {
    const migrationResult = await this.axios({
      method: 'post',
      url: '/api/migration/space',
      data: { token: googleAccessToken },
    })

    return TypedSpaceMigrationResult.fromJSON(migrationResult.data)
  }

  async checkMigrationFailedDocs(): Promise<void> {
    await this.axios({
      method: 'post',
      url: '/api/migration/space/checked',
    })
  }
}

export default autoBind(MigrationAPI)
