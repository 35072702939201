interface AppHomeText {
  header: {
    Upgrade: string
    'You’re on the free plan': string
  }
  sideBarView: {
    MyHome: string
    Inbox: string
    'Settings & Members': string
    Favorites: string
    CreateGroup: string
    ReviewList: string
    Projects: string
    Group: string
    Notification: string
  }
  projectList: {
    'My Documents': string
    Name: string
    'Created by': string
    References: string
    Updated: string
    Created: string
    'Add Project': string
    'Add Document': string
    'Personal Space': string
    'All Projects': string
    'You cannot invite members': string
    Progress: string
    Done: string
  }
  search: {
    Document: string
    Project: string
    Reference: string
    NoDocumentTitle: string
    NoProjectTitle: string
    NoReferenceTitle: string
    NoDocumentDescriptionFirstLine: string
    NoProjectDescriptionFirstLine: string
    NoReferenceDescriptionFirstLine: string
    NoDocumentDescriptionSecondLine: string
    NoProjectDescriptionSecondLine: string
    NoReferenceDescriptionSecondLine: string
  }
  documentList: {
    Done: string
  }
}

const englishAppHomeText: AppHomeText = {
  header: {
    Upgrade: 'Upgrade',
    'You’re on the free plan':
      'You’re on the free plan with a 25 document limit',
  },
  sideBarView: {
    Inbox: 'Inbox',
    MyHome: 'My Home',
    'Settings & Members': 'Settings & Members',
    Favorites: 'Favorites',
    CreateGroup: 'Create New Group',
    Projects: 'Projects',
    ReviewList: 'Review Requests',
    Group: 'Group',
    Notification: 'Notification',
  },
  projectList: {
    'My Documents': 'My Documents',
    Name: 'Name',
    'Created by': 'Created by',
    References: 'References',
    Updated: 'Updated',
    Created: 'Created',
    'Add Project': 'Add Project',
    'Add Document': 'Add Document',
    'Personal Space': 'Personal Space',
    'All Projects': 'All Projects',
    'You cannot invite members':
      'You cannot invite members to the personal space.',
    Progress: 'In progress',
    Done: 'Done',
  },
  search: {
    Document: 'Documents',
    Project: 'Projects',
    Reference: 'References',
    NoDocumentTitle: 'No Document Found',
    NoProjectTitle: 'No Project Found',
    NoReferenceTitle: 'No Reference Found',
    NoDocumentDescriptionFirstLine: "We couldn't find any document",
    NoDocumentDescriptionSecondLine: 'matching your search',
    NoProjectDescriptionFirstLine: "We couldn't find any project",
    NoProjectDescriptionSecondLine: 'matching your search',
    NoReferenceDescriptionFirstLine: "We couldn't find any reference",
    NoReferenceDescriptionSecondLine: 'matching your search',
  },
  documentList: {
    Done: 'Done',
  },
}

const koreanAppHomeText: AppHomeText = {
  header: {
    Upgrade: '업그레이드',
    'You’re on the free plan':
      '문서를 25개까지 생성할 수 있는 무료 플랜을 사용중입니다.',
  },
  sideBarView: {
    Inbox: '인박스',
    MyHome: '마이홈',
    'Settings & Members': '설정 & 멤버',
    Favorites: '즐겨찾기',
    CreateGroup: '새 그룹 만들기',
    ReviewList: '검토 알림함',
    Projects: '프로젝트',
    Group: '그룹',
    Notification: '알림',
  },
  projectList: {
    'My Documents': '내 문서',
    Name: '이름',
    'Created by': '만든 사람',
    References: '참고자료',
    Created: '생성일',
    Updated: '수정일',
    'Add Project': '프로젝트 추가',
    'Add Document': '문서 추가',
    'Personal Space': '개인 스페이스',
    'All Projects': '모든 프로젝트',
    'You cannot invite members': '개인 스페이스에는 멤버를 초대할 수 없습니다.',
    Progress: '작업중',
    Done: '완료',
  },
  search: {
    Document: '문서',
    Project: '프로젝트',
    Reference: '참고자료',
    NoDocumentTitle: '일치하는 도큐먼트가 없습니다.',
    NoProjectTitle: '일치하는 프로젝트가 없습니다.',

    NoReferenceTitle: '일치하는 참고자료가 없습니다.',
    NoDocumentDescriptionFirstLine: '입력하신 검색어와 일치하는',
    NoDocumentDescriptionSecondLine: '도큐먼트가 없습니다.',
    NoProjectDescriptionFirstLine: '입력하신 검색어와 일치하는',
    NoProjectDescriptionSecondLine: '프로젝트가 없습니다.',

    NoReferenceDescriptionFirstLine: '입력하신 검색어와 일치하는',
    NoReferenceDescriptionSecondLine: '참고자료가 없습니다.',
  },
  documentList: {
    Done: '완료',
  },
}

export { englishAppHomeText, koreanAppHomeText }
