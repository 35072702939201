import { ITypedAxiosGroup } from '@/axios'
import { Amplitude } from '@/integrations/logging/client/amplitudeClient'
import { TypedLoggingClient } from '@/integrations/logging/client/typedLoggingClient'
import { DocumentEvent } from '@/integrations/logging/event/document'
import { FolderEvent } from '@/integrations/logging/event/folder'
import { HighlightEvent } from '@/integrations/logging/event/highlight'
import { OtherEvent } from '@/integrations/logging/event/other'
import { ProjectEvent } from '@/integrations/logging/event/project'
import { ResourceEvent } from '@/integrations/logging/event/resource'
import { TaskEvent } from '@/integrations/logging/event/task'
import { ITypedEventLoggers } from '@/integrations/logging/event/typedEventLoggers'
import { UserEvent } from '@/integrations/logging/event/user'
import { ITypedRepositories } from '@/repositories'
import { IScriptsLoadingState } from '@/setup'
import PageEvent, { IPageEvent } from '@/integrations/logging/event/page'
import { InboxEvent } from '@/integrations/logging/event/inbox'
import { SpaceEvent } from '@/integrations/logging/event/space'
import { SearchEvent } from '@/integrations/logging/event/search'
import { NetworkEvent } from '@/integrations/logging/event/network'
import { GroupEvent } from '@/integrations/logging/event/group'
import { QuantcastClient } from '@/integrations/QuantcastClient'
import { PublicDocumentEvent } from '@/integrations/logging/event/publicDocument'
import { HomepageEvent } from '@/integrations/logging/event/homepage'
import { TemplateEvent } from '@/integrations/logging/event/template'
import { InTextCitationEvent } from '@/integrations/logging/event/inTextCitation'
import { TypedNoteEvent } from '@/integrations/logging/event/typedNote'
import { NotificationEvent } from '@/integrations/logging/event/notification'

export default class TypedEventLoggers implements ITypedEventLoggers {
  ampClient: Amplitude
  typedLoggingClient: TypedLoggingClient
  userEvent: UserEvent
  resourceEvent: ResourceEvent
  folderEvent: FolderEvent
  projectEvent: ProjectEvent
  documentEvent: DocumentEvent
  highlightEvent: HighlightEvent
  taskEvent: TaskEvent
  otherEvent: OtherEvent
  pageEvent: IPageEvent
  inboxEvent: InboxEvent
  spaceEvent: SpaceEvent
  searchEvent: SearchEvent
  networkEvent: NetworkEvent
  groupEvent: GroupEvent
  quantcast: QuantcastClient
  publicDocumentEvent: PublicDocumentEvent
  homepageEvent: HomepageEvent
  templateEvent: TemplateEvent
  inTextCitationEvent: InTextCitationEvent
  typedNoteEvent: TypedNoteEvent
  notificationEvent: NotificationEvent

  constructor(
    readonly axiosGroup: ITypedAxiosGroup,
    readonly repositories: ITypedRepositories,
    readonly scriptsLoadingState: IScriptsLoadingState,
  ) {
    this.ampClient = new Amplitude()
    this.typedLoggingClient = new TypedLoggingClient(
      this.axiosGroup.loggingAxios,
    )
    const clients = [this.ampClient, this.typedLoggingClient]
    this.userEvent = new UserEvent(clients)
    this.resourceEvent = new ResourceEvent(clients)
    this.folderEvent = new FolderEvent(clients)
    this.projectEvent = new ProjectEvent(clients)
    this.documentEvent = new DocumentEvent(clients)
    this.highlightEvent = new HighlightEvent(clients, this.repositories)
    this.taskEvent = new TaskEvent(clients)
    this.otherEvent = new OtherEvent(clients)
    this.pageEvent = new PageEvent(clients, this.repositories)
    this.inboxEvent = new InboxEvent(clients)
    this.spaceEvent = new SpaceEvent(clients)
    this.searchEvent = new SearchEvent(clients)
    this.networkEvent = new NetworkEvent(clients)
    this.groupEvent = new GroupEvent(clients)
    this.quantcast = new QuantcastClient(process.env.QUANTCAST_ID!)
    this.publicDocumentEvent = new PublicDocumentEvent(clients)
    this.homepageEvent = new HomepageEvent(clients)
    this.templateEvent = new TemplateEvent(clients)
    this.inTextCitationEvent = new InTextCitationEvent(clients)
    this.typedNoteEvent = new TypedNoteEvent(clients)
    this.notificationEvent = new NotificationEvent(clients)

    this.scriptsLoadingState.isAmplitudeLoaded = true
  }
}
