export const isResourceCardOrToolbar = (DOMNode) =>
  DOMNode?.dataset?.testid === 'resource-card' ||
  DOMNode?.id === 'bulk_action_toolbar'

/**  Returns HTML DOM node satisfies specific condition
 * @param {any} DOMNode
 * @param {(DOMNode: HTMLElement) => boolean} conditionFunc
 * @returns {HTMLElement} */
export const getSpecificDOMNode = (DOMNode, conditionFunc) => {
  if (conditionFunc(DOMNode)) return DOMNode
  if (!DOMNode?.parentNode) return
  return getSpecificDOMNode(DOMNode.parentNode, conditionFunc)
}

export function Version(ver) {
  this.arr = ver.split('.').map(Number)
}
Version.prototype.compareTo = function (version) {
  for (let i = 0; ; i++) {
    if (i >= version.arr.length) return i >= this.arr.length ? 0 : 1
    if (i >= this.arr.length) return -1
    const diff = this.arr[i] - version.arr[i]
    if (diff) return diff > 0 ? 1 : -1
  }
}
Version.prototype.isOutdatedTo = function (version) {
  return this.compareTo(version) < 0
}
