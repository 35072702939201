import { PUBLIC_DOCUMENT_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class PublicDocumentEvent extends TypedEvent {
  pageViewed(data: { anchorResourceId: string }) {
    this.sendEvent(PUBLIC_DOCUMENT_EVENT.PUBLIC_DOCUMENT_PAGE_VIEWED, data)
  }

  pageClosed(data: { anchorResourceId: string; duration: any }) {
    this.sendEvent(PUBLIC_DOCUMENT_EVENT.PUBLIC_DOCUMENT_PAGE_CLOSED, data)
  }

  resourceViewed(data: { anchorResourceId: string; resourceId: string }) {
    this.sendEvent(PUBLIC_DOCUMENT_EVENT.PUBLIC_DOCUMENT_RESOURCE_VIEWED, data)
  }

  tryTypedButtonClicked(data: { anchorResourceId: string }) {
    this.sendEvent(
      PUBLIC_DOCUMENT_EVENT.PUBLIC_DOCUMENT_PAGE_TRY_TYPED_BUTTON_CLICKED,
      data,
    )
  }
}
