import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showTransition: false,
}

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    showDocumentTransitionEffect: (state) => {
      state.showTransition = true
    },
    hideDocumentTransitionEffect: (state) => {
      state.showTransition = false
    },
  },
})

export const { showDocumentTransitionEffect, hideDocumentTransitionEffect } =
  documentSlice.actions

export default documentSlice.reducer
