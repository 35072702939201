export interface NotificationText {
  Title: string
  'Mark all as read': string
  taskAssignedMiddleText: string
  taskAssignedTrailingText: string
  reviewAssignedMiddleText: string
  reviewAssignedTrailingText: string
  reviewCheckedMiddleText: string
  reviewCheckedTrailingText: string
  projectInvitedMiddleText: string
  projectInvitedTrailingText: string
  notificationBoxEmptyText1: string
  notificationBoxEmptyText2: string
  commentCreatedText: string
}

export const englishNotificationText: NotificationText = {
  Title: 'Notification',
  'Mark all as read': 'Mark all as read',
  taskAssignedMiddleText: 'assigned a new task in',
  taskAssignedTrailingText: '',
  reviewAssignedMiddleText: 'requested a review in',
  reviewAssignedTrailingText: '',
  reviewCheckedMiddleText: 'reviewed',
  reviewCheckedTrailingText: '',
  projectInvitedMiddleText: 'added you to',
  projectInvitedTrailingText: '',
  notificationBoxEmptyText1:
    'When someone invites you to a project, assigns a task,',
  notificationBoxEmptyText2: 'or requests a review, you’ll be notified here.',
  commentCreatedText: 'has left a comment',
}

export const koreanNotificationText: NotificationText = {
  Title: '알림',
  'Mark all as read': '모두 읽음 처리',
  taskAssignedMiddleText: '님이',
  taskAssignedTrailingText: '에 새로운 태스크를 지정했습니다.',
  reviewAssignedMiddleText: '님이',
  reviewAssignedTrailingText: '을 검토 요청했습니다.',
  reviewCheckedMiddleText: '님이',
  reviewCheckedTrailingText: '을 검토 완료했습니다.',
  projectInvitedMiddleText: '님이 나를',
  projectInvitedTrailingText: '에 추가했습니다.',
  notificationBoxEmptyText1: '내가 받은 요청, 태스크,',
  notificationBoxEmptyText2: '프로젝트 초대 알림이 여기에 표시됩니다.',
  commentCreatedText: '님이 보낸 요청에 댓글을 남겼습니다.',
}
