import { HackleClient, getUserId, setUserId } from '@hackler/javascript-sdk'
import LocalStorageManagerFactory from '@/utils/common/localStorageManagerFactory'

export class Hackle {
  private localCache: { [key: string]: boolean } = {}
  private localStorage = LocalStorageManagerFactory.hackle
  private isReady = false

  constructor(
    private source: HackleClient,
    // TODO: fuck
    private hackleKeyMap: { [key: string]: number },
  ) {
    this.source.onInitialized().then(() => (this.isReady = true))
  }

  getUserId(): string {
    return getUserId()
  }

  setUserId(userId: string) {
    setUserId(userId)
  }

  getBooleanValue(key: string): boolean | undefined {
    if (this.isReady) {
      return this.getValue(key)
    } else {
      const fallbackValue = this.getValueFromFallback(key)

      this.source
        .onInitialized()
        .then(() => this.getValueFromOrigin(key))
        .catch((error: any) => {
          console.error('Hackle is not initialized', error)
        })

      return fallbackValue
    }
  }

  private getValue(key: string) {
    if (this.hasKeyInLocalCache(key)) {
      return this.getValueFromLocalCache(key)
    }

    return this.getValueFromOrigin(key)
  }

  private hasKeyInLocalCache(key: string): boolean {
    return Object.prototype.hasOwnProperty.call(this.localCache, key)
  }

  private getValueFromLocalCache(key: string): boolean | undefined {
    return this.localCache[key]
  }

  private setValueToLocalCache(key: string, value: boolean) {
    this.localCache[key] = value
  }

  private getValueFromOrigin(key: string): boolean | undefined {
    const hackleKey = this.toHackleKey(key)

    if (hackleKey === undefined) {
      return undefined
    }

    const originValue = this.source.isFeatureOn(hackleKey)
    this.setValueToLocalCache(key, originValue)
    this.setValueToLocalStorage(key, originValue)

    return originValue
  }

  private getValueFromFallback(key: string): boolean | undefined {
    return this.getValueFromLocalStorage(key)
  }

  private getValueFromLocalStorage(key: string): boolean | undefined {
    return this.localStorage.getData()?.[key]
  }

  private setValueToLocalStorage(
    key: string,
    value: boolean | undefined,
  ): void {
    const localStorageData = this.localStorage.getData() || {}
    localStorageData[key] = value

    this.localStorage.setData(localStorageData)
  }

  private toHackleKey(key: string): number {
    return this.hackleKeyMap[key]
  }
}
