import { FeaturePropsProvider } from '@/utils/featureProps/featurePropsProvider'

class MockFeaturePropsProvider implements FeaturePropsProvider {
  private stores: { [key: string]: any }
  private _identifier = 'identifier'

  constructor() {
    this.clear()
  }

  get identifier(): string {
    return this._identifier
  }

  set identifier(identifier: string) {
    this._identifier = identifier
  }

  getBooleanValue(key: string): boolean | undefined {
    return this.stores[key]
  }

  setValue(key: string | undefined, value: any) {
    if (key === undefined) {
      throw new Error('invalid feature props key')
    }

    this.stores[key] = value
  }

  clear() {
    this.stores = {}
  }
}

const mockFeaturePropsProvider = new MockFeaturePropsProvider()

export default mockFeaturePropsProvider
