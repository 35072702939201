import dayjs from 'dayjs'

interface TaskText {
  Task: string
  'Add Task': string
  Remove: string
  Unassigned: string
  'No due date': string
  Clear: string
  'Push to my calendar': string
  Pushed: string
  'To do': string
  Done: string
  'Pushed to my calendar': string
  'Search People': string
  'No match items': string
  'No document': string
  'Go to document': string
  dueDate: (date: Date) => string
}

export const englishTaskText: TaskText = {
  Task: 'Task',
  'Add Task': 'Add Task',
  Remove: 'Remove',
  Unassigned: 'Unassigned',
  'No due date': 'No due date',
  Clear: 'Clear',
  'Push to my calendar': 'Push to my calendar',
  Pushed: 'Pushed',
  'To do': 'To do',
  Done: 'Done',
  'Pushed to my calendar': 'Pushed to my calendar',
  'Search People': 'Search people...',
  'No match items': 'No matches found',
  'No document': 'No document',
  'Go to document': 'Go to document',
  dueDate: (date: Date) => `${dayjs(date).format('MMM D')}`,
}

export const koreanTaskText: TaskText = {
  Task: '태스크',
  'Add Task': '태스크 추가',
  Remove: '삭제',
  Unassigned: '담당자 없음',
  'No due date': '마감일 없음',
  Clear: '지우기',
  'Push to my calendar': '내 캘린더로 보내기',
  Pushed: '완료',
  'To do': '할 일',
  Done: '완료한 일',
  'Pushed to my calendar': '내 캘린더로 보냈습니다',
  'Search People': '멤버 찾기',
  'No match items': '일치하는 항목 없음',
  'No document': '문서 없음',
  'Go to document': '문서로 이동',
  dueDate: (date: Date) => `${dayjs(date).format('M월 D일')}`,
}
