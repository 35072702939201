import { getFileMD5Hash } from './util'

const getRemovedDuplicateFiles = async (files) => {
  const removeDuplicates = (fileObject) => {
    const visited = {}
    return fileObject.reduce((acc, cur) => {
      if (cur.md5Hash in visited) return acc
      visited[cur.md5Hash] = true
      return [...acc, cur]
    }, [])
  }

  const fileObjectListWithMD5Hash = await Promise.all(
    files.map((file) => getFileMD5Hash(file)),
  )

  return removeDuplicates(fileObjectListWithMD5Hash)
}

export { getRemovedDuplicateFiles, getFileMD5Hash }
