import { Store } from 'redux'
import FolderAPI from '@/api/folderAPI'
import ResourceAPI from '@/api/resourceAPI'
import IntercomManager from '@/integrations/intercom/IntercomManager'
import { ResourceEvent } from '@/integrations/logging/event/resource'
import { FolderInfo, TypedFolder } from '@/models/folder'
import FolderRepository from '@/repositories/folderRepository'
import ResourceRepository from '@/repositories/resourceRepository'
import autoBind from '@/utils/autoBind'
import { getRemovedDuplicateFiles } from '@/api/helper'
import { RootState } from '@/store'
import { TypedResourceType } from '@/models/resource/TypedResource'
import { TypedTextResource } from '@/models/resource/TypedTextResource'
import SpaceService from '@/services/spaceService'
import { QuotaExcessType } from '@/models/subscription'
import { openModal } from '@/utils/feedback/feedbackSlice'
import { ModalType } from '@/hooks/feedback/modals'
import RequestingService from '@/services/requestingService'
import GooglePickerService from '@/services/googlePickerService'
import DuplicateService from '@/services/duplicateService'
import { GoogleDocumentObject } from '@/api/documentAPI'
import { GoogleDocumentType } from '@/models/document/TypedGoogleDocument'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'

export type AddedFrom = 'addnew' | 'clipboard' | 'dnd' | 'empty_inbox'

export const defaultDocumentTitle = 'Untitled'

class ResourceAdderService {
  constructor(
    private readonly store: Store<RootState>,
    private readonly spaceService: InstanceType<typeof SpaceService>,
    private readonly requestingService: InstanceType<typeof RequestingService>,
    private readonly googlePickerService: InstanceType<
      typeof GooglePickerService
    >,
    private readonly duplicateService: InstanceType<typeof DuplicateService>,
    private readonly resourceAPI: InstanceType<typeof ResourceAPI>,
    private readonly folderAPI: InstanceType<typeof FolderAPI>,
    private readonly resourceRepo: InstanceType<typeof ResourceRepository>,
    private readonly folderRepo: InstanceType<typeof FolderRepository>,
    private readonly resourceEvent: ResourceEvent,
  ) {}

  async addUrl(
    url: string,
    folderInfo: FolderInfo,
    addedFrom: AddedFrom,
  ): Promise<void> {
    const { folderId, documentId } = folderInfo

    const resourceSkeletonIds =
      this.requestingService.libraryPanelResources.add([
        { folderId, resourceType: TypedResourceType.URL },
      ])

    const newResource = await this.resourceAPI.addUrlNode(url, folderId)

    const updatedFolderData = await this.folderAPI.getSpecificFolder(folderId)
    this.folderRepo.update(updatedFolderData.folderId, updatedFolderData)
    this.resourceRepo.add(newResource)

    this.resourceEvent.createdEvent({
      documentId,
      ...newResource,
      addedFrom,
      addedToInbox: false,
    })
    IntercomManager.resource.sendCreatedEvent()

    this.requestingService.libraryPanelResources.remove(resourceSkeletonIds)
  }

  async addFiles(
    files: File[],
    folderId: string,
    spaceId: string,
    addedFrom: AddedFrom,
  ): Promise<void> {
    const totalFilesSize = files.reduce((total, file) => total + file.size, 0)

    if (
      await this.spaceService.willStorageQuotaBeExceeded(
        spaceId,
        totalFilesSize,
      )
    ) {
      this.showQuotaExcessModal(QuotaExcessType.storage)
      return
    }

    const resourceSkeletonIds =
      this.requestingService.libraryPanelResources.add(
        files.map(() => ({
          folderId,
          resourceType: TypedResourceType.FILE,
        })),
      )

    const folder = this.folderRepo.find(folderId)

    const newResources = await this.resourceAPI.addResourcesV2ByFiles({
      folderId,
      files,
    })
    this.resourceRepo.addAll(newResources)
    for (const resource of newResources) {
      this.resourceEvent.createdEvent({
        ...resource,
        addedFrom,
        addedToInbox: false,
        documentId: folder?.documentId,
      })
      IntercomManager.resource.sendCreatedEvent()
    }

    const updatedFolderData = await this.folderAPI.getSpecificFolder(folderId)
    this.folderRepo.update(updatedFolderData.folderId, updatedFolderData)

    this.requestingService.libraryPanelResources.remove(resourceSkeletonIds)
  }

  async addFilesWithoutMock(
    files: File[],
    folderId: string,
    spaceId: string,
    addedFrom: AddedFrom,
  ): Promise<TypedFolder | undefined> {
    /**
     * https://linear.app/typed/issue/CORE-55/구글-드라이브-문서-첨부파일-업로드-스켈레톤-방식-통일
     * 위의 티켓을 처리하며, MOCK resource 생성 및 삭제 제어를 호출부에서 해야하는데 기존 코드는 안쪽에서 하고 있음
     * 이에 대해 addFiles 과 로직은 동일하나, mock resource 작업만 외부의 메소드에 위임하도록 처리
     * 이후엔 위와 동일하게 외부의 메소드에서 제어할 수 있도록 개선되어야 함
     */
    const totalFilesSize = files.reduce((total, file) => total + file.size, 0)

    if (
      await this.spaceService.willStorageQuotaBeExceeded(
        spaceId,
        totalFilesSize,
      )
    ) {
      this.showQuotaExcessModal(QuotaExcessType.storage)
      return
    }

    const addedMd5HashFileList = await getRemovedDuplicateFiles(
      Array.from(files),
    )

    const folder = this.folderRepo.find(folderId)
    for (const file of addedMd5HashFileList) {
      const newResource = await this.resourceAPI.addFileNodes({
        file,
        folderId,
      })

      this.resourceRepo.add(newResource)
      this.resourceEvent.createdEvent({
        ...newResource,
        addedFrom,
        addedToInbox: false,
        documentId: folder?.documentId,
      })
      IntercomManager.resource.sendCreatedEvent()
    }

    return await this.folderAPI.getSpecificFolder(folderId)
  }

  async addText(
    val: { title: string; body: string },
    folder: FolderInfo,
    addedFrom: AddedFrom,
  ): Promise<TypedTextResource> {
    const resourceSkeletonIds =
      this.requestingService.libraryPanelResources.add([
        { folderId: folder.folderId, resourceType: TypedResourceType.TEXT },
      ])

    const newTextResourceRef = await this.resourceAPI.addTextNode(
      val,
      folder.folderId,
    )

    this.resourceRepo.add(newTextResourceRef)
    const updatedFolderData = await this.folderAPI.getSpecificFolder(
      folder.folderId,
    )
    this.folderRepo.update(updatedFolderData.folderId, updatedFolderData)
    this.resourceEvent.createdEvent({
      ...newTextResourceRef,
      addedFrom,
      addedToInbox: false,
      documentId: folder.documentId,
    })
    IntercomManager.resource.sendCreatedEvent()

    this.requestingService.libraryPanelResources.remove(resourceSkeletonIds)
    return newTextResourceRef
  }

  async addDocument(
    title: string,
    type: GoogleDocumentType,
    // FIXME: 이후에 requestingService가 documentId로도 추가할 수 있게 변경된 후 documentId만, folderId만 받아도 되도록 변경
    target: { folderId: string; documentId: string },
    addedFrom: AddedFrom,
  ) {
    const { folderId, documentId } = target
    const resourceSkeletonIds =
      this.requestingService.libraryPanelResources.add([
        { folderId: target.folderId, resourceType: TypedResourceType.DOCUMENT },
      ])

    const newResource = await this.resourceAPI.addDocumentNode(title, type, {
      folderId,
      documentId,
    })

    this.resourceEvent.createdEvent({
      documentId,
      ...newResource,
      addedFrom,
      addedToInbox: false,
    })

    this.requestingService.libraryPanelResources.remove(resourceSkeletonIds)
    const updatedFolderData = await this.folderAPI.getSpecificFolder(folderId)
    this.folderRepo.update(updatedFolderData.folderId, updatedFolderData)
  }

  async addFileResourceFromGoogleDrive(
    folder: FolderInfo,
    spaceId: string,
  ): Promise<void> {
    try {
      const googlePickerResponse =
        await this.googlePickerService.buildGooglePicker(
          GooglePickerService.nonGoogleDocumentFileMimeTypes.join(','),
        )
      if (!googlePickerResponse) {
        return
      }

      this.requestingService.libraryPanelResources.add(
        googlePickerResponse.docs.map(() => ({
          folderId: folder.folderId,
          resourceType: TypedResourceType.FILE,
        })),
      )

      const filesData = googlePickerResponse.docs.filter(
        (data) =>
          !GooglePickerService.formatsCanBeGoogleDocs.includes(data.mimeType),
      )

      const addFileFlow = async () => {
        if (filesData.length < 1) return
        const fileBufferList = await this.generateFileBufferList(filesData)
        const notDuplicateFiles =
          await this.duplicateService.addNonDuplicateFileResources(
            folder,
            'document',
            fileBufferList,
            spaceId,
          )
        await this.addFilesWithoutMock(
          notDuplicateFiles,
          folder.folderId,
          spaceId,
          'addnew',
        )
      }

      await addFileFlow()

      const updatedFolderData = await this.folderAPI.getSpecificFolder(
        folder.folderId,
      )
      this.folderRepo.update(updatedFolderData.folderId, updatedFolderData)
    } catch (error) {
      console.error(error)
      if (error instanceof Error) {
        sendErrorLog(error.message)
      }
      throw error
    } finally {
      this.requestingService.libraryPanelResources.clear()
    }
  }

  async addResourceFromGoogleDrive(
    folder: FolderInfo,
    spaceId: string,
  ): Promise<void> {
    try {
      const googlePickerResponse =
        await this.googlePickerService.buildGooglePicker(
          GooglePickerService.fileResourceMimeTypes.join(',') +
            ',' +
            GooglePickerService.formatsCanBeGoogleDocs.join(','),
        )

      if (!googlePickerResponse) {
        return
      }
      this.requestingService.libraryPanelResources.add(
        googlePickerResponse.docs.map(() => ({
          folderId: folder.folderId,
          resourceType: TypedResourceType.DOCUMENT,
        })),
      )

      const docsData = googlePickerResponse.docs.filter((data) =>
        GooglePickerService.formatsCanBeGoogleDocs.includes(data.mimeType),
      )

      const filesData = googlePickerResponse.docs.filter(
        (data) =>
          !GooglePickerService.formatsCanBeGoogleDocs.includes(data.mimeType),
      )

      const addFileFlow = async () => {
        if (filesData.length < 1) return
        const fileBufferList = await this.generateFileBufferList(filesData)
        const notDuplicateFiles =
          await this.duplicateService.addNonDuplicateFileResources(
            folder,
            'document',
            fileBufferList,
            spaceId,
          )
        await this.addFilesWithoutMock(
          notDuplicateFiles,
          folder.folderId,
          spaceId,
          'addnew',
        )
      }

      await Promise.all([
        this.resourceAPI.addResourcesV2ByGoogleDocObject(docsData, folder),
        addFileFlow(),
      ])

      const updatedFolderData = await this.folderAPI.getSpecificFolder(
        folder.folderId,
      )
      this.folderRepo.update(updatedFolderData.folderId, updatedFolderData)
    } finally {
      this.requestingService.libraryPanelResources.clear()
    }
  }

  async addResourcesFromLocal(
    gdriveId: string,
    target: { documentId?: string; folderId?: string },
  ) {
    if (!target.documentId && !target.folderId) {
      const errorMessage =
        'You should have one parameter about target (documentId or folderId) in addResourcesFromLocal'
      sendErrorLog(errorMessage)
      throw Error(errorMessage)
    }
    const googlePickerResponse =
      await this.googlePickerService.buildLocalGooglePicker(
        GooglePickerService.fileResourceMimeTypes.join(',') +
          +',' +
          GooglePickerService.formatsCanBeGoogleDocs.join(','),
        gdriveId,
      )

    const resources = await this.resourceAPI.addResourceByGooglePicker(
      googlePickerResponse.docs,
      'local',
      target,
    )

    return resources
  }

  private async generateFileBufferList(
    googleDocs: GoogleDocumentObject[],
  ): Promise<File[]> {
    const fileBufferList = await Promise.all(
      googleDocs.map(async (doc) => {
        const fileBuffer =
          await this.googlePickerService.downloadFileFromGoogleDrive(doc.id)
        return fileBuffer
          ? new File([fileBuffer], doc.name, { type: doc.mimeType })
          : undefined
      }),
    )
    return fileBufferList.filter((source: undefined | File): source is File => {
      return source instanceof File
    })
  }

  private showQuotaExcessModal(quotaExcessType: QuotaExcessType) {
    this.store.dispatch(
      openModal({ name: ModalType.QUOTA_EXCESS, data: quotaExcessType }),
    )
  }
}

export default autoBind(ResourceAdderService)
