import autoBind from '@/utils/autoBind'

type CardBrand =
  | 'amex'
  | 'cartes_bancaires'
  | 'diners_club'
  | 'discover'
  | 'jcb'
  | 'mastercard'
  | 'visa'
  | 'unionpay'

export interface IPaymentMethodDTO {
  card?: {
    brand: CardBrand
    last4: string
  }
}

class PaymentMethod {
  constructor(private cardBrand: CardBrand, readonly cardLast4: string) {}

  get displayCardBrand() {
    switch (this.cardBrand) {
      case 'amex':
        return 'American Express'
      case 'cartes_bancaires':
        return 'Cartes Bancaires'
      case 'diners_club':
        return 'Diners Club'
      case 'discover':
        return 'Discover'
      case 'jcb':
        return 'JCB'
      case 'mastercard':
        return 'Mastercard'
      case 'visa':
        return 'Visa'
      case 'unionpay':
        return 'UnionPay'
    }
  }
}

export default autoBind(PaymentMethod)
