import { AxiosInstance } from 'axios'
import autoBind from '@/utils/autoBind'
import { CustomError } from '@/util'
import {
  IntegrationDTO,
  Integration,
  IntegrationType,
} from '@/models/integration'

class IntegrationAPI {
  constructor(readonly axios: AxiosInstance) {}

  async sendOAuthCode({
    type,
    code,
    redirect_uri,
  }: {
    type: IntegrationType
    code: string
    redirect_uri: string
  }): Promise<Integration> {
    try {
      const result = await this.axios.post<IntegrationDTO>(
        '/api/integrations',
        {
          code,
          type,
          redirect_uri,
        },
      )
      return Integration.fromJSON(result.data)
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error('send OAuthCode failed', e)
      }
      throw e
    }
  }

  async getIntegrations(): Promise<Integration[]> {
    const result = await this.axios({
      method: 'get',
      url: '/api/integrations',
    })

    return result.data.map(Integration.fromJSON)
  }

  async deleteIntegration({
    type,
  }: {
    type: IntegrationType
  }): Promise<boolean> {
    try {
      const result = await this.axios({
        method: 'delete',
        url: '/api/integrations',
        params: { type },
      })
      return result.status === 200
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error('Delete Integration failed', e)
      }
      throw e
    }
  }
}

export default autoBind(IntegrationAPI)
