import { GetTasksFilter, TaskLocation } from '@/models/task'
import { ReviewListType } from '@/models/review'
import { SUPPORTED_LANGUAGES } from '@/locale/locale.util'
import {
  DocumentFilterType,
  ResourceFilterType,
} from '@/models/resource/ResourceFilter'

export default {
  allResources: () => ['resources'],
  resource: (resourceId: string) => ['resource', resourceId],
  allHighlights: () => ['highlights'],
  highlightsByResourceId: (resourceId: string) => ['highlights', resourceId],
  allCitations: () => ['citations'],
  citationsByResourceId: (resourceId: string) => ['citations', resourceId],
  allGroups: () => ['groups'],
  groupsBySpaceId: (spaceId: string) => ['groups', spaceId],
  allProjects: () => ['projects'],
  projectsBySpaceId: (spaceId: string) => ['projects', { spaceId }],
  project: (projectId: string) => ['projects', { projectId }],
  projectsByGroupId: (groupId: string) => ['projects', { groupId }],
  privateProjectMembers: (projectId: string) => [
    'projects',
    { projectId, privateProjectMembers: true },
  ],
  favoritesBySpaceId: (spaceId: string) => ['favorites', { spaceId }],
  allDocuments: () => ['documents'],
  documentsByProjectId: (projectId: string) => ['documents', { projectId }],
  allInfiniteDocuments: () => ['infiniteDocuments'],
  documentsCreatedByMe: (spaceId: string) => [
    'infiniteDocuments',
    { spaceId, createdByMe: true },
  ],
  documentsViewedByMe: (spaceId: string) => [
    'infiniteDocuments',
    { spaceId, viewedByMe: true },
  ],
  allResourcesInDocument: (documentId: string) => [
    'allResourcesInDocument',
    { documentId },
  ],
  document: (documentId: string) => ['documents', { documentId }],
  documentSharedPermission: (documentId: string) => [
    'permission',
    { documentId },
  ],
  allFolders: () => ['folders'],
  foldersByDocumentId: (documentId: string) => ['folders', documentId],
  inbox: () => ['inbox'],
  allTasks: () => ['tasks'],
  tasksByFilter: (filter: GetTasksFilter) => ['tasks', filter],
  allInfiniteTasks: () => ['infiniteTasks'],
  infiniteTasks: (
    filter: Omit<GetTasksFilter, 'cursor' | 'limit'> & {
      spaceId: string
      location: TaskLocation
    },
  ) => ['infiniteTasks', filter],
  allSpaces: () => ['spaces'],
  allSpaceMembers: () => ['spaceMembers'],
  spaceMembersBySpaceId: (spaceId: string) => ['spaceMembers', spaceId],
  allDocumentPageSearchResults: () => ['documentPageSearchResults'],
  documentPageSearchResult: ({
    searchWord,
    resourceType,
    createdBy,
    orderBy,
  }: {
    searchWord: string
    resourceType?: ResourceFilterType
    createdBy?: string
    orderBy?: string
  }) => [
    'documentPageSearchResults',
    {
      searchWord,
      resourceType,
      createdBy,
      orderBy,
    },
  ],
  allAppHomeDocumentSearchResults: () => ['appHomeDocumentSearchResults'],
  appHomeDocumentSearchResultsBy: ({
    searchWord,
    documentType,
    createdBy,
  }: {
    searchWord: string
    documentType?: DocumentFilterType
    createdBy?: string
  }) => [
    'appHomeDocumentSearchResults',
    { searchWord, documentType, createdBy },
  ],
  allAppHomeProjectSearchResults: () => ['appHomeProjectSearchResults'],
  appHomeProjectSearchResultsBySearchWord: (searchWord: string) => [
    'appHomeProjectSearchResults',
    searchWord,
  ],
  allAppHomeReferenceSearchResults: () => ['appHomeReferenceSearchResults'],
  appHomeReferenceSearchResultsBySearchWord: (searchWord: string) => [
    'appHomeReferenceSearchResults',
    searchWord,
  ],
  allRecommendResources: () => ['recommendResources'],
  recommendResourcesByDocumentId: (documentId: string) => [
    'recommendResources',
    documentId,
  ],
  networkByProjectId: (projectId: string) => ['networks', projectId],
  networkBySpaceId: (spaceId: string) => ['networks', spaceId],
  allNetworks: () => ['networks'],
  pageViewUser: (resourceId: string) => ['pageViewUser', resourceId],
  pageViewCount: (resourceId: string) => ['pageViewCount', resourceId],
  spaceUsageBySpaceId: (spaceId: string) => ['spaceUsage', spaceId],
  allTemplates: () => ['templates'],
  templatesByLanguage: (language: SUPPORTED_LANGUAGES) => [
    'templates',
    { language },
  ],
  allReviews: () => ['reviews'],
  allReviewsByType: ({ type }: { type: ReviewListType }) => [
    'reviews',
    { type },
  ],
  reviewsByFilter: ({
    documentId,
    type,
  }: {
    documentId: string
    type: ReviewListType
  }) => ['reviews', { documentId, type }],
  commentsByReviewId: (reviewId: string) => ['comments', reviewId],
  allPaymentMethods: () => ['paymentMethods'],
  paymentMethodByFilter: (filter: { spaceId: string }) => [
    'paymentMethods',
    filter,
  ],
  allIntegrations: ({ spaceId }: { spaceId: string }) => [
    'integrations',
    { spaceId },
  ],
}
