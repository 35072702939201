import { TypedDocument } from '@/models/document/TypedDocument'
import TypedResource from '@/models/resource/TypedResource'
import ViewerStatus from '@/models/viewerStatus/ViewerStatus'

export default class InstanceViewerStatus<
  T extends TypedResource | TypedDocument,
> extends ViewerStatus<T> {
  isOpen: true = true
  viewerType: 'instance' = 'instance'
  constructor(readonly data: T) {
    super()
  }
}
