/* global chrome */
import autoBind from '@/utils/autoBind'
import AuthService from '@/services/authService'

interface UserData {
  displayName: string
  email: string
  photoUrl: string
  userId: string
}

class ExtensionService {
  constructor(
    readonly chromeRuntime: typeof chrome.runtime | undefined,
    readonly authService: AuthService,
  ) {}

  getTabIdToGoBack(): number | null {
    const tabId = new URLSearchParams(window.location.search).get(
      'tabIdToGoBack',
    )

    return tabId === null ? tabId : Number(tabId)
  }

  signOut(): void {
    if (!this.chromeRuntime || !process.env.EXTENSION_ID) return

    const tabIdToGoBack = this.getTabIdToGoBack()

    this.chromeRuntime.sendMessage(process.env.EXTENSION_ID, {
      type: 'auth',
      data: {
        tokenData: null,
        tabIdToGoBack,
        userData: null,
      },
      action: 'signOut',
    })

    if (tabIdToGoBack) {
      window.open('', '_self')?.close()
    }
  }

  async signIn(userData: UserData): Promise<void> {
    if (!this.chromeRuntime || !process.env.EXTENSION_ID) return
    const tabIdToGoBack = this.getTabIdToGoBack()
    const tokenData = await this.authService.getTokenDataForExtension()
    this.chromeRuntime.sendMessage(process.env.EXTENSION_ID, {
      type: 'auth',
      data: {
        tokenData,
        tabIdToGoBack,
        userData,
      },
      action: 'signIn',
    })

    if (tabIdToGoBack) {
      window.open('', '_self')?.close()
    }
  }
}

export default autoBind(ExtensionService)
