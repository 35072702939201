export type FolderInfo = { folderId: string; documentId: string }

export interface ITypedFolder extends FolderInfo {
  readonly folderId: string
  readonly name: string
  readonly createdAt: number
  readonly updatedAt: number
  readonly resourceList: string[]
  readonly documentId: string
  readonly spaceId: string
  prevFolder: string
  nextFolder: string
}

export class TypedFolder implements ITypedFolder {
  constructor(
    readonly folderId: string,
    readonly name: string,
    readonly createdAt: number,
    readonly updatedAt: number,
    readonly resourceList: string[],
    readonly documentId: string,
    readonly spaceId: string,
    public prevFolder: string,
    public nextFolder: string,
  ) {
    this.prevFolder = prevFolder
    this.nextFolder = nextFolder
  }

  static fromJSON(json: ITypedFolder, spaceId: string): TypedFolder {
    return new TypedFolder(
      json.folderId,
      json.name,
      json.createdAt,
      json.updatedAt,
      json.resourceList,
      json.documentId,
      spaceId,
      json.prevFolder,
      json.nextFolder,
    )
  }

  toJSON(): ITypedFolder {
    return {
      folderId: this.folderId,
      name: this.name,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      resourceList: this.resourceList,
      documentId: this.documentId,
      spaceId: this.spaceId,
      prevFolder: this.prevFolder,
      nextFolder: this.nextFolder,
    }
  }

  copyWith(options?: Partial<TypedFolder>): TypedFolder {
    return new TypedFolder(
      options?.folderId ?? this.folderId,
      options?.name ?? this.name,
      options?.createdAt ?? this.createdAt,
      options?.updatedAt ?? this.updatedAt,
      options?.resourceList ?? this.resourceList,
      options?.documentId ?? this.documentId,
      options?.spaceId ?? this.spaceId,
      options?.prevFolder ?? this.prevFolder,
      options?.nextFolder ?? this.nextFolder,
    )
  }
}
