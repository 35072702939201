import { TypedIconSvgs, colors } from 'typed-design-system'
import { INode } from '@/models/network'
import { TypedResourceType } from '@/models/resource/TypedResource'
import { isImageMimeType } from '@/util'
import { TypedDocumentType } from '@/models/document/TypedDocument'

export enum IconSizeEnum {
  small = 'small',
  xsmall = 'xsmall',
}

/** @deprecated DocumentIconFactoryByDocType 사용 */
export function getXsmallBoxIconOfResource(node: INode) {
  if (node.node_type === TypedResourceType.URL) {
    return TypedIconSvgs.url
  } else if (node.node_type === TypedResourceType.TEXT) {
    return TypedIconSvgs.page
  } else if (node.node_type === TypedResourceType.FILE) {
    switch (node.metadata.contentType) {
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        return TypedIconSvgs.word
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-powerpoint':
        return TypedIconSvgs.ppt
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel':
        return TypedIconSvgs.excel
      case 'application/pdf':
      case 'application/vnd.epapyrus.pdf':
        return TypedIconSvgs.pdf
      case 'application/octet-stream':
      case 'application/x-hwp':
      case 'application/haansofthwp':
      case 'application/vnd.hancom.hwp':
        return TypedIconSvgs.hwp
      case isImageMimeType(node.metadata.contentType):
        return TypedIconSvgs.img
      default:
        return TypedIconSvgs.file
    }
  } else {
    return TypedIconSvgs.hwp
  }
}

/** @deprecated DocumentIconFactoryByDocType 사용 */
export const getSmallIconByDocType = (docType?: TypedDocumentType) => {
  switch (docType) {
    case 'document':
      return TypedIconSvgs.docs
    case 'presentation':
      return TypedIconSvgs.slides
    case 'spreadsheet':
      return TypedIconSvgs.sheets
    case 'pdf':
      return TypedIconSvgs.pdf
    case 'editor':
      return TypedIconSvgs.page
    default:
      throw Error('invalid docType in function getSmallIconByDocType')
  }
}

/** @deprecated box 아이콘 사라짐 */
export const getBoxIconByDocType = (docType: TypedDocumentType) => {
  return getDocumentBoxIconByDocType(docType)
}

/** @deprecated DocumentIconFactoryByDocType 사용 */
export const getDocumentBoxIconByDocType = (docType: TypedDocumentType) => {
  switch (docType) {
    case 'document':
      return TypedIconSvgs.docs
    case 'presentation':
      return TypedIconSvgs.slides

    case 'spreadsheet':
      return TypedIconSvgs.sheets
    case 'pdf':
      return TypedIconSvgs.pdf
    case 'editor':
      return TypedIconSvgs.page
    // TODO: docs를 내보낼게 아니라 throwError 해야함
    default:
      return TypedIconSvgs.docs
  }
}

export const getShadowColorByDocType = (docType: TypedDocumentType) => {
  switch (docType) {
    case 'document':
      return colors.blue80(0.3).toString()
    case 'presentation':
      return colors.yellow80(0.3).toString()
    case 'spreadsheet':
      return colors.green80(0.3).toString()
    case 'pdf':
      return colors.red80(0.3).toString()
    case 'editor':
      return colors.darker8().toString()
    default:
      throw Error('Invalid docType')
  }
}

export const getDropShadowByDocType = (docType: TypedDocumentType) => {
  switch (docType) {
    case 'document':
      return 'drop-shadow(0px 0px 8px rgba(27, 93, 202, 0.2))'
    case 'presentation':
      return 'drop-shadow(0px 0px 8px rgba(188, 126, 6, 0.2))'
    case 'spreadsheet':
      return 'drop-shadow(0px 0px 8px rgba(11, 116, 25, 0.2))'
    case 'pdf':
      return 'drop-shadow(0px 0px 8px rgba(135, 16, 39, 0.2))'
    case 'editor':
      return 'drop-shadow(0px 0px 8px rgba(51, 51, 51, 0.2))'
    default:
      return ''
  }
}
