import { QueryClient } from 'react-query'
import { ITypedRepositories } from '@/repositories'
import FolderRepository from '@/repositories/folderRepository'
import HighlightRepository from '@/repositories/highlightRepository'
import InboxRepository from '@/repositories/inboxRepository'
import ProjectRepository from '@/repositories/projectRepository'
import ResourceRepository from '@/repositories/resourceRepository'
import SpaceRepository from '@/repositories/spaceRepository'
import GroupRepository from '@/repositories/groupRepository'
import RecommendRepository from '@/repositories/recommendRepository'
import favoriteRepository from '@/repositories/favoriteRepository'
import CitationRepository from '@/repositories/citationRepository'

export default class TypedRepositories implements ITypedRepositories {
  folder: FolderRepository
  resource: ResourceRepository
  project: ProjectRepository
  inbox: InboxRepository
  highlight: HighlightRepository
  space: SpaceRepository
  group: GroupRepository
  recommend: RecommendRepository
  favorite: favoriteRepository
  citation: CitationRepository
  constructor(readonly queryClient: QueryClient) {
    this.folder = new FolderRepository(queryClient)
    this.resource = new ResourceRepository(queryClient)
    this.project = new ProjectRepository(queryClient)
    this.inbox = new InboxRepository(queryClient)
    this.highlight = new HighlightRepository(queryClient)
    this.space = new SpaceRepository(queryClient)
    this.group = new GroupRepository(queryClient)
    this.recommend = new RecommendRepository(queryClient)
    this.favorite = new favoriteRepository(queryClient)
    this.citation = new CitationRepository(queryClient)
  }
}
