import TypedResource, {
  ITypedResource,
  TypedResourceType,
} from '@/models/resource/TypedResource'
import { ResourceTag } from '@/models/resource/ResourceTag'
import { ITypedUser } from '@/models/user'

export interface ITypedUrlResourceData {
  url: string
  compareString?: string
  image?: {
    url?: string
  }
}

export interface ITypedUrlResource extends ITypedResource {
  readonly data: ITypedUrlResourceData
  readonly isOpenGraphDataFetched: boolean
  readonly readable: boolean
  readonly highlightCount?: number
}

export default class TypedUrlResource
  extends TypedResource
  implements ITypedUrlResource
{
  type = TypedResourceType.URL

  constructor(
    readonly resourceId: string,
    readonly backlinks: string[],
    readonly links: string[],
    readonly name: string,
    readonly createdAt: number,
    readonly createdBy: ITypedUser,
    readonly tags: ResourceTag[],
    readonly data: ITypedUrlResourceData,
    readonly isOpenGraphDataFetched: boolean,
    readonly readable: boolean,
    readonly highlightCount?: number,
  ) {
    super(resourceId, backlinks, links, name, createdAt, createdBy, tags, false)
  }

  get canBeDocument() {
    return false
  }

  static fromJSON(json: ITypedUrlResource): TypedUrlResource {
    if (json.type !== 'url') {
      throw new Error(
        `the type of UrlResource must be url, but given type is ${json.type}`,
      )
    }

    return new TypedUrlResource(
      json.resourceId,
      json.backlinks,
      json.links,
      json.name,
      json.createdAt,
      json.createdBy,
      json.tags?.map((tag) => ResourceTag.fromJSON(tag)) || [], // FIXME: 옵셔널 체이닝 삭제
      json.data,
      json.isOpenGraphDataFetched,
      json.readable,
      json.highlightCount,
    )
  }

  copyWith(
    options?: Partial<Omit<TypedUrlResource, 'type'>>,
  ): TypedUrlResource {
    return new TypedUrlResource(
      options?.resourceId ?? this.resourceId,
      options?.backlinks ?? this.backlinks,
      options?.links ?? this.links,
      options?.name ?? this.name,
      options?.createdAt ?? this.createdAt,
      options?.createdBy ?? this.createdBy,
      options?.tags ?? this.tags,
      options?.data ?? this.data,
      options?.isOpenGraphDataFetched ?? this.isOpenGraphDataFetched,
      options?.readable ?? this.readable,
      options?.highlightCount ?? this.highlightCount,
    )
  }

  toJSON(): ITypedUrlResource {
    return {
      ...super.toJSON(),
      data: this.data,
      isOpenGraphDataFetched: this.isOpenGraphDataFetched,
      readable: this.readable,
      highlightCount: this.highlightCount,
    }
  }
}
