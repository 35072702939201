/**
 * @summary Range 객체를 텍스트 노드로 쪼개는 역할 담당
 */
export default class NodeParser {
  static rangeToTextNodes(range: Range) {
    const textNodes = this.wholeTextNodesInRange(range)

    let textNodeSpans: Node[][] = []
    let prevNode: Node | null = null
    let currentSpan: Node[] | null = null

    textNodes.forEach((node) => {
      if (prevNode?.nextSibling === node) {
        currentSpan?.push(node)
      } else {
        currentSpan = [node]
        textNodeSpans.push(currentSpan)
      }
      prevNode = node
    })

    const whitespace = /^\s*$/
    textNodeSpans = textNodeSpans.filter((span) =>
      span.some((node) => !whitespace.test(node.nodeValue ?? '')),
    )

    return textNodeSpans
  }

  private static wholeTextNodesInRange(range: Range) {
    if (range.collapsed) return []

    let root: Node | HTMLElement | null = range.commonAncestorContainer
    if (root.nodeType !== Node.ELEMENT_NODE) {
      root = root.parentElement
    }
    if (!root) return []

    const textNodes = []
    const nodeIter = document.createNodeIterator(root, NodeFilter.SHOW_TEXT)

    let node
    while ((node = nodeIter.nextNode())) {
      if (!this.isNodeInRange(range, node)) {
        continue
      }

      if (node === range.startContainer && range.startOffset > 0) {
        ;(node as Text).splitText(range.startOffset)
        continue
      }

      if (
        node === range.endContainer &&
        range.endOffset < (node as Text).data.length
      ) {
        ;(node as Text).splitText(range.endOffset)
      }

      textNodes.push(node)
    }

    return textNodes
  }

  private static isNodeInRange(range: Range, node: Node) {
    try {
      const length = node.nodeValue?.length ?? node.childNodes.length
      return (
        range.comparePoint(node, 0) <= 0 &&
        range.comparePoint(node, length) >= 0
      )
    } catch (e) {
      return false
    }
  }
}
