const MASKING_TOKEN = '*'

export function maskingEmail(email: string) {
  const regex = /(^[\w\d.+-_]{2})([\w\d.+-_]+)(@[A-Z\d.-]+\.[A-Z]{2,4}$)/i

  return email.replace(regex, (_, $1, $2, $3) =>
    $1.concat($2.replaceAll(/[\w\d.+-_]/g, MASKING_TOKEN)).concat($3),
  )
}

export function maskingUserName(username: string) {
  return username.length < 3
    ? username.replace(/(.)(.*)/g, (_, $1, $2) => {
        return $1.concat($2.replaceAll(/./g, MASKING_TOKEN))
      })
    : username
        .split(' ')
        .map((name) => {
          return name.replace(/(.)(.*)(.)/g, (_, $1, $2, $3) => {
            return $1.concat($2.replaceAll(/./g, MASKING_TOKEN)).concat($3)
          })
        })
        .join(' ')
}
