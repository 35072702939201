import dayjs from 'dayjs'
import store from '@/store'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'
import { ReviewAPI } from '@/api/reviewAPI'
import {
  Review,
  ReviewComment,
  ReviewListType,
  ReviewPurposeType,
  ReviewStatus,
} from '@/models/review'
import PaginationWithRestCount from '@/models/pagination/PaginationWithRestCount'

export default class ReviewService {
  constructor(private readonly reviewAPI: ReviewAPI) {}
  async createReview({
    documentId,
    reviewerIds,
    purpose,
    dueDate,
    message,
    shouldSendMessageToSlack,
  }: {
    documentId: string
    reviewerIds: string[]
    purpose: ReviewPurposeType
    dueDate: Date
    message?: string
    shouldSendMessageToSlack?: boolean
  }): Promise<Review> {
    const response = await this.reviewAPI.createReview({
      documentId,
      reviewerIds,
      purpose,
      dueDate,
      message,
      shouldSendMessageToSlack,
    })
    return response
  }

  async getRequestedReviewList(): Promise<Review[]> {
    const response = await this.reviewAPI.getRequestedReviewList()

    return response.map(this.generateRequestedReviewStatus)
  }

  async getReceivedReviewList() {
    const response = await this.reviewAPI.getReceivedReviewList()

    return response.map(this.generateReceivedReviewStatus)
  }

  async getRequestReviewsByDocumentId(documentId: string): Promise<Review[]> {
    const response = await this.reviewAPI.getReviewsByDocumentId(
      documentId,
      ReviewListType.REQUEST,
    )
    return response
  }

  async getReceiveReviewsByDocumentId(documentId: string): Promise<Review[]> {
    const response = await this.reviewAPI.getReviewsByDocumentId(
      documentId,
      ReviewListType.RECEIVE,
    )
    return response
  }

  async updateReview(reviewId: string, isConfirmed: boolean): Promise<boolean> {
    const response = await this.reviewAPI.updateReview(reviewId, isConfirmed)
    return response
  }

  async getCommentsByReviewId({
    reviewId,
    limit,
    cursor,
  }: {
    reviewId: string
    limit?: number
    cursor?: string
  }): Promise<PaginationWithRestCount<ReviewComment>> {
    const response = await this.reviewAPI.getCommentsByReviewId({
      reviewId,
      limit,
      cursor,
    })
    return response
  }

  async createComment({
    reviewId,
    contents,
  }: {
    reviewId: string
    contents: string
  }): Promise<ReviewComment> {
    const response = await this.reviewAPI.createComment({
      reviewId,
      contents,
    })
    return response
  }

  async deleteComment(commentId: string): Promise<boolean> {
    const response = await this.reviewAPI.deleteComment(commentId)
    return response
  }

  private generateReceivedReviewStatus(review: Review) {
    try {
      const user = store.getState().user
      const reviewer = review.reviewers.find(({ id }) => id === user.data?.uid)
      const isExpired = dayjs(review.dueDate).isBefore(dayjs(), 'date')
      if (reviewer) {
        if (isExpired) {
          return review.copyWith({
            status: ReviewStatus.EXPIRED,
          })
        }
        return review.copyWith({
          status: reviewer.isConfirmed
            ? ReviewStatus.COMPLETED
            : ReviewStatus.REQUIRED,
        })
      }

      return review.copyWith({
        status: isExpired ? ReviewStatus.EXPIRED : ReviewStatus.REQUIRED,
      })
    } catch (error) {
      sendErrorLog('generateReceivedReviewStatus invoked failed.', {
        review,
        error,
      })
      throw new Error('generate received review status failed.')
    }
  }

  private generateRequestedReviewStatus(review: Review) {
    try {
      const readEveryReviewers = review.reviewers.every(
        ({ isConfirmed }) => isConfirmed,
      )
      const isExpired = dayjs(review.dueDate).isBefore(dayjs(), 'date')
      if (readEveryReviewers) {
        return review.copyWith({
          status: ReviewStatus.COMPLETED,
        })
      }
      return review.copyWith({
        ...review,
        status: isExpired ? ReviewStatus.EXPIRED : ReviewStatus.REQUIRED,
      })
    } catch (error) {
      sendErrorLog('generateRequestedReviewStatus invoked failed.', {
        review,
        error,
      })
      throw new Error('generate requested review status failed.')
    }
  }
}
