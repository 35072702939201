import { createInstance, HackleClient } from '@hackler/javascript-sdk'
import { FeaturePropsProvider } from '@/utils/featureProps/featurePropsProvider'
import { Hackle } from '@/integrations/hackle/hackle'
import { MockHackleClient } from '@/integrations/hackle/MockHackleClient'

class HackleFeaturePropsProvider implements FeaturePropsProvider {
  constructor(private source: Hackle) {}

  get identifier(): string {
    return this.source.getUserId()
  }

  set identifier(identifier: string) {
    this.source.setUserId(identifier)
  }

  getBooleanValue(key: string): boolean | undefined {
    return this.source.getBooleanValue(key)
  }
}

// TODO: Separate to a module
const isTest = process.env.NODE_ENV === 'test'
let hackleClient: HackleClient

if (!isTest) {
  const sdkKey = process.env.HACKLE_SDK_KEY as string

  const config = {
    pollingIntervalMillis: 60000,
    exposureEventDedupIntervalMillis: 1000,
  }

  hackleClient = createInstance(sdkKey, config)
} else {
  hackleClient = new MockHackleClient()
}

const hackle = new Hackle(hackleClient, {
  is_test_feature: 1,
  is_page_foundation_used: 12,
  is_review_comment_used: 13,
})

const hackleFeaturePropsProvider = new HackleFeaturePropsProvider(hackle)

export default hackleFeaturePropsProvider
