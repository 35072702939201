import CryptoJS from 'crypto-js'

export const getFileMD5Hash = (
  file: File,
): Promise<File & { md5Hash: string }> =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const fileBinary = CryptoJS.lib.WordArray.create(e.target!.result as any)
      const fileHash = CryptoJS.MD5(fileBinary).toString(CryptoJS.enc.Base64)
      const resolveFile = file as File & { md5Hash: string }
      resolveFile.md5Hash = fileHash
      resolve(resolveFile)
    }
    reader.readAsArrayBuffer(file)
  })
