export interface IPagination<T> {
  readonly data: T[]
  // FIXME: 백엔드 통일 작업 끝나면 타입 하나로 통일
  readonly cursor?: string | number
}

class Pagination<T> {
  constructor(
    readonly data: T[],
    // FIXME: 백엔드 통일 작업 끝나면 타입 하나로 통일
    readonly cursor?: string | number,
  ) {}

  static fromJSON<T>(json: IPagination<T>) {
    return new Pagination<T>(json.data, json.cursor)
  }
}

export default Pagination
