import React, { ButtonHTMLAttributes } from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import colors from '@/styles/colors'
import FlatButton from '@/components/elements/FlatButton'

/**
 * Design System :
 * https://www.figma.com/file/jGfCodIb0zer22xqwpdBaq/Typed-Design-System-(V0.2)?node-id=4490%3A6202
 */

export enum ButtonTheme {
  'PRIMARY',
  'SECONDARY',
  'SECONDARY_WITH_OUTLINE',
  'RED',
  'RED_WITH_OUTLINE',
}

export enum ButtonSize {
  'LARGE',
  'MEDIUM',
  'SMALL',
}

export interface ThemeButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode
  buttonTheme: ButtonTheme
  size?: ButtonSize
  isDisabled?: boolean
}

const ThemeButton = (
  {
    buttonTheme,
    size,
    isDisabled,
    children,
    onClick,
    ...rest
  }: ThemeButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  return (
    <StyledFlatButton
      buttonTheme={buttonTheme}
      size={size}
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : onClick}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledFlatButton>
  )
}

const StyledFlatButton = styled(FlatButton)<{
  buttonTheme: ButtonTheme
  size?: ButtonSize
  isDisabled?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.015em;

  ${({ size }) => {
    switch (size) {
      case ButtonSize.LARGE:
        return css`
          height: 44px;
        `
      case ButtonSize.MEDIUM:
        return css`
          height: 36px;
        `
      case ButtonSize.SMALL:
        return css`
          height: 28px;
        `
      default:
        return ''
    }
  }}

  ${({ buttonTheme, isDisabled }) => {
    switch (buttonTheme) {
      case ButtonTheme.PRIMARY:
        return css`
          background-color: ${colors.blue50()};
          color: ${colors.gray100()};

          &:hover {
            background-color: ${colors.blue60()};
          }

          ${isDisabled &&
          css`
            cursor: not-allowed;
            &:hover {
              background-color: ${colors.blue50()};
            }
          `}
        `
      case ButtonTheme.SECONDARY:
        return css`
          background-color: ${colors.darker4()};
          color: ${colors.gray20()};
          &:hover {
            background-color: ${colors.darker8()};
          }

          ${isDisabled &&
          css`
            cursor: not-allowed;
            &:hover {
              background-color: ${colors.darker4()};
            }
          `}
        `
      case ButtonTheme.SECONDARY_WITH_OUTLINE:
        return css`
          border: 1px solid ${colors.darker16()};
          color: ${colors.gray20()};
          &:hover {
            background-color: ${colors.darker4()};
          }

          ${isDisabled &&
          css`
            background-color: ${colors.darker16()};
            cursor: not-allowed;
            &:hover {
              background-color: #e5e5e5;
            }
          `}
        `
      case ButtonTheme.RED:
        return css`
          background-color: ${colors.red50()};
          color: ${colors.gray100()};
          &:hover {
            background-color: ${colors.red60()};
          }

          ${isDisabled &&
          css`
            cursor: not-allowed;
            &:hover {
              background-color: ${colors.red50()};
            }
          `}
        `
      case ButtonTheme.RED_WITH_OUTLINE:
        return css`
          border: 1px solid ${colors.red50()};
          color: ${colors.red50()};
          &:hover {
            background-color: ${colors.red50(0.04)};
          }

          ${isDisabled &&
          css`
            background-color: rgba(249, 68, 68, 8%);
            cursor: not-allowed;
            &:hover {
              background-color: #e5e5e5;
            }
          `}
        `

      default:
        break
    }
  }}

  opacity: ${({ isDisabled }) => (isDisabled ? '60%' : '100%')};
`

export default React.forwardRef(ThemeButton)
