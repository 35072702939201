import { AxiosInstance } from 'axios'
import TypedProject, {
  ITypedProject,
  IPrivateProjectMember,
} from '@/models/project'
import { CustomError, MESSAGES } from '@/util'
import autoBind from '@/utils/autoBind'
import { TypedSpaceMember } from '@/models/space'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'

type RequiredProjectId = Pick<TypedProject, 'projectId'> & Partial<TypedProject>
class ProjectAPI {
  // TODO: getgoogleAccessToken 혹은 gdrive handling class를 주입하도록 설계 변경
  // TODO: CustomError는 Error model로, MESSAGES는 constants 정도로 설계 변경
  constructor(readonly axios: AxiosInstance) {}

  async createProject(
    name: string,
    spaceId: string,
    groupId: string,
    isPrivate: boolean,
  ) {
    try {
      const projectData = await this.axios({
        method: 'post',
        url: '/api/projects',
        data: {
          name,
          isNewUser: false,
          pos: 'root',
          groupId,
          isPrivate,
        },
      })

      return TypedProject.fromJSON(projectData.data, spaceId)
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error('Cannot create Project', e)
        alert(MESSAGES.COMMON_ERROR)
      }
      throw e
    }
  }

  async updateProjects(projectsData: RequiredProjectId[]): Promise<void> {
    try {
      await this.axios({
        method: 'patch',
        url: '/api/projects',
        data: {
          projectsData,
        },
      })
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error('Cannot update Projects', e)
        alert(MESSAGES.COMMON_ERROR)
      }

      throw e
    }
  }

  async deleteProject(projectId: string): Promise<void> {
    try {
      await this.axios({
        method: 'delete',
        url: `/api/projects/${projectId}`,
      })
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error('Cannot delete Projects', e)
        alert(MESSAGES.COMMON_ERROR)
      }

      throw e
    }
  }

  async getProject(projectId: string, spaceId: string): Promise<TypedProject> {
    try {
      const { data } = await this.axios({
        method: 'get',
        url: `/api/v2/projects/${projectId}`,
      })

      return TypedProject.fromJSON(data, spaceId)
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async getProjects({
    spaceId,
    groupId,
  }: {
    spaceId: string
    groupId?: string
  }): Promise<TypedProject[]> {
    try {
      const { data } = await this.axios({
        method: 'get',
        url: '/api/v2/projects',
        params: { groupId },
      })

      return (data as ITypedProject[]).map((projectJson) =>
        TypedProject.fromJSON(projectJson, spaceId),
      )
    } catch (e) {
      if (e instanceof CustomError) {
        console.error(e.message)
      } else {
        console.error('Cannot get Projects', e)
      }
      throw e
    }
  }

  async addProjectToFavorites(projectId: string): Promise<void> {
    await this.axios({
      method: 'post',
      url: `/api/favorite/${projectId}?type=project`,
    })
  }

  async removeProjectFromFavorites(projectId: string): Promise<void> {
    await this.axios({
      method: 'delete',
      url: `/api/favorite/${projectId}?type=project`,
    })
  }

  async moveProjectToTargetGroup(
    projectId: string,
    groupId: string,
  ): Promise<void> {
    await this.axios({
      method: 'patch',
      url: `/api/projects/${projectId}/actions/move?groupId=${groupId}`,
    })
  }

  async getPrivateProjectMembers(
    projectId: string,
  ): Promise<TypedSpaceMember[]> {
    if (!projectId) {
      sendErrorLog(`Project id is invalid : ${projectId}`)
      throw new Error('Project id is invalid')
    }

    const { data } = await this.axios({
      method: 'get',
      url: `/api/projects/${projectId}/permissions`,
    })
    const members = data.permissions as IPrivateProjectMember[]
    return members.map((member) =>
      TypedSpaceMember.fromJSON({
        userId: member.id,
        displayName: member.displayName,
        photoUrl: member.photo,
        email: member.email,
        isPending: false,
        role: member.role,
      }),
    )
  }

  async addMembersToPrivateProject(
    projectId: string,
    userIds: string[],
  ): Promise<void> {
    await this.axios({
      method: 'post',
      url: `/api/projects/${projectId}/permissions`,
      data: {
        ids: userIds,
      },
    })
  }

  async removeMembersFromPrivateProject(
    projectId: string,
    userIds: string[],
  ): Promise<void> {
    await this.axios({
      method: 'delete',
      url: `/api/projects/${projectId}/permissions`,
      data: {
        ids: userIds,
      },
    })
  }
}

export default autoBind(ProjectAPI)
