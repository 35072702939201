import { Store } from 'redux'
import ResourceAPI from '@/api/resourceAPI'
import TypedResource, {
  ReservedResourceTag,
} from '@/models/resource/TypedResource'
import ResourceRepository from '@/repositories/resourceRepository'
import { setViewerStatus } from '@/slices/viewers/viewerSlice'
import autoBind from '@/utils/autoBind'
import { IResourceEvent } from '@/integrations/logging/event/resource'
import { ResourceTag } from '@/models/resource/ResourceTag'
import InstanceViewerStatus from '@/models/viewerStatus/InstanceViewerStatus'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'
import SpaceService from '@/services/spaceService'
import { QuotaExcessType } from '@/models/subscription'
import { openModal } from '@/utils/feedback/feedbackSlice'
import { ModalType } from '@/hooks/feedback/modals'
import { TypedTextResource } from '@/models/resource/TypedTextResource'

class ResourceService {
  constructor(
    private readonly spaceService: InstanceType<typeof SpaceService>,
    private readonly resourceRepository: InstanceType<
      typeof ResourceRepository
    >,
    private readonly resourceAPI: InstanceType<typeof ResourceAPI>,
    private readonly store: Store,
    private readonly resourceEvent: IResourceEvent,
  ) {}

  async linkResource(
    curReferenceId: string,
    targetFolderId: string,
    targetDocumentId: string,
    spaceId?: string,
  ) {
    await this.resourceAPI.linkResource(
      curReferenceId,
      targetFolderId,
      targetDocumentId,
      spaceId,
    )
  }

  async renameResource(newName: string, resourceId: string) {
    this.resourceAPI.renameResource(resourceId, newName)
  }

  async editTextResource(newResource: TypedTextResource) {
    try {
      await this.resourceAPI.editTextResource({
        id: newResource.id,
        title: newResource.name,
        body: newResource.data.body,
      })
    } catch (error) {
      sendErrorLog('Error occurred while editing text resource', {
        error,
        newResource,
      })
    }
  }

  async addReadTag(resourceToAdd: TypedResource): Promise<void> {
    const newTag = await this.resourceAPI.addTag(
      resourceToAdd.resourceId,
      ReservedResourceTag.READ,
    )

    const newTags = [...resourceToAdd.tags, newTag]

    this.resourceRepository.update(resourceToAdd.resourceId || '', {
      tags: newTags.map((tag) => ResourceTag.fromJSON(tag)),
    })

    this.store.dispatch(
      setViewerStatus(
        new InstanceViewerStatus(
          resourceToAdd.copyWith({
            tags: newTags,
          }),
        ),
      ),
    )
  }

  async removeReadTag(
    resource: TypedResource,
    tagIdToDelete: string,
  ): Promise<void> {
    this.resourceRepository.update(resource.resourceId || '', {
      tags:
        resource.tags
          ?.filter((tag) => tag.name !== ReservedResourceTag.READ)
          .map((tag) => ResourceTag.fromJSON(tag)) || [],
    })
    this.store.dispatch(
      setViewerStatus(
        new InstanceViewerStatus(
          resource.copyWith({
            tags:
              resource?.tags?.filter(
                (tag) => tag.name !== ReservedResourceTag.READ,
              ) || [],
          }),
        ),
      ),
    )
    await this.resourceAPI.deleteTag(tagIdToDelete)
  }

  async moveResourceToProject(
    resourceId: string,
    projectId: string,
    spaceId: string,
  ): Promise<void> {
    try {
      if (await this.spaceService.willDocumentCountBeExceeded(spaceId)) {
        this.showQuotaExcessModal(QuotaExcessType.documentCount)
        throw Error('Quota Exceeded Error')
      }
      await this.resourceAPI.moveResourceToProject(resourceId, projectId)
    } catch (error: unknown) {
      sendErrorLog('Error occurred while moving resource to project', {
        error,
        resourceId,
        projectId,
        spaceId,
      })
      throw error
    }
  }

  private showQuotaExcessModal(quotaExcessType: QuotaExcessType) {
    this.store.dispatch(
      openModal({ name: ModalType.QUOTA_EXCESS, data: quotaExcessType }),
    )
  }
}

export default autoBind(ResourceService)
