import { IResourceTag, ResourceTag } from '@/models/resource/ResourceTag'
import { ITypedUser } from '@/models/user'

export enum TypedResourceType {
  URL = 'url',
  DOCUMENT = 'document',
  TEXT = 'text',
  FILE = 'file',
}

export enum TypedFileResourceType {
  WORD,
  PPT,
  EXCEL,
  PDF,
  HWP,
  IMAGE,
  NOT_SUPPORTED,
}

interface IDuplicateResourceSourceFolder {
  folderId: string
  name: string
  resourceId: string
  documentData: {
    documentId: string
    name: string
  }
}
export interface IResourceDuplicateInfo {
  resourceId: string
  resourceName: string
  sourceFolders: IDuplicateResourceSourceFolder[]
}

export interface ITypedResource {
  readonly type: `${TypedResourceType}`
  readonly resourceId: string
  readonly backlinks: string[]
  readonly links: string[]
  readonly name: string
  readonly createdAt: number
  readonly createdBy: ITypedUser
  readonly tags: IResourceTag[]
  readonly hasWorkspace: boolean
}

export enum ReservedResourceTag {
  READ = '@read@',
}

export default abstract class TypedResource implements ITypedResource {
  abstract get type(): TypedResourceType
  abstract get canBeDocument(): boolean

  constructor(
    readonly resourceId: string,
    readonly backlinks: string[],
    readonly links: string[],
    readonly name: string,
    readonly createdAt: number,
    readonly createdBy: ITypedUser,
    readonly tags: ResourceTag[],
    readonly hasWorkspace: boolean,
  ) {}

  abstract copyWith(options?: Partial<TypedResource>): TypedResource

  get id() {
    return this.resourceId
  }

  toJSON(): ITypedResource {
    return {
      type: this.type,
      resourceId: this.resourceId,
      backlinks: this.backlinks,
      links: this.links,
      name: this.name,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      tags: this.tags.map((tag) => tag.toJSON()),
      hasWorkspace: this.hasWorkspace,
    }
  }

  removeBacklink(idToDelete: string): TypedResource {
    return this.copyWith({
      backlinks: this.backlinks.filter((docId) => docId !== idToDelete),
    })
  }

  canDownload() {
    return (
      this.type === TypedResourceType.DOCUMENT ||
      this.type === TypedResourceType.FILE
    )
  }
}
