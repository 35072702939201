import { QueryClient } from 'react-query'
import LogAPI from '@/api/logAPI'
import autoBind from '@/utils/autoBind'
import { queryKeys } from '@/providers/react-query'

class LogService {
  constructor(
    readonly logApi: InstanceType<typeof LogAPI>,
    readonly queryClient: QueryClient,
  ) {}

  loggingResourceViewEvent(spaceId: string, resourceId: string): void {
    this.logApi.loggingResourceViewEvent(resourceId)
    this.queryClient.invalidateQueries(queryKeys.documentsViewedByMe(spaceId)) //FIXME mutation으로 변경
  }
}

export default autoBind(LogService)
