import TypedEvent from '@/integrations/logging/event/typedEvent'
import { TEMPLATE_EVENT } from '@/integrations/logging/enum'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class TemplateEvent extends TypedEvent {
  templateCreated({
    spaceId,
    projectId,
    templateId,
  }: {
    spaceId: string
    projectId: string
    templateId: string
  }) {
    this.sendEvent(TEMPLATE_EVENT.CREATED, {
      spaceId,
      projectId,
      templateId,
    })
  }
}
