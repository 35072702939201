import { QueryClient } from 'react-query'
import { INBOX_FOLDER_ID, TypedInbox } from '@/models/inbox'
import IBaseRepository from '@/repositories/types/baseRepository'
import { queryKeys } from '@/providers/react-query'
import { TypedFileResource } from '@/models/resource/TypedFileResource'
import TypedUrlResource from '@/models/resource/TypedUrlResource'

export default class InboxRepository implements IBaseRepository<TypedInbox> {
  constructor(readonly queryClient: QueryClient) {}

  add(item: TypedInbox): void {
    this.queryClient.setQueryData<TypedInbox>(queryKeys.inbox(), item)
  }
  addAll(): void {
    throw new Error('Method not implemented.')
  }
  find(): TypedInbox | undefined {
    return this.queryClient.getQueryData(queryKeys.inbox())
  }
  exist(): boolean {
    return Boolean(this.queryClient.getQueryData(queryKeys.inbox()))
  }
  update(
    id: typeof INBOX_FOLDER_ID,
    itemToPatch: Partial<TypedInbox>,
  ): boolean {
    const inbox = this.find()

    if (!inbox) {
      return false
    }

    this.queryClient.setQueryData<TypedInbox>(
      queryKeys.inbox(),
      inbox.copyWith(itemToPatch),
    )
    return true
  }
  delete(): boolean {
    throw new Error('Method not implemented.')
  }
  clear(): void {
    this.queryClient.removeQueries(queryKeys.inbox())
  }

  addResource(resource: TypedUrlResource | TypedFileResource): void {
    const inbox = this.find()

    if (!inbox) {
      return
    }

    this.queryClient.setQueryData<TypedInbox>(
      queryKeys.inbox(),
      (oldData: any) => {
        if (!oldData) {
          return
        }
        return {
          ...oldData,
          resourceList: [resource, ...oldData.resourceList],
        }
      },
    )
  }

  deleteResource(resourceId: string): void {
    const inbox = this.find()

    if (!inbox) {
      return
    }

    this.queryClient.setQueryData<TypedInbox>(
      queryKeys.inbox(),
      (oldData: any) => {
        const updated = oldData!.resourceList.filter(
          (resource: TypedFileResource | TypedUrlResource) =>
            resource.resourceId !== resourceId,
        )

        return {
          ...oldData,
          resourceList: updated,
        }
      },
    )
  }
}
