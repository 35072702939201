interface TooltipText {
  imageViewer: {
    'zoom in': string
    'zoom out': string
    'rotate left': string
    'rotate right': string
    reset: string
    copy: string
    copied: string
    removeFromFavorite: string
    addToFavorite: string
  }
}

const englishTooltipText: TooltipText = {
  imageViewer: {
    'zoom in': 'zoom in',
    'zoom out': 'zoom out',
    'rotate left': 'rotate left',
    'rotate right': 'rotate right',
    reset: 'reset',
    copy: 'copy',
    copied: 'copied!',
    removeFromFavorite: 'Remove from Favorites',
    addToFavorite: 'Add to Favorites',
  },
}

const koreanTooltipText: TooltipText = {
  imageViewer: {
    'zoom in': '확대',
    'zoom out': '축소',
    'rotate left': '왼쪽으로 회전',
    'rotate right': '오른쪽으로 회전',
    reset: '초기화',
    copy: '복사',
    copied: '복사 완료!',
    removeFromFavorite: '즐겨찾기 지우기',
    addToFavorite: '즐겨찾기에 추가',
  },
}

export { englishTooltipText, koreanTooltipText }
