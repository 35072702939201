import { AxiosInstance } from 'axios'
import autoBind from '@/utils/autoBind'
import TypedResource, { ITypedResource } from '@/models/resource/TypedResource'
import { getResourceInstanceByJson } from '@/factories/getResourceInstanceByJson'

class RecommendAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getRecommendResources(docId: string): Promise<TypedResource[]> {
    const response = await this.axios({
      method: 'get',
      url: '/api/recommend/resource',
      params: {
        docId,
      },
    })

    return response.data.data.map((resource: ITypedResource) =>
      getResourceInstanceByJson(resource),
    )
  }
}

export default autoBind(RecommendAPI)
