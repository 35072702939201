type CitationTextKey = 'Search' | 'No match items' | 'Close' | 'Delete'

export const englishCitationText: Record<CitationTextKey, string> = {
  Search: 'Search references',
  Close: 'Close',
  Delete: 'Delete',
  'No match items': 'No matches found',
}

export const koreanCitationText: Record<CitationTextKey, string> = {
  Search: '참조할 자료를 검색해보세요',
  Close: '닫기',
  Delete: '삭제',
  'No match items': '일치하는 항목 없음',
}
