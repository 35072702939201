import {
  INotification,
  Notification,
  NotificationType,
} from '@/models/notification'
import { TypedDocumentType } from '@/models/document/TypedDocument'
import { ITypedUser } from '@/models/user'

export interface IReviewCheckedNotification extends INotification {
  documentId: string
  documentType: TypedDocumentType
  documentTitle: string
  reviewMessage: string
  checker: Omit<ITypedUser, 'userId'>
  createdAt: Date
}

class ReviewCheckedNotification
  extends Notification
  implements IReviewCheckedNotification
{
  constructor(
    readonly id: string,
    readonly type: NotificationType,
    readonly checked: boolean,
    readonly spaceId: string,
    readonly documentId: string,
    readonly documentType: TypedDocumentType,
    readonly documentTitle: string,
    readonly reviewMessage: string,
    readonly checker: Omit<ITypedUser, 'userId'>,
    readonly createdAt: Date,
  ) {
    super(id, type, checked, spaceId)
  }

  static fromJSON(json: IReviewCheckedNotification): ReviewCheckedNotification {
    return new ReviewCheckedNotification(
      json.id,
      json.type,
      json.checked,
      json.spaceId,
      json.documentId,
      json.documentType,
      json.documentTitle,
      json.reviewMessage,
      json.checker,
      new Date(json.createdAt),
    )
  }

  copyWith(
    options?: Partial<IReviewCheckedNotification>,
  ): ReviewCheckedNotification {
    return new ReviewCheckedNotification(
      options?.id ?? this.id,
      options?.type ?? this.type,
      options?.checked ?? this.checked,
      options?.spaceId ?? this.spaceId,
      options?.documentId ?? this.documentId,
      options?.documentType ?? this.documentType,
      options?.documentTitle ?? this.documentTitle,
      options?.reviewMessage ?? this.reviewMessage,
      options?.checker ?? this.checker,
      options?.createdAt ?? this.createdAt,
    )
  }
}

export default ReviewCheckedNotification
