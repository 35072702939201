import { TypedDocumentType } from '@/models/document/TypedDocument'
import autoBind from '@/utils/autoBind'

export enum SpaceMigrationResultStatus {
  'active' = 'active',
  'complete' = 'complete',
  'fail' = 'fail',
}

export enum MigrationStatus {
  'REFETCH_REQUIRED' = 'REFETCH_REQUIRED',
  'FAILED' = 'FAILED',
  'COMPLETED' = 'COMPLETED',
}

export interface ISpaceMigrationFailData {
  id: string
  name: string
  status: number
  docType: TypedDocumentType
}

export interface ISpaceMigrationResult {
  checked: boolean // migration failed 문서가 있을 때, 이 문서를 확인했는지 안했는지 체크하기 위한 값
  message: string
  status: SpaceMigrationResultStatus
  progress: number
  failCount: number
  failData: ISpaceMigrationFailData[]
}

export interface ITypedSpaceMigrationResult extends ISpaceMigrationResult {
  getMigrationStatus: () => MigrationStatus
}

class TypedSpaceMigrationResult implements ITypedSpaceMigrationResult {
  constructor(
    readonly checked: boolean,
    readonly message: string,
    readonly status: SpaceMigrationResultStatus,
    readonly progress: number,
    readonly failCount: number,
    readonly failData: ISpaceMigrationFailData[],
  ) {}

  getMigrationStatus() {
    if (
      this.status === SpaceMigrationResultStatus.active ||
      (this.status === SpaceMigrationResultStatus.fail && this.failCount < 3)
    ) {
      return MigrationStatus.REFETCH_REQUIRED
    }

    if (this.status == SpaceMigrationResultStatus.complete) {
      return MigrationStatus.COMPLETED
    }

    if (
      this.status === SpaceMigrationResultStatus.fail &&
      this.failCount >= 3
    ) {
      return MigrationStatus.FAILED
    }

    throw new Error(`Can't get migration status`)
  }

  static fromJSON(data: ISpaceMigrationResult) {
    return new TypedSpaceMigrationResult(
      data.checked,
      data.message,
      data.status,
      data.progress,
      data.failCount,
      data.failData,
    )
  }
}

export default autoBind(TypedSpaceMigrationResult)
