interface TemplateText {
  referencesCount: (count: number) => string
  templatePageDescription1: string
  templatePageDescription2: string
}

const englishTemplateText: TemplateText = {
  referencesCount: (count: number) => `${count} References`,
  templatePageDescription1:
    'Boost your productivity by starting off with a Template!',
  templatePageDescription2:
    'Typed Template is not only a helpful guide but a quality source of reference.',
}

const koreanTemplateText: TemplateText = {
  referencesCount: (count: number) => `참고자료 ${count}개`,
  templatePageDescription1: '성공한 스타트업이 되기 위한 템플릿.',
  templatePageDescription2: '다양한 참고자료가 포함된 템플릿들을 활용해보세요.',
}

export { englishTemplateText, koreanTemplateText }
