import DisplayError from '@/errors/display'
import { TEXTS_WITH_LOCALE } from '@/locale/locale.util'

class InputError extends DisplayError {
  constructor(
    readonly name = 'InputError',
    koMessage: string,
    enMessage: string,
  ) {
    super(name, koMessage, enMessage)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class InvalidFieldError extends InputError {
  constructor(
    readonly name = 'InvalidFieldError',
    koMessage: string,
    enMessage: string,
  ) {
    super(name, koMessage, enMessage)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export class InvalidEmptyFieldError extends InvalidFieldError {
  constructor() {
    super(
      'InvalidEmptyFieldError',
      TEXTS_WITH_LOCALE.ko.modal.spaceCreate.invalidEmptyNameError,
      TEXTS_WITH_LOCALE.en.modal.spaceCreate.invalidEmptyNameError,
    )
    Object.setPrototypeOf(this, new.target.prototype)
  }
}
