interface ViewerText {
  readBadge: string
}

const englishViewerText: ViewerText = {
  readBadge: 'Read',
}

const koreanViewerText: ViewerText = {
  readBadge: '읽음',
}

export { englishViewerText, koreanViewerText }
