interface FolderText {
  EmptySectionMessage: string
  Delete: string
  DefaultName: string
  Recommended: string
  Section: string
}

const englishFolderText: FolderText = {
  EmptySectionMessage: 'This section is empty.',
  Delete: 'Delete',
  DefaultName: 'Unnamed',
  Recommended: 'Recommended',
  Section: 'Section',
}

const koreanFolderText: FolderText = {
  EmptySectionMessage: '이 섹션은 비어있어요.',
  Delete: '삭제',
  DefaultName: '새 섹션',
  Recommended: '추천 자료',
  Section: '섹션',
}

export { englishFolderText, koreanFolderText }
