import { AxiosInstance } from 'axios'
import TypedTemplate, {
  ITypedTemplate,
  TypedTemplateDTO,
} from '@/models/template'
import autoBind from '@/utils/autoBind'
import { ITypedDocument, TypedDocument } from '@/models/document/TypedDocument'
import { getDocumentInstanceByJson } from '@/factories/getDocumentInstanceByJson'
import { SUPPORTED_LANGUAGES } from '@/locale/locale.util'

class TemplateAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getAllTemplatesByLanguage(
    language: SUPPORTED_LANGUAGES,
  ): Promise<ITypedTemplate[]> {
    const { data } = await this.axios.get<{
      data: { templates: TypedTemplateDTO[] }
    }>('/api/template', {
      params: { languageType: language.toUpperCase() },
    })

    const templateDTOs = data.data.templates
    return templateDTOs.map(TypedTemplate.fromDTO)
  }

  async createDocumentFromTemplate(
    projectId: string,
    templateId: string,
  ): Promise<TypedDocument> {
    const { data } = await this.axios.post<{
      data: { resource: ITypedDocument }
    }>('/api/template/create-resource', { projectId, templateId })

    return getDocumentInstanceByJson(data.data.resource)
  }
}

export default autoBind(TemplateAPI)
