import axios, { AxiosInstance } from 'axios'
import { TypedUser } from '@/models/user'
import { isProduction } from '@/utils/environment'
import autoBind from '@/utils/autoBind'

class UserAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getUserProfile(): Promise<TypedUser> {
    const response = await this.axios({
      method: 'get',
      url: '/api/user',
    })

    return TypedUser.fromJSON(response.data)
  }

  async setUserDisplayName(displayName: string): Promise<TypedUser> {
    const response = await this.axios({
      method: 'patch',
      url: '/api/user/displayName',
      data: {
        displayName,
      },
    })

    return TypedUser.fromJSON(response.data)
  }

  async setUserPhoto(file: File): Promise<TypedUser> {
    const form = buildUserImageFormData({ file })

    const result = await this.axios.patch('/api/user/photo', form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })

    return TypedUser.fromJSON(result.data)
  }

  async updateUserOriginPhotoUrl(originalPhotoUrl: string): Promise<void> {
    await this.axios({
      method: 'patch',
      url: '/api/user/photo',
      data: {
        originalPhotoUrl,
      },
    })
  }

  async setIcp(data: any): Promise<any> {
    const url = isProduction()
      ? 'https://asia-northeast3-typed-app.cloudfunctions.net/postIcp'
      : 'https://asia-northeast3-typed-app-staging.cloudfunctions.net/postIcp'
    const res = await axios.post(url, data)

    return res.data
  }

  async checkValidUserIcp(email: string): Promise<{ exists: boolean }> {
    try {
      const url = isProduction()
        ? 'https://asia-northeast3-typed-app.cloudfunctions.net/validUserIcp'
        : 'https://asia-northeast3-typed-app-staging.cloudfunctions.net/validUserIcp' // TODO: staging function is deprecated
      const res = await axios.get(`${url}?email=${email}`)
      return res.data
    } catch (e) {
      return { exists: false }
    }
  }
}

const buildUserImageFormData = ({ file }: { file: File }) => {
  const formData = new FormData()
  formData.append('file', file)
  return formData
}

export default autoBind(UserAPI)
