import { Store } from 'redux'
import { isEmpty } from 'lodash-es'
import FolderAPI from '@/api/folderAPI'
import ResourceAPI from '@/api/resourceAPI'
import { getFileMD5Hash } from '@/api/util'
import { IResourceDuplicateInfo } from '@/models/resource/TypedResource'
import FolderRepository from '@/repositories/folderRepository'
import { RootState } from '@/store'
import autoBind from '@/utils/autoBind'
import {
  addResourceDuplicateInfoItems,
  setShowDuplicateModal,
  setShowLinkSucceededModal,
} from '@/slices/common/duplicateResourceSlice'
import { FolderInfo } from '@/models/folder'

export enum ResourceDestination {
  INBOX = 'inbox',
  DOCUMENT = 'document',
}

class DuplicateService {
  constructor(
    private readonly store: Store<RootState>,
    private readonly resourceAPI: InstanceType<typeof ResourceAPI>,
    private readonly folderAPI: InstanceType<typeof FolderAPI>,
    private readonly folderRepo: InstanceType<typeof FolderRepository>,
  ) {}

  async getUrlResourceDuplicateInfo(
    destination: `${ResourceDestination}`,
    url: string,
    spaceId?: string,
  ): Promise<IResourceDuplicateInfo> {
    if (destination === ResourceDestination.INBOX) {
      return await this.resourceAPI.getInboxUrlResourceDuplicateInfo(url)
    }

    return await this.resourceAPI.getSpaceUrlResourceDuplicateInfo(url, spaceId)
  }

  async groupFilesByDuplicateInfo(
    destination: `${ResourceDestination}`,
    files: File[],
    spaceId?: string,
  ): Promise<{
    filesDuplicateInfo: IResourceDuplicateInfo[]
    notDuplicateFiles: File[]
  }> {
    const filesDuplicateInfo: IResourceDuplicateInfo[] = []
    const notDuplicateFiles: File[] = []

    files = Array.from(files)

    await Promise.all(
      files.map(async (file) => {
        const addedMD5HashFile = await getFileMD5Hash(file)

        let fileDuplicateInfo
        if (destination === ResourceDestination.INBOX) {
          fileDuplicateInfo =
            await this.resourceAPI.getInboxFileResourceDuplicateInfo(
              addedMD5HashFile.md5Hash,
            )
        } else {
          fileDuplicateInfo =
            await this.resourceAPI.getSpaceFileResourceDuplicateInfo(
              addedMD5HashFile.md5Hash,
              spaceId,
            )
        }

        if (isEmpty(fileDuplicateInfo)) {
          notDuplicateFiles.push(file)
        } else {
          filesDuplicateInfo.push(fileDuplicateInfo)
        }
      }),
    )

    return {
      filesDuplicateInfo,
      notDuplicateFiles,
    }
  }

  async moveAndLinkResource(
    targetFolderId: string,
    targetDocumentId: string,
    spaceId: string,
  ): Promise<void> {
    const resourcesDuplicateInfo =
      this.store.getState().duplicateResource.resourcesDuplicateInfo
    for (const resourceDuplicateInfo of resourcesDuplicateInfo) {
      const targetFolder = this.folderRepo.find(targetFolderId)

      const duplicateResourceIdsFromSourceFolders =
        resourceDuplicateInfo.sourceFolders.map((folder) => folder.resourceId)

      const isDuplicateResourceInTargetFolder = targetFolder?.resourceList.some(
        (resourceId) =>
          duplicateResourceIdsFromSourceFolders.includes(resourceId),
      )

      const duplicateFolderInSameDocument =
        resourceDuplicateInfo.sourceFolders.find(
          (folder) =>
            folder.documentData.documentId === targetDocumentId &&
            folder.folderId !== targetFolderId,
        )

      if (isDuplicateResourceInTargetFolder) {
        return
      }

      if (duplicateFolderInSameDocument) {
        await this.resourceAPI.moveToFolder(
          duplicateFolderInSameDocument.resourceId,
          duplicateFolderInSameDocument.folderId,
          targetFolderId,
          spaceId,
        )
        const updatedSourceFolderData = await this.folderAPI.getSpecificFolder(
          duplicateFolderInSameDocument.folderId,
          spaceId,
        )
        this.folderRepo.update(
          updatedSourceFolderData.folderId,
          updatedSourceFolderData,
        )
      } else {
        await this.resourceAPI.linkResource(
          resourceDuplicateInfo.sourceFolders[0].resourceId,
          targetFolderId,
          targetDocumentId,
          spaceId,
        )
      }
      const updatedTargetFolderData = await this.folderAPI.getSpecificFolder(
        targetFolderId,
        spaceId,
      )

      this.folderRepo.update(
        updatedTargetFolderData.folderId,
        updatedTargetFolderData,
      )
    }
  }

  async addNonDuplicateUrlResource(
    targetFolder: FolderInfo,
    panelType: `${ResourceDestination}`,
    url: string,
    spaceId: string,
  ): Promise<boolean> {
    const duplicate = await this.getUrlResourceDuplicateInfo(
      panelType,
      url,
      spaceId,
    )
    if (!isEmpty(duplicate)) {
      this.store.dispatch(
        addResourceDuplicateInfoItems({ resourcesDuplicateInfo: [duplicate] }),
      )

      if (panelType === 'inbox') {
        this.store.dispatch(setShowDuplicateModal(true))
      } else {
        await this.moveAndLinkResource(
          targetFolder.folderId,
          targetFolder.documentId,
          spaceId,
        )
        this.store.dispatch(setShowLinkSucceededModal(true))
      }
      return true
    }
    return false
  }

  async addNonDuplicateFileResources(
    targetFolder: FolderInfo,
    panelType: `${ResourceDestination}`,
    files: File[],
    spaceId: string,
  ): Promise<File[]> {
    const { filesDuplicateInfo, notDuplicateFiles } =
      await this.groupFilesByDuplicateInfo(panelType, files, spaceId)

    if (!isEmpty(filesDuplicateInfo)) {
      this.store.dispatch(
        addResourceDuplicateInfoItems({
          resourcesDuplicateInfo: filesDuplicateInfo,
        }),
      )
      if (panelType === 'inbox') {
        this.store.dispatch(setShowDuplicateModal(true))
      } else {
        await this.moveAndLinkResource(
          targetFolder.folderId,
          targetFolder.documentId,
          spaceId,
        )
        this.store.dispatch(setShowLinkSucceededModal(true))
      }
    }

    return notDuplicateFiles
  }
}

export default autoBind(DuplicateService)
