import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IResourceDuplicateInfo } from '@/models/resource/TypedResource'

interface DuplicateAction {
  resourcesDuplicateInfo: IResourceDuplicateInfo[]
}

interface DuplicateState {
  resourcesDuplicateInfo: IResourceDuplicateInfo[]
  showDuplicateModal: boolean
  showLinkSucceededModal: boolean
}

const initialState: DuplicateState = {
  resourcesDuplicateInfo: [],
  showDuplicateModal: false,
  showLinkSucceededModal: false,
}

export const duplicateResourceSlice = createSlice({
  name: 'duplicateResource',
  initialState,
  reducers: {
    addResourceDuplicateInfoItems: (
      state: DuplicateState,
      action: PayloadAction<DuplicateAction>,
    ) => {
      const { resourcesDuplicateInfo } = action.payload
      state.resourcesDuplicateInfo = resourcesDuplicateInfo
    },
    setShowDuplicateModal: (
      state: DuplicateState,
      action: PayloadAction<boolean>,
    ) => {
      state.showDuplicateModal = action.payload
    },
    setShowLinkSucceededModal: (
      state: DuplicateState,
      action: PayloadAction<boolean>,
    ) => {
      state.showLinkSucceededModal = action.payload
    },
    clearDuplicateResourceItems: (state: DuplicateState) => {
      state.resourcesDuplicateInfo = []
    },
  },
})

export const {
  addResourceDuplicateInfoItems,
  clearDuplicateResourceItems,
  setShowDuplicateModal,
  setShowLinkSucceededModal,
} = duplicateResourceSlice.actions

export default duplicateResourceSlice.reducer
