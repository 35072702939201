import { Store } from 'redux'
import autoBind from '@/utils/autoBind'
import RequestingStorage, {
  IRequestingStorage,
} from '@/models/requestingStorage'
import { RootState } from '@/store'
import { RequestingDataTypeMap, RequestingType } from '@/slices/requestingSlice'

export type IRequestingService = {
  [type in RequestingType]: IRequestingStorage<RequestingDataTypeMap[type]>
}

class RequestingService {
  newDocument: RequestingStorage<
    RequestingDataTypeMap[RequestingType.newDocument]
  >
  documentsFromGoogleDrive: RequestingStorage<
    RequestingDataTypeMap[RequestingType.documentsFromGoogleDrive]
  >
  libraryPanelResources: RequestingStorage<
    RequestingDataTypeMap[RequestingType.libraryPanelResources]
  >
  inboxResources: RequestingStorage<
    RequestingDataTypeMap[RequestingType.inboxResources]
  >

  constructor(store: Store<RootState>) {
    this.newDocument = new RequestingStorage(store, RequestingType.newDocument)
    this.documentsFromGoogleDrive = new RequestingStorage(
      store,
      RequestingType.documentsFromGoogleDrive,
    )
    this.libraryPanelResources = new RequestingStorage(
      store,
      RequestingType.libraryPanelResources,
    )
    this.inboxResources = new RequestingStorage(
      store,
      RequestingType.inboxResources,
    )
  }
}

export default autoBind(RequestingService)
