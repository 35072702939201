const INTERCOM_COMMAND = {
  EVENT: 'trackEvent',
} as const

const RESOURCE_EVENT = {
  CREATED: 'resource_created',
} as const

const DOCUMENT_EVENT = {
  CREATED: 'document_created',
} as const

const PAGE_EVENT = {
  SPLIT_VIEWER_VIEWED: 'split_viewer_viewed',
} as const

type INTERCOM_COMMAND = typeof INTERCOM_COMMAND[keyof typeof INTERCOM_COMMAND]
type RESOURCE_EVENT = typeof RESOURCE_EVENT[keyof typeof RESOURCE_EVENT]
type DOCUMENT_EVENT = typeof DOCUMENT_EVENT[keyof typeof DOCUMENT_EVENT]
type PAGE_EVENT = typeof PAGE_EVENT[keyof typeof PAGE_EVENT]

export { INTERCOM_COMMAND, RESOURCE_EVENT, DOCUMENT_EVENT, PAGE_EVENT }
