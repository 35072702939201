import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SortFilterType } from '@/models/SortFilter'
import { DocumentFilterType } from '@/models/resource/ResourceFilter'

export enum AppHomeSearchTab {
  DOCUMENTS = 'Documents',
  REFERENCES = 'References',
  PROJECTS = 'Projects',
}

const initialState: {
  searchWord: string
  isSearching: boolean
  isNextPaginationSearching: boolean
  isErrorInSearching: boolean
  selectedDocumentFilter?: DocumentFilterType
  selectedOwnerUserId?: string
  selectedSortFilter: SortFilterType
  selectedSearchTab: AppHomeSearchTab
} = {
  searchWord: '',
  isSearching: false,
  isNextPaginationSearching: false,
  isErrorInSearching: false,
  selectedDocumentFilter: undefined,
  selectedOwnerUserId: undefined,
  selectedSortFilter: 'default',
  selectedSearchTab: AppHomeSearchTab.DOCUMENTS,
}

export const appHomeSearchSlice = createSlice({
  name: 'apphome-search',
  initialState,
  reducers: {
    setAppHomeSearchWord: (state, action: PayloadAction<string>) => {
      state.searchWord = action.payload
    },
    setAppHomeDocumentFilterType: (
      state,
      action: PayloadAction<DocumentFilterType | undefined>,
    ) => {
      state.selectedDocumentFilter = action.payload
      if (!action.payload) return
      state.selectedSearchTab = AppHomeSearchTab.DOCUMENTS
    },
    setAppHomeSelectedOwnerUserId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.selectedOwnerUserId = action.payload
    },
    setAppHomeSelectedSortFilter: (
      state,
      action: PayloadAction<SortFilterType>,
    ) => {
      state.selectedSortFilter = action.payload
    },
    setAppHomeLibrarySearchTab: (
      state,
      action: PayloadAction<AppHomeSearchTab>,
    ) => {
      state.selectedSearchTab = action.payload
    },
    resetAppHomeSearchStore: () => initialState,
  },
})

export const {
  setAppHomeSearchWord,
  resetAppHomeSearchStore,
  setAppHomeDocumentFilterType,
  setAppHomeSelectedOwnerUserId,
  setAppHomeSelectedSortFilter,
  setAppHomeLibrarySearchTab,
} = appHomeSearchSlice.actions

export default appHomeSearchSlice.reducer
