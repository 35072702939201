import _axios, { AxiosInstance } from 'axios'
import { SubscriptionStatus } from '@/models/subscription'
import autoBind from '@/utils/autoBind'
import errors from '@/errors'
import { StripePromotionCode } from '@/models/stripe'
import PaymentMethod, { IPaymentMethodDTO } from '@/models/paymentMethod'

interface ICustomerResult {
  customerId: string
  userId: string
}

interface ICheckoutResult {
  id: string // invoice id
  amount: number // amount as cents
  priceId: string
  subscriptionItemId: string
  spaceId: string
  customerId: string
  period: { start: string; end: string } // ISO date string
  status: `${SubscriptionStatus}`
}

class SubscriptionAPI {
  constructor(readonly axios: AxiosInstance) {}

  async createCustomer(): Promise<ICustomerResult> {
    const { data } = await this.axios({
      method: 'post',
      url: '/customer/stripe',
    })
    return data
  }

  async checkout(
    priceId: string,
    spaceId: string,
    paymentId: string,
    coupon?: string,
  ): Promise<ICheckoutResult> {
    const { data } = await this.axios({
      method: 'post',
      url: '/checkout',
      data: { priceId, spaceId, paymentId, coupon },
    })
    return data
  }

  async getPromotionCodeDetails(
    code: string,
    customerId: string,
    productId: string,
  ): Promise<StripePromotionCode | undefined> {
    try {
      const { data } = await this.axios({
        method: 'get',
        url: '/promo',
        params: { code, customerId, productId },
      })
      return StripePromotionCode.fromJson(data)
    } catch (err) {
      if (_axios.isAxiosError(err) && err.response?.status === 404) {
        throw new errors.api.StripePromotionCodeNotFoundError()
      }
      throw err
    }
  }

  async cancelSubscription(spaceId: string): Promise<void> {
    await this.axios({
      method: 'post',
      url: '/cancel',
      data: { spaceId },
    })
  }

  async createCustomerPortalSession(redirectUrl: string) {
    const { data } = await this.axios.post<string>(
      '/customer/stripe/create-customer-portal-session',
      { redirectUrl },
    )
    return data
  }

  async getPaymentMethod(spaceId: string) {
    const { data } = await this.axios.get<IPaymentMethodDTO>(
      `/space/${spaceId}/payment`,
    )
    if (!data.card) {
      return undefined
    }

    return new PaymentMethod(data.card.brand, data.card.last4)
  }
}

export default autoBind(SubscriptionAPI)
