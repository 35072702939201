import { OTHER_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class OtherEvent extends TypedEvent {
  extensionAddButtonClickedEvent() {
    this.sendEvent(OTHER_EVENT.EXTENSION_ADD_BUTTON_CLICKED)
  }

  addDocumentButtonClickedEvent() {
    this.sendEvent(OTHER_EVENT.ADD_DOCUMENT_BUTTON_CLICKED)
  }

  backlinkOtherLibraryClickedEvent() {
    this.sendEvent(OTHER_EVENT.BACKLINK_OTHER_LIBRARY_CLICKED)
  }

  resourceLibraryLockButtonClickedEvent() {
    this.sendEvent(OTHER_EVENT.RESOURCE_LIBRARY_LOCK_BUTTON_CLICKED)
  }

  resourceLibraryUnlockButtonClickedEvent() {
    this.sendEvent(OTHER_EVENT.RESOURCE_LIBRARY_UNLOCK_BUTTON_CLICKED)
  }

  openedInDriveButtonClickedEvent(data: any) {
    this.sendEvent(OTHER_EVENT.OPENED_IN_DRIVE_BUTTON_CLICKED, {
      anchorResourceId: data.documentId,
    })
  }

  addNewButtonClickedEvent(data: any) {
    this.sendEvent(OTHER_EVENT.ADD_NEW_BUTTON_CLICKED, {
      type: data.type,
    })
  }

  emptyFolderAddResourceButtonClickedEvent() {
    this.sendEvent(OTHER_EVENT.EMPTY_FOLDER_ADD_RESOURCE_BUTTON_CLICKED)
  }

  libraryPanelButtonClickedEvent(type: string) {
    this.sendEvent(OTHER_EVENT.LIBRARY_PANEL_BUTTON_CLICKED, {
      type,
    })
  }

  documentPageChangedEvent(data: any) {
    this.sendEvent(OTHER_EVENT.DOCUMENT_PAGE_CHANGED, {
      changeType: data.changeType,
    })
  }

  documentPageAddDocumentButtonClickedEvent() {
    this.sendEvent(OTHER_EVENT.WORKSPACE_ADD_DOCUMENT_BUTTON_CLICKED)
  }

  signUpWithGoogleButtonClickedEvent() {
    this.sendEvent(OTHER_EVENT.SIGN_UP_WITH_GOOGLE_BUTTON_CLICKED)
  }

  signInWithGoogleButtonClickedEvent() {
    this.sendEvent(OTHER_EVENT.SIGN_IN_WITH_GOOGLE_BUTTON_CLICKED)
  }
}
