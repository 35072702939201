import { HOMEPAGE_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class HomepageEvent extends TypedEvent {
  homepageViewed(eventFrom: HOMEPAGE_EVENT) {
    this.sendEvent(eventFrom)
  }
}
