import axios, { AxiosInstance, AxiosResponse } from 'axios'
import TypedResource, {
  IResourceDuplicateInfo,
} from '@/models/resource/TypedResource'
import { TypedTextResource } from '@/models/resource/TypedTextResource'
import { TypedDocumentResource } from '@/models/resource/TypedDocumentResource'
import { TypedFileResource } from '@/models/resource/TypedFileResource'
import { BulkSelectedItem } from '@/slices/bulkActions/bulkActionSlice'
import { isInboxId } from '@/util'
import { isProduction } from '@/utils/environment'
import autoBind from '@/utils/autoBind'
import { TypedDocument } from '@/models/document/TypedDocument'

import { GoogleDocumentType } from '@/models/document/TypedGoogleDocument'
import { GoogleDocumentObject } from '@/api/documentAPI'
import { getDocumentInstanceByJson } from '@/factories/getDocumentInstanceByJson'
import TypedUrlResource from '@/models/resource/TypedUrlResource'
import { ResourceTag } from '@/models/resource/ResourceTag'
import { getResourceInstanceByJson } from '@/factories/getResourceInstanceByJson'

interface ItemFromOtherFolders {
  resourceId: string
  folderId: string
  data: any
}

class ResourceAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getSpecificResource(
    resourceId: string,
  ): Promise<
    | TypedUrlResource
    | TypedTextResource
    | TypedDocumentResource
    | TypedFileResource
  > {
    const response = await this.axios({
      method: 'get',
      url: `/api/resources/${resourceId}`,
    })

    return getResourceInstanceByJson(response.data)
  }

  async getResource(resourceId: string): Promise<TypedResource> {
    const response = await this.axios({
      method: 'get',
      url: `/api/resources/${resourceId}`,
      params: {
        include: 'hasWorkspace',
      },
    })

    return response.data
  }

  async getResourcesByIds(resourceIds: string[]): Promise<TypedResource[]> {
    const response = await this.axios({
      method: 'get',
      url: '/api/resources',
      params: {
        ids: resourceIds,
        include: 'hasWorkspace',
      },
    })

    return response.data.map(getResourceInstanceByJson)
  }

  async getResources(folderId: string): Promise<TypedResource[]> {
    const response = await this.axios({
      method: 'get',
      url: '/api/resources',
      params: {
        folderId,
        include: 'hasWorkspace',
      },
    })

    return response.data.map(getResourceInstanceByJson)
  }

  async getBacklinks(resourceId: string): Promise<TypedDocument[]> {
    const response = await this.axios({
      method: 'get',
      url: `/api/resources/${resourceId}/backlinks`,
      params: {
        include: 'hasWorkspace',
      },
    })
    return response.data.map(getDocumentInstanceByJson)
  }

  async addDocumentNode(
    title: string,
    docType: GoogleDocumentType,
    target: { folderId?: string; documentId: string },
  ) {
    const { folderId, documentId } = target

    const response = await this.axios({
      method: 'post',
      url: '/api/docs/sub-document',
      data: { newDocumentData: { docType, title, documentId, folderId } },
    })

    return TypedDocumentResource.fromJSON(response.data)
  }

  async addUrlNode(url: string, folderId: string): Promise<TypedUrlResource> {
    const response = await this.axios({
      method: 'post',
      url: '/api/resources',
      data: {
        type: 'url',
        folderId,
        data: { url },
      },
    })

    return TypedUrlResource.fromJSON(response.data)
  }

  async addTextNode(
    { title, body }: { title: string; body: string },
    folderId: string,
  ): Promise<TypedTextResource> {
    const response = await this.axios({
      method: 'post',
      url: '/api/resources',
      data: {
        type: 'text',
        folderId: folderId,
        data: { name: title, title, body },
      },
    })

    return TypedTextResource.fromJSON(response.data)
  }

  async addFileNodes({
    file,
    folderId,
  }: {
    file: File
    folderId: string
  }): Promise<TypedFileResource> {
    const form = buildFileFormDataWithFolderId({
      file,
      folderId,
    })
    const response = await this.axios.post('/api/resources', form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })

    return TypedFileResource.fromJSON(response.data)
  }

  async addResourcesV2ByFiles({
    folderId,
    files,
  }: {
    folderId: string
    files: File[]
  }): Promise<TypedResource[]> {
    const form = buildFilesFormDataWithFolderId({ folderId, files })
    const response = await this.axios.post('/api/v2/resources', form, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })

    return response.data.map(getResourceInstanceByJson)
  }

  async addResourcesV2ByGoogleDocObject(
    googleDocObjs: GoogleDocumentObject[],
    target: { documentId?: string; folderId?: string },
  ): Promise<TypedResource[]> {
    const { documentId, folderId } = target

    const response = await this.axios({
      method: 'post',
      url: `/api/v2/resources`,
      data: {
        files: googleDocObjs,
        folderId,
        documentId,
      },
    })

    return response.data.map(getResourceInstanceByJson)
  }

  async addResourceByGooglePicker(
    googleDocObj: GoogleDocumentObject[],
    importLocation: 'drive' | 'local',
    target: { documentId?: string; folderId?: string },
  ): Promise<TypedResource[]> {
    const { documentId, folderId } = target
    const requestData = {
      files: googleDocObj,
      documentId,
      folderId,
      importType: importLocation,
    }

    const response = await this.axios({
      method: 'post',
      url: `/api/resources/drive`,
      data: requestData,
    })

    return response.data.map(getResourceInstanceByJson)
  }

  async renameResource(resourceId: string, changedName: string): Promise<void> {
    await this.axios({
      method: 'patch',
      url: `/api/resources/${resourceId}`,
      data: {
        name: changedName,
      },
    })
  }

  async editTextResource(data: {
    id: string
    title: string
    body: string
  }): Promise<void> {
    const title = data.title
    const body = data.body
    if (!body) {
      throw new Error('body must exists')
    }

    await this.axios({
      method: 'patch',
      url: `/api/resources/${data.id}`,
      data: {
        type: 'text',
        name: title,
        data: { body },
      },
    })
  }

  async deleteResource(
    curResourceId: string,
    curFolderId: string,
    curDocumentId?: string,
  ): Promise<void> {
    await this.axios({
      method: 'post',
      url: '/api/resources/actions/delete',
      data: [
        {
          resourceId: curResourceId,
          documentId: curDocumentId,
          folderId: curFolderId,
        },
      ],
    })
  }

  async moveToFolder(
    resourceId: string,
    sourceFolderId: string,
    targetFolderId: string,
    spaceId?: string,
  ): Promise<void> {
    if (isInboxId(targetFolderId)) return

    await this.axios({
      method: 'post',
      url: '/api/resources/actions/move',
      headers: spaceId ? { 'x-space-id': spaceId } : {},
      data: [
        {
          resourceId,
          sourceFolderId,
          targetFolderId,
        },
      ],
    })
  }

  async moveResourceFromInbox(
    resourceId: string,
    targetFolderId: string,
    spaceId: string,
  ): Promise<TypedResource> {
    const result = await this.axios({
      method: 'post',
      url: '/api/resources/actions/move/inbox',
      headers: { 'x-space-id': spaceId },
      data: {
        resourceId,
        targetFolderId,
      },
    })
    return result.data as TypedResource
  }

  async bulkMoveToFolder(
    itemsFromOtherFolders: ItemFromOtherFolders[],
    targetFolderId: string,
  ): Promise<void> {
    if (isInboxId(targetFolderId)) return
    const data = itemsFromOtherFolders.map((item) => ({
      resourceId: item.resourceId,
      sourceFolderId: item.folderId,
      targetFolderId,
    }))

    await this.axios({
      method: 'post',
      url: '/api/resources/actions/move',
      data,
    })
  }

  async getInboxUrlResourceDuplicateInfo(
    url: string,
  ): Promise<IResourceDuplicateInfo> {
    const result = await this.axios({
      method: 'get',
      url: '/api/resources/duplicate/inbox',
      params: {
        type: 'url',
        subject: url,
      },
    })

    return result.data as IResourceDuplicateInfo
  }

  async getInboxFileResourceDuplicateInfo(
    md5Hash: string,
  ): Promise<IResourceDuplicateInfo> {
    const result = await this.axios({
      method: 'get',
      url: '/api/resources/duplicate/inbox',
      params: {
        type: 'file',
        subject: md5Hash,
      },
    })

    return result.data as IResourceDuplicateInfo
  }

  async getSpaceUrlResourceDuplicateInfo(
    url: string,
    spaceId?: string,
  ): Promise<IResourceDuplicateInfo> {
    const result = await this.axios({
      method: 'get',
      url: '/api/resources/duplicate/workspace',
      headers: { 'x-space-id': spaceId },
      params: {
        type: 'url',
        subject: url,
      },
    })

    return result.data as IResourceDuplicateInfo
  }

  async getSpaceFileResourceDuplicateInfo(
    md5Hash: string,
    spaceId?: string,
  ): Promise<IResourceDuplicateInfo> {
    const result = await this.axios({
      method: 'get',
      url: '/api/resources/duplicate/workspace',
      headers: { 'x-space-id': spaceId },
      params: {
        type: 'file',
        subject: md5Hash,
      },
    })

    return result.data as IResourceDuplicateInfo
  }

  async linkResource(
    curResourceId: string,
    targetFolderId: string,
    targetDocumentId: string,
    spaceId?: string,
  ): Promise<void> {
    await this.axios({
      method: 'post',
      url: '/api/resources/actions/link',
      headers: spaceId ? { 'x-space-id': spaceId } : {},
      data: [
        {
          resourceId: curResourceId,
          documentId: targetDocumentId,
          folderId: targetFolderId,
        },
      ],
    })
  }

  async bulkLinkResources(
    selectedItems: BulkSelectedItem[],
    targetFolderId: string,
    targetDocumentId: string,
  ): Promise<void> {
    if ([targetFolderId, targetDocumentId].includes('inbox')) return
    const data = selectedItems.map((item) => ({
      resourceId: item.resourceId,
      documentId: targetDocumentId,
      folderId: targetFolderId,
    }))

    await this.axios({
      method: 'post',
      url: '/api/resources/actions/link',
      data,
    })
  }

  async bulkDelete(selectedItems: BulkSelectedItem[]): Promise<void> {
    const data = selectedItems.map((item) => ({
      resourceId: item.resourceId,
      documentId: item.documentId,
      folderId: item.folderId,
    }))

    await this.axios({
      method: 'post',
      url: '/api/resources/actions/delete',
      data,
    })
  }

  async insertResources(
    targetFolderId: string,
    selectedItems: BulkSelectedItem[],
    pos: number,
  ): Promise<void> {
    const data = {
      pos,
      resources: selectedItems.map((item: any) => ({
        resourceId: item.resourceId,
        folderId: item.folderId,
      })),
    }

    await this.axios({
      method: 'post',
      url: `/api/folders/${targetFolderId}/actions/insert_resources`,
      data,
    })
  }

  async postReportUrl(data: {
    email: string
    resource: string
    message: string
    createdAt: string
  }): Promise<any> {
    const reportApiUrl = isProduction()
      ? 'https://asia-northeast3-typed-app.cloudfunctions.net/user-reports'
      : 'https://asia-northeast3-typed-app-staging.cloudfunctions.net/user-reports'

    const res = await axios.post(reportApiUrl, data)
    return res.data
  }

  async addTag(resourceId: string, tagName: string): Promise<ResourceTag> {
    const response = await this.axios({
      method: 'post',
      url: `/api/resources/tag?resourceId=${resourceId}&tag=${tagName}`,
    })
    return ResourceTag.fromJSON(response.data.data)
  }

  async deleteTag(tagId: string): Promise<AxiosResponse> {
    return await this.axios({
      method: 'delete',
      url: `/api/resources/tag?tagId=${tagId}`,
    })
  }

  async moveResourceToProject(
    resourceId: string,
    projectId: string,
  ): Promise<void> {
    await this.axios({
      method: 'post',
      url: `/api/resources/${resourceId}/convert`,
      data: {
        projectId,
        type: 'document',
      },
    })
  }
}

const buildFileFormDataWithFolderId = ({
  folderId,
  file,
}: {
  folderId: string
  file: File
}) => {
  const formData = new FormData()
  formData.append('folderId', folderId)
  formData.append('file', file)
  formData.append('type', 'file')

  return formData
}

const buildFilesFormDataWithFolderId = ({
  folderId,
  files,
}: {
  folderId: string
  files: File[]
}) => {
  const formData = new FormData()
  formData.append('folderId', folderId)
  formData.append('type', 'file')
  files.forEach((file) => {
    formData.append('files', file)
  })

  return formData
}

export default autoBind(ResourceAPI)
