import { IResourceTag, ResourceTag } from '@/models/resource/ResourceTag'
import TypedResource, {
  TypedResourceType,
  ITypedResource,
} from '@/models/resource/TypedResource'
import featureProps from '@/utils/featureProps/featureProps'
import isHtmlString from '@/utils/isHtmlString'
import { ITypedUser } from '@/models/user'

export class ITypedTextResourceData {
  readonly body: string
}

export interface ITypedTextResource extends ITypedResource {
  readonly data: ITypedTextResourceData
}

export class TypedTextResource
  extends TypedResource
  implements ITypedTextResource
{
  type = TypedResourceType.TEXT

  constructor(
    readonly resourceId: string,
    readonly backlinks: string[],
    readonly links: string[],
    readonly name: string,
    readonly createdAt: number,
    readonly createdBy: ITypedUser,
    readonly tags: ResourceTag[],
    readonly data: ITypedTextResourceData,
    readonly hasWorkspace: boolean,
  ) {
    super(
      resourceId,
      backlinks,
      links,
      name,
      createdAt,
      createdBy,
      tags,
      hasWorkspace,
    )
    this.data = { body: data.body }
  }

  get canBeDocument() {
    if (featureProps.isPageFoundationUsed()) return !this.hasWorkspace
    return false
  }

  get isTypedNote(): boolean {
    return isHtmlString(this.data.body)
  }

  static fromJSON(json: ITypedTextResource): TypedTextResource {
    return new TypedTextResource(
      json.resourceId,
      json.backlinks,
      json.links,
      json.name,
      json.createdAt,
      json.createdBy,
      json.tags?.map((tag: IResourceTag) => ResourceTag.fromJSON(tag)) || [],
      json.data,
      // TODO: remove '?? false' this after api updated
      json.hasWorkspace ?? false,
    )
  }

  copyWith(
    options?: Partial<Omit<TypedTextResource, 'type'>>,
  ): TypedTextResource {
    return new TypedTextResource(
      options?.resourceId ?? this.resourceId,
      options?.backlinks ?? this.backlinks,
      options?.links ?? this.links,
      options?.name ?? this.name,
      options?.createdAt ?? this.createdAt,
      options?.createdBy ?? this.createdBy,
      options?.tags ?? this.tags,
      options?.data ?? this.data,
      options?.hasWorkspace ?? this.hasWorkspace,
    )
  }

  toJSON(): ITypedTextResource {
    return {
      ...super.toJSON(),
      data: this.data,
    }
  }
}
