import { createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { ITypedHighlight } from '@/models/highlight'
import { IHighlightTool, ISelection } from '@/utils/viewers/highlightTool.util'

interface IHighlightsSlice extends EntityState<ITypedHighlight> {
  activeHighlightId: string | null
  selectionData: ISelection | null
  toolData: IHighlightTool | null
}

const highlightsSlice = createSlice({
  name: 'highlights',
  initialState: {
    activeHighlightId: null,
    selectionData: null,
    toolData: null,
  } as IHighlightsSlice,
  reducers: {
    setActiveHighlightId(state, action: PayloadAction<string | null>) {
      state.activeHighlightId = action.payload
    },
    setSelectionData(state, action: PayloadAction<ISelection | null>) {
      state.selectionData = action.payload
    },
    setToolData(state, action: PayloadAction<IHighlightTool | null>) {
      state.toolData = action.payload
    },
  },
})

export const { setActiveHighlightId, setSelectionData, setToolData } =
  highlightsSlice.actions

export default highlightsSlice.reducer
