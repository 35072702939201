import { QueryClient } from 'react-query'
import TypedResource from '@/models/resource/TypedResource'
import IBaseRepository from '@/repositories/types/baseRepository'
import { queryKeys } from '@/providers/react-query'

export interface AllResources<T extends TypedResource = TypedResource> {
  [resourceId: string]: T
}

export default class ResourceRepository
  implements IBaseRepository<TypedResource>
{
  constructor(readonly store: QueryClient) {}

  add(item: TypedResource) {
    this.store.setQueryData<AllResources>(
      queryKeys.allResources(),
      (prevData) =>
        prevData
          ? { ...prevData, [item.resourceId]: item }
          : { [item.resourceId]: item },
    )
  }

  addAll(items: TypedResource[]) {
    const itemsToObject = items.reduce<AllResources>(
      (acc, cur) => ({ ...acc, [cur.resourceId]: cur }),
      {},
    )
    this.store.setQueryData<AllResources>(
      queryKeys.allResources(),
      (prevData) =>
        prevData ? { ...prevData, ...itemsToObject } : itemsToObject,
    )
  }

  find<T extends TypedResource>(id: string): T | undefined {
    const getResource = <P extends TypedResource>(): P | undefined =>
      this.store.getQueryData<AllResources<P>>(queryKeys.allResources())?.[id]
    return getResource<T>()
  }

  findMany(ids: string[]): TypedResource[] {
    const allResources = this.store.getQueryData<AllResources>(
      queryKeys.allResources(),
    )

    return allResources ? ids.map((id) => allResources[id]) : []
  }

  findAll<T extends TypedResource>(): T[] {
    const allResources = this.store.getQueryData<AllResources<T>>(
      queryKeys.allResources(),
    )
    return allResources ? Object.values(allResources) : []
  }

  exist(id: string): boolean {
    return Boolean(this.find(id))
  }

  update<T extends TypedResource>(
    id: string,
    itemToPatch: Partial<Omit<T, 'type'>>,
  ): boolean {
    let updated = false

    this.store.setQueryData<AllResources>(
      queryKeys.allResources(),
      (oldData) => {
        if (!oldData) return {}
        updated = true
        return { ...oldData, [id]: oldData[id].copyWith(itemToPatch) }
      },
    )

    return updated
  }

  delete(id: string): boolean {
    const resourceToBeDeleted = this.find(id)

    if (!resourceToBeDeleted) {
      return false
    }

    this.store.setQueryData<AllResources>(
      queryKeys.allResources(),
      (oldData) => {
        if (oldData) delete oldData[id]
        return { ...oldData }
      },
    )

    return true
  }

  clear(): void {
    this.store.removeQueries(queryKeys.allResources())
  }
}
