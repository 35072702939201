import dayjs from 'dayjs'
import { ClipboardData } from '@/observers/useClipboardDataObserver'

interface ResourceText {
  Rename: string
  'Copy URL': string
  'Copy image': string
  'Copy Text': string
  CopyViewerLink: string
  Download: string
  Delete: string
  textResource: {
    Title: string
    'Type new Memo': string
    Cancel: string
    Save: string
    Edit: string
    Edited: string
  }
  addToProject: string
  Backlinks: string
  Highlights: string
  References: string
  'Add to current library': string
  'Move to current library': string
  'Already added': string
  'Add to current document': string
  'Move to current document': string
  documentResource: {
    'This library is empty': string
  }
  urlResource: {
    'The issue has been reported to Team Typed': string
    'Open in new tab': string
    'URL Issue Report': string
  }
  highlight: {
    'Copied to clipboard': string
    'Copy Text': string
    Delete: string
  }
  clipboard: {
    'Paste from clipboard': string
  }
  Reference: string
  optionCreatedInformation: (createdBy: string, createdAt: string) => string
  'Go to Document': string
  Copied: string
}

const englishResourceText: ResourceText = {
  Rename: 'Rename',
  'Copy URL': 'Copy URL',
  'Copy image': 'Copy image',
  'Copy Text': 'Copy Text',
  CopyViewerLink: 'Copy Viewer link',
  Download: 'Download',
  Delete: 'Delete',
  Backlinks: 'Backlinks',
  Highlights: 'Highlights',
  References: 'References',
  textResource: {
    Title: 'Title',
    'Type new Memo': 'Type new Memo',
    Cancel: 'Cancel',
    Save: 'Save',
    Edit: 'Edit',
    Edited: 'Edited',
  },
  addToProject: 'Add to Project',
  'Add to current library': 'Add to current library',
  'Move to current library': 'Move to current library',
  'Already added': 'Already added',
  'Add to current document': 'Add to current document',
  'Move to current document': 'Move to current document',
  documentResource: {
    'This library is empty': 'This library is empty.',
  },
  urlResource: {
    'The issue has been reported to Team Typed':
      'The issue has been reported to Team Typed.',
    'Open in new tab': 'Open in new tab',
    'URL Issue Report': 'URL Issue Report',
  },
  highlight: {
    'Copied to clipboard': 'Copied to clipboard',
    'Copy Text': 'Copy Text',
    Delete: 'Delete',
  },
  clipboard: {
    'Paste from clipboard': 'Paste from clipboard',
  },
  Reference: 'Reference',
  optionCreatedInformation: (createdBy, createdAt) =>
    `Created by ${createdBy}<br />${dayjs(createdAt).format('MMM D, YYYY')}`,
  'Go to Document': 'Go to Document',
  Copied: 'Copied',
}

const koreanResourceText: ResourceText = {
  Rename: '이름 변경',
  'Copy URL': 'URL 복사',
  'Copy image': '이미지 복사',
  'Copy Text': '텍스트 복사',
  CopyViewerLink: '뷰어 링크 복사',
  Download: '다운로드',
  Delete: '삭제',
  Backlinks: '백링크',
  Highlights: '하이라이트',
  References: '참고자료',
  textResource: {
    Title: '제목',
    'Type new Memo': '새 메모 작성',
    Cancel: '취소',
    Save: '저장',
    Edit: '편집',
    Edited: '생성일',
  },
  addToProject: '프로젝트에 추가',
  'Add to current library': '현재 라이브러리에 추가',
  'Move to current library': '현재 라이브러리로 이동',
  'Already added': '이미 추가된 리소스',
  'Add to current document': '현재 라이브러리에 추가',
  'Move to current document': '현재 라이브러리로 이동',
  documentResource: {
    'This library is empty': '이 라이브러리는 비어있어요.',
  },
  urlResource: {
    'The issue has been reported to Team Typed':
      'Team Typed에게 문제를 제보하였습니다.',
    'Open in new tab': '새 탭에서 보기',
    'URL Issue Report': 'URL 문제 제보',
  },
  highlight: {
    'Copied to clipboard': '클립보드로 복사 완료!',
    'Copy Text': '텍스트 복사',
    Delete: '삭제',
  },
  clipboard: {
    'Paste from clipboard': '클립보드에서 붙여넣기',
  },
  Reference: '참고자료',
  optionCreatedInformation: (createdBy, createdAt) =>
    `${createdBy}에 의해<br />${dayjs(createdAt).format('YYYY.M.D')}에 생성됨`,
  'Go to Document': '도큐먼트로 이동',
  Copied: '복사됨',
}

export const clipboardDataTranslate = (
  isKorean: boolean,
  clipboardData?: ClipboardData,
) => {
  if (typeof clipboardData === 'string') {
    return clipboardData
  }
  return isKorean ? '클립보드에서 이미지 붙여넣기' : 'Paste a clipboard image'
}

export { englishResourceText, koreanResourceText }
