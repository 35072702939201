function fillTextByNumberOfLines(
  ctx: CanvasRenderingContext2D,
  str: string,
  x: number,
  y: number,
  fontSize: number,
  maxWidth: number,
  numberOfLines: number,
  stroke = false,
) {
  let ellipsisString = ''
  let prevLineString = ''
  for (let currentLine = 1; currentLine <= numberOfLines; currentLine++) {
    const forFittingString =
      currentLine === 1 ? str : str.replace(prevLineString, '')
    if (forFittingString === '') break

    if (currentLine === numberOfLines) ellipsisString = '...'

    const { str: strInLine } = getFittingStringAndWidth(
      ctx,
      forFittingString,
      maxWidth,
      ellipsisString,
    )
    if (stroke) {
      ctx.strokeText(strInLine, x, y + (fontSize + 2) * (currentLine - 1))
    }

    ctx.fillText(strInLine, x, y + (fontSize + 2) * (currentLine - 1))

    prevLineString = strInLine
  }
}

function getFittingStringAndWidth(
  ctx: CanvasRenderingContext2D,
  str: string,
  maxWidth: number,
  ellipsisString = '...',
) {
  const width = ctx.measureText(str).width
  const ellipsis = ellipsisString
  const ellipsisWidth = ctx.measureText(ellipsis).width
  if (width <= maxWidth || width <= ellipsisWidth) {
    return { str, width }
  }

  const maxWidthWithoutEllipsis = maxWidth - ellipsisWidth
  let left = 0
  let right = str.length - 1
  let i = -1

  while (left <= right) {
    const guessIndex = Math.floor((left + right) / 2)
    const compareWidth = ctx.measureText(str.slice(0, guessIndex)).width

    if (compareWidth === maxWidthWithoutEllipsis) {
      i = guessIndex
      break
    }

    if (compareWidth < maxWidthWithoutEllipsis) {
      left = guessIndex + 1
    } else {
      right = guessIndex - 1
    }
  }

  return {
    width:
      ctx.measureText(str.slice(0, i === -1 ? right : i)).width + ellipsisWidth,
    str: str.slice(0, right) + ellipsis,
  }
}

export default { getFittingStringAndWidth, fillTextByNumberOfLines }
