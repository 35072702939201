import { HighlightElementManager, TextPositionAnchor } from '@/utils/highlights'

const createAnchorFromRange = (root, range) => {
  try {
    return TextPositionAnchor.fromRange(root, range).toSelector()
  } catch (err) {
    console.error(err)
  }
}

const selectionToAnchor = ({ selection, pageIndex }) => {
  if (selection === null || selection.isCollapsed) return null

  const root = HighlightElementManager.getRenderBoundary(pageIndex)

  const range = selection.getRangeAt(0)
  const content = createAnchorFromRange(root, range)

  return { content }
}

const extractAnchorFromSelection = ({ selection, pageIndex }) => {
  const anchor = selectionToAnchor({ selection, pageIndex })

  return { anchor }
}

const getAnchor = (root, selector) => {
  if (selector) {
    return TextPositionAnchor.fromSelector(root, selector).toRange()
  }

  throw new Error('unable to anchor')
}

const anchorToRange = ({ anchor, rootElement }) => {
  return getAnchor(rootElement, anchor.content)
}

export default {
  extractAnchorFromSelection,
  anchorToRange,
}
