import { TextRange } from '@/utils/highlights'

class TextPositionAnchor {
  constructor(root, start, end) {
    this.root = root
    this.start = start
    this.end = end
  }

  static fromRange(root, range) {
    const textRange = TextRange.fromRange(range).relativeTo(root)
    return new TextPositionAnchor(
      root,
      textRange.start.offset,
      textRange.end.offset,
    )
  }

  static fromSelector(root, selector) {
    return new TextPositionAnchor(root, selector.start, selector.end)
  }

  toSelector() {
    return {
      start: this.start,
      end: this.end,
    }
  }

  toRange() {
    return TextRange.fromOffsets(this.root, this.start, this.end).toRange()
  }
}

export default TextPositionAnchor
