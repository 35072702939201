import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { getLanguage } from '@/locale/locale.util'
import LocalStorageManagerFactory from '@/utils/common/localStorageManagerFactory'
import RouteManager from '@/utils/routeManager'
import { IAppSettings } from '@/setup'
import { isDev } from '@/utils/environment'
import popupCenterOption from '@/utils/popupCenterOption'
import GoogleIdentityService from '@/integrations/googleIdentityService'
import store from '@/store'
import parseJwt from '@/utils/parseJwt'

const setUserLanguage = (config: AxiosRequestConfig) => {
  const LSM = LocalStorageManagerFactory.userLanguage
  config.headers['user-language'] = LSM.getData() || getLanguage() || 'ko'
}

const goToLogin = () => {
  if (window.location.hostname === 'local.business-canvas.xyz') {
    // dev
    const { origin, pathname } = window.location
    window.location.href = `${origin}/${pathname.split('/')[1]}/login`
  } else {
    window.location.href = '/login'
  }
}
const notUsingTokenUrls = ['/api/auth/signin', '/api/auth/token']
const isUrlTokenRequired = (requestUrl: string) =>
  notUsingTokenUrls.every((url) => url !== requestUrl)

const handle401Error = async (error: AxiosError, appSettings: IAppSettings) => {
  if (
    error.request.responseURL ===
    `${process.env.firebase.apiServerURL}/api/user`
  ) {
    appSettings.services.auth.logout()
  }
  const refreshToken = appSettings.services.auth.getRefreshToken()
  if (refreshToken) {
    await appSettings.services.auth.refreshAccessToken(refreshToken)
  } else {
    GoogleIdentityService.prompt()
    const prevEmail = store.getState().user.data?.email

    const res = await GoogleIdentityService.waitIdTokenChange()
    const profile = parseJwt(res.credential)

    // 다른 계정으로 로그인 한 경우 /app 으로 보내기
    if (prevEmail !== profile.email) {
      window.location.href = RouteManager.getAppPagePath()
      return Promise.resolve()
    }
  }

  return axios.request(error.config)
}

export const addTypedAppInterceptor = (
  instance: AxiosInstance,
  appSettings: IAppSettings,
) => {
  // space-id 자체는 server 에서 추가를 해줘야하지 않나. 클라이언트에서 박아서 넣어줘야 할까.
  // 어디에서 넣어야 줘야할까.
  // class 형의 service 를 function 으로 변경
  // class 인스턴스화 할때 가져다가 쓰는 건 어떨까

  // bootstrap 해줄 수 있는게 필요할 듯
  // setSpace:83 -> const workspaceId: string | undefined = req.header('x-space-id') || req.query.spaceId 코드 참조
  instance.interceptors.request.use(
    async function (config: AxiosRequestConfig) {
      const routeManager = new RouteManager(
        window.location.pathname,
        window.location.search,
      )

      // FIXME (서경): Shared Document와 Space 내 Document에서의 요청은 다른 axios를 사용하도록 변경
      if (routeManager.isSharedDocumentPage()) {
        let token = routeManager.getAnonymousTokenFromQueryString()

        if (config.url === '/api/authorize/access-token') {
          token = await appSettings.services.auth.getTypedToken()
        }

        config.headers['Authorization'] = `Bearer ${token}`

        const spaceId = routeManager.getSpaceIdFromPathname()
        if (
          !config.headers['x-space-id'] &&
          spaceId &&
          spaceId !== 'personal'
        ) {
          config.headers['x-space-id'] = spaceId
        }
      } else {
        const spaceId = routeManager.getSpaceIdFromPathname()

        if (
          !config.headers['x-space-id'] &&
          spaceId &&
          spaceId !== 'personal'
        ) {
          config.headers['x-space-id'] = spaceId
        }
        const url = config.url!

        if (isUrlTokenRequired(url)) {
          const typedToken = await appSettings.services.auth.getTypedToken()
          if (!appSettings.services.auth.isTypedTokenExpired()) {
            config.headers['Authorization'] = `Bearer ${typedToken}`
          }
        }

        if (
          appSettings.services.auth.isTypedTokenExpired() &&
          !url.includes('/api/auth')
        ) {
          appSettings.services.tracker.releaseTracker()
          return Promise.reject({
            config,
            response: { status: 401, message: 'Invalid JWT' },
          })
        }
      }

      setUserLanguage(config)

      return config
    },
    function (error) {
      console.error(error.message)
      return Promise.reject(error)
    },
  )

  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      const isPersonalSpaceMigrationRequired =
        response.headers['required-migration'] === 'true' ? true : false

      const isPathRequiredToRedirect = ![
        '/login',
        '/logout',
        '/signup',
        '/migration',
      ].includes(window.location.pathname)

      if (isPersonalSpaceMigrationRequired && isPathRequiredToRedirect) {
        if (isDev()) {
          const sha = window.location.pathname.split('/')[1]
          window.location.href = `/${sha}/migration`
        } else {
          window.location.href = '/migration'
        }
      }
      return response
    },
    async function (error: AxiosError) {
      console.error('error: ', error)
      const routeManager = new RouteManager(
        window.location.pathname,
        window.location.search,
      )
      const { config, response } = error
      if (!response) {
        return Promise.reject(error)
      }

      try {
        const { status } = response
        if (status === 444) {
          const childWindow = window.open(
            response.data.authUrl,
            '_blank',
            popupCenterOption(500, 600),
          )

          try {
            await new Promise<void>((resolve, reject) => {
              setInterval(() => {
                if (childWindow?.closed) {
                  resolve()
                }
              }, 1000)

              setTimeout(() => reject(), 600000)
            })

            return axios.request(config)
          } catch (err) {}

          return
        }

        if (routeManager.isSharedDocumentPage()) {
          if (status === 403)
            window.location.href = routeManager.getAccessRequiredPath()
        }

        if (config.url?.includes('/api/v2/projects') && status === 401) {
          window.location.href = routeManager.getNotInvitedProjectPath()
        }

        if (response.status === 403) {
          if (config.url === '/api/spaces') {
            window.location.href = routeManager.getNotInvitedSpacePath()
          }
          if (config.url === '/api/auth/token') {
            /**
             * 일반적으로는 firebase 에서 제공해주는 refresh token 은 만료되지 않지만,
             * 이유는 모르겠으나 간헐적으로 만료가 되는 경우가 생기고 있음.
             * 이러한 때는 재로그인을 시켜야 한다.
             */
            return Promise.reject(error)
          }
        }
        if (status === 401) {
          return handle401Error(error, appSettings)
        } else if (status === 402) {
          return Promise.resolve()
        } else {
          return Promise.reject(error)
        }
      } catch (e) {
        appSettings.services.extension.signOut()
        goToLogin()
      }
    },
  )
}
