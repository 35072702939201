import TypedEvent from '@/integrations/logging/event/typedEvent'
import { TYPED_NOTE_EVENT } from '@/integrations/logging/enum'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class TypedNoteEvent extends TypedEvent {
  typedNoteCreated({
    anchorResourceId,
    resourceId,
  }: {
    anchorResourceId: string
    resourceId: string
  }) {
    this.sendEvent(TYPED_NOTE_EVENT.CREATE_TYPED_NOTE_AS_REFERENCE, {
      anchorResourceId,
      resourceId,
    })
  }

  typedNoteAddInTextCitation({
    anchorResourceId,
    resourceId,
    citationResourceId,
  }: {
    anchorResourceId: string
    resourceId: string
    citationResourceId: string
  }) {
    this.sendEvent(TYPED_NOTE_EVENT.TYPED_NOTE_CITATION_ADDED, {
      anchorResourceId,
      resourceId,
      citationResourceId,
    })
  }

  typedNoteClickInTextCitation({
    anchorResourceId,
    resourceId,
    citationResourceId,
  }: {
    anchorResourceId: string
    resourceId: string
    citationResourceId: string
  }) {
    this.sendEvent(TYPED_NOTE_EVENT.TYPED_NOTE_CITATION_CLICKED, {
      anchorResourceId,
      resourceId,
      citationResourceId,
    })
  }
}
