import dayjs from 'dayjs'

interface ReviewText {
  reviewPanelHeader: {
    requestReview: string
    receivedTab: string
    sentTab: string
    filterInReview: string
    filterCompleted: string
  }
  reviewRequestModal: {
    title: string
    receivedReviewers: string
    purpose: string
    dueDate: string
    warningToast: string
    requestSuccessToastTitle: string
    requestSuccessToastSubTitle: string
  }
  reviewRequestCard: {
    createdAt: (date: Date) => string
  }
  reviewComment: {
    inputPlaceholder: string
    showMoreComments: (count: number) => string
    deleteButton: string
  }
  reviewContainer: {
    AnyReceiveReviewWarnHead: string
    AnyReviewWarnBody: string
    AnyRequestReviewWarnHead: string
    From: string
    To: string
    DueDate: string
    Message: string
    Status: string
    'Status Complete': string
    'Status InComplete': string
    'Status Expired': string
    requestReview: string
    receiveReview: string
  }
  requestReview: {
    DueDate: string
    'Request Review': string
    'New Request': string
    'Request Status': string
    Reviewers: string
    'Request Type': string
    'Select reviewers': string
    toRefer: string
    comment: string
    mustRead: string
    Clear: string
    'Select date': string
    date: (date: Date) => string
    Preview: string
    'Send Request': string
    'Request Reason PlaceHolder': string
    'Request Complete': string
    'No results': string
    tooltip: string
    'Search People': string
    SlackSendDescription: string
  }
  requestReviewList: {
    createdAt: (date: Date) => string
    Reviewers: string
    DueDate: string
    date: (date: Date) => string
    Expired: string
    EmptyRequest: string
    EmptyReceive: string
    'Has Review': string
    'In Review': string
    Completed: string
    'Status In Progress': string
    'Status Complete': string
    'Expired Request': string
    'Cancel review': string
    'Mark as reviewed': string
    'Expired Warning Message': string
    'Can Request Only Owner': string
    'Review Complete': string
  }
  memberChip: {
    tooltipInReview: (name: string) => string
    tooltipReviewed: (name: string) => string
    me: string
  }
  slackBanner: {
    connectSlackGuide: string
    connectSlack: string
  }
}

const englishReviewText: ReviewText = {
  reviewPanelHeader: {
    requestReview: 'New Request',
    receivedTab: 'Received',
    sentTab: 'Sent',
    filterInReview: 'In Review',
    filterCompleted: 'Completed',
  },
  reviewRequestModal: {
    title: 'Create New Request',
    purpose: 'Purpose',
    receivedReviewers: 'Reviewers',
    dueDate: 'Due Date',
    warningToast: 'Please select reviewers and due date.',
    requestSuccessToastTitle: 'Request Sent!',
    requestSuccessToastSubTitle:
      'View sent requests in the “Sent” tab of the Review Request page.',
  },
  reviewRequestCard: {
    createdAt: (date: Date) =>
      `Requested on ${dayjs(date).format('MMM D, YYYY')}`,
  },
  reviewComment: {
    inputPlaceholder: 'Type your comments...',
    showMoreComments: (count: number) => `Show ${count} more comments`,
    deleteButton: 'Delete',
  },
  reviewContainer: {
    AnyReceiveReviewWarnHead: 'No request to review.',
    AnyReviewWarnBody: 'You can request reviews on your documents.',
    AnyRequestReviewWarnHead: 'There is no document sent for review.',
    From: 'From',
    To: 'To',
    DueDate: 'Due date',
    Message: 'Message',
    Status: 'Review Status',
    'Status Complete': 'Complete',
    'Status InComplete': 'Needs review',
    'Status Expired': 'Expired',
    requestReview: 'Sent',
    receiveReview: 'Received',
  },
  requestReview: {
    DueDate: 'Due date',
    'Request Review': 'Request Review',
    'New Request': 'New Request',
    'Request Status': 'Request Status',
    Reviewers: 'Reviewers',
    'Request Type': 'Request Type',
    'Select reviewers': 'Select reviewers',
    toRefer: 'Refer',
    comment: 'Comment',
    mustRead: 'Must Read',
    Clear: 'Clear',
    'Select date': 'Select Date',
    date: (date: Date) => dayjs(date).format('MMM D, YYYY'),
    Preview: 'Request Preview',
    'Send Request': 'Send Request',
    'Request Reason PlaceHolder': 'Reason for request: Required for meeting',
    'Request Complete': 'Request Sent',
    'No results': 'No results',
    tooltip: `Your request will be sent to reviewer's Review Requests.`,
    'Search People': 'Search people...',
    SlackSendDescription: 'Also send to Slack',
  },
  requestReviewList: {
    createdAt: (date: Date) => `Requested on ${dayjs(date).format('MMM D')}`,
    Reviewers: 'Reviewers',
    DueDate: 'Due date',
    date: (date: Date) => dayjs(date).format('MMM D, YYYY'),
    Expired: 'Request expired',
    EmptyRequest: 'No reviews requested.',
    EmptyReceive: 'No reviews received.',
    'Has Review': `'s request`,
    'In Review': 'In Review',
    Completed: 'Completed',
    'Expired Request': 'Expired Request',
    'Cancel review': 'Cancel review',
    'Mark as reviewed': 'Mark as reviewed',
    'Expired Warning Message': 'Review status cannot be changed once expired.',
    'Can Request Only Owner': 'Only the document owner can request a review',
    'Review Complete': 'Review Complete',
    'Status In Progress': 'In Progress',
    'Status Complete': 'Complete',
  },
  memberChip: {
    tooltipInReview: (name: string) =>
      `${name} is currently reviewing your request.`,
    tooltipReviewed: (name: string) => `${name} has reviewed your request.`,
    me: 'me',
  },
  slackBanner: {
    connectSlackGuide: 'Connect to Slack to send a review request alert.',
    connectSlack: 'Connect to Slack',
  },
}

const koreanReviewText: ReviewText = {
  reviewPanelHeader: {
    requestReview: '요청 작성하기',
    receivedTab: '받은 요청',
    sentTab: '보낸 요청',
    filterInReview: '진행중',
    filterCompleted: '완료',
  },
  reviewRequestModal: {
    title: '신규 요청 작성하기',
    receivedReviewers: '받는 사람',
    purpose: '요청 목적',
    dueDate: '마감 기한',
    warningToast: '받는 사람이나 마감기한을 설정하세요.',
    requestSuccessToastTitle: '요청 보내기 완료!',
    requestSuccessToastSubTitle: `보낸 요청은 ‘보낸 요청’ 페이지에서 확인해주세요.`,
  },
  reviewRequestCard: {
    createdAt: (date: Date) => `${dayjs(date).format('YYYY. MM. DD')} 요청`,
  },
  reviewComment: {
    inputPlaceholder: '댓글 남기기...',
    showMoreComments: (count: number) => `${count}개 추가 댓글 표시`,
    deleteButton: '삭제',
  },
  reviewContainer: {
    AnyReceiveReviewWarnHead: '검토 요청 받은 문서가 없어요.',
    AnyReviewWarnBody: '내가 만든 문서를 팀원에게 검토 요청을 할 수 있어요!',
    AnyRequestReviewWarnHead: '검토 요청 보낸 문서가 없어요.',
    From: '보낸 사람',
    To: '받는 사람',
    DueDate: '마감 기한',
    Message: '메세지',
    Status: '확인 상태',
    'Status Complete': '완료',
    'Status InComplete': '검토 필요',
    'Status Expired': '기한 만료',
    requestReview: '보낸 요청',
    receiveReview: '받은 요청',
  },
  requestReview: {
    DueDate: '마감 기한',
    'Request Review': '검토 요청',
    'New Request': '신규 요청 작성',
    'Request Status': '요청 현황',
    Reviewers: '받는 사람',
    'Request Type': '요청 목적',
    'Select reviewers': '사람 선택',
    toRefer: '참고',
    comment: '의견',
    mustRead: '필독',
    Clear: '지우기',
    'Select date': '날짜 선택',
    date: (date: Date) => dayjs(date).format('YYYY. M. D'),
    Preview: '요청 미리보기',
    'Send Request': '검토 요청 보내기',
    'Request Reason PlaceHolder': '요청 사유: 미팅 시 필요',
    'Request Complete': '요청 보내기 완료',
    'No results': '일치하는 항목 없음',
    tooltip: `보낸 요청은 받는 사람의 '검토 알림함'으로 발송됩니다.`,
    'Search People': '멤버 찾기',
    SlackSendDescription: '슬랙에도 보내기',
  },
  requestReviewList: {
    createdAt: (date: Date) => `${dayjs(date).format('M월 D일')} 작성됨`,
    Reviewers: '요청 받은 사람',
    DueDate: '마감 기한',
    date: (date: Date) => dayjs(date).format('YYYY년 M월 D일'),
    Expired: '기한 지남',
    EmptyRequest: '보낸 요청이 없습니다.',
    EmptyReceive: '받은 요청이 없습니다.',
    'Has Review': '님이 보낸 요청',
    'In Review': '진행',
    Completed: '완료',
    'Expired Request': '만료된 요청',
    'Cancel review': '검토 완료 취소',
    'Mark as reviewed': '검토 완료',
    'Expired Warning Message': '기한이 만료된 요청은 확인할 수 없어요.',
    'Can Request Only Owner': '검토 요청은 문서 오너만 가능합니다.',
    'Review Complete': '검토를 완료했습니다.',
    'Status In Progress': '진행중',
    'Status Complete': '완료',
  },
  memberChip: {
    tooltipInReview: (name: string) => `${name} 님이 확인중이에요.`,
    tooltipReviewed: (name: string) => `${name} 님이 요청을 수행했어요.`,
    me: '나',
  },
  slackBanner: {
    connectSlackGuide: '동시에 슬랙으로도 요청을 전달할 수 있어요.',
    connectSlack: '슬랙 연동하기',
  },
}

export { englishReviewText, koreanReviewText }
