import { AxiosInstance, AxiosResponse } from 'axios'
import autoBind from '@/utils/autoBind'
import {
  IReviewComment,
  Review,
  ReviewComment,
  ReviewListType,
  ReviewPurposeType,
} from '@/models/review'
import { CustomError } from '@/util'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'
import PaginationWithRestCount from '@/models/pagination/PaginationWithRestCount'

interface PaginatedCommentResponseDto {
  data: IReviewComment[]
  cursor?: string
  numOfItemsInNextPage: number
}

export class ReviewAPI {
  constructor(readonly axios: AxiosInstance) {}
  async createReview({
    documentId,
    reviewerIds,
    purpose,
    dueDate,
    message,
    shouldSendMessageToSlack = false,
  }: {
    documentId: string
    reviewerIds: string[]
    purpose: ReviewPurposeType
    dueDate: Date
    message?: string
    shouldSendMessageToSlack?: boolean
  }) {
    try {
      const response = await this.axios({
        method: 'post',
        url: `/api/reviews`,
        data: {
          review: { documentId, reviewerIds, purpose, dueDate, message },
          shouldSendMessageToSlack,
        },
      })
      return Review.fromJSON(response.data)
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error('Cannot Create Review', e)
      }
      throw e
    }
  }
  async getRequestedReviewList() {
    const type = ReviewListType.REQUEST
    try {
      const response = await this.axios({
        method: 'get',
        url: `/api/reviews/`,
        params: {
          type,
        },
      })
      return response.data.map(Review.fromJSON)
    } catch (error) {
      if (error instanceof CustomError) {
        alert(error.message)
      }
      sendErrorLog('Fetch requested review list failed.', {
        type,
        errorStace: error,
      })
      throw error
    }
  }

  async getReceivedReviewList() {
    const type = ReviewListType.RECEIVE
    try {
      const response = await this.axios({
        method: 'get',
        url: `/api/reviews/`,
        params: {
          type,
        },
      })
      return response.data.map(Review.fromJSON)
    } catch (error) {
      if (error instanceof CustomError) {
        alert(error.message)
      }
      sendErrorLog('Fetch received review list failed.', {
        type,
        errorStace: error,
      })
      throw error
    }
  }

  async getReviewsByDocumentId(documentId: string, type: ReviewListType) {
    try {
      const response = await this.axios({
        method: 'get',
        url: `/api/reviews/`,
        params: {
          documentId,
          type,
        },
      })
      return response.data.map(Review.fromJSON)
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error('Cannot Get Reviews By DocumentId', e)
      }
      throw e
    }
  }

  async updateReview(reviewId: string, confirmed: boolean) {
    try {
      const response = await this.axios({
        method: 'patch',
        url: `/api/reviews/${reviewId}`,
        data: {
          isConfirmed: confirmed,
        },
      })
      return response.status === 200
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error('Update Review Failed', e)
      }
      throw e
    }
  }

  async getCommentsByReviewId({
    reviewId,
    limit,
    cursor,
  }: {
    reviewId: string
    limit?: number
    cursor?: string
  }): Promise<PaginationWithRestCount<ReviewComment>> {
    try {
      const { data }: AxiosResponse<PaginatedCommentResponseDto> =
        await this.axios({
          method: 'get',
          url: `/api/comments`,
          params: {
            reviewId,
            limit,
            cursor,
          },
        })

      return PaginationWithRestCount.fromJSON({
        data: data.data.map(ReviewComment.fromJSON),
        cursor: data.cursor,
        restCount: data.numOfItemsInNextPage,
      })
    } catch (e) {
      sendErrorLog('Cannot Get Comments By ReviewId', {
        trace: {
          reviewId,
          limit,
          cursor,
        },
      })
      throw e
    }
  }

  async createComment({
    reviewId,
    contents,
  }: {
    reviewId: string
    contents: string
  }) {
    try {
      const response = await this.axios({
        method: 'post',
        url: `/api/comments`,
        data: {
          reviewId,
          contents,
        },
      })
      return ReviewComment.fromJSON(response.data)
    } catch (e) {
      sendErrorLog('Cannot Create Comment', {
        trace: {
          reviewId,
          contents,
        },
      })
      throw e
    }
  }

  async deleteComment(commentId: string) {
    try {
      const response = await this.axios({
        method: 'delete',
        url: `/api/comments/${commentId}`,
      })
      return response.status === 200
    } catch (e) {
      sendErrorLog('Cannot Delete Comment', {
        trace: {
          commentId,
        },
      })
      throw e
    }
  }
}

export default autoBind(ReviewAPI)
