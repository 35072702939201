import { IResourceTag, ResourceTag } from '@/models/resource/ResourceTag'
import TypedResource, {
  ITypedResource,
  TypedResourceType,
} from '@/models/resource/TypedResource'
import TypedGoogleDocument, {
  GoogleDocumentType,
  IGoogleDocumentOrigin,
} from '@/models/document/TypedGoogleDocument'
import { ITypedUser } from '@/models/user'

export interface ITypedDocumentResourceData {
  readonly docType: `${GoogleDocumentType}`
  readonly origin: IGoogleDocumentOrigin
}

export interface ITypedDocumentResource extends ITypedResource {
  readonly data: ITypedDocumentResourceData
}

export class TypedDocumentResourceData implements ITypedDocumentResourceData {
  constructor(
    readonly docType: GoogleDocumentType,
    readonly origin: IGoogleDocumentOrigin,
  ) {}

  static fromJSON(json: ITypedDocumentResourceData): TypedDocumentResourceData {
    let docType: GoogleDocumentType
    switch (json.docType) {
      case 'document':
        docType = 'document'
        break
      case 'spreadsheet':
        docType = 'spreadsheet'
        break
      case 'presentation':
        docType = 'presentation'
        break
      default:
        throw new Error('Invalid docType')
    }

    return new TypedDocumentResourceData(docType, json.origin)
  }

  static fromGoogleDocument(
    document: TypedGoogleDocument,
  ): TypedDocumentResourceData {
    return new TypedDocumentResourceData(document.docType, document.origin)
  }

  toJSON(): ITypedDocumentResourceData {
    return {
      docType: this.docType,
      origin: this.origin,
    }
  }
}

export class TypedDocumentResource
  extends TypedResource
  implements ITypedDocumentResource
{
  type = TypedResourceType.DOCUMENT

  get canBeDocument() {
    return !this.hasWorkspace
  }

  constructor(
    readonly resourceId: string,
    readonly backlinks: string[],
    readonly links: string[],
    readonly name: string,
    readonly createdAt: number,
    readonly createdBy: ITypedUser,
    readonly tags: ResourceTag[],
    readonly data: TypedDocumentResourceData,
    readonly hasWorkspace: boolean,
  ) {
    super(
      resourceId,
      backlinks,
      links,
      name,
      createdAt,
      createdBy,
      tags,
      hasWorkspace,
    )
  }

  static fromJSON(json: ITypedDocumentResource): TypedDocumentResource {
    if (json.type !== 'document') {
      throw new Error(
        `the type of FileResource must be file, but given type is ${json.type}`,
      )
    }

    return new TypedDocumentResource(
      json.resourceId,
      json.backlinks,
      json.links,
      json.name,
      json.createdAt,
      json.createdBy,
      json.tags?.map((tag: IResourceTag) => ResourceTag.fromJSON(tag)) || [],
      TypedDocumentResourceData.fromJSON(json.data),
      json.hasWorkspace,
    )
  }

  get docType() {
    return this.data.docType
  }

  copyWith(
    options?: Partial<Omit<TypedDocumentResource, 'type'>>,
  ): TypedDocumentResource {
    return new TypedDocumentResource(
      options?.resourceId ?? this.resourceId,
      options?.backlinks ?? this.backlinks,
      options?.links ?? this.links,
      options?.name ?? this.name,
      options?.createdAt ?? this.createdAt,
      options?.createdBy ?? this.createdBy,
      options?.tags ?? this.tags,
      options?.data ?? this.data,
      options?.hasWorkspace ?? this.hasWorkspace,
    )
  }

  toJSON(): ITypedDocumentResource {
    return {
      ...super.toJSON(),
      data: this.data.toJSON(),
      createdBy: this.createdBy,
    }
  }
}
