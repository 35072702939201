import autoBind from '@/utils/autoBind'
import SearchAPI, {
  ProjectSearchParams,
  ResourceSearchParams,
  DocumentSearchParams,
} from '@/api/searchAPI'

class SearchService {
  constructor(private readonly searchAPI: InstanceType<typeof SearchAPI>) {}

  async getReferenceResult(params: ResourceSearchParams) {
    return this.searchAPI.getResourceSearchResult(params)
  }

  async getProjectResult(params: ProjectSearchParams) {
    return this.searchAPI.getProjectSearchResult(params)
  }

  async getDocumentResult(params: DocumentSearchParams) {
    return this.searchAPI.getDocumentSearchResult(params)
  }
}

export default autoBind(SearchService)
