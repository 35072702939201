interface SearchText {
  searchInputPlaceholder: string
  'Not Found Title': string
  'Not Found Description 1': string
  'Not Found Description 2': string
  'Not Found Filter Title 1': string
  'Not Found Filter Title 2': string
  'Not Found Filter Description 1': string
  'Not Found Filter Description 2': string
  'A Network Error Occurred': string
  'Please try again later': string
  'Results for': string
  'Not the results you expected': string
  Retry: string
  'Search for projects and documents': string
  recommendedReferences: string
  Projects: string
  document: string
  documentPageDocumentsTab: string
  documentPageReferencesTab: string
  sheet: string
  slide: string
  pdf: string
  url: string
  image: string
  text: string
  hwp: string
  ppt: string
  word: string
  excel: string
  editor: string
  default: string
  last: string
  latest: string
}

const englishSearchText: SearchText = {
  searchInputPlaceholder: 'Search all resources...',
  'Not Found Title': 'No Result Found 😢',
  'Not Found Description 1': "We couldn't find any resource",
  'Not Found Description 2': 'matching your search',
  'Not Found Filter Title 1': 'No results match',
  'Not Found Filter Title 2': 'this filter.',
  'Not Found Filter Description 1': "We couldn't find any reference",
  'Not Found Filter Description 2': 'matching your filter!',
  'A Network Error Occurred': 'A Network Error Occurred 😵',
  'Please try again later': 'Please try again later',
  'Results for': 'Results for',
  'Not the results you expected': 'Not the results you expected?',
  recommendedReferences: 'Recommended references',
  Retry: 'Retry',
  'Search for projects and documents': 'Search for projects and documents',
  Projects: 'Projects',
  document: 'Docs',
  documentPageDocumentsTab: 'Documents',
  documentPageReferencesTab: 'References',
  sheet: 'Sheets',
  slide: 'Slides',
  pdf: 'PDF',
  url: 'URL',
  image: 'Image',
  text: 'Memo',
  hwp: 'Hwp',
  ppt: 'PPT',
  word: 'Word',
  excel: 'Excel',
  editor: 'Page',
  default: 'Default',
  latest: 'Newest',
  last: 'Oldest',
}

const koreanSearchText: SearchText = {
  searchInputPlaceholder: '모든 리소스를 검색하세요',
  'Not Found Title': '일치하는 결과가 없습니다 😢',
  'Not Found Description 1': '입력하신 검색어와 일치하는',
  'Not Found Description 2': '리소스가 없습니다.',
  'Not Found Filter Title 1': '해당 필터와 일치하는',
  'Not Found Filter Title 2': '결과가 없습니다.',
  'Not Found Filter Description 1': '찾고싶은 문서의 필터 종류를 ',
  'Not Found Filter Description 2': '다시 선택해주세요!',
  'A Network Error Occurred': '검색결과 요청에 실패했어요 😵',
  'Please try again later': '잠시 후 다시 시도해 주세요',
  'Results for': '검색결과',
  'Not the results you expected': '더 나은 검색 환경을 위해',
  recommendedReferences: '추천 자료',
  Retry: '다시시도',
  'Search for projects and documents': '찾고 싶은 프로젝트나 문서를 검색하세요',
  Projects: 'Projects',
  document: 'Docs',
  documentPageDocumentsTab: '문서',
  documentPageReferencesTab: '참고자료',
  sheet: 'Sheets',
  slide: 'Slides',
  pdf: 'PDF',
  url: 'URL',
  image: 'Image',
  text: 'Memo',
  hwp: 'Hwp',
  ppt: 'PPT',
  word: 'Word',
  excel: 'Excel',
  editor: 'Page',
  default: 'Default',
  latest: 'Newest',
  last: 'Oldest',
}

export { englishSearchText, koreanSearchText }
