import { AxiosInstance } from 'axios'
import TypedGroup from '@/models/group'

class GroupAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getGroups(): Promise<{ defaultGroupId: string; groups: TypedGroup[] }> {
    const { data } = await this.axios({
      method: 'get',
      url: '/api/groups',
    })

    return {
      defaultGroupId: data.defaultGroupId,
      groups: data.groups.map((group: TypedGroup) =>
        TypedGroup.fromJSON(group),
      ),
    }
  }

  async createGroup(name: string): Promise<TypedGroup> {
    const { data } = await this.axios({
      method: 'post',
      url: '/api/groups',
      data: {
        name,
      },
    })

    return TypedGroup.fromJSON(data)
  }

  async renameGroup(id: string, newName: string): Promise<void> {
    await this.axios({
      method: 'post',
      url: `/api/groups/${id}/actions/rename`,
      data: {
        name: newName,
      },
    })
  }

  async deleteGroup(id: string): Promise<void> {
    await this.axios({
      method: 'delete',
      url: `/api/groups/${id}`,
      data: {
        id,
      },
    })
  }

  async moveGroup(
    id: string,
    prevGroupId: string,
    nextGroupId: string,
  ): Promise<void> {
    await this.axios({
      method: 'post',
      url: `/api/groups/${id}/actions/move`,
      data: {
        prevGroupId,
        nextGroupId,
      },
    })
  }
}

export default GroupAPI
