import { QueryClient } from 'react-query'
import TypedCitation from '@/models/citation'
import { queryKeys } from '@/providers/react-query'
import { implementError } from '@/utils/repositoryUtils'

// Drag citation 구현 시 하이라이트 로직을 재활용할 수 있도록 하기 위해 repo로 구현 (beanie)
export default class CitationRepository {
  constructor(readonly store: QueryClient) {}

  add<T extends TypedCitation>(item: T): void {
    this.store.setQueryData<T[]>(
      queryKeys.citationsByResourceId(item.citerResourceId),
      (oldData) => {
        if (!oldData) return [item]

        return this.exist(item.id) ? oldData : [...oldData, item]
      },
    )
  }
  addAll(): void {
    throw new Error(implementError)
  }
  find<T extends TypedCitation>(id: string): T | undefined {
    const allCitations = this.store
      .getQueriesData<T[]>(queryKeys.allCitations())
      .reduce<T[]>((acc, [, citations]) => {
        return [...acc, ...citations]
      }, [])
    return allCitations.find((citation) => citation.id === id)
  }
  exist(id: string): boolean {
    return Boolean(this.find(id))
  }
  update(): boolean {
    throw new Error(implementError)
  }
  delete(id: string, citerResourceId: string): boolean {
    let deleted = false

    this.store.setQueryData<TypedCitation[]>(
      queryKeys.citationsByResourceId(citerResourceId),
      (oldData) => {
        if (!oldData) return []
        deleted = true
        return oldData.filter((citation) => citation.id !== id)
      },
    )

    return deleted
  }
  clear(): void {
    this.store.removeQueries(queryKeys.allCitations())
  }
}
