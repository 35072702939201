import { SEARCH_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class SearchEvent extends TypedEvent {
  searchResultsReturnedEvent(data: any) {
    this.sendEvent(SEARCH_EVENT.SEARCH_RESULT_RETURNED, {
      anchorResourceId: data.documentId,
      query: data.query,
      took: data.took,
      numReturned: data.numReturned,
      resourceIdList: data.resourceIdList,
    })
  }

  appHomeSearchResultReturnedEvent(data: {
    query: string
    searchResultInfo: {
      took: number
      projects?: string[]
      documents?: string[]
    }
  }) {
    const { query, searchResultInfo } = data
    this.sendEvent(SEARCH_EVENT.APP_HOME_SEARCH_RESULT_RETURNED, {
      query,
      searchResultInfo,
    })
  }

  appHomeSearchResultPreviewEvent(data: {
    query?: string
    documentId: string
  }) {
    const { query, documentId } = data
    this.sendEvent(SEARCH_EVENT.APP_HOME_SEARCH_RESULT_PREVIEW, {
      query,
      anchorResourceId: documentId,
    })
  }
}
