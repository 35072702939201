import { GROUP_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class GroupEvent extends TypedEvent {
  createdEvent(data: { spaceId: string; groupId: string }) {
    this.sendEvent(GROUP_EVENT.GROUP_CREATED, data)
  }

  deletedEvent(data: { groupId: string }) {
    this.sendEvent(GROUP_EVENT.GROUP_DELETED, data)
  }
}
