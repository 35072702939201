import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ResourceFilterType } from '@/models/resource/ResourceFilter'
import { SortFilterType } from '@/models/SortFilter'

const initialState: {
  currentLibrarySelectedFilterType?: ResourceFilterType
  currentLibrarySelectedSortType: SortFilterType
} = {
  currentLibrarySelectedFilterType: undefined,
  currentLibrarySelectedSortType: 'default',
}

export const currentLibrarySlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setCurrentLibrarySelectedFilterType: (
      state,
      action: PayloadAction<ResourceFilterType | undefined>,
    ) => {
      state.currentLibrarySelectedFilterType = action.payload
    },
    setCurrentLibrarySelectedSortType: (
      state,
      action: PayloadAction<SortFilterType>,
    ) => {
      state.currentLibrarySelectedSortType = action.payload
    },
  },
})

export const {
  setCurrentLibrarySelectedFilterType,
  setCurrentLibrarySelectedSortType,
} = currentLibrarySlice.actions

export default currentLibrarySlice.reducer
