import autoBind from '@/utils/autoBind'
import DocumentAPI from '@/api/documentAPI'

class MyHomeService {
  private static DOCUMENTS_FETCHING_SIZE = 20
  constructor(private readonly documentAPI: InstanceType<typeof DocumentAPI>) {}

  async fetchDocumentsCreatedByMe(cursor: number) {
    const result = await this.documentAPI.getDocumentsCreatedByMe(
      cursor,
      MyHomeService.DOCUMENTS_FETCHING_SIZE,
    )
    return result
  }

  async fetchDocumentsViewedByMe(cursor: number) {
    const result = await this.documentAPI.getDocumentsViewedByMe(
      cursor,
      MyHomeService.DOCUMENTS_FETCHING_SIZE,
    )
    return result
  }
}

export default autoBind(MyHomeService)
