export interface IFileMetaData {
  readonly contentType: string
  readonly fullPath: string
  readonly md5Hash: string
  readonly name: string
  readonly bucket: string
  readonly thumbnailUrl?: string
  readonly thumbnailMetadata?: IFileMetaData
  readonly token?: string
}

export class FileMetaData implements IFileMetaData {
  constructor(
    readonly contentType: string,
    readonly fullPath: string,
    readonly md5Hash: string,
    readonly name: string,
    readonly bucket: string,
    readonly thumbnailUrl?: string,
    readonly thumbnailMetadata?: FileMetaData,
    readonly token?: string,
  ) {}

  static fromJSON(json: IFileMetaData): FileMetaData {
    return new FileMetaData(
      json.contentType,
      json.fullPath,
      json.md5Hash,
      json.name,
      json.bucket,
      json.thumbnailUrl,
      json.thumbnailMetadata
        ? FileMetaData.fromJSON(json.thumbnailMetadata)
        : undefined,
      json.token,
    )
  }

  toJSON(): IFileMetaData {
    return {
      contentType: this.contentType,
      fullPath: this.fullPath,
      md5Hash: this.md5Hash,
      name: this.name,
      bucket: this.bucket,
      thumbnailUrl: this.thumbnailUrl,
      thumbnailMetadata: this.thumbnailMetadata?.toJSON(),
      token: this.token,
    }
  }
}
