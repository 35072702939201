import { Store } from 'redux'
import Modal from 'react-modal'
import { Stripe } from '@stripe/stripe-js'
import { focusManager, QueryClient } from 'react-query'

import store from '@/store'
import { ITypedAxiosGroup } from '@/axios'
import { onWindowFocus } from '@/utils/onWindowFocus'
import { isLocal, isTest } from '@/utils/environment'
import { SubscriptionMetadataSet } from '@/models/subscription'

// repositories
import { ITypedRepositories } from '@/repositories'

// APIs
import { ITypedAPIs } from '@/api'

// event loggers
import { ITypedEventLoggers } from '@/integrations/logging/event/typedEventLoggers'

// services
import TypedServices from '@/setup/serviceSetup'

import autoBind from '@/utils/autoBind'

import SetupStripe from '@/setup/integrations/stripeSetup'
import TypedAxiosGroup from '@/setup/axiosSetup'
import TypedRepositories from '@/setup/repositoriesSetup'
import TypedEventLoggers from '@/setup/eventLoggersSetup'
import TypedAPIs from '@/setup/apisSetup'
import { googleIntegrationSetup } from '@/setup/integrations/googleIntegrationsSetup'
import { sentrySetup } from '@/setup//integrations/sentrySetup'
import { intercomSetup } from '@/setup//integrations/intercomSetup'
import { facebookPixelSetup } from '@/setup/facebookPixelSetup'
import { gApiSetup } from '@/setup/gApiSetup'
import { quantcastSetup } from '@/setup/integrations/quantcastSetup'
import { googleIdentitySetup } from '@/setup/googleIdentitySetup'
import { addTypedAppInterceptor } from '@/setup/axios/inteceptors/typed-app-interceptor'
import { addTypedGlobalInterceptor } from '@/setup/axios/inteceptors/typed-global-interceptor'
import { addTypedPaymentInterceptor } from '@/setup/axios/inteceptors/typed-payment-interceptor'

export const SCRIPT_KEYS_TO_IGNORE: Partial<
  Record<keyof IScriptsLoadingState, true | undefined>
> = {}
if (isLocal()) {
  SCRIPT_KEYS_TO_IGNORE['isSentryLoaded'] = true
}

export interface IScriptsLoadingState {
  // user trackers
  isSentryLoaded: boolean
  isIntercomLoaded: boolean
  isAmplitudeLoaded: boolean

  // etc
  isGoogleIdentityLoaded: boolean
  isGapiLoaded: boolean
  isGoogleAnalyticsLoaded: boolean
  isGoogleAdsLoaded: boolean
  isGoogleAdsConversionEventLoaded: boolean
  isGoogleTagManagerLoaded: boolean
  isStripeLoaded: boolean
  isFacebookPixelLoaded: boolean
  isQuantcastLoaded: boolean
}

export interface IAppSettings {
  readonly stripePromise: Promise<Stripe | null>
  readonly subscriptionMetadataSet: SubscriptionMetadataSet
  readonly productIdTypedProAnnual: string
  readonly productIdTypedProMonthly: string
  readonly axiosGroup: ITypedAxiosGroup
  readonly queryClient: QueryClient
  readonly repositories: ITypedRepositories
  readonly eventLoggers: ITypedEventLoggers
  readonly scriptsLoadingState: IScriptsLoadingState
  APIs: ITypedAPIs
  services: TypedServices
  onLoad?: () => void
}

class AppSettings implements IAppSettings {
  readonly stripePromise: Promise<Stripe | null>
  readonly subscriptionMetadataSet: SubscriptionMetadataSet
  readonly productIdTypedProAnnual: string
  readonly productIdTypedProMonthly: string
  readonly axiosGroup: ITypedAxiosGroup
  readonly queryClient: QueryClient
  readonly repositories: ITypedRepositories
  readonly eventLoggers: ITypedEventLoggers
  private _APIs: ITypedAPIs
  private _services: TypedServices
  onLoad?: () => void

  get APIs() {
    return this._APIs
  }

  get services() {
    return this._services
  }

  readonly scriptsLoadingState = new Proxy(
    {
      // user trackers
      isSentryLoaded: false,
      isIntercomLoaded: false,
      isAmplitudeLoaded: false,

      // etc
      isGoogleIdentityLoaded: false,
      isGapiLoaded: false,
      isGoogleAnalyticsLoaded: false,
      isGoogleAdsLoaded: false,
      isGoogleAdsConversionEventLoaded: false,
      isGoogleTagManagerLoaded: false,
      isStripeLoaded: false,
      isFacebookPixelLoaded: false,
      isQuantcastLoaded: false,
    },
    {
      set: (obj, key: keyof IScriptsLoadingState, value) => {
        obj[key] = value

        if (
          !this.wasOnLoadCalled &&
          this.onLoad &&
          this.isScriptsLoaded(this.NECESSARY_SCRIPT_KEYS)
        ) {
          this.onLoad()
          this.wasOnLoadCalled = true
        }

        if (
          !this.wasUserInfoSet &&
          this.isScriptsLoaded(this.NECESSARY_SCRIPT_KEYS) &&
          this.isScriptsLoaded(this.USER_TRACKER_KEYS)
        ) {
          this.services.tracker.setUserInfoForTrackers()
          this.wasUserInfoSet = true
        }

        return true
      },
    },
  )

  private NECESSARY_SCRIPT_KEYS: (keyof IScriptsLoadingState)[] = [
    'isGoogleIdentityLoaded',
    'isGapiLoaded',
    'isSentryLoaded',
  ]
  private USER_TRACKER_KEYS: (keyof IScriptsLoadingState)[] = [
    'isSentryLoaded',
    'isIntercomLoaded',
    'isAmplitudeLoaded',
  ]

  private wasOnLoadCalled = false
  private wasUserInfoSet = false

  constructor(store: Store) {
    // No dependencies
    googleIdentitySetup(this.scriptsLoadingState)
    const gapiPromise = gApiSetup()
    googleIntegrationSetup(this.scriptsLoadingState)
    this.setupModal()
    sentrySetup(this.scriptsLoadingState)
    const {
      stripePromise,
      subscriptionMetadataSet,
      productIdTypedProAnnual,
      productIdTypedProMonthly,
    } = new SetupStripe()
    this.stripePromise = stripePromise
    this.subscriptionMetadataSet = subscriptionMetadataSet
    this.productIdTypedProAnnual = productIdTypedProAnnual
    this.productIdTypedProMonthly = productIdTypedProMonthly
    intercomSetup(this.scriptsLoadingState)
    facebookPixelSetup(this.scriptsLoadingState)
    quantcastSetup(this.scriptsLoadingState)
    this.axiosGroup = new TypedAxiosGroup()
    this.queryClient = new QueryClient({
      defaultOptions: {
        queries: { cacheTime: Infinity, staleTime: 3 * 60 * 1000 },
      },
    })

    // Has dependencies
    this.repositories = new TypedRepositories(this.queryClient)
    this.eventLoggers = new TypedEventLoggers(
      this.axiosGroup,
      this.repositories,
      this.scriptsLoadingState,
    )
    this._APIs = new TypedAPIs(this.axiosGroup, this.subscriptionMetadataSet)

    gapiPromise.then(() => {
      // because of google picker, TypedServices should be instantiated after load gapi
      this._services = new TypedServices(
        this.repositories,
        this.APIs,
        this.queryClient,
        store,
        this.stripePromise,
        this.eventLoggers,
      )

      this.scriptsLoadingState.isGapiLoaded = true
    })
  }

  private isScriptsLoaded(keys: (keyof IScriptsLoadingState)[]) {
    return keys
      .filter((key) => !SCRIPT_KEYS_TO_IGNORE[key])
      .every((key) => this.scriptsLoadingState[key])
  }

  private setupModal() {
    if (!isTest()) {
      Modal.setAppElement('#root')
    }
  }
}

const appSettings = new (autoBind(AppSettings))(store)

addTypedAppInterceptor(appSettings.axiosGroup.typedAxios, appSettings)
addTypedGlobalInterceptor(appSettings.axiosGroup.globalAxios, appSettings)
addTypedPaymentInterceptor(appSettings.axiosGroup.paymentAxios, appSettings)

focusManager.setEventListener(onWindowFocus)

export default appSettings
