import GoogleIdentityService from '@/integrations/googleIdentityService'
import { IScriptsLoadingState } from '@/setup'

export const googleIdentitySetup = async (
  scriptsLoadingState: IScriptsLoadingState,
) => {
  const clientId = process.env.GOOGLE_DRIVE_CLIENT_ID
  if (clientId) {
    await GoogleIdentityService.init(clientId)
    scriptsLoadingState.isGoogleIdentityLoaded = true
  } else {
    console.error('Invalid google client id')
  }
}
