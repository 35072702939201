import {
  ButtonTheme,
  ThemeButtonProps,
} from '@/components/elements/ThemeButton'
import { TEXTS_WITH_LOCALE } from '@/locale/locale.util'
import { TypedSpaceMemberRole } from '@/models/space'

export enum QuotaExcessType {
  documentCount = 'documentCount',
  storage = 'storage',
  editorCount = 'editorCount',
  moreTemplate = 'moreTemplate',
}

export enum SubscriptionProduct {
  free,
  pro,
  enterprise,

  appsumoTier1,
  appsumoTier2,
  appsumoTier3,
  appsumoTier4,
}

export enum SubscriptionType {
  prevTypedProMonthly = 'prevTypedProMonthly',
  typedProAnnual = 'typedProAnnual',
  typedProAnnualDiffProduct = 'typedProAnnualDiffProduct',
  typedProMonthly = 'typedProMonthly',
  appsumoTier1 = 'appsumoTier1',
  appsumoTier2 = 'appsumoTier2',
  appsumoTier3 = 'appsumoTier3',
  appsumoTier4 = 'appsumoTier4',
}

export interface ISubscriptionMetadata {
  priceId: string
  pricePerEditorPerMonthAsDollar: number
}

export type SubscriptionMetadataSet = Partial<{
  [subscriptionType in `${SubscriptionType}`]: ISubscriptionMetadata
}>

export enum SubscriptionStatus {
  no = 'no',
  active = 'active',
  cancelRequested = 'cancel_requested',
}

export interface ITypedSubscriptionPlan {
  priceId: string
  status: SubscriptionStatus
  start: Date
  end: Date

  isPaid: boolean
  isCancelled: boolean
  isMonthly: boolean
  isAnnual: boolean
  isAppSumo: boolean
  getPricePerEditorPerMonthAsDollar(): number | undefined
  getPricePerPeriod(): number | undefined
  getSubscriptionProduct(): SubscriptionProduct
  getButtonPropsOf(
    subscriptionProduct: SubscriptionProduct,
    role: TypedSpaceMemberRole,
  ): Partial<ThemeButtonProps> & Pick<ThemeButtonProps, 'buttonTheme'>
  getButtonTextOf(
    subscriptionProduct: SubscriptionProduct,
    isKorean: boolean,
  ): string
  canUpgradeTo(subscriptionProduct: SubscriptionProduct): boolean
  getDocumentCountQuota(): number
  getEditorCountQuota(): number
}

export class NoSubscriptionPlan implements ITypedSubscriptionPlan {
  readonly priceId = ''
  readonly status = SubscriptionStatus.no
  readonly start = new Date()
  readonly end = new Date()
  readonly isPaid = false
  readonly isCancelled = false
  readonly isMonthly = false
  readonly isAnnual = false
  readonly isAppSumo = false

  getPricePerEditorPerMonthAsDollar() {
    return 0
  }

  getPricePerPeriod() {
    return 0
  }

  getSubscriptionProduct(): SubscriptionProduct {
    return SubscriptionProduct.free
  }

  getButtonPropsOf(
    subscriptionProduct: SubscriptionProduct,
    role: TypedSpaceMemberRole,
  ): Partial<ThemeButtonProps> & Pick<ThemeButtonProps, 'buttonTheme'> {
    const thisProduct = this.getSubscriptionProduct()

    if (thisProduct === subscriptionProduct) {
      return { buttonTheme: ButtonTheme.SECONDARY_WITH_OUTLINE }
    }
    return {
      buttonTheme: ButtonTheme.PRIMARY,
      isDisabled:
        subscriptionProduct !== SubscriptionProduct.enterprise &&
        role !== TypedSpaceMemberRole.OWNER,
    }
  }

  getButtonTextOf(
    subscriptionProduct: SubscriptionProduct,
    isKorean: boolean,
  ): string {
    const thisProduct = this.getSubscriptionProduct()
    const texts = TEXTS_WITH_LOCALE[isKorean ? 'ko' : 'en'].modal.spaceSettings

    if (thisProduct === subscriptionProduct) {
      return texts['Current Plan']
    }

    if (subscriptionProduct === SubscriptionProduct.pro) {
      return texts['Upgrade to Pro']
    }

    if (subscriptionProduct === SubscriptionProduct.enterprise) {
      return texts['Contact Sales 🤙']
    }

    return ''
  }

  canUpgradeTo(subscriptionProduct: SubscriptionProduct) {
    return subscriptionProduct !== SubscriptionProduct.free
  }

  getDocumentCountQuota() {
    return 1
  }

  getEditorCountQuota() {
    return Infinity
  }
}

export default class TypedSubscriptionPlan implements ITypedSubscriptionPlan {
  constructor(
    readonly priceId: string,
    readonly status: SubscriptionStatus,
    readonly start: Date,
    readonly end: Date,
    readonly subscriptionMetadataSet: SubscriptionMetadataSet,
  ) {}

  static fromJSON(
    json: ITypedSubscriptionPlan,
    subscriptionMetadataSet: SubscriptionMetadataSet,
  ) {
    return new TypedSubscriptionPlan(
      json.priceId,
      json.status,
      new Date(json.start),
      new Date(json.end),
      subscriptionMetadataSet,
    )
  }

  get isPaid() {
    return this.status === SubscriptionStatus.active
  }

  get isCancelled() {
    return this.status === SubscriptionStatus.cancelRequested
  }

  private get subscriptionType(): SubscriptionType | undefined {
    if (
      this.priceId === this.subscriptionMetadataSet.prevTypedProMonthly?.priceId
    ) {
      return SubscriptionType.prevTypedProMonthly
    }

    if (
      this.priceId === this.subscriptionMetadataSet.typedProMonthly?.priceId
    ) {
      return SubscriptionType.typedProMonthly
    }

    if (this.priceId === this.subscriptionMetadataSet.typedProAnnual?.priceId) {
      return SubscriptionType.typedProAnnual
    }

    if (
      this.priceId ===
      this.subscriptionMetadataSet.typedProAnnualDiffProduct?.priceId
    ) {
      return SubscriptionType.typedProAnnualDiffProduct
    }

    if (this.priceId === this.subscriptionMetadataSet.appsumoTier1?.priceId) {
      return SubscriptionType.appsumoTier1
    }
    if (this.priceId === this.subscriptionMetadataSet.appsumoTier2?.priceId) {
      return SubscriptionType.appsumoTier2
    }
    if (this.priceId === this.subscriptionMetadataSet.appsumoTier3?.priceId) {
      return SubscriptionType.appsumoTier3
    }
    if (this.priceId === this.subscriptionMetadataSet.appsumoTier4?.priceId) {
      return SubscriptionType.appsumoTier4
    }

    return undefined
  }

  get isMonthly() {
    return (
      this.subscriptionType === SubscriptionType.prevTypedProMonthly ||
      this.subscriptionType === SubscriptionType.typedProMonthly
    )
  }

  get isAnnual() {
    return (
      this.subscriptionType === SubscriptionType.typedProAnnual ||
      this.subscriptionType === SubscriptionType.typedProAnnualDiffProduct
    )
  }

  get isAppSumo() {
    return (
      this.subscriptionType === SubscriptionType.appsumoTier1 ||
      this.subscriptionType === SubscriptionType.appsumoTier2 ||
      this.subscriptionType === SubscriptionType.appsumoTier3 ||
      this.subscriptionType === SubscriptionType.appsumoTier4
    )
  }

  getPricePerEditorPerMonthAsDollar() {
    if (!this.subscriptionType) {
      return undefined
    }

    return this.subscriptionMetadataSet[this.subscriptionType]
      ?.pricePerEditorPerMonthAsDollar
  }

  getPricePerPeriod() {
    const pricePerMonth = this.getPricePerEditorPerMonthAsDollar()
    if (!pricePerMonth) {
      return undefined
    }

    if (this.isMonthly) {
      return pricePerMonth
    }

    if (this.isAnnual) {
      return pricePerMonth * 12
    }

    return undefined
  }

  getSubscriptionProduct(): SubscriptionProduct {
    if (
      this.subscriptionType === SubscriptionType.prevTypedProMonthly ||
      this.subscriptionType === SubscriptionType.typedProMonthly ||
      this.subscriptionType === SubscriptionType.typedProAnnual ||
      this.subscriptionType === SubscriptionType.typedProAnnualDiffProduct
    ) {
      return SubscriptionProduct.pro
    }

    if (this.subscriptionType === SubscriptionType.appsumoTier1) {
      return SubscriptionProduct.appsumoTier1
    }
    if (this.subscriptionType === SubscriptionType.appsumoTier2) {
      return SubscriptionProduct.appsumoTier2
    }
    if (this.subscriptionType === SubscriptionType.appsumoTier3) {
      return SubscriptionProduct.appsumoTier3
    }
    if (this.subscriptionType === SubscriptionType.appsumoTier4) {
      return SubscriptionProduct.appsumoTier4
    }

    return SubscriptionProduct.free
  }

  getButtonPropsOf(
    subscriptionProduct: SubscriptionProduct,
    role: TypedSpaceMemberRole,
  ): Partial<ThemeButtonProps> & Pick<ThemeButtonProps, 'buttonTheme'> {
    const thisProduct = this.getSubscriptionProduct()

    if (thisProduct === subscriptionProduct) {
      return { buttonTheme: ButtonTheme.SECONDARY_WITH_OUTLINE }
    }

    if (thisProduct < subscriptionProduct) {
      return {
        buttonTheme: ButtonTheme.PRIMARY,
        isDisabled: role !== TypedSpaceMemberRole.OWNER,
      }
    }

    return { buttonTheme: ButtonTheme.SECONDARY_WITH_OUTLINE, isDisabled: true }
  }

  getButtonTextOf(subscriptionProduct: SubscriptionProduct, isKorean: boolean) {
    const thisProduct = this.getSubscriptionProduct()
    const texts = TEXTS_WITH_LOCALE[isKorean ? 'ko' : 'en'].modal.spaceSettings

    if (thisProduct === subscriptionProduct) {
      return texts['Current Plan']
    }

    if (thisProduct < subscriptionProduct) {
      if (subscriptionProduct === SubscriptionProduct.pro) {
        return texts['Upgrade to Pro']
      }
      if (subscriptionProduct === SubscriptionProduct.enterprise) {
        return texts['Contact Sales 🤙']
      }
      if (this.isAppSumo) {
        return 'Buy Now'
      }
    }

    if (thisProduct > subscriptionProduct) {
      if (thisProduct === SubscriptionProduct.pro) {
        return texts['Upgraded to Pro']
      }
      if (thisProduct === SubscriptionProduct.enterprise) {
        return texts['Upgraded to Enterprise']
      }
      if (this.isAppSumo) {
        return `Upgraded to Tier ${
          thisProduct - SubscriptionProduct.appsumoTier1 + 1
        }`
      }
    }

    return ''
  }

  canUpgradeTo(subscriptionProduct: SubscriptionProduct) {
    const thisProduct = this.getSubscriptionProduct()
    return thisProduct < subscriptionProduct
  }

  getDocumentCountQuota() {
    if (this.getSubscriptionProduct() === SubscriptionProduct.free) {
      return 25
    }

    return Infinity
  }

  getEditorCountQuota() {
    const thisProduct = this.getSubscriptionProduct()
    switch (thisProduct) {
      case SubscriptionProduct.appsumoTier1:
        return 3
      case SubscriptionProduct.appsumoTier2:
        return 10
      case SubscriptionProduct.appsumoTier3:
        return 50
      case SubscriptionProduct.appsumoTier4:
        return 150
      default:
        return Infinity
    }
  }
}
