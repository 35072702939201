import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import TypedResource from '@/models/resource/TypedResource'

export interface IViewerCoordinateToolPositionProps {
  resourceId: string
  citationButtonPositionRatio: {
    topRatio: number
    leftRatio: number
  }
  toolPositionRatio: {
    topRatio: number
    leftRatio: number
  }
}

export interface ICitation {
  resource: TypedResource
  position: {
    topRatioFromPageTop: number
    pageIndex: number
    leftRatio: number
  }
}

export interface pdfPositionInformationForCitationButtonProps {
  pageWidth: number
  pageHeight: number
  leftPadding: number
}

interface ICitationsSlice {
  coordinateToolPosition: IViewerCoordinateToolPositionProps | null
  pdfPositionInformationForCitationButton: {
    [resourceId: string]: pdfPositionInformationForCitationButtonProps
  }
}
const initialState: ICitationsSlice = {
  coordinateToolPosition: null,
  pdfPositionInformationForCitationButton: {},
}

const citationsSlice = createSlice({
  name: 'citations',
  initialState: initialState,
  reducers: {
    setCoordinateToolPosition(
      state,
      action: PayloadAction<IViewerCoordinateToolPositionProps | null>,
    ) {
      state.coordinateToolPosition = action.payload
    },
    setPdfPositionInformationForCitationButton(state, action) {
      const { resourceId, pageWidth, pageHeight, leftPadding } = action.payload
      state.pdfPositionInformationForCitationButton = {
        ...state.pdfPositionInformationForCitationButton,
        [resourceId]: {
          pageWidth,
          pageHeight,
          leftPadding,
        },
      }
    },
  },
})

export const {
  setCoordinateToolPosition,
  setPdfPositionInformationForCitationButton,
} = citationsSlice.actions

export default citationsSlice.reducer
