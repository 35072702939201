import { SUPPORTED_LANGUAGES } from '@/locale/locale.util'
import { TokenData } from '@/models/auth'
import LocalStorageManager from '@/utils/localStorageManager'

export default class LocalStorageManagerFactory {
  private static storage: {
    [key: string]: LocalStorageManager<unknown> | undefined
  } = {}

  private static getOrCreateLSM<T>(key: string): LocalStorageManager<T> {
    const LSMExist = this.storage[key]
    if (LSMExist) {
      return LSMExist as LocalStorageManager<T>
    }

    const newLSM = new LocalStorageManager<T>(key)
    this.storage[key] = newLSM

    return newLSM
  }

  static get extensionIntentAlertClosedDate() {
    return this.getOrCreateLSM<Date>('extension-intent-alert-close')
  }

  static get extensionDialogClosedDate() {
    return this.getOrCreateLSM<Date>('extension-dialog-close')
  }

  static get splitViewerSizes() {
    return this.getOrCreateLSM<{ [name: string]: number[] | undefined }>(
      'split-sizes',
    )
  }

  static get userLanguage() {
    return this.getOrCreateLSM<SUPPORTED_LANGUAGES>('userLanguage')
  }

  static get lastCheckedVersion() {
    return this.getOrCreateLSM<string>('lastCheckedVersion')
  }

  static get readMode() {
    return this.getOrCreateLSM<boolean>('url-resource-read-mode')
  }

  static get spaceLastEnteredDates() {
    return this.getOrCreateLSM<{ [spaceId: string]: Date }>(
      'spaces-last-entered-dates',
    )
  }

  static get tokenData() {
    return this.getOrCreateLSM<TokenData>('token-data')
  }

  static get migrationFailedDocsChecked() {
    return this.getOrCreateLSM<boolean>('migration-failed-docs-checked')
  }

  static recommendFolderShowed(documentId: string) {
    return this.getOrCreateLSM<boolean>(`recommend-folder-show=${documentId}`)
  }

  static get groupOpenStatus() {
    return this.getOrCreateLSM<{ [groupId: string]: boolean }>(
      'sidebar-group-open-status',
    )
  }

  static get appHomeSearchHistoryQueue() {
    return this.getOrCreateLSM<{ [spaceId: string]: string[] }>(
      `appHomeSearchHistory`,
    )
  }

  static get lastVisitedSpaceId() {
    return this.getOrCreateLSM<{ spaceId: string }>('last-visited-space-id')
  }

  static get shouldPublicTemplateAdShow() {
    return this.getOrCreateLSM<boolean>('should-public-template-ad-show-v2')
  }

  static get tempGoogleIdToken() {
    return this.getOrCreateLSM<string>('temp-google-id-token')
  }

  static get myHomeTemplatesOpenStatus() {
    return this.getOrCreateLSM<boolean>('my-home-templates-open-status')
  }

  static get isTemplatesMenuItemNewBadgeHidden() {
    return this.getOrCreateLSM<boolean>(
      'is-templates-menu-item-new-badge-hidden',
    )
  }

  static get hackle() {
    return this.getOrCreateLSM<{ [key: string]: boolean | undefined }>('hackle')
  }

  static get featureProps() {
    return this.getOrCreateLSM<{ [key: string]: boolean }>('feature-props')
  }
}
