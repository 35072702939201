import amplitude, { AmplitudeClient } from 'amplitude-js'
import { ILoggingClient } from '.'
import { auth } from '@/integrations/firebase/firebase'

export class Amplitude implements ILoggingClient {
  private client: AmplitudeClient

  constructor() {
    this.client = amplitude.getInstance()

    this.client.init(
      process.env.AMPLITUDE_API_KEY as string,
      auth.currentUser?.uid,
      {
        includeFbclid: true,
        includeGclid: true,
        includeReferrer: true,
        includeUtm: true,
      },
    )
  }

  setUserProperty(
    id: string | null,
    email?: string,
    userInfo?: Record<string, string>,
  ) {
    this.client.setUserId(id)
    this.client.setUserProperties({ Email: email, ...userInfo })
  }

  sendEvent(eventName: string, property?: object) {
    this.client.logEvent(eventName, { ...property })
  }
}
