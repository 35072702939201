import { sendErrorLog } from '@/integrations/sentry/sentryLogger'
import { TypedDocument } from '@/models/document/TypedDocument'
import TypedFileDocument from '@/models/document/TypedFileDocument'
import TypedGoogleDocument, {
  GoogleDocumentType,
} from '@/models/document/TypedGoogleDocument'
import { TypedDocumentResource } from '@/models/resource/TypedDocumentResource'
import { TypedFileResource } from '@/models/resource/TypedFileResource'

class DownloadService {
  async download(
    data:
      | TypedFileDocument
      | TypedGoogleDocument
      | TypedFileResource
      | TypedDocumentResource,
  ) {
    const title = data instanceof TypedDocument ? data.title : data.name
    const url = this.getDownloadUrlByInstance(data)

    return this.downloadUrl(url, title)
  }

  private getDownloadUrlByInstance(
    data:
      | TypedFileDocument
      | TypedGoogleDocument
      | TypedFileResource
      | TypedDocumentResource,
  ) {
    if (data instanceof TypedFileDocument) {
      return data.origin.signedUrl
    } else if (data instanceof TypedGoogleDocument) {
      return this.getGoogleDownloadUrl(data.docType, data.origin.id)
    } else if (data instanceof TypedFileResource) {
      return data.signedUrl
    } else {
      return this.getGoogleDownloadUrl(data.docType, data.data.origin.id)
    }
  }

  private downloadUrl(url: string, title?: string) {
    try {
      const elementForDownload = document.createElement('a')
      elementForDownload.href = url
      if (title) elementForDownload.download = title
      elementForDownload.click()
      document.removeChild(elementForDownload)
    } catch (error) {
      sendErrorLog('error has occurred while download', {
        url,
        title,
      })
    }
  }

  private getGoogleDownloadUrl(docType: GoogleDocumentType, originId: string) {
    let format
    let type
    switch (docType) {
      case 'document':
        format = 'doc'
        type = 'document'
        break
      case 'spreadsheet':
        format = 'xlsx'
        type = 'spreadsheets'
        break
      case 'presentation':
        format = 'pptx'
        type = 'presentation'
        break
      default:
        throw Error('invalid docType in DownloadService.downloadGoogldDoc')
    }

    return `https://docs.google.com/${type}/d/${originId}/export?format=${format}`
  }
}

export default DownloadService
