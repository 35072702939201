import autoBind from '@/utils/autoBind'
import FolderAPI from '@/api/folderAPI'
import { TypedFolder } from '@/models/folder'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'

class FolderService {
  constructor(private folderAPI: InstanceType<typeof FolderAPI>) {}

  async getDocumentFolders(documentId: string): Promise<TypedFolder[]> {
    if (documentId === '') {
      const errorMessage = 'Document id is empty string (E0001)'
      sendErrorLog(errorMessage)
      throw Error(errorMessage)
    }
    const folders = await this.folderAPI.getDocumentFolders(documentId)
    return folders
  }
}

export default autoBind(FolderService)
