import { QueryClient, QueryKey } from 'react-query'
import { flow, map, reduce, uniqBy } from 'lodash-es'
import TypedProject from '@/models/project'
import IBaseRepository from '@/repositories/types/baseRepository'
import { queryKeys } from '@/providers/react-query'

export default class ProjectRepository
  implements IBaseRepository<TypedProject>
{
  constructor(readonly queryClient: QueryClient) {}

  add(item: TypedProject): void {
    this.queryClient.setQueryData<TypedProject[]>(
      queryKeys.projectsBySpaceId(item.spaceId),
      (prevData) => [item, ...(prevData ?? [])],
    )
  }

  addToByGroupKey(item: TypedProject): void {
    this.queryClient.setQueryData<TypedProject[]>(
      queryKeys.projectsByGroupId(item.groupId),
      (prevData) => [item, ...(prevData ?? [])],
    )
  }

  addAll(): void {
    throw new Error('Method not implemented.')
  }

  find(id: string): TypedProject | undefined {
    return this.findAll().find((project) => project.projectId === id)
  }

  findAll(spaceId?: string): TypedProject[] {
    const queriesData = this.queryClient.getQueriesData<TypedProject[]>(
      spaceId ? queryKeys.projectsBySpaceId(spaceId) : queryKeys.allProjects(),
    )

    const addProjectByQueryResult = (
      projects: [QueryKey, TypedProject[] | TypedProject][],
    ): TypedProject[] => {
      return reduce(
        projects,
        (allProjects, queryResult) => {
          const [, results] = queryResult
          if (Array.isArray(results)) {
            return [...allProjects, ...results]
          }
          if (results instanceof TypedProject) {
            return [...allProjects, results]
          }

          return [...allProjects]
        },
        [] as TypedProject[],
      )
    }

    function excludeDuplicateProjects(projects: TypedProject[]) {
      return uniqBy(projects, 'projectId')
    }

    return flow([addProjectByQueryResult, excludeDuplicateProjects])(
      queriesData,
    )
  }

  exist(id: string): boolean {
    return Boolean(this.find(id))
  }

  update(id: string, item: Partial<TypedProject>): boolean {
    const project = this.find(id)

    if (!project) {
      return false
    }

    const queriesData = this.queryClient.getQueriesData<
      TypedProject[] | TypedProject
    >({
      queryKey: queryKeys.allProjects(),
      exact: false,
    })

    queriesData.forEach(([queryKey, queryResult]) => {
      if (Array.isArray(queryResult)) {
        this.queryClient.setQueryData<TypedProject[]>(queryKey, (oldItems) => {
          return map(oldItems, (oldItem) =>
            oldItem.projectId === id ? oldItem.copyWith(item) : oldItem,
          )
        })
      } else if (queryResult instanceof TypedProject) {
        this.queryClient.setQueryData<TypedProject>(queryKey, (oldItem) => {
          if (item.projectId === oldItem?.projectId && oldItem) {
            return oldItem!.copyWith(item)
          }
          return oldItem!
        })
      }
    })

    return true
  }

  replace(items: TypedProject[]) {
    const allTheSameSpace = items.every(
      (project) => project.spaceId === items[0].spaceId,
    )
    if (!allTheSameSpace) {
      throw new Error('Every single project must have the same space!')
    }

    const spaceId = items[0].spaceId
    this.queryClient.setQueryData<TypedProject[]>(
      queryKeys.projectsBySpaceId(spaceId),
      items.filter((project) => project.spaceId === spaceId),
    )
  }

  delete(id: string): boolean {
    const project = this.find(id)

    if (!project) return false

    this.queryClient.setQueryData<TypedProject[]>(
      queryKeys.projectsBySpaceId(project.spaceId),
      (prevData) =>
        prevData!.filter(({ projectId }) => projectId !== project.projectId),
    )

    return true
  }

  deleteFromByGroupKey(id: string, groupId: string) {
    this.queryClient.setQueryData<TypedProject[]>(
      queryKeys.projectsByGroupId(groupId),
      (prevData) => prevData!.filter(({ projectId }) => projectId !== id),
    )
  }

  clear(): void {
    this.queryClient.removeQueries(queryKeys.allProjects())
  }
}
