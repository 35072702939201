import { INotificationDTO } from '@/api/notificationAPI'
import { TypedDocumentType } from '@/models/document/TypedDocument'
import { NotificationType } from '@/models/notification'
import CommentCreatedNotification from '@/models/notification/CommentCreatedNotification'
import ProjectInvitedNotification from '@/models/notification/ProjectInvitedNotification'
import ReviewAssignedNotification from '@/models/notification/ReviewAssignedNotification'
import ReviewCheckedNotification from '@/models/notification/ReviewCheckedNotification'
import TaskAssignedNotification from '@/models/notification/TaskAssignedNotification'
import { ReviewPurposeType } from '@/models/review'
import { ITypedUser } from '@/models/user'

interface IDocumentDto {
  id: string
  type: TypedDocumentType
  title: string
}

interface ITaskAssignedNotificationDTO extends INotificationDTO {
  document: IDocumentDto
  payload: {
    content: string
    assignor: Omit<ITypedUser, 'userId'>
    createdAt: string
  }
}

interface IReviewAssignedNotificationDTO extends INotificationDTO {
  document: IDocumentDto
  payload: {
    message: string
    assignor: Omit<ITypedUser, 'userId'>
    createdAt: string
  }
}

interface IReviewCheckedNotificationDTO extends INotificationDTO {
  document: IDocumentDto
  payload: {
    message: string
    checker: Omit<ITypedUser, 'userId'>
    createdAt: string
  }
}

interface IProjectInvitedNotificationDTO extends INotificationDTO {
  project: {
    id: string
    emoji: string
    title: string
  }
  payload: {
    inviter: Omit<ITypedUser, 'userId'>
    createdAt: string
  }
}
interface ICommentCreatedNotificationDTO extends INotificationDTO {
  review: {
    id: string
    purpose: ReviewPurposeType
    document: {
      id: string
      type: TypedDocumentType
      title: string
    }
  }
  payload: {
    content: string
    creator: Omit<ITypedUser, 'userId'>
    createdAt: string
  }
}
export function getNotificationInstanceByJson(noti: INotificationDTO) {
  if (taskAssignedNotificationGuard(noti)) {
    return TaskAssignedNotification.fromJSON({
      id: noti.id,
      type: noti.type,
      checked: noti.checked,
      spaceId: noti.spaceId,
      documentId: noti.document.id,
      documentType: noti.document.type,
      documentTitle: noti.document.title,
      taskContent: noti.payload.content,
      assignor: noti.payload.assignor,
      createdAt: new Date(noti.payload.createdAt),
    })
  } else if (reviewAssignedNotificationGuard(noti)) {
    return ReviewAssignedNotification.fromJSON({
      id: noti.id,
      type: noti.type,
      checked: noti.checked,
      spaceId: noti.spaceId,
      documentId: noti.document.id,
      documentType: noti.document.type,
      documentTitle: noti.document.title,
      reviewMessage: noti.payload.message,
      assignor: noti.payload.assignor,
      createdAt: new Date(noti.payload.createdAt),
    })
  } else if (reviewCheckedNotificationGuard(noti)) {
    return ReviewCheckedNotification.fromJSON({
      id: noti.id,
      type: noti.type,
      checked: noti.checked,
      spaceId: noti.spaceId,
      documentId: noti.document.id,
      documentType: noti.document.type,
      documentTitle: noti.document.title,
      reviewMessage: noti.payload.message,
      checker: noti.payload.checker,
      createdAt: new Date(noti.payload.createdAt),
    })
  } else if (projectInvitedNotificationGuard(noti)) {
    return ProjectInvitedNotification.fromJSON({
      id: noti.id,
      type: noti.type,
      checked: noti.checked,
      spaceId: noti.spaceId,
      projectId: noti.project.id,
      projectEmoji: noti.project.emoji,
      projectTitle: noti.project.title,
      inviter: noti.payload.inviter,
      createdAt: new Date(noti.payload.createdAt),
    })
  } else if (commentCreatedNotificationGuard(noti)) {
    return CommentCreatedNotification.fromJSON({
      id: noti.id,
      type: noti.type,
      checked: noti.checked,
      spaceId: noti.spaceId,
      reviewId: noti.review.id,
      reviewPurpose: noti.review.purpose,
      documentId: noti.review.document.id,
      documentType: noti.review.document.type,
      documentTitle: noti.review.document.title,
      commentContent: noti.payload.content,
      assignor: noti.payload.creator,
      createdAt: new Date(noti.payload.createdAt),
    })
  } else {
    throw Error('Unknown notification type')
  }
}

const taskAssignedNotificationGuard = (
  notificationDTO: INotificationDTO,
): notificationDTO is ITaskAssignedNotificationDTO => {
  return notificationDTO.type === NotificationType.TASK_ASSIGNED
}

const reviewAssignedNotificationGuard = (
  notificationDTO: INotificationDTO,
): notificationDTO is IReviewAssignedNotificationDTO => {
  return notificationDTO.type === NotificationType.REVIEW_ASSIGNED
}

const reviewCheckedNotificationGuard = (
  notificationDTO: INotificationDTO,
): notificationDTO is IReviewCheckedNotificationDTO => {
  return notificationDTO.type === NotificationType.REVIEW_CHECKED
}

const projectInvitedNotificationGuard = (
  notificationDTO: INotificationDTO,
): notificationDTO is IProjectInvitedNotificationDTO => {
  return notificationDTO.type === NotificationType.PROJECT_INVITED
}

const commentCreatedNotificationGuard = (
  notificationDTO: INotificationDTO,
): notificationDTO is ICommentCreatedNotificationDTO => {
  return notificationDTO.type === NotificationType.COMMENT_CREATED
}
