import { Store } from 'redux'
import TemplateAPI from '@/api/templateAPI'
import autoBind from '@/utils/autoBind'
import SpaceService from '@/services/spaceService'
import { RootState } from '@/store'
import { openModal } from '@/utils/feedback/feedbackSlice'
import { ModalType } from '@/hooks/feedback/modals'
import { QuotaExcessType } from '@/models/subscription'
import { TypedDocument } from '@/models/document/TypedDocument'
import RequestingService from '@/services/requestingService'
import { TemplateEvent } from '@/integrations/logging/event/template'

class TemplateService {
  constructor(
    readonly store: Store<RootState>,
    readonly templateAPI: InstanceType<typeof TemplateAPI>,
    readonly spaceService: InstanceType<typeof SpaceService>,
    readonly requestingService: InstanceType<typeof RequestingService>,
    readonly templateEvent: TemplateEvent,
  ) {}

  async createDocumentFromTemplate(
    spaceId: string,
    projectId: string,
    templateId: string,
  ): Promise<TypedDocument | undefined> {
    const isDocumentCountExceeded =
      await this.spaceService.willDocumentCountBeExceeded(spaceId)
    if (isDocumentCountExceeded) {
      this.store.dispatch(
        openModal({
          name: ModalType.QUOTA_EXCESS,
          data: QuotaExcessType.documentCount,
        }),
      )

      return
    }

    const skeletonIds = this.requestingService.newDocument.add([{ projectId }])
    const createdDocument = await this.templateAPI.createDocumentFromTemplate(
      projectId,
      templateId,
    )

    this.requestingService.newDocument.remove(skeletonIds)
    this.templateEvent.templateCreated({
      spaceId,
      projectId,
      templateId,
    })

    return createdDocument
  }
}

export default autoBind(TemplateService)
