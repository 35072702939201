interface ExtensionText {
  'Add the extension': string
  extensionIntallMessage: string
  ExtensionMessage: string
}

const englishExtensionText: ExtensionText = {
  'Add the extension': 'Add the extension',
  extensionIntallMessage: 'Why not make life easier?',
  ExtensionMessage: 'Add the extension to collect resources quickly and easily',
}

const koreanExtensionText: ExtensionText = {
  'Add the extension': '확장 프로그램 설치',
  extensionIntallMessage: '3배 더 빠르게 수집하는 방법!',
  ExtensionMessage:
    '확장 프로그램을 설치하면 더 빠르고 쉽게 URL을 수집할 수 있어요',
}

export { englishExtensionText, koreanExtensionText }
