import { TypedHighlight } from '@/models/highlight'

export interface IHighlightTool {
  type: 'add' | 'delete' | 'disabled'
  resourceId: string
  highlightId: string | null
  position: {
    top: number
    left: number
  }
}

export interface ISelection extends Pick<TypedHighlight, 'range' | 'text'> {}

export const TOOLTIP_TYPES: {
  ADD: 'add'
  DELETE: 'delete'
  DISABLED: 'disabled'
} = Object.freeze({
  ADD: 'add',
  DELETE: 'delete',
  DISABLED: 'disabled',
})
