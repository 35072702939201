import { AxiosInstance } from 'axios'
import {
  highlightFactory,
  ITypedHighlight,
  ITypedPDFHighlight,
  ITypedURLHighlight,
  TypedHighlight,
  TypedPDFHighlight,
  TypedURLHighlight,
} from '@/models/highlight'
import autoBind from '@/utils/autoBind'

class HighlightAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getURLHighlights(resourceId: string): Promise<TypedURLHighlight[]> {
    const pathToRequest = `/api/resources/${resourceId}/highlights`
    const result = await this.axios.get<ITypedURLHighlight[]>(pathToRequest)

    return result.data.map(TypedURLHighlight.fromJSON)
  }

  async getPDFHighlights(resourceId: string): Promise<TypedPDFHighlight[]> {
    const pathToRequest = `/api/resources/${resourceId}/highlights`
    const result = await this.axios.get<ITypedPDFHighlight[]>(pathToRequest)

    return result.data.map(TypedPDFHighlight.fromJSON)
  }

  async addHighlight(
    resourceId: string,
    highlightDataToAdd: Partial<ITypedHighlight>,
  ): Promise<TypedHighlight> {
    const pathToRequest = `/api/resources/${resourceId}/highlights`
    const result = await this.axios.post<TypedHighlight>(
      pathToRequest,
      highlightDataToAdd,
    )
    return highlightFactory(result.data)
  }

  async deleteHighlight(id: string): Promise<boolean> {
    const pathToRequest = `/api/resources/highlights/${id}`
    const result = await this.axios.delete(pathToRequest)
    return result.status === 200
  }
}

export default autoBind(HighlightAPI)
