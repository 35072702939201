import IntercomManager from '@/integrations/intercom/IntercomManager'
import { IScriptsLoadingState } from '@/setup'
import { loadScriptWithRetry } from '@/utils/loadScript'

export const intercomSetup = (scriptsLoadingState: IScriptsLoadingState) => {
  const intercomAppId = process.env.INTERCOM_APP_ID
  const w = window
  const ic = w.Intercom
  if (typeof ic === 'function') {
    ic('reattach_activator')
    ic('update', w.intercomSettings)
  } else if (intercomAppId) {
    const i = ((...args: any[]) => {
      i.c && i.c(args)
    }) as typeof window['Intercom']
    i.q = []
    i.c = (args: any[]) => {
      i.q?.push(args)
    }
    w.Intercom = i
    loadScriptWithRetry(
      `https://widget.intercom.io/widget/${intercomAppId}`,
    ).then(() => {
      scriptsLoadingState.isIntercomLoaded = true
      IntercomManager.initialize(intercomAppId)
    })
  }
}
