import AuthAPI from '@/api/authAPI'
import { AuthData, TokenData } from '@/models/auth'
import LocalStorageManagerFactory from '@/utils/common/localStorageManagerFactory'

export default class AuthService {
  tokenDataLSM = LocalStorageManagerFactory.tokenData

  constructor(readonly authAPI: InstanceType<typeof AuthAPI>) {}

  async getTypedToken(): Promise<TokenData['typedToken']> {
    const expiresAt = this.getExpiresAt()

    const isExpired = expiresAt
      ? expiresAt - new Date().getTime() < 1000 * 60 * 10
      : true

    if (isExpired) {
      const refreshToken = this.getRefreshToken()
      if (refreshToken) {
        const result = await this.refreshAccessToken(refreshToken)
        this.setTokenData({
          typedToken: result.typedToken,
          refreshToken: result.refreshToken,
          expiresAt: result.expiresAt,
        })
        return result.typedToken
      } else {
        return undefined
      }
    }
    return this.tokenDataLSM.getData()?.typedToken ?? undefined
  }

  async loginWithGoogleToken(
    typedToken: string,
  ): Promise<AuthData | undefined> {
    const userData = await this.authAPI.signInWithGoogleToken(typedToken)

    this.setTokenData({
      typedToken: userData.idToken,
      refreshToken: userData.refreshToken,
      expiresAt: userData.expiresAt,
    })

    if (userData) {
      return userData
    }
  }

  async deleteUser(): Promise<void> {
    await this.authAPI.deleteUser()
  }

  async logout(): Promise<void> {
    this.tokenDataLSM.removeData()
  }

  async getTokenDataForExtension(): Promise<TokenData> {
    const typedToken = await this.getTypedToken()
    const refreshToken = this.getRefreshToken()
    const expiresAt = this.getExpiresAt()
    return {
      typedToken,
      refreshToken,
      expiresAt,
    }
  }

  getRefreshToken(): TokenData['refreshToken'] {
    return this.tokenDataLSM.getData()?.refreshToken ?? undefined
  }

  isLoggedIn(): boolean {
    return Boolean(this.tokenDataLSM.getData()?.refreshToken)
  }

  isTypedTokenExpired(): boolean {
    const expiresAt = this.getExpiresAt()
    const isExpired = expiresAt ? expiresAt < new Date().getTime() : true
    return isExpired
  }

  async refreshAccessToken(refreshToken: string): Promise<TokenData> {
    const result = await this.authAPI.refreshToken(refreshToken)
    const tokenData = {
      typedToken: result.idToken,
      refreshToken: result.refreshToken,
      expiresAt: result.expiresAt,
    }
    this.setTokenData(tokenData)
    return tokenData
  }

  private getExpiresAt(): TokenData['expiresAt'] {
    return this.tokenDataLSM.getData()?.expiresAt ?? undefined
  }

  private setTokenData({
    typedToken,
    refreshToken,
    expiresAt,
  }: TokenData): void {
    this.tokenDataLSM.setData({ typedToken, refreshToken, expiresAt })
  }
}
