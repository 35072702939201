import React from 'react'
import styled from '@emotion/styled'

interface ErrorFallbackProps {
  error: Error
  stack: string
}

export default function ErrorFallback({ error, stack }: ErrorFallbackProps) {
  return (
    <ErrorFallbackWrapper>
      <MessageBox>
        <GoToIntercomMessage>
          An error has occurred. Click on the chat icon in the bottom right
          corner to inquire about errors.
        </GoToIntercomMessage>
        <GoToIntercomMessage>
          오류가 발생했습니다. 우측 하단 채팅 아이콘을 클릭해서 고객대응팀에
          문의해주세요.
        </GoToIntercomMessage>
        <ErrorInfoTable>
          <ErrorInfoRow>
            <InfoTitle>name / 오류명</InfoTitle>
            <InfoDetail>{error.name}</InfoDetail>
          </ErrorInfoRow>
          <ErrorInfoRow>
            <InfoTitle>message / 메세지</InfoTitle>
            <InfoDetail>{error.message}</InfoDetail>
          </ErrorInfoRow>
          <ErrorInfoRow>
            <InfoTitle>location / 발생 장소</InfoTitle>
            <InfoDetail>{stack.split('\n').shift()}</InfoDetail>
          </ErrorInfoRow>
        </ErrorInfoTable>

        <RedirectBtn
          onClick={(e) => {
            e.preventDefault()
            const currentUrl = window.location.href
            window.location.href = currentUrl
          }}
        >
          Reboot / 서비스 재실행
        </RedirectBtn>
      </MessageBox>
    </ErrorFallbackWrapper>
  )
}

const ErrorFallbackWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
`

const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 1200px;
`

const GoToIntercomMessage = styled.h1`
  font-size: 16px;
  font-weight: bold;
`

const ErrorInfoTable = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  margin-top: 36px;
`

const ErrorInfoRow = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
`

const InfoTitle = styled.div`
  flex-shrink: 0;
  width: 150px;
  white-space: nowrap;
`

const InfoDetail = styled.div`
  white-space: nowrap;
`

const RedirectBtn = styled.button`
  all: unset;
  height: 44px;
  margin-top: 36px;
  border-radius: 10px;
  padding: 4px 24px 0;
  background-color: #000;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  :hover {
    opacity: 80%;
  }
`
