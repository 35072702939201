import autoBind from '@/utils/autoBind'
import NotificationAPI from '@/api/notificationAPI'
import { Notification } from '@/models/notification'

class NotificationService {
  constructor(private notificationAPI: InstanceType<typeof NotificationAPI>) {}

  async getNotifications(): Promise<Notification[]> {
    const notifications = await this.notificationAPI.getNotifications()
    return notifications
  }

  async checkNotification(id: string) {
    await this.notificationAPI.checkNotification(id)
  }

  async checkAllNotifications() {
    await this.notificationAPI.checkAllNotifications()
  }
}

export default autoBind(NotificationService)
