interface DocumentText {
  Rename: string
  Delete: string
  shareTitle: string
  Share: string
  ShareToWeb: string
  ShareToPublic: string
  ShareToWebPublicDescription: string
  ShareToWebGuideDescription: string
  'Copy Link': string
  'Public Link': string
  Members: string
  AnyoneCanAccessInSpace: (space: string) => string
  AnyoneCanEdit: string
  AnyoneCanView: string
  EveryoneAtSpace: (space: string) => string
  copiedToastDescription: string
  numDocuments: (num: number) => string
  'Add to Favorites': string
  'Remove from Favorites': string
  FullAccess: string
  shareDescription1: string
  shareDescription2: string
  'No document has been created yet': string
  reference: string
  section: string
  permission: {
    view: string
    edit: string
    none: string
  }
  sharedPage: {
    goToTyped: string
    signUpMessage: string
    signUpButton: string
  }
  'Copy Link Toast': string
  'Access Updated': string
}

const englishDocumentText: DocumentText = {
  Rename: 'Rename',
  Delete: 'Delete',
  shareTitle: 'Share this Document',
  Share: 'Share',
  ShareToWeb: 'Share to web',
  ShareToPublic: 'Share to public',
  ShareToWebPublicDescription: 'Anyone with the link can view',
  ShareToWebGuideDescription: 'Publish and share link with anyone',
  reference: 'Reference',
  section: 'Section',
  'Copy Link': 'Copy Link',
  'Public Link': 'Public Link',
  Members: ' Members',
  AnyoneCanAccessInSpace: (space: string) =>
    `Everyone at '${space}' can access this document`,
  AnyoneCanEdit: 'Anyone with the link can edit',
  AnyoneCanView: 'Anyone with the link can view',
  EveryoneAtSpace: (space: string) => `Everyone at ${space}`,
  copiedToastDescription: 'Link copied',
  numDocuments: (num: number) => `${num} documents`,
  'Add to Favorites': 'Add to Favorites',
  'Remove from Favorites': 'Remove from Favorites',

  FullAccess: 'Full Access',
  shareDescription1: 'Anyone at ‘',
  shareDescription2: '’ can view this document.',
  'Copy Link Toast': 'Link copied to clipboard',
  'Access Updated': 'Access was updated!',
  'No document has been created yet': 'No document has been created yet',
  permission: {
    view: 'Can View',
    edit: 'Can Edit',
    none: 'No Access',
  },
  sharedPage: {
    goToTyped: 'Go To Typed',
    signUpMessage: 'Create an account to edit the document',
    signUpButton: 'Sign up',
  },
}

const koreanDocumentText: DocumentText = {
  Rename: '이름 변경',
  Delete: '삭제',
  shareTitle: '이 문서 공유',
  Share: '공유',
  ShareToWeb: '웹에서 공유',
  ShareToPublic: '웹에서 공유',
  ShareToWebPublicDescription: '링크를 통한 읽기 허용',
  ShareToWebGuideDescription: '웹에 게시하고 원하는 사람과 링크를 공유하세요',
  reference: '참고자료',
  section: '섹션',
  'Copy Link': '링크 복사',
  'Public Link': '공개 링크',
  Members: '명의 멤버',
  AnyoneCanAccessInSpace: (space: string) =>
    `'${space}'의 누구나 이 문서를 볼 수 있습니다.`,
  AnyoneCanEdit: '링크를 가진 모든 사용자는 이 문서를 변경할 수 있습니다.',
  AnyoneCanView: '링크를 가진 모든 사용자는 이 문서를 볼 수 있습니다.',
  EveryoneAtSpace: (space: string) => `${space}의 모든 사용자`,
  copiedToastDescription: '링크 복사 완료',
  numDocuments: (num: number) => `문서 ${num}개`,
  'Add to Favorites': '즐겨찾기에 추가',
  'Remove from Favorites': '즐겨찾기에서 제거',
  FullAccess: '전체 허용',
  shareDescription1: '‘',
  shareDescription2: '’의 누구나 이 문서를 볼 수 있습니다.',
  'Copy Link Toast': '링크가 복사되었습니다.',
  'Access Updated': '권한이 수정되었습니다.',
  'No document has been created yet': '아직 생성된 문서가 없습니다',
  permission: {
    view: '읽기 허용',
    edit: '편집 허용',
    none: '액세스 권한 없음',
  },
  sharedPage: {
    goToTyped: '타입드로 바로가기',
    signUpMessage: '문서를 편집하려면 타입드 계정을 생성하세요',
    signUpButton: '가입하기',
  },
}

export { englishDocumentText, koreanDocumentText }
