import axios, { AxiosError, AxiosInstance } from 'axios'

import autoBind from '@/utils/autoBind'
import { CustomError } from '@/util'
import { getNotificationInstanceByJson } from '@/factories/getNotificationInstanceByJson'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'
import { Notification, NotificationType } from '@/models/notification'

export interface INotificationDTO {
  id: string
  type: NotificationType
  checked: boolean
  spaceId: string
}

class NotificationAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getNotifications(): Promise<Notification[]> {
    try {
      const { notificationServerURL } = process.env.firebase

      const { data } = await this.axios.get<{ data: INotificationDTO[] }>(
        `${notificationServerURL}`,
      )

      return data.data.map(getNotificationInstanceByJson)
    } catch (error: unknown | CustomError | AxiosError) {
      if (error instanceof CustomError) {
        alert(error.message)
        sendErrorLog(error.message, { trace: error })
      }

      if (axios.isAxiosError(error)) {
        const { response } = error
        if (response?.status === 401) {
          sendErrorLog(
            'Token is invalid when getNotifications API called (E0005)',
          )
        } else if (response?.status === 403) {
          sendErrorLog(
            'Space id is invalid when getNotifications API called (E0006)',
          )
        } else if (response?.status === 500) {
          sendErrorLog(
            'Internal server error when getNotifications API called (E0007)',
          )
        }
      }

      sendErrorLog('Unknown exception GET getNotifications (E0008)', {
        trace: error,
      })
      throw error
    }
  }

  async checkNotification(id: string) {
    try {
      const { notificationServerURL } = process.env.firebase

      await this.axios({
        method: 'post',
        url: `${notificationServerURL}/check`,
        data: { id },
      })
    } catch (error: unknown | CustomError | AxiosError) {
      if (error instanceof CustomError) {
        alert(error.message)
        sendErrorLog(error.message, { trace: error })
      }

      if (axios.isAxiosError(error)) {
        const { response } = error as unknown as AxiosError
        if (response?.status === 400) {
          sendErrorLog(
            `Id(${id}) is not string when checkNotification API called (E0009)`,
          )
        } else if (response?.status === 401) {
          sendErrorLog(
            'Token is invalid when checkNotification API called (E0010)',
          )
        } else if (response?.status === 403) {
          sendErrorLog(
            'Space id is invalid when checkNotification API called (E0011)',
          )
        } else if (response?.status === 406) {
          sendErrorLog(
            `Id(${id}) is not Acceptable when checkNotification API called (E0012)`,
          )
        } else if (response?.status === 500) {
          sendErrorLog(
            'Internal server error when checkNotification API called (E0013)',
          )
        }
      }

      sendErrorLog('Unknown exception POST checkNotification (E0014)', {
        trace: error,
      })
      throw error
    }
  }

  async checkAllNotifications() {
    try {
      const { notificationServerURL } = process.env.firebase

      await this.axios({
        method: 'post',
        url: `${notificationServerURL}/check`,
        data: { id: 'all' },
      })
    } catch (error: unknown | CustomError | AxiosError) {
      if (error instanceof CustomError) {
        alert(error.message)
        sendErrorLog(error.message, { trace: error })
      }

      if (axios.isAxiosError(error)) {
        const { response } = error as unknown as AxiosError
        if (response?.status === 401) {
          sendErrorLog(
            'Token is invalid when checkAllNotifications API called (E0015)',
          )
        } else if (response?.status === 403) {
          sendErrorLog(
            'Space id is invalid when checkAllNotifications API called (E0016)',
          )
        } else if (response?.status === 500) {
          sendErrorLog(
            'Internal server error when checkAllNotifications API called (E0017)',
          )
        }
      }

      sendErrorLog('Unknown exception POST checkAllNotifications (E0018)', {
        trace: error,
      })
      throw error
    }
  }
}

export default autoBind(NotificationAPI)
