import errors from '@/errors'
import { TextPosition } from '@/utils/highlights'

const resolveOffsets = (element, ...offsets) => {
  let nextOffset = offsets.shift()
  const nodeIter = document.createNodeIterator(element, NodeFilter.SHOW_TEXT)

  const results = []

  let currentNode = nodeIter.nextNode()
  let textNode
  let length = 0

  while (nextOffset !== undefined && currentNode) {
    textNode = currentNode
    if (length + textNode.data.length > nextOffset) {
      results.push({ node: textNode, offset: nextOffset - length })
      nextOffset = offsets.shift()
    } else {
      currentNode = nodeIter.nextNode()
      length += textNode.data.length
    }
  }

  if (textNode) {
    while (nextOffset !== undefined && length === nextOffset) {
      results.push({ node: textNode, offset: textNode.data.length })
      nextOffset = offsets.shift()
    }
  }

  if (nextOffset !== undefined) {
    throw new errors.textRange.OffsetExceedError()
  }

  return results
}

const RESOLVE_FORWARDS = 1
const RESOLVE_BACKWARDS = 2

class TextRange {
  constructor(start, end) {
    this.start = start
    this.end = end
  }

  relativeTo(element) {
    return new TextRange(
      this.start.relativeTo(element),
      this.end.relativeTo(element),
    )
  }

  static fromOffsets(root, start, end) {
    return new TextRange(
      new TextPosition(root, start),
      new TextPosition(root, end),
    )
  }

  static fromRange(range) {
    const start = TextPosition.fromPoint(
      range.startContainer,
      range.startOffset,
    )
    const end = TextPosition.fromPoint(range.endContainer, range.endOffset)
    return new TextRange(start, end)
  }

  toRange() {
    let start
    let end

    if (
      this.start.element === this.end.element &&
      this.start.offset <= this.end.offset
    ) {
      ;[start, end] = resolveOffsets(
        this.start.element,
        this.start.offset,
        this.end.offset,
      )
    } else {
      start = this.start.resolve({ direction: RESOLVE_FORWARDS })
      end = this.end.resolve({ direction: RESOLVE_BACKWARDS })
    }

    const range = new Range()
    range.setStart(start.node, start.offset)
    range.setEnd(end.node, end.offset)
    return range
  }
}

export default TextRange
