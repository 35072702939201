import { PAGE_EVENT } from '@/integrations/logging/enum'
import { getDuration, getLifeSpan } from '@/integrations/logging/utils'
import TypedEvent from '@/integrations/logging/event/typedEvent'
import IntercomManager from '@/integrations/intercom/IntercomManager'
import { ILoggingClient } from '@/integrations/logging/client'
import { ITypedRepositories } from '@/repositories'
import autoBind from '@/utils/autoBind'

interface ICommonPageEventData {
  docId?: string
  spaceId?: string
}

interface IPageClosedEventData extends ICommonPageEventData {
  startTime?: Date
  endTime: Date
}

interface IPageRecommendationEventData extends ICommonPageEventData {
  spaceId?: string
  userId?: string
  resourceId?: string
}

interface IPageSearchTabRecommendationEventData extends ICommonPageEventData {
  docId?: string
  resourceId?: string
}

interface IPageCurrentLibraryFilteringEventData extends ICommonPageEventData {
  docId?: string
  resourceType?: string
  sortingType?: string
}

interface IViewedResourceData {
  resourceId?: string
  documentId?: string
  createdAt?: number
}
interface ISplitViewerData {
  viewerType?: string
  data?: IViewedResourceData
  docId?: string
  spaceId?: string
}
interface ISplitViewerDurationData extends ISplitViewerData {
  startTime: Date
  endTime: Date
}

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export interface IPageEvent {
  repositories: ITypedRepositories
  sendPageViewedEvent<D extends ICommonPageEventData>(
    type: `${string}_viewed`,
    data: D,
  ): void
  sendPageClosedEvent<D extends IPageClosedEventData>(
    type: `${string}_closed`,
    data: D,
  ): void
  sendPageRecommendationEvent<D extends IPageRecommendationEventData>(
    type: `current_library_recommended_resources_${string}`,
    data: D,
  ): void

  sendPageSearchTabRecommendationEvent<D extends IPageRecommendationEventData>(
    type: `document_page_search_tab_recommended_resources_${string}`,
    data: D,
  ): void
}

class PageEvent extends TypedEvent implements IPageEvent {
  constructor(
    clients: ILoggingClient[],
    readonly repositories: ITypedRepositories,
  ) {
    super(clients)
  }

  sendPageViewedEvent<D extends ICommonPageEventData>(
    type: `${string}_viewed`,
    data: D,
  ) {
    switch (type) {
      case PAGE_EVENT.SIGNUP_VIEWED:
      case PAGE_EVENT.SIGNIN_VIEWED:
      case PAGE_EVENT.MY_HOME_VIEWED:
        this.sendEvent(type, data)
        break
      case PAGE_EVENT.DOCUMENT_PAGE_VIEWED:
        this.sendDocumentPageViewedEvent(type, data)
        break
      case PAGE_EVENT.APPHOME_INBOX_RESOURCE_VIEWED:
        IntercomManager.page.sendSplitViewerViewedEvent()
        this.sendAppHomeInboxResourceViewedEvent(data as any)
        break
      case PAGE_EVENT.DOCUMENT_PAGE_RESOURCE_VIEWED:
        IntercomManager.page.sendSplitViewerViewedEvent()
        this.sendDocumentPageResourceViewedEvent(data)
        break
    }
  }

  sendPageClosedEvent<D extends IPageClosedEventData>(
    type: `${string}_closed`,
    data: D,
  ) {
    switch (type) {
      case PAGE_EVENT.APPHOME_INBOX_RESOURCE_CLOSED:
        this.sendAppHomeInboxResourceClosedEvent(data as any)
        break
      case PAGE_EVENT.DOCUMENT_PAGE_RESOURCE_CLOSED:
        this.sendDocumentPageResourceClosedEvent(data as any)
        break
      case PAGE_EVENT.SIGNUP_CLOSED:
      case PAGE_EVENT.SIGNIN_CLOSED:
        this.sendEvent(type, {
          duration: getDuration(
            data.startTime?.getTime() ?? data.endTime.getTime(),
            data.endTime.getTime(),
          ),
        })
        break
      case PAGE_EVENT.DOCUMENT_PAGE_CLOSED:
        this.sendEvent(type, {
          documentId: data.docId,
          duration: getDuration(
            data.startTime?.getTime() ?? data.endTime.getTime(),
            data.endTime.getTime(),
          ),
        })
        break
    }
  }

  sendPageRecommendationEvent<D extends IPageRecommendationEventData>(
    type: `current_library_recommended_resources_${string}`,
    data: D,
  ) {
    switch (type) {
      case PAGE_EVENT.CURRENT_LIBRARY_RECOMMENDED_RESOURCES_SECTION_OPEN:
        this.sendDocumentPageCurrentLibraryOpenRecommendationFolderEvent(
          type,
          data as any,
        )
        break
      case PAGE_EVENT.CURRENT_LIBRARY_RECOMMENDED_RESOURCES_CLICKED:
        this.sendDocumentPageCurrentLibraryClickRecommendationEvent(
          type,
          data as any,
        )
        break
      case PAGE_EVENT.CURRENT_LIBRARY_RECOMMENDED_RESOURCES_ADD_TO_LIBRARY:
        this.sendDocumentPageCurrentLibraryAddRecommendationAsReferenceEvent(
          type,
          data as any,
        )
        break
    }
  }

  sendPageSearchTabRecommendationEvent<
    D extends IPageSearchTabRecommendationEventData,
  >(type: `document_page_search_tab_recommended_resources_${string}`, data: D) {
    switch (type) {
      case PAGE_EVENT.SEARCH_TAB_RECOMMENDED_RESOURCES_CLICKED:
        this.sendDocumentPageSearchTabRecommendedResourcesClickedEvent(
          type,
          data as any,
        )
        break
      case PAGE_EVENT.SEARCH_TAB_RECOMMENDED_RESOURCES_ADD_TO_LIBRARY:
        this.sendDocumentPageSearchTabRecommendedResourcesAddToLibraryEvent(
          type,
          data as any,
        )
        break
    }
  }

  private sendAppHomeInboxResourceViewedEvent(entityData: ISplitViewerData) {
    const { viewerType, data } = entityData

    this.sendEvent(PAGE_EVENT.APPHOME_INBOX_RESOURCE_VIEWED, {
      resourceType: viewerType,
      resourceId: data?.resourceId || data?.documentId,
      resourceLifeSpan: getLifeSpan(data?.createdAt),
      spaceId: entityData.spaceId,
    })
  }

  private sendAppHomeInboxResourceClosedEvent(
    entityData: ISplitViewerDurationData,
  ) {
    const { viewerType, data, startTime, endTime } = entityData

    this.sendEvent(PAGE_EVENT.APPHOME_INBOX_RESOURCE_CLOSED, {
      resourceType: viewerType,
      resourceId: data?.resourceId || data?.documentId,
      resourceLifeSpan: getLifeSpan(data?.createdAt),
      duration: getDuration(startTime.getTime(), endTime.getTime()),
      spaceId: entityData.spaceId,
    })
  }

  private sendDocumentPageResourceViewedEvent(entityData: ISplitViewerData) {
    const { data, docId } = entityData
    const inbox = this.repositories.inbox.find()
    const isResourceInInbox = inbox?.resourceList.some(
      (resource) => resource.resourceId === data?.resourceId,
    )
    const currentDocumentFolders = this.repositories.folder.findAll(docId)
    const isResourceInCurrentDocument = currentDocumentFolders.some((folder) =>
      folder.resourceList.some((resourceId) => resourceId === data?.resourceId),
    )

    this.sendEvent(PAGE_EVENT.DOCUMENT_PAGE_RESOURCE_VIEWED, {
      resourceId: data?.resourceId || data?.documentId,
      currentAnchorResourceId: docId,
      isResourceInCurrentDocument,
      resourceLifeSpan: getLifeSpan(data?.createdAt),
      isResourceInInbox,
      spaceId: entityData.spaceId,
    })
  }

  private sendDocumentPageResourceClosedEvent(
    entityData: ISplitViewerDurationData,
  ) {
    const { data, docId, startTime, endTime } = entityData
    const inbox = this.repositories.inbox.find()
    const isResourceInInbox = inbox?.resourceList.some(
      (resource) => resource.resourceId === data?.resourceId,
    )
    const currentDocumentFolders = this.repositories.folder.findAll(docId)
    const isResourceInCurrentDocument = currentDocumentFolders.some((folder) =>
      folder.resourceList.some((resourceId) => resourceId === data?.resourceId),
    )

    this.sendEvent(PAGE_EVENT.DOCUMENT_PAGE_RESOURCE_CLOSED, {
      resourceId: data?.resourceId || data?.documentId,
      currentAnchorResourceId: docId,
      isResourceInCurrentDocument,
      resourceLifeSpan: getLifeSpan(data?.createdAt),
      isResourceInInbox,
      duration: getDuration(startTime.getTime(), endTime.getTime()),
      spaceId: entityData.spaceId,
    })
  }

  private sendDocumentPageViewedEvent(type: string, data: any) {
    this.sendEvent(type, {
      anchorResourceId: data.docId,
      spaceId: data.spaceId,
    })
  }

  private sendDocumentPageCurrentLibraryOpenRecommendationFolderEvent(
    type: string,
    data: any,
  ) {
    this.sendEvent(type, {
      spaceId: data.spaceId,
      anchorResourceId: data.docId,
      userId: data.userId,
    })
  }

  private sendDocumentPageCurrentLibraryClickRecommendationEvent(
    type: string,
    data: any,
  ) {
    this.sendEvent(type, {
      spaceId: data.spaceId,
      anchorResourceId: data.docId,
      userId: data.userId,
      resourceId: data.resourceId,
    })
  }

  private sendDocumentPageCurrentLibraryAddRecommendationAsReferenceEvent(
    type: string,
    data: any,
  ) {
    this.sendEvent(type, {
      spaceId: data.spaceId,
      anchorResourceId: data.docId,
      userId: data.userId,
      resourceId: data.resourceId,
    })
  }

  private sendDocumentPageSearchTabRecommendedResourcesClickedEvent(
    type: string,
    data: any,
  ) {
    this.sendEvent(type, {
      spaceId: data.spaceId,
      anchorResourceId: data.docId,
      userId: data.userId,
      resourceId: data.resourceId,
    })
  }

  private sendDocumentPageSearchTabRecommendedResourcesAddToLibraryEvent(
    type: string,
    data: any,
  ) {
    this.sendEvent(type, {
      spaceId: data.spaceId,
      anchorResourceId: data.docId,
      userId: data.userId,
      resourceId: data.resourceId,
    })
  }

  private sendDocumentPageCurrentLibraryResourceTypeSelectionEvent(
    type: string,
    data: any,
  ) {
    this.sendEvent(type, {
      spaceId: data.spaceId,
      anchorResourceId: data.docId,
      userId: data.userId,
      resourceType: data.resourceType,
    })
  }
}

export default autoBind(PageEvent)
