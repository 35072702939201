interface ProjectText {
  Rename: string
  Delete: string
  'Add to Favorites': string
  'Remove from Favorites': string
  numSharedProjectMemeber: (num: number) => string
  Default: string
  Descending: string
  Ascending: string
}

const englishProjectText: ProjectText = {
  Rename: 'Rename',
  Delete: 'Delete',
  'Add to Favorites': 'Add to Favorites',
  'Remove from Favorites': 'Remove from Favorites',
  numSharedProjectMemeber: (num: number) =>
    `Shared with ${num} ${num > 1 ? 'members' : 'member'}`,
  Default: 'Default',
  Descending: 'Descending',
  Ascending: 'Ascending',
}

const koreanProjectText: ProjectText = {
  Rename: '이름 변경',
  Delete: '삭제',
  'Add to Favorites': '즐겨찾기에 추가',
  'Remove from Favorites': '즐겨찾기에서 제거',
  numSharedProjectMemeber: (num: number) => `${num}명의 멤버와 공유됨`,
  Default: '기본',
  Descending: '내림차순',
  Ascending: '오름차순',
}

export { englishProjectText, koreanProjectText }
