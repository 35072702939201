import { INBOX_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class InboxEvent extends TypedEvent {
  inboxTabClickedEvent() {
    this.sendEvent(INBOX_EVENT.INBOX_TAB_CLICKED)
  }

  inboxResourceDirectionButtonClickedEvent(direction: string) {
    switch (direction) {
      case 'prev':
        this.sendEvent(INBOX_EVENT.INBOX_RESOURCE_PREVIOUS_BUTTON_CLICKED)
        break
      case 'next':
        this.sendEvent(INBOX_EVENT.INBOX_RESOURCE_NEXT_BUTTON_CLICKED)
        break
    }
  }

  inboxResourceDeleteButtonClickedEvent() {
    this.sendEvent(INBOX_EVENT.INBOX_RESOURCE_DELETE_BUTTON_CLICKED)
  }

  inboxMoveResourceButtonClickedEvent() {
    this.sendEvent(INBOX_EVENT.INBOX_MOVE_RESOURCE_BUTTON_CLICKED)
  }
}
