import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const initialState: UserActionState = {
  toCreateDocument: '',
  openedProjectIds: {},
  documentPageHistory: {},
}

interface UserActionState {
  toCreateDocument: any
  openedProjectIds: {
    [key: string]: string[]
  }
  documentPageHistory: {
    [key: string]: string[]
  }
}

interface ProjectAction {
  openingProjectId?: string
  closingProjectId?: string
  userId: string
}

interface DocumentPageAction {
  curDocumentPageDocumentId?: string
  userId: string
}

const projectActionReducers = {
  setProjectToCreateDocument: (
    state: UserActionState,
    action: PayloadAction<string>,
  ) => {
    state.toCreateDocument = action.payload
  },
  openProject: (
    state: UserActionState,
    action: PayloadAction<ProjectAction>,
  ) => {
    const { openingProjectId, userId } = action.payload
    if (!userId || !openingProjectId) return
    if (state.openedProjectIds[userId] === undefined) {
      state.openedProjectIds[userId] = []
    }

    state.openedProjectIds[userId].push(openingProjectId)
    state.openedProjectIds[userId] = Array.from(
      new Set(state.openedProjectIds[userId]),
    )
  },
  closeProject: (
    state: UserActionState,
    action: PayloadAction<ProjectAction>,
  ) => {
    const { closingProjectId, userId } = action.payload
    if (!userId) return
    state.openedProjectIds[userId] = state.openedProjectIds[userId].filter(
      (openedProjectId) => openedProjectId !== closingProjectId,
    )
  },
}

const documentPageActionReducers = {
  pushDocumentToDocumentPageHistory: (
    state: UserActionState,
    action: PayloadAction<DocumentPageAction>,
  ) => {
    const { curDocumentPageDocumentId, userId } = action.payload
    if (!curDocumentPageDocumentId || !userId) return

    const historyStack = state.documentPageHistory[userId] || []
    historyStack.unshift(curDocumentPageDocumentId)
    state.documentPageHistory[userId] = historyStack
  },

  removeDocumentFromHistory: (
    state: UserActionState,
    action: PayloadAction<DocumentPageAction>,
  ) => {
    const { curDocumentPageDocumentId, userId } = action.payload
    if (
      !curDocumentPageDocumentId ||
      !userId ||
      state.documentPageHistory[userId]
    )
      return
    state.documentPageHistory[userId] = state.documentPageHistory[
      userId
    ].filter((document) => document !== curDocumentPageDocumentId)
  },

  flushDocumentPageHistory: (
    state: UserActionState,
    action: PayloadAction<DocumentPageAction>,
  ) => {
    const { userId } = action.payload
    if (!userId) {
      state.documentPageHistory = {}
      return
    }
    state.documentPageHistory[userId] = []
  },
}

export const userActionSlice = createSlice({
  name: 'userAction',
  initialState,
  reducers: {
    ...projectActionReducers,
    ...documentPageActionReducers,
  },
})

export const {
  setProjectToCreateDocument,
  openProject,
  closeProject,
  pushDocumentToDocumentPageHistory,
  removeDocumentFromHistory,
  flushDocumentPageHistory,
} = userActionSlice.actions

export default userActionSlice.reducer
