import {
  INotification,
  Notification,
  NotificationType,
} from '@/models/notification'
import { TypedDocumentType } from '@/models/document/TypedDocument'
import { ITypedUser } from '@/models/user'

export interface ITaskAssignedNotification extends INotification {
  documentId: string
  documentType: TypedDocumentType
  documentTitle: string
  taskContent: string
  assignor: Omit<ITypedUser, 'userId'>
  createdAt: Date
}

class TaskAssignedNotification
  extends Notification
  implements ITaskAssignedNotification
{
  constructor(
    readonly id: string,
    readonly type: NotificationType,
    readonly checked: boolean,
    readonly spaceId: string,
    readonly documentId: string,
    readonly documentType: TypedDocumentType,
    readonly documentTitle: string,
    readonly taskContent: string,
    readonly assignor: Omit<ITypedUser, 'userId'>,
    readonly createdAt: Date,
  ) {
    super(id, type, checked, spaceId)
  }

  static fromJSON(json: ITaskAssignedNotification): TaskAssignedNotification {
    return new TaskAssignedNotification(
      json.id,
      json.type,
      json.checked,
      json.spaceId,
      json.documentId,
      json.documentType,
      json.documentTitle,
      json.taskContent,
      json.assignor,
      new Date(json.createdAt),
    )
  }

  copyWith(
    options?: Partial<ITaskAssignedNotification>,
  ): TaskAssignedNotification {
    return new TaskAssignedNotification(
      options?.id ?? this.id,
      options?.type ?? this.type,
      options?.checked ?? this.checked,
      options?.spaceId ?? this.spaceId,
      options?.documentId ?? this.documentId,
      options?.documentType ?? this.documentType,
      options?.documentTitle ?? this.documentTitle,
      options?.taskContent ?? this.taskContent,
      options?.assignor ?? this.assignor,
      options?.createdAt ?? this.createdAt,
    )
  }
}

export default TaskAssignedNotification
