import { AxiosInstance } from 'axios'
import {
  ITypedDocument,
  ITypedSharedDocumentLink,
  TypedDocument,
  TypedDocumentSharedPermission,
  TypedDocumentType,
} from '@/models/document/TypedDocument'
import { CustomError, HTTP_STATUS_CODES, MESSAGES } from '@/util'
import autoBind from '@/utils/autoBind'
import { getDocumentInstanceByJson } from '@/factories/getDocumentInstanceByJson'
import TypedResource from '@/models/resource/TypedResource'
import { sendErrorLog } from '@/integrations/sentry/sentryLogger'
import Pagination from '@/models/pagination/Pagination'

export type GooglePickerBuilderInstance = InstanceType<
  typeof window.google.picker.PickerBuilder
>
export type GooglePickerBuilderCallback = Parameters<
  GooglePickerBuilderInstance['setCallback']
>[0]
export type GooglePickerResponseObject =
  Parameters<GooglePickerBuilderCallback>[0]
export type GoogleDocumentObject = GooglePickerResponseObject['docs'][0]

interface PaginatedDocsResponseDTO {
  data: ITypedDocument[]
  cursor?: number
}

class DocumentAPI {
  // TODO: CustomError는 Error model로, HTTP_STATUS_CODES, MESSAGES는 constants 정도로 설계 변경
  constructor(readonly axios: AxiosInstance) {}

  async getDocumentData(documentId: string): Promise<TypedDocument> {
    const documentData = await this.axios({
      method: 'get',
      url: `/api/docs/${documentId}`,
    })

    return getDocumentInstanceByJson(documentData.data)
  }

  async getDocumentAllResourcesData(
    documentId: string,
  ): Promise<TypedResource[]> {
    const documentData = await this.axios({
      method: 'get',
      url: `/api/docs/${documentId}?include=folder`,
    })

    const folders = documentData.data.folders
    let allResourcesInDocument: TypedResource[] = []

    for (const key in folders) {
      const folder = folders[key]
      allResourcesInDocument = allResourcesInDocument.concat(folder.resources)
    }
    return allResourcesInDocument
  }

  async getDocumentsByProjectId(
    projectId: string,
    spaceId?: string,
  ): Promise<TypedDocument[]> {
    const { data } = await this.axios.get<PaginatedDocsResponseDTO>(
      '/api/docs',
      {
        headers: spaceId ? { 'x-space-id': spaceId } : {},
        params: { projectId },
      },
    )

    return data.data.filter(Boolean).map(getDocumentInstanceByJson)
  }

  async getDocumentsCreatedByMe(
    cursor: number,
    limit: number,
  ): Promise<Pagination<TypedDocument>> {
    const { data } = await this.axios.get<PaginatedDocsResponseDTO>(
      '/api/docs',
      { params: { cursor, limit, type: 'created' } },
    )

    return Pagination.fromJSON<TypedDocument>({
      data: data.data.filter(Boolean).map(getDocumentInstanceByJson),
      cursor: data.cursor ?? undefined,
    })
  }

  async getDocumentsViewedByMe(
    cursor: number,
    limit: number,
  ): Promise<Pagination<TypedDocument>> {
    const { data } = await this.axios.get<PaginatedDocsResponseDTO>(
      '/api/docs',
      { params: { cursor, limit, type: 'viewed' } },
    )

    return Pagination.fromJSON<TypedDocument>({
      data: data.data.filter(Boolean).map(getDocumentInstanceByJson),
      cursor: data.cursor ?? undefined,
    })
  }

  async createGoogleDocument({
    projectId,
    title,
    docType,
  }: {
    projectId: string
    title: string
    docType: TypedDocumentType
  }): Promise<TypedDocument> {
    try {
      const documentData = await this.axios({
        method: 'post',
        url: '/api/docs',
        data: {
          newDocumentData: { title, docType, projectId },
        },
      })

      return getDocumentInstanceByJson(documentData.data)
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error(e)
      }
      throw e
    }
  }

  async createFileDocument({
    projectId,
    file,
  }: {
    projectId: string
    file: File
  }): Promise<TypedDocument> {
    const form = buildCreateDocFormData({ projectId, file })
    try {
      const documentData = await this.axios.post('/api/docs', form, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })

      return getDocumentInstanceByJson(documentData.data)
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error(e)
      }
      throw e
    }
  }

  async createDocumentByGooglePicker(
    googleDocObj: GoogleDocumentObject[],
    importLocation: 'drive' | 'local',
    projectId: string,
  ): Promise<TypedDocument[]> {
    const requestData = {
      files: googleDocObj,
      projectId,
    }

    const documentsData = await this.axios({
      method: 'post',
      url: `/api/docs/import/${importLocation}`,
      data: requestData,
    })

    return documentsData.data.filter(Boolean).map(getDocumentInstanceByJson)
  }

  async deleteDocument(docId: string): Promise<boolean> {
    try {
      const response = await this.axios({
        method: 'delete',
        url: `/api/docs/${docId}`,
      })

      return response.status === HTTP_STATUS_CODES.OK
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        alert(MESSAGES.COMMON_ERROR)
        console.error(e)
      }
      throw e
    }
  }

  async renameDocument(newName: string, docId: string): Promise<boolean> {
    try {
      const documentsData = [
        {
          documentId: docId,
          title: newName,
        },
      ]

      const response = await this.axios({
        method: 'patch',
        url: '/api/docs/',
        data: {
          documentsData,
        },
      })

      return response.status === HTTP_STATUS_CODES.OK
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        alert(MESSAGES.COMMON_ERROR)
        console.error(e)
      }
      throw e
    }
  }

  async updateDocuments(
    updatedDocuments: {
      projectId: string
      documentId: string
      prevDocument?: string
      nextDocument?: string
    }[],
  ): Promise<boolean> {
    try {
      const response = await this.axios({
        method: 'patch',
        url: '/api/docs',
        data: {
          documentsData: updatedDocuments,
        },
      })

      return response.status === HTTP_STATUS_CODES.OK
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        alert(MESSAGES.COMMON_ERROR)
        console.error(e)
      }
      throw e
    }
  }

  async updateDocumentStatus(
    docId: string,
    isCompleted: boolean,
  ): Promise<boolean> {
    try {
      const response = await this.axios({
        method: 'patch',
        url: `/api/docs/${docId}/status`,
        data: {
          done: isCompleted,
        },
      })

      return response.status === HTTP_STATUS_CODES.OK
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        alert(MESSAGES.COMMON_ERROR)
        console.error(e)
      }
      throw e
    }
  }

  async getGoogleLink(docId: string): Promise<string> {
    try {
      const response = await this.axios({
        method: 'get',
        url: `/api/docs/${docId}/share_link`,
        params: {
          role: 'editor',
        },
      })

      return response.data.link
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        alert(MESSAGES.COMMON_ERROR)
        console.error(e)
      }
      throw e
    }
  }

  async duplicateDocument(
    spaceId: string,
    docId: string,
    docName: string,
    projectId: string,
    isCopyResources: boolean,
  ): Promise<TypedDocument> {
    try {
      const response = await this.axios({
        method: 'post',
        url: `/api/docs/${docId}/duplicate`,
        headers: {
          'x-space-id': spaceId,
        },
        data: {
          docName,
          projectId,
          isCopyResources,
        },
      })
      return getDocumentInstanceByJson(response.data)
    } catch (e) {
      if (e instanceof CustomError) {
        alert(e.message)
      } else {
        console.error(e)
      }
      throw e
    }
  }

  async getBacklinkedDocuments(): Promise<TypedDocument[]> {
    // TODO: implement
    return []
  }

  async getSharedDocLink(docId: string): Promise<ITypedSharedDocumentLink> {
    try {
      const response = await this.axios({
        method: 'post',
        url: `/api/docs/${docId}/share_link`,
      })

      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async changeSharedDocPermission(
    docId: string,
    permission: TypedDocumentSharedPermission,
  ): Promise<boolean> {
    try {
      const response = await this.axios({
        method: 'patch',
        url: `/api/docs/${docId}/share_link`,
        data: {
          permission,
        },
      })

      return response.status === HTTP_STATUS_CODES.OK
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async addDocumentToFavorites(docId: string): Promise<void> {
    await this.axios({
      method: 'post',
      url: `/api/favorite/${docId}?type=document`,
    })
  }

  async removeDocumentFromFavorites(docId: string): Promise<void> {
    await this.axios({
      method: 'delete',
      url: `/api/favorite/${docId}?type=document`,
    })
  }

  async getSharedPermission(
    docId: string,
  ): Promise<TypedDocumentSharedPermission> {
    try {
      const response = await this.axios({
        method: 'get',
        url: `/api/docs/${docId}/share_link/permission`,
      })
      return response.data.permission
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async patchLastUpdated(docId: string): Promise<void> {
    try {
      await this.axios({
        method: 'patch',
        url: `/api/docs/${docId}/last-modified`,
      })
    } catch (error) {
      sendErrorLog('Error occurred in patchLastUpdated on documentAPI', {
        docId,
        errorStace: error,
      })
      throw error
    }
  }
}

export default autoBind(DocumentAPI)

const buildCreateDocFormData = ({
  projectId,
  file,
}: {
  projectId: string
  file: File
}) => {
  const formData = new FormData()
  formData.append('type', 'file')
  formData.append('projectId', projectId)
  formData.append('file', file)
  return formData
}
