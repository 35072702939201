import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypedDragSource } from '@/models/dnd'

type DndState = {
  [dragSource in TypedDragSource]?: { isDragging: boolean }
}

const initialState: DndState = {}

export const dndSlice = createSlice({
  name: 'dragAndDrop',
  initialState,
  reducers: {
    beginDrag: (state, action: PayloadAction<TypedDragSource>) => {
      state[action.payload] = { isDragging: true }
    },
    endDrag: (state, action: PayloadAction<TypedDragSource>) => {
      state[action.payload] = { isDragging: false }
    },
  },
})

export const { beginDrag, endDrag } = dndSlice.actions

export default dndSlice.reducer
