/* https://fkhadra.github.io/react-toastify/how-to-style */

import css from '@emotion/css'
import colors from '@/styles/colors'
import shadows from '@/styles/shadows'

export default css`
  /** Used to define container behavior: width, position: fixed etc... **/
  .Toastify__toast-container .Toastify__toast {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 45px;
    min-height: unset;
    border-radius: 10px;
    padding: 0 15px;
  }

  /** Used to define the position of the ToastContainer **/
  .Toastify__toast-container.Toastify__toast-container--bottom-center {
    width: unset;
  }

  /** handle the notificaiton color and the text color based on the theme **/
  .Toastify__toast-container .Toastify__toast--error {
    background-color: ${colors.red60()};
    box-shadow: ${shadows.red15};
  }

  .Toastify__toast-container .Toastify__toast--warning {
    background-color: ${colors.yellow60()};
  }

  .Toastify__toast-container .Toastify__toast--success {
    padding-left: 10px;
    padding-right: 15px;
    background-color: ${colors.green60()};
    box-shadow: ${shadows.green15};
  }

  .Toastify__toast-container .Toastify__toast-body {
    margin: 0;
    padding: 0;
    font-family: 'Pretendard';
    font-size: 13px;
    font-weight: 500;
    text-shadow: 0px 0px 5px rgba(0, 0, 0, 20%);
    line-height: 1.25;
  }

  .Toastify__toast-container
    .Toastify__toast.Toastify__toast--success
    .Toastify__toast-body {
    display: flex;
    align-items: center;
  }

  /** Classes for the close button. Better use your own closeButton **/
  .Toastify__toast-container
    .Toastify__close-button.Toastify__close-button--error,
  .Toastify__toast-container
    .Toastify__close-button.Toastify__close-button--warning {
    display: flex;
    align-items: center;
    align-self: unset;
    margin-left: 19px;
    margin-bottom: 2px;
  }

  .Toastify__toast-container
    .Toastify__close-button.Toastify__close-button--success {
    display: none;
  }

  #inboxMove {
    .Toastify__toast-container--bottom-center {
      bottom: 130px;
    }
    .Toastify__toast--success {
      /* height: 45px; */
      padding: 16px 13px;
      background: ${colors.green50()};
    }
  }
`
