export interface MyHomeText {
  myHomeGreeting: (name: string) => string
  myHomeDescription: (spaceName: string, name: string) => string
  Templates: string
  Show: string
  Hide: string
  Favorites: string
  Documents: string
  documents: string
  'Recently viewed': string
  'Created by me': string
  'Favorite documents and projects! ⭐️': string
  'Add your first document! 🗳': string
  'Create your first Document': string
  'Create your first task': string
  'Add Document': string
  'Add new doucment': string
  'See more': string
  Progress: string
  Done: string
}

const englishMyHomeText: MyHomeText = {
  myHomeGreeting: (name: string) => `Hi, ${name}!`,
  myHomeDescription: (spaceName: string) =>
    `Welcome to ${spaceName}. Find all of your projects, documents, and tasks here!`,
  Templates: 'Templates',
  Show: 'Show',
  Hide: 'Hide',
  Favorites: 'Favorites',
  Documents: 'Documents',
  documents: 'documents',
  'Recently viewed': 'Recently viewed',
  'Created by me': 'Created by me',
  'Favorite documents and projects! ⭐️':
    'Favorite documents and projects! ⭐️',
  'Add your first document! 🗳': 'Add your first document! 🗳',
  'Create your first Document': 'Create your first Document',
  'Create your first task': 'Create your first task',
  'Add Document': 'Add Document',
  'Add new doucment': 'Add new',
  'See more': 'See more',
  Progress: 'In progress',
  Done: 'Done',
}

const koreanMyHomeText: MyHomeText = {
  myHomeGreeting: (name: string) => `안녕하세요 ${name}님!`,
  myHomeDescription: (spaceName: string, name: string) =>
    `${spaceName}에 오신걸 환영합니다. ${name}님의 프로젝트, 문서, 그리고 할 일들을 찾아보세요!`,
  Templates: '템플릿',
  Show: '열기',
  Hide: '닫기',
  Favorites: '즐겨찾기',
  Documents: '문서',
  documents: '문서',
  'Recently viewed': '최근 본 문서',
  'Created by me': '내 문서',
  'Favorite documents and projects! ⭐️':
    '즐겨찾기에 도큐먼트와 프로젝트를 추가해보세요! ⭐️',
  'Add your first document! 🗳': '첫 문서를 만들어보세요! 🗳',
  'Create your first Document': 'Create your first Document',
  'Create your first task': 'Create your first task',
  'Add Document': '문서 추가',
  'Add new doucment': '문서 추가',
  'See more': '더 보기',
  Progress: '작업중',
  Done: '완료',
}

export { englishMyHomeText, koreanMyHomeText }
