import { ITypedSelectionText } from '@/models/selectionText'
import { ITypedRange } from '@/models/range'

export interface ITypedCitationCoordData {
  readonly pageIndex: number
  readonly topRatio: number
  readonly leftRatio: number
}

export interface ITypedCitationDragData {
  readonly text: ITypedSelectionText
  readonly range: ITypedRange
}

export interface ITypedCitation {
  readonly id: string
  readonly citerResourceId: string
  readonly citedResourceId: string
  readonly citationType: string
  readonly data: ITypedCitationCoordData | ITypedCitationDragData
}

export default class TypedCitation implements ITypedCitation {
  constructor(
    readonly id: string,
    readonly citerResourceId: string,
    readonly citedResourceId: string,
    readonly citationType: string,
    readonly data: ITypedCitationCoordData | ITypedCitationDragData,
  ) {}

  static fromJSON(json: ITypedCitation) {
    return new TypedCitation(
      json.id,
      json.citerResourceId,
      json.citedResourceId,
      json.citationType,
      json.data,
    )
  }

  copyWith(options?: Partial<TypedCitation>): TypedCitation {
    return new TypedCitation(
      options?.id ?? this.id,
      options?.citerResourceId ?? this.citerResourceId,
      options?.citedResourceId ?? this.citedResourceId,
      options?.citationType ?? this.citationType,
      options?.data ?? this.data,
    )
  }
}
