import dayjs from 'dayjs'
import { TypedUser } from '@/models/user'

export enum ReviewPurposeType {
  'TO REFER' = 'toRefer',
  'COMMENT' = 'comment',
  'MUST READ' = 'mustRead',
}

export enum ReviewListType {
  'REQUEST' = 'request',
  'RECEIVE' = 'receive',
}

export enum ReviewStatus {
  REQUIRED = 'required',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}

export const ReviewPurposeList = [
  ReviewPurposeType['TO REFER'],
  ReviewPurposeType.COMMENT,
  ReviewPurposeType['MUST READ'],
]

export interface ReviewPreviewMessage {
  documentTitle: string
  purpose: ReviewPurposeType
  messageContent: string
  dueDate: Date | null
}

export interface IReviewerInfo {
  id: string
  displayName: string
  isConfirmed: boolean
  photo: string
}
export class ReviewerInfo implements IReviewerInfo {
  constructor(
    readonly id: string,
    readonly displayName: string,
    public isConfirmed: boolean,
    readonly photo: string,
  ) {}

  changeConfirm(confirm: boolean) {
    this.isConfirmed = confirm
  }

  static fromJSON(json: IReviewerInfo): ReviewerInfo {
    return new ReviewerInfo(
      json.id,
      json.displayName,
      json.isConfirmed,
      json.photo,
    )
  }

  toJSON(): IReviewerInfo {
    return {
      id: this.id,
      displayName: this.displayName,
      isConfirmed: this.isConfirmed,
      photo: this.photo,
    }
  }

  copyWith(options?: Partial<ReviewerInfo>): ReviewerInfo {
    return new ReviewerInfo(
      options?.id ?? this.id,
      options?.displayName ?? this.displayName,
      options?.isConfirmed ?? this.isConfirmed,
      options?.photo ?? this.photo,
    )
  }
}
export interface IReview {
  readonly id: string
  readonly purpose: ReviewPurposeType
  readonly dueDate: Date
  readonly message: string
  readonly createdBy: TypedUser
  readonly documentId: string
  readonly reviewers: ReviewerInfo[]
  readonly createdAt: Date
  readonly status: ReviewStatus
}

export class Review implements IReview {
  constructor(
    readonly id: string,
    readonly purpose: ReviewPurposeType,
    readonly dueDate: Date,
    readonly message: string,
    readonly createdBy: TypedUser,
    readonly documentId: string,
    readonly reviewers: ReviewerInfo[],
    readonly createdAt: Date,
    readonly status: ReviewStatus = ReviewStatus.REQUIRED,
  ) {}

  isExpired() {
    return dayjs(this.dueDate).isBefore(new Date(), 'date')
  }

  reviewerInfoByUserId(userId: string): ReviewerInfo | undefined {
    return this.reviewers.find((reviewer) => reviewer.id === userId)
  }

  isReviewerConfirmed(userId: string): boolean {
    const reviewer = this.reviewerInfoByUserId(userId)
    if (!reviewer) return false
    return reviewer.isConfirmed
  }

  isAllReviewerConfirmed(): boolean {
    return this.reviewers.every((reviewer) => reviewer.isConfirmed)
  }

  changeReviewerConfirmStatus(userId: string, confirm: boolean): void {
    const reviewer = this.reviewerInfoByUserId(userId)
    if (!reviewer) return
    reviewer.changeConfirm(confirm)
  }

  static fromJSON(json: IReview): Review {
    return new Review(
      json.id,
      json.purpose,
      json.dueDate,
      json.message,
      TypedUser.fromJSON(json.createdBy),
      json.documentId,
      json.reviewers.map(ReviewerInfo.fromJSON),
      json.createdAt,
      json.status,
    )
  }

  toJSON(): IReview {
    return {
      id: this.id,
      purpose: this.purpose,
      dueDate: this.dueDate,
      message: this.message,
      createdBy: this.createdBy,
      documentId: this.documentId,
      reviewers: this.reviewers,
      createdAt: this.createdAt,
      status: this.status,
    }
  }

  copyWith(options?: Partial<Review>): Review {
    return new Review(
      options?.id ?? this.id,
      options?.purpose ?? this.purpose,
      options?.dueDate ?? this.dueDate,
      options?.message ?? this.message,
      options?.createdBy ?? this.createdBy,
      options?.documentId ?? this.documentId,
      options?.reviewers ?? this.reviewers,
      options?.createdAt ?? this.createdAt,
      options?.status ?? this.status,
    )
  }
}

export interface IReviewComment {
  readonly id: string
  readonly contents: string
  readonly createdBy: TypedUser
  readonly createdAt: Date
}

export class ReviewComment implements IReviewComment {
  constructor(
    readonly id: string,
    readonly contents: string,
    readonly createdBy: TypedUser,
    readonly createdAt: Date,
  ) {}

  static fromJSON(json: IReviewComment): ReviewComment {
    return new ReviewComment(
      json.id,
      json.contents,
      TypedUser.fromJSON(json.createdBy),
      json.createdAt,
    )
  }

  toJSON(): IReviewComment {
    return {
      id: this.id,
      contents: this.contents,
      createdBy: this.createdBy,
      createdAt: this.createdAt,
    }
  }
}
