interface HomeViewText {
  headerNavigation: { [key: string]: string }
  header: {
    signIn: string
    signUp: string
    goToTyped: string
  }
  hero: { [key: string]: string }
  testimonial: {
    [key: string]: {
      name: string
      role: string
      description: string
    }
  }
  futureFeatures: {
    network: {
      title: string
      description: string
    }
    recommendation: {
      title: string
      description: string
    }
    search: {
      title: string
      description: string
    }
  }
  blockTitle: {
    testimonialHeader: string
    companyTypersHeader: string
  }
  footer: {
    [key: string]: string
  }
}

const englishHomeViewText: HomeViewText = {
  headerNavigation: {
    Company: 'https://www.typed.biz/about',
    Learn:
      'https://businesscanvas.notion.site/Typed-User-Guide-a3c6c767daa9497ab3bb28db5e621067',
    Blog: 'https://typed.blog',
    Pricing: 'https://www.typed.biz/pricing?lang=en',
  },
  header: {
    signIn: 'Sign in',
    signUp: 'Start 1-month Trial',
    goToTyped: 'Go to Typed',
  },
  hero: {
    trying: 'Typed Now',
    request: 'Request Demo',
  },
  testimonial: {
    student: {
      name: 'Ethan Romero',
      role: 'Student, Yale University',
      description:
        'Typed is the (not-so) secret tool I use to get an advantage in all my classes and produce work that I’m really proud of. As a student, it is difficult to source, organize, and synthesize all your ideas for a project.  Typed is the only tool that allows me to solely focus on efficiently turning my ideas into reality.',
    },
    enterprise: {
      name: 'Tim Paolini',
      role: 'Sales Lead, Google',
      description:
        'Typed upgrades Google Docs, Slides, and Sheets into a fully immersive workspace where you can maximize your productivity like never before. If you or your team deals with a lot of research and writing at work, this is just the right tool for you.',
    },
    faculty: {
      name: 'Peter Bae',
      role: 'University Librarian, Princeton University',
      description:
        'Typed reminds me of when I was writing on paper with various  reference resources spread around my desk. Having your references  at the same place where you write is the most significant advantage  only Typed can offer.',
    },
    entrepreneur: {
      name: 'Johann Romefort',
      role: 'Co-Founder & CTO, Seesmic(Hootsuite)',
      description:
        'Typed is amazing! It brings all my documents together in the most seamless way possible and allow me to focus on the work to be done instead of tab-switching.',
    },
    consultant: {
      name: 'Juli Cho',
      role: 'Consultant, Boston Consulting Group',
      description:
        'Typed’s ‘Backlinks’ are a must if you ever have had to deal with scattered information and files. Typed’s knowledge network will minimize unncessary effort and optimize my output, saving me a ton of time for the things that actually matter.',
    },
    'venture Capital': {
      name: 'Eamonn Carey',
      role: 'Managing Director, Techstars London',
      description:
        'Typed merges research and writing into a single activity by curating the information relavent to my writing.  Not only is it time-saving and extremely easy to use, but it also dramatically improves my thought process by helping me connect the dots faster.',
    },
  },
  blockTitle: {
    testimonialHeader: 'Testimonial',
    companyTypersHeader: 'Where Typers are from',
  },
  futureFeatures: {
    network: {
      title: 'Stop wandering your folders',
      description:
        'Typed’s knowledge network weaves all your work so that nothing is siloed and lost.',
    },
    recommendation: {
      title: 'Two heads are better than one',
      description:
        "With Typed's Second Brain technology, we recommend resources to you so can achieve twice as much in half the time.",
    },
    search: {
      title: 'All signal, no noise',
      description:
        'Unified search gives you immediate insight into all your research across every platform',
    },
  },
  footer: {
    home: 'Home',
    company: 'Company',
    career: 'Career',
    notice: 'Notice',
    reviews: 'Reviews',
    learn: 'Learn',
    tutorial: 'Tutorial Video',
    whitePaper: 'White Paper',
    request: 'Request a Demo',
    blog: 'Blog',
    youtube: 'Youtube',
    twitter: 'Twitter',
    discord: 'Discord Community',
    koreanAddress: '🇰🇷  242 Teheran-ro, Gangnam-gu, Seoul',
  },
}

const koreanHomeviewText: HomeViewText = {
  headerNavigation: {
    회사: 'https://www.typed.biz/about',
    '사용 가이드':
      'https://businesscanvas.notion.site/Typed-aa28b00ce2e9437aa39c372e3a3d2b4d',
    채용: 'https://www.typed.biz/career',
    가격: 'https://www.typed.biz/pricing?lang=ko',
  },
  header: {
    signIn: '로그인',
    signUp: '한 달 무료 체험하기',
    goToTyped: '바로 시작하기',
  },
  hero: {
    trying: '무료 체험하기',
    request: '온보딩 신청하기',
  },
  testimonial: {
    student: {
      name: 'Ethan Romero',
      role: '학생, Yale 대학교',
      description:
        '타입드에서 문서를 작성하다 보면 저절로 제 생각들이 정리되는 것 같아요. 그동안 문서를 작성할 때면, 정작 문서를 작성하는 시간보다 자료들을 찾고 정리하는데 더 많은 시간을 썼던 것 같아요. 이제는 타입드를 통해 편하게 자료를 수집하고 조회할 수 있다는 점이 참 좋습니다.',
    },
    enterprise: {
      name: 'Tim Paolini',
      role: '세일즈 총괄, Google',
      description:
        '타입드는 제가 기존에 사용하던 구글 문서, 시트, 슬라이드를 더욱 효율적으로 활용할 수 있도록 도와주고 있어요. 다뤄야 하는 정보가 많아 걱정이라면, 타입드를 꼭 추천드리고 싶습니다.',
    },
    faculty: {
      name: 'Peter Bae',
      role: '대학 도서관 사서, Princeton 대학교 도서관',
      description:
        '타입드를 사용하다 보면 넓은 책상 위에 가지런히 참고 자료들을 펼쳐놓고 리포트를 쓰던 기억이 떠오릅니다. 제가 지금 작성하고 있는 글과 각종 참고 자료들을 한곳에서 같이 보면서 일할 수 있다는 점이 타입드만의 강점이라고 생각합니다.',
    },
    entrepreneur: {
      name: 'Johann Romefort',
      role: 'Seesmic (Hootsuite), Co-Founder & CTO',
      description:
        '타입드는 제가 작성한 수많은 문서들의 관계성을 정의해줍니다. 덕분에 다양한 자료들을 관리함에 있어서 각각의 맥락을 잊지 않을 수 있어요. 또 타입드를 사용하다보면 번거롭게 여러 탭과 어플을 오가는 일을 줄일 수 있다는 점이 편리한 것 같아요.',
    },
    consultant: {
      name: 'Juli Cho',
      role: '컨설턴트, Boston Consulting Group ',
      description:
        '타입드의 백링크 기능은 흩어진 수많은 자료들을 정리하기 위한 가장 이상적인 방법입니다. 앞으로 타입드에 지식 네트워크 기능까지 보완된다면, 제가 지금까지 자료를 체계적으로 관리하기 위해 낭비했던 수많은 시간들을 절약할 수 있을 것이라 기대하고 있어요.',
    },
    'venture Capital': {
      name: 'Eamonn Carey',
      role: '프로젝트 총괄, Techstars London',
      description:
        '타입드는 그동안 제가 분리된 활동이라고 생각해왔던 ‘자료 수집’과 ‘문서 작성’이라는 두가지 업무를 동시에 진행할 수 있도록 도와줍니다. 사용법이 간단하며, 업무 시간이 단축되는 것은 물론, 근본적으로 문서 작성과 자료 관리가 쉬워진다는 점이 타입드만의 강점입니다.',
    },
  },
  blockTitle: {
    testimonialHeader: '사용자 후기',
    companyTypersHeader: 'Typed는 전세계 Typer들과 함께하고 있습니다.',
  },
  futureFeatures: {
    network: {
      title: '지식 네트워크 캔버스',
      description:
        '웹 자료와 모든 포맷의 파일 간의 관계를 시각화하여 보여주는 지식 네트워크 캔버스로 폴더 관리에서 벗어나세요.',
    },
    recommendation: {
      title: '자료 추천 기능',
      description:
        '작성 중인 문서에 맞춤화된 자료 추천 기능을 통해 생각의 폭을 확장하고, 정보의 종합과 활용에 온전히 몰입해보세요.',
    },
    search: {
      title: '통합 검색 기능',
      description:
        '이메일, 드라이브, 클라우드 등을 아우르는 통합 검색 기능을 통해 흩어져있던 자료들을 한눈에 확인하세요.',
    },
  },
  footer: {
    home: '홈',
    company: '회사 정보',
    career: '인재 채용',
    notice: '공지사항',
    reviews: '리뷰',
    learn: '유저 가이드',
    tutorial: '튜토리얼',
    whitePaper: '제품 철학',
    request: '온보딩 신청하기',
    blog: '블로그',
    youtube: '유튜브',
    twitter: '트위터',
    discord: '디스코드',
    koreanAddress: '🇰🇷  서울 강남구 테헤란로 242 아이타워 8층',
  },
}

export { englishHomeViewText, koreanHomeviewText }
