import { DOCUMENT_EVENT } from '@/integrations/logging/enum'
import { getLifeSpan } from '@/integrations/logging/utils'
import TypedEvent from '@/integrations/logging/event/typedEvent'
import { ITypedDocument } from '@/models/document/TypedDocument'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class DocumentEvent extends TypedEvent {
  createdEvent(data: {
    anchorResourceId: string
    type: ITypedDocument['docType']
    addedFrom: string
    createdLocation: string
    spaceId: string
    projectId: string
  }) {
    this.sendEvent(DOCUMENT_EVENT.CREATED, data)
  }

  deletedEvent(data: any) {
    this.sendEvent(DOCUMENT_EVENT.DELETED, {
      anchorResourceId: data.documentId,
      lifeSpan: getLifeSpan(data.createdAt),
    })
  }

  documentListAddDocumentButtonClickedEvent() {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_LIST_ADD_DOCUMENT_BUTTON_CLICKED)
  }

  documentMovedToOtherProjectEvent({
    resourceId,
    userId,
    sourceProjectId,
    targetProjectId,
  }: {
    resourceId: string
    userId: string
    sourceProjectId: string
    targetProjectId: string
  }) {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_MOVED_TO_OTHER_PROJECT, {
      resourceId,
      userId,
      timestamp: new Date().toISOString(),
      sourceProjectId,
      targetProjectId,
    })
  }

  documentPageHistoryButtonClicked(resourceId: string) {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_PAGE_HISTORY_BUTTON_CLICKED, {
      anchorResourceId: resourceId,
    })
  }

  documentPageHistoryAddToLibraryEvent(
    resourceId: string,
    addedResourceId: string,
  ) {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_PAGE_HISTORY_ADD_TO_LIBRARY, {
      anchorResourceId: resourceId,
      resourceId: addedResourceId,
    })
  }

  documentPageHeaderBacklinkButtonClickedEvent(
    resourceId: string,
    backlinkedResourceIds: string[],
  ) {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_PAGE_BACKLINK_INFO_CLICKED, {
      anchorResourceId: resourceId,
      backlinkedResourceIds,
    })
  }

  documentPageBacklinkAddToLibraryEvent(
    resourceId: string,
    addedResourceId: string,
  ) {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_PAGE_BACKLINK_ADD_TO_LIBRARY, {
      anchorResourceId: resourceId,
      addedResourceId,
    })
  }

  documentPageBacklinkOpenResourceEvent(
    resourceId: string,
    openedResourceId: string,
  ) {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_PAGE_BACKLINK_OPEN_RESOURCE, {
      anchorResourceId: resourceId,
      resourceId: openedResourceId,
    })
  }

  documentPagePageViewsClickedEvent(resourceId: string) {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_PAGE_PAGE_VIEWS_CLICKED, {
      anchorResourceId: resourceId,
    })
  }

  documentPageShareClickedEvent(resourceId: string) {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_PAGE_SHARE_CLICKED, {
      anchorResourceId: resourceId,
    })
  }

  documentPageShareInternalUrlCopyEvent(resourceId: string) {
    this.sendEvent(DOCUMENT_EVENT.DOCUMENT_PAGE_SHARE_INTERNAL_URL_COPY, {
      anchorResourceId: resourceId,
    })
  }
}
