class ApiError extends Error {
  constructor(readonly name = 'ApiError', message: string) {
    super(message)
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

class RequestFailError extends ApiError {
  constructor() {
    super('RequestFailError', 'API request has failed!')
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

class StripePromotionCodeNotFoundError extends ApiError {
  constructor() {
    super(
      'StripePromotionCodeNotFoundError',
      'Stripe promotion code is not found!',
    )
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

class GdriveInsufficientFilePermissionsError extends ApiError {
  constructor() {
    super(
      'GdriveInsufficientFilePermissionsError',
      'The user does not have sufficient permissions for file!',
    )
    Object.setPrototypeOf(this, new.target.prototype)
  }
}

export default {
  ApiError,
  RequestFailError,
  StripePromotionCodeNotFoundError,
  GdriveInsufficientFilePermissionsError,
}
