import { TypedDocumentResource } from '@/models/resource/TypedDocumentResource'
import { TypedFileResource } from '@/models/resource/TypedFileResource'
import TypedResource from '@/models/resource/TypedResource'
import { TypedTextResource } from '@/models/resource/TypedTextResource'
import TypedUrlResource from '@/models/resource/TypedUrlResource'

export const getResourceInstanceByJson = (resourceJson: any): TypedResource => {
  switch (resourceJson?.type) {
    case 'url':
      return TypedUrlResource.fromJSON(resourceJson)
    case 'document':
      return TypedDocumentResource.fromJSON(resourceJson)
    case 'text':
      return TypedTextResource.fromJSON(resourceJson)
    case 'file':
      return TypedFileResource.fromJSON(resourceJson)
    default:
      throw new Error('Invalid resource type')
  }
}
