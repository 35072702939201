import { QueryClient } from 'react-query'
import IBaseRepository from '@/repositories/types/baseRepository'
import { queryKeys } from '@/providers/react-query'
import TypedSpace from '@/models/space'

export default class SpaceRepository implements IBaseRepository<TypedSpace> {
  constructor(readonly queryClient: QueryClient) {}

  add(item: TypedSpace): void {
    this.queryClient.setQueryData<TypedSpace[]>(
      queryKeys.allSpaces(),
      (prevData) => {
        if (!prevData) {
          return [item]
        }

        const nextData = [...prevData]
        const existingIndex = prevData.findIndex(
          (space) => space.id === item.id,
        )
        if (existingIndex === -1) {
          nextData.push(item)
        } else {
          nextData[existingIndex] = item
        }
        return nextData
      },
    )
  }

  addAll(): never {
    throw new Error('Method not implemented.')
  }

  find(id: string): TypedSpace | undefined {
    const allSpaces = this.findAll()
    return allSpaces?.find((space) => space.id === id)
  }

  findAll(): TypedSpace[] {
    return (
      this.queryClient.getQueryData<TypedSpace[]>(queryKeys.allSpaces()) ?? []
    )
  }

  exist = (id: string): boolean => {
    return Boolean(this.find(id))
  }

  update(id: string, itemToUpdate: Partial<TypedSpace>): boolean {
    const spaceToUpdate = this.find(id)

    if (!spaceToUpdate) {
      return false
    }

    this.queryClient.setQueryData<TypedSpace[]>(
      queryKeys.allSpaces(),
      (oldData) => {
        if (!oldData) return [] as TypedSpace[]

        const newData = oldData.map((space) =>
          space.id === id ? space.copyWith(itemToUpdate) : space,
        )

        return newData
      },
    )

    return true
  }

  delete(id: string): boolean {
    if (!this.exist(id)) {
      return false
    }

    this.queryClient.setQueryData<TypedSpace[]>(
      queryKeys.allSpaces(),
      (oldData) => (oldData ?? []).filter((space) => space.id !== id),
    )

    return true
  }

  clear(): void {
    this.queryClient.removeQueries(queryKeys.allSpaces(), { exact: false })
  }
}
