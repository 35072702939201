import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IMyUserData {
  uid: string
  userDisplayName: string
  displayName: string
  imageURL: string
  originalImageURL: string
  email: string
}

interface IAuthState {
  data?: IMyUserData
}

const initialState: IAuthState = {
  data: undefined,
}

export const authActionSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<IMyUserData>) => {
      const {
        uid,
        userDisplayName,
        displayName,
        imageURL,
        originalImageURL,
        email,
      } = action.payload

      state.data = {
        uid,
        userDisplayName,
        displayName,
        imageURL,
        originalImageURL,
        email,
      }
    },
    logout: (state) => {
      state.data = undefined
    },
    updateProfile: (
      state,
      action: PayloadAction<
        Partial<Pick<IMyUserData, 'imageURL' | 'userDisplayName'>>
      >,
    ) => {
      if (state.data) {
        state.data = {
          ...state.data,
          userDisplayName:
            action.payload.userDisplayName ?? state.data.userDisplayName,
          imageURL: action.payload.imageURL ?? state.data.imageURL,
        }
      } else {
        console.error('Tried to call updateProfile without user data')
      }
    },
  },
})

export const { login, logout, updateProfile } = authActionSlice.actions

export default authActionSlice.reducer
