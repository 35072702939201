import { Stripe } from '@stripe/stripe-js'
import { QueryClient } from 'react-query'
import { Store } from 'redux'
import { ITypedAPIs } from '@/api'
import { ITypedEventLoggers } from '@/integrations/logging/event/typedEventLoggers'
import { ITypedRepositories } from '@/repositories'
import HighlightService from '@/services/highlightService'
import HighlightDOMService from '@/services/highlightDOMService'
import SubscriptionService from '@/services/subscriptionService'
import InboxService from '@/services/inboxService'
import ProjectService from '@/services/projectService'
import ResourceService from '@/services/resourceService'
import ExtensionService from '@/services/extensionService'
import TaskService from '@/services/taskService'
import SpaceService from '@/services/spaceService'
import AuthService from '@/services/authService'
import SearchService from '@/services/searchService'
import DocumentService from '@/services/documentService'
import DuplicateService from '@/services/duplicateService'
import ResourceAdderService from '@/services/resourceAdderService'
import LogService from '@/services/logService'
import GroupService from '@/services/groupService'
import MyHomeService from '@/services/myHomeService'
import ClipboardService from '@/services/clipboardService'
import GooglePickerService from '@/services/googlePickerService'
import ResourceRepository from '@/repositories/resourceRepository'
import RequestingService from '@/services/requestingService'
import TrackerService from '@/services/trackerService'
import LoginFlowService from '@/services/loginFlowService'
import TemplateService from '@/services/templateService'
import ReviewService from '@/services/reviewService'
import SlackIntegrationService from '@/services/slackIntegrationService'
import NotificationService from '@/services/notificationService'
import FolderService from '@/services/folderService'
import DownloadService from '@/services/DownloadService'
import citationService from '@/services/citationService'
import ViewerService from '@/services/ViewerService'

export default class TypedServices {
  inbox: InstanceType<typeof InboxService>
  highlight: InstanceType<typeof HighlightService>
  highlightDOM: InstanceType<typeof HighlightDOMService>
  subscription: InstanceType<typeof SubscriptionService>
  project: InstanceType<typeof ProjectService>
  resource: InstanceType<typeof ResourceService>
  extension: InstanceType<typeof ExtensionService>
  task: InstanceType<typeof TaskService>
  space: InstanceType<typeof SpaceService>
  auth: InstanceType<typeof AuthService>
  search: InstanceType<typeof SearchService>
  group: InstanceType<typeof GroupService>
  document: InstanceType<typeof DocumentService>
  duplicate: InstanceType<typeof DuplicateService>
  resourceAdder: InstanceType<typeof ResourceAdderService>
  log: InstanceType<typeof LogService>
  myHome: InstanceType<typeof MyHomeService>
  clipboard: InstanceType<typeof ClipboardService>
  requesting: InstanceType<typeof RequestingService>
  template: InstanceType<typeof TemplateService>
  tracker: InstanceType<typeof TrackerService>
  loginFlow: InstanceType<typeof LoginFlowService>
  review: InstanceType<typeof ReviewService>
  slackIntegration: InstanceType<typeof SlackIntegrationService>
  notification: InstanceType<typeof NotificationService>
  folder: InstanceType<typeof FolderService>
  download: InstanceType<typeof DownloadService>
  citation: InstanceType<typeof citationService>
  viewer: InstanceType<typeof ViewerService>

  constructor(
    readonly repositories: ITypedRepositories,
    readonly APIs: ITypedAPIs,
    readonly queryClient: QueryClient,
    readonly store: Store,
    readonly stripePromise: Promise<Stripe | null>,
    readonly eventLoggers: ITypedEventLoggers,
  ) {
    const authService = new AuthService(APIs.auth)
    const spaceService = new SpaceService(
      queryClient,
      store,
      repositories.space,
      APIs.space,
      eventLoggers.spaceEvent,
    )
    const duplicateService = new DuplicateService(
      store,
      APIs.resource,
      APIs.folder,
      repositories.folder,
    )
    const resourceRepository = new ResourceRepository(queryClient)
    const clipboardService = new ClipboardService(store, resourceRepository)
    const googlePickerService = new GooglePickerService(
      APIs.auth,
      window.google?.picker,
    )
    const requestingService = new RequestingService(store)
    const inboxService = new InboxService(
      repositories.inbox,
      repositories.folder,
      repositories.resource,
      APIs.resource,
      APIs.highlight,
      APIs.folder,
      APIs.inbox,
      store,
      eventLoggers.resourceEvent,
      duplicateService,
      clipboardService,
      googlePickerService,
      requestingService,
    )
    const resourceAdderService = new ResourceAdderService(
      store,
      spaceService,
      requestingService,
      googlePickerService,
      duplicateService,
      APIs.resource,
      APIs.folder,
      repositories.resource,
      repositories.folder,
      eventLoggers.resourceEvent,
    )
    const reviewService = new ReviewService(APIs.review)
    const slackService = new SlackIntegrationService(APIs.integration)
    const downloadService = new DownloadService()
    const viewerService = new ViewerService()
    this.inbox = inboxService
    this.highlight = new HighlightService(
      repositories.highlight,
      repositories.resource,
      APIs.highlight,
      store,
    )
    this.highlightDOM = new HighlightDOMService(store)
    this.project = new ProjectService(
      repositories.project,
      repositories.favorite,
      eventLoggers.projectEvent,
      APIs.project,
    )
    this.resource = new ResourceService(
      spaceService,
      repositories.resource,
      APIs.resource,
      store,
      eventLoggers.resourceEvent,
    )
    this.auth = authService
    this.extension = new ExtensionService(window.chrome?.runtime, authService)
    this.task = new TaskService(APIs.task, APIs.auth, eventLoggers.taskEvent)
    this.space = spaceService
    this.search = new SearchService(APIs.search)
    this.group = new GroupService(
      repositories.group,
      APIs.group,
      repositories.project,
      eventLoggers.groupEvent,
    )
    this.document = new DocumentService(
      store,
      queryClient,
      APIs.document,
      APIs.folder,
      APIs.resource,
      APIs.auth,
      repositories.project,
      repositories.folder,
      repositories.space,
      eventLoggers.documentEvent,
      eventLoggers.resourceEvent,
      window.google?.picker,
      spaceService,
      googlePickerService,
      requestingService,
    )
    this.duplicate = duplicateService
    this.resourceAdder = resourceAdderService
    this.log = new LogService(APIs.log, queryClient)
    this.myHome = new MyHomeService(APIs.document)
    this.clipboard = clipboardService
    this.requesting = requestingService
    this.tracker = new TrackerService(
      store,
      APIs.user,
      eventLoggers,
      authService,
    )
    this.loginFlow = new LoginFlowService(
      store,
      APIs.auth,
      APIs.user,
      eventLoggers,
      authService,
      this.extension,
      this.tracker,
    )
    this.template = new TemplateService(
      store,
      APIs.template,
      spaceService,
      requestingService,
      eventLoggers.templateEvent,
    )
    this.review = reviewService
    this.notification = new NotificationService(APIs.notification)
    this.subscription = new SubscriptionService(
      stripePromise,
      APIs.subscription,
    )
    this.slackIntegration = slackService
    this.folder = new FolderService(APIs.folder)
    this.download = downloadService
    this.citation = new citationService(repositories.citation, APIs.citation)
    this.viewer = viewerService
  }
}
