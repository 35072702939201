import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TypedResourceType } from '@/models/resource/TypedResource'

export enum RequestingType {
  newDocument = 'newDocument',
  documentsFromGoogleDrive = 'documentsFromGoogleDrive',
  inboxResources = 'inboxResources',
  libraryPanelResources = 'libraryPanelResources',
}

export type RequestingDataTypeMap = {
  [RequestingType.newDocument]: { projectId: string }
  [RequestingType.documentsFromGoogleDrive]: { projectId: string }
  [RequestingType.libraryPanelResources]: {
    folderId: string
    resourceType: TypedResourceType
  }
  [RequestingType.inboxResources]: { resourceType: TypedResourceType }
}

export type RequestingItem<T = undefined> = { id: string; data: T }

type RequestingState = {
  [type in RequestingType]?: RequestingItem<RequestingDataTypeMap[type]>[]
}

const initialState: RequestingState = {}

export const requestingSlice = createSlice({
  name: 'requesting',
  initialState,
  reducers: {
    addRequestings: (
      state,
      action: PayloadAction<{
        type: RequestingType
        items: RequestingItem<any>[]
      }>,
    ) => {
      const { type, items } = action.payload
      const requestings = [...(state[type] ?? [])] as RequestingItem<any>[]
      requestings.push(...items)
      state[type] = requestings
    },
    removeRequestings: (
      state,
      action: PayloadAction<{ type: RequestingType; ids: string[] }>,
    ) => {
      const { type, ids } = action.payload
      state[type] = (state[type] ?? ([] as RequestingItem<any>[])).filter(
        ({ id }) => !ids.includes(id),
      )
    },
    clearRequestings: (
      state,
      action: PayloadAction<{ type: RequestingType }>,
    ) => {
      const { type } = action.payload
      state[type] = []
    },
  },
})

export const { addRequestings, removeRequestings, clearRequestings } =
  requestingSlice.actions

export default requestingSlice.reducer
