import React, { useState } from 'react'
import styled from '@emotion/styled'
import { colors, TypedButton } from 'typed-design-system'
import { css, Global } from '@emotion/core'
import featureProps from '@/utils/featureProps/featureProps'

const TdsHighlightTool = () => {
  const on = featureProps.isTdsHighlightToolUsed()
  const [isHighlightOn, setIsHighlightOn] = useState(false)

  return on ? (
    <TdsHighlightToolWrapper
      backgroundColor={colors.blue50()}
      height={32}
      onClick={() => setIsHighlightOn(!isHighlightOn)}
    >
      {isHighlightOn ? 'Off' : 'On'}
      {isHighlightOn && <Global styles={highlightCss} />}
    </TdsHighlightToolWrapper>
  ) : null
}

const TdsHighlightToolWrapper = styled(TypedButton)`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 10000;
`

const highlightCss = css`
  [class^='tds-'] {
    position: relative;
    z-index: 1;
  }

  [class^='tds-']:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: rgba(255, 0, 0, 30%);
    content: '';
  }
`

export default TdsHighlightTool
