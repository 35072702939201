interface AuthText {
  signUp: {
    title: string
    subtitle: string
    buttonTxt: string
    ['already have an account']: string
    ['sign in']: string
    googleDriveAuthMessage: string
    bySignUp: string
  }
  signIn: {
    title: string
    subtitle: string
    buttonTxt: string
    ["don't have an account"]: string
    ['sign up']: string
    bySignIn: string
  }
}

const parseStrToLink = (content: string, href: string) => {
  return `<a href="${href}">${content}</a>`
}

const englishAuthText: AuthText = {
  signUp: {
    title: 'Welcome to Typed',
    subtitle: 'Ready to write your next amazing document?',
    buttonTxt: 'Sign up with Google',
    ['already have an account']: 'Already have an account?',
    ['sign in']: 'Sign in',
    googleDriveAuthMessage: `<span>In order to fully utilize Typed, you need to allow Typed to “see, edit, create and delete all of your Google Drive files.” Don’t worry! The Google Drive access is limited to you and you only per Google’s ${parseStrToLink(
      'Privacy & Terms',
      '/privacy',
    )}.  Nobody else can ever access your Google Drive.</span>`,
    bySignUp: `By signing up, you agree to our ${parseStrToLink(
      'TOS',
      '/terms',
    )} and ${parseStrToLink('Privacy Policy', '/privacy')}`,
  },
  signIn: {
    title: 'Welcome back, Typer',
    subtitle: 'Ready to write your next amazing document?',
    buttonTxt: 'Log in with Google',
    ["don't have an account"]: 'Don’t have an account?',
    ['sign up']: 'Sign up',
    bySignIn: `By signing in, you agree to our ${parseStrToLink(
      'TOS',
      '/terms',
    )} and ${parseStrToLink('Privacy Policy', '/privacy')}`,
  },
}

const koreanAuthText: AuthText = {
  signUp: {
    title: 'Welcome to Typed',
    subtitle: 'Ready to write your next amazing document?',
    buttonTxt: 'Google로 가입하기',
    ['already have an account']: '이미 가입한 계정이 있으신가요?',
    ['sign in']: '로그인하기',
    googleDriveAuthMessage: `<span>Typed 를 활용하기 위해서는 "Google 드라이브 파일 보기, 수정, 생성, 삭제" 액세스를 허용해야 합니다! 다만, 본 액세스는 Typed를 사용하는 사용자 본인의 ${parseStrToLink(
      'Google 드라이브 계정에 관련된 권한',
      '/privacy',
    )}으로, 사용자를 제외한 3자가 Google 드라이브 계정에 접근할 수 없습니다.</span>`,
    bySignUp: `계정 생성을 완료하시면 ${parseStrToLink(
      'TOS',
      '/terms',
    )} 및 ${parseStrToLink(
      '개인정보 취급방침',
      '/privacy',
    )}에 동의하는 것으로 간주합니다.`,
  },
  signIn: {
    title: 'Welcome back, Typer',
    subtitle: 'Ready to write your next amazing document?',
    buttonTxt: 'Google로 로그인하기',
    ["don't have an account"]: '아직 가입을 안 하셨나요?',
    ['sign up']: '가입하기',
    bySignIn: `계정 생성을 완료하시면 ${parseStrToLink(
      '사용 약관',
      '/terms',
    )} 및 ${parseStrToLink(
      '개인정보 취급방침',
      '/privacy',
    )}에 동의하는 것으로 간주합니다.`,
  },
}

export { englishAuthText, koreanAuthText }
