import { InvalidEmptyFieldError } from '@/errors/input'
import { getLanguage, TEXTS_WITH_LOCALE } from '@/locale/locale.util'
import LocalStorageManagerFactory from '@/utils/common/localStorageManagerFactory'
import TypedSubscriptionPlan, {
  ITypedSubscriptionPlan,
  NoSubscriptionPlan,
  SubscriptionMetadataSet,
} from '@/models/subscription'

export interface ITypedSpace {
  readonly id: string
  readonly name: string | undefined
  readonly hex: string
  readonly permissionType: `${TypedSpaceMemberRole}`
  readonly plan: ITypedSubscriptionPlan
  readonly driveId: string
  readonly photo?: string
}

class TypedSpace implements ITypedSpace {
  constructor(
    readonly id: string,
    readonly name: string | undefined,
    readonly hex: string,
    readonly permissionType: TypedSpaceMemberRole,
    readonly plan: ITypedSubscriptionPlan,
    readonly driveId: string,
    readonly photo?: string,
  ) {}

  static fromJSON(
    json: ITypedSpace,
    subscriptionMetadataSet: SubscriptionMetadataSet,
  ): TypedSpace {
    let permissionType = TypedSpaceMemberRole.VIEWER
    switch (json.permissionType) {
      case 'owner':
        permissionType = TypedSpaceMemberRole.OWNER
        break
      case 'editor':
        permissionType = TypedSpaceMemberRole.EDITOR
        break
    }

    return new TypedSpace(
      json.id,
      json.name,
      json.hex,
      permissionType,
      json.plan
        ? TypedSubscriptionPlan.fromJSON(json.plan, subscriptionMetadataSet)
        : new NoSubscriptionPlan(),
      json.driveId,
      json.photo,
    )
  }

  static validateName(name: string) {
    if (name.length === 0) {
      throw new InvalidEmptyFieldError()
    }

    return true
  }

  toJSON(): ITypedSpace {
    return {
      id: this.id,
      name: this.name,
      hex: this.hex,
      permissionType: this.permissionType,
      driveId: this.driveId,
      photo: this.photo,
      plan: this.plan,
    }
  }

  copyWith(options?: Partial<TypedSpace>): TypedSpace {
    return new TypedSpace(
      options?.id ?? this.id,
      options?.name ?? this.name,
      options?.hex ?? this.hex,
      options?.permissionType ?? this.permissionType,
      options?.plan ?? this.plan,
      options?.driveId ?? this.driveId,
      options?.photo ?? this.photo,
    )
  }
}

export enum TypedSpaceMemberRole {
  OWNER = 'owner',
  EDITOR = 'editor',
  VIEWER = 'viewer',
}

export interface ITypedSpaceMember {
  readonly userId: string
  readonly displayName: string
  readonly photoUrl: string
  readonly email: string
  readonly isPending: boolean
  readonly role: `${TypedSpaceMemberRole}`
}

export class TypedSpaceMember implements ITypedSpaceMember {
  constructor(
    readonly userId: string,
    readonly displayName: string,
    readonly photoUrl: string,
    readonly email: string,
    readonly isPending: boolean,
    readonly role: TypedSpaceMemberRole,
  ) {}

  get id() {
    return this.userId
  }

  static fromJSON(json: ITypedSpaceMember) {
    let role: TypedSpaceMemberRole
    switch (json.role) {
      case 'owner':
        role = TypedSpaceMemberRole.OWNER
        break
      case 'editor':
        role = TypedSpaceMemberRole.EDITOR
        break
      case 'viewer':
        role = TypedSpaceMemberRole.VIEWER
        break
      default:
        throw new Error('Invalid space member role')
    }

    return new TypedSpaceMember(
      json.userId,
      json.displayName,
      json.photoUrl,
      json.email,
      json.isPending,
      role,
    )
  }

  toJSON(): ITypedSpaceMember {
    return {
      userId: this.userId,
      displayName: this.displayName,
      photoUrl: this.photoUrl,
      email: this.email,
      isPending: this.isPending,
      role: this.role,
    }
  }

  copyWith(options?: Partial<TypedSpaceMember>): TypedSpaceMember {
    return new TypedSpaceMember(
      options?.userId ?? this.userId,
      options?.displayName ?? this.displayName,
      options?.photoUrl ?? this.photoUrl,
      options?.email ?? this.email,
      options?.isPending ?? this.isPending,
      options?.role ?? this.role,
    )
  }

  get displayRole() {
    const UserLanguageLSM = LocalStorageManagerFactory.userLanguage
    const language = UserLanguageLSM.getData() ?? getLanguage()

    switch (this.role) {
      case TypedSpaceMemberRole.OWNER:
        return TEXTS_WITH_LOCALE[language].space.Owner
      case TypedSpaceMemberRole.EDITOR:
        return TEXTS_WITH_LOCALE[language].space.Editor
      case TypedSpaceMemberRole.VIEWER:
        return TEXTS_WITH_LOCALE[language].space.Viewer
    }
  }
}

export type AssignableRole = Exclude<
  TypedSpaceMemberRole,
  TypedSpaceMemberRole.OWNER
>

export type SpaceInvitationInfo = {
  email: string
  role: AssignableRole
}

export interface ISpaceUsage {
  doc: {
    current: number
    max: number
  }
  editor: {
    current: number
    max: number
  }
  storage: {
    current: number
    max: number
  }
}

export default TypedSpace
