import { IN_TEXT_CITATION_EVENT } from '@/integrations/logging/enum'
import TypedEvent from '@/integrations/logging/event/typedEvent'

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */ export class InTextCitationEvent extends TypedEvent {
  copyCitation(data: { anchorResourceId: string; resourceId: string }) {
    this.sendEvent(
      IN_TEXT_CITATION_EVENT.CURRENT_LIBRARY_CITATION_LINK_COPY,
      data,
    )
  }

  splitViewOpen(data: { anchorResourceId: string; resourceId: string }) {
    this.sendEvent(IN_TEXT_CITATION_EVENT.CITATION_SPLIT_VIEW_OPEN, data)
  }
}
