import { QueryClient } from 'react-query'
import { queryKeys } from '@/providers/react-query'
import { Favorite } from '@/api/favoriteAPI'

export default class FavoriteRepository {
  constructor(readonly queryClient: QueryClient) {}

  findAll(spaceId: string): Favorite[] {
    return (
      this.queryClient.getQueryData<Favorite[]>(
        queryKeys.favoritesBySpaceId(spaceId),
      ) ?? []
    )
  }

  find(id: string, spaceId: string): Favorite | undefined {
    return this.findAll(spaceId).find((favorite) => favorite.id === id)
  }

  add(favorite: Favorite, spaceId: string): void {
    this.queryClient.setQueryData<Favorite[]>(
      queryKeys.favoritesBySpaceId(spaceId),
      (prevData) => [favorite, ...(prevData ?? [])],
    )
  }

  exist(id: string, spaceId: string): boolean {
    return Boolean(this.find(id, spaceId))
  }

  delete(id: string, spaceId: string): boolean {
    const favorite = this.find(id, spaceId)
    if (!favorite) {
      return false
    }

    const favoriteProject = this.findAll(spaceId).find(
      (favorite) => favorite.id === id,
    )
    if (!favoriteProject) {
      return false
    }

    this.queryClient.setQueryData<Favorite[]>(
      queryKeys.favoritesBySpaceId(spaceId),
      (prevData) => prevData!.filter((favorite) => favorite.id !== id),
    )

    return true
  }
  clear(): void {
    this.queryClient.clear()
  }
}
