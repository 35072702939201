import { HIGHLIGHT_EVENT } from '@/integrations/logging/enum'
import { getLifeSpan } from '@/integrations/logging/utils'
import { HighlightableResources } from '@/models/resource/HighlightableResources'
import { ITypedRepositories } from '@/repositories'
import TypedEvent from '@/integrations/logging/event/typedEvent'
import { ILoggingClient } from '@/integrations/logging/client'

export interface IHighlightEvent {
  repositories: ITypedRepositories
}

/**
 * @deprecated
 *  Use `useSendEventLog` instead. Use `ClickEventLogger` for click events.
 */
export class HighlightEvent extends TypedEvent implements IHighlightEvent {
  constructor(
    clients: ILoggingClient[],
    readonly repositories: ITypedRepositories,
  ) {
    super(clients)
  }

  createdEvent(highlightId: string, currentDocumentId: string) {
    const highlight = this.repositories.highlight.find(highlightId)!
    const resource = this.repositories.resource.find<HighlightableResources>(
      highlight.resourceId,
    )

    this.sendEvent(HIGHLIGHT_EVENT.CREATED, {
      highlightId,
      anchorResourceId: currentDocumentId,
      resourceId: resource?.resourceId,
      type: resource?.type,
    })
  }

  deletedEvent(highlightId: string, documentId: string) {
    const highlight = this.repositories.highlight.find(highlightId)!
    const resource = this.repositories.resource.find<HighlightableResources>(
      highlight?.resourceId,
    )

    this.sendEvent(HIGHLIGHT_EVENT.DELETED, {
      highlightId,
      lifeSpan: getLifeSpan(resource?.createdAt),
      anchorResourceId: documentId,
      resourceId: resource?.resourceId,
      type: resource?.type,
    })
  }

  viewedEvent(highlightId: string, currentDocumentId: string) {
    const highlight = this.repositories.highlight.find(highlightId)!
    const resource = this.repositories.resource.find<HighlightableResources>(
      highlight.resourceId,
    )

    this.sendEvent(HIGHLIGHT_EVENT.VIEWED, {
      highlightId,
      anchorResourceId: currentDocumentId,
      resourceId: resource?.resourceId,
      type: resource?.type,
    })
  }

  copiedEvent(highlightId: string) {
    const highlight = this.repositories.highlight.find(highlightId)!
    const resource = this.repositories.resource.find<HighlightableResources>(
      highlight.resourceId,
    )

    this.sendEvent(HIGHLIGHT_EVENT.COPIED, {
      highlightId,
      resourceId: resource?.resourceId,
      type: resource?.type,
    })
  }
}
