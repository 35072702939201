import {
  getShadowColorByDocType,
  getDocumentBoxIconByDocType,
  getXsmallBoxIconOfResource,
} from '@/helpers/imageByTypes'
import { NetworkNode, nodePaintParams } from '@/models/networkNode'
import TypedProject from '@/models/project'
import { TypedResourceType } from '@/models/resource/TypedResource'
import ProjectRepository from '@/repositories/projectRepository'
import colors from '@/styles/colors'
import canvasModules from '@/utils/canvas'

export default class NodePainter {
  constructor(readonly networkNode: NetworkNode) {}

  paint(
    ctx: CanvasRenderingContext2D,
    {
      clickedNodeId,
      hoverNodeId,
      highlightNodeIds,
      projectRepository,
    }: nodePaintParams,
  ) {
    switch (this.networkNode.node_type) {
      case TypedResourceType.DOCUMENT:
        this.paintDocumentNode(
          ctx,
          clickedNodeId,
          highlightNodeIds,
          hoverNodeId,
          projectRepository,
        )
        break
      default:
        this.paintResourceNode(
          ctx,
          clickedNodeId,
          hoverNodeId,
          highlightNodeIds,
        )
    }
  }

  paintClickableNodeArea(ctx: CanvasRenderingContext2D, color: string) {
    ctx.fillStyle = color
    switch (this.networkNode.node_type) {
      case TypedResourceType.DOCUMENT:
        return ctx.fillRect(
          this.networkNode.x -
            NetworkNode.DOCUMENT_IMAGE_SIZE / 2 -
            NetworkNode.DOCUMENT_CLICKED_HIGHLIGH_PADDING,
          this.networkNode.y -
            NetworkNode.DOCUMENT_IMAGE_SIZE / 2 -
            NetworkNode.DOCUMENT_CLICKED_HIGHLIGH_PADDING,
          NetworkNode.DOCUMENT_IMAGE_SIZE +
            2 * NetworkNode.DOCUMENT_CLICKED_HIGHLIGH_PADDING,
          NetworkNode.DOCUMENT_IMAGE_SIZE +
            2 * NetworkNode.DOCUMENT_CLICKED_HIGHLIGH_PADDING,
        )

      default:
        return ctx.fillRect(
          this.networkNode.x -
            NetworkNode.RESOURCE_ICON_SIZE / 2 -
            NetworkNode.RESOURCE_CARD_PADDING,
          this.networkNode.y -
            NetworkNode.RESOURCE_ICON_SIZE / 2 -
            NetworkNode.RESOURCE_CARD_PADDING,
          NetworkNode.RESOURCE_ICON_SIZE +
            NetworkNode.RESOURCE_CARD_PADDING * 2,
          NetworkNode.RESOURCE_ICON_SIZE +
            NetworkNode.RESOURCE_CARD_PADDING * 2,
        )
    }
  }

  private setGlobalAlphaByNodes(
    ctx: CanvasRenderingContext2D,
    hoverNodeId: string,
    clickedNodeId: string,
    highlightNodeIds: string[],
  ) {
    if (
      (!clickedNodeId && !this.networkNode.archived) ||
      highlightNodeIds.includes(this.networkNode.id) ||
      clickedNodeId === this.networkNode.id ||
      hoverNodeId === this.networkNode.id
    ) {
      ctx.globalAlpha = 1
    } else {
      ctx.globalAlpha = 0.6
    }
  }

  private paintDocumentNode(
    ctx: CanvasRenderingContext2D,
    clickedNodeId: string,
    highlightNodeIds: string[],
    hoverNodeId: string,
    projectRepository: ProjectRepository,
  ) {
    if (clickedNodeId === this.networkNode.id) {
      this.paintDocumentClickedRect(ctx)
    }

    this.setGlobalAlphaByNodes(
      ctx,
      hoverNodeId,
      clickedNodeId,
      highlightNodeIds,
    )

    if (
      this.networkNode.currentProjectId !== 'all' &&
      !this.networkNode.project_ids.includes(this.networkNode.currentProjectId)
    ) {
      const project = projectRepository.find(this.networkNode.project_ids[0])
      if (!project) return
      this.paintOtherProjectName(ctx, project)
    }

    this.paintDocumentIcon(ctx)

    this.paintDocumentName(ctx)
  }

  private paintDocumentClickedRect(ctx: CanvasRenderingContext2D) {
    ctx.globalAlpha = 0.2
    ctx.strokeStyle = '#38a5e1'

    ctx.fillStyle = '#38a5e1'
    canvasModules.shape.fillRadiusRect(
      ctx,
      this.networkNode.x - NetworkNode.CLICKED_HIGHLIGH_DOCUMENT_SIZE / 2,
      this.networkNode.y - NetworkNode.CLICKED_HIGHLIGH_DOCUMENT_SIZE / 2,
      NetworkNode.CLICKED_HIGHLIGH_DOCUMENT_SIZE,
      NetworkNode.CLICKED_HIGHLIGH_DOCUMENT_SIZE,
      { upperLeft: 10, upperRight: 10, lowerLeft: 10, lowerRight: 10 },
      true,
      true,
    )
  }

  private paintDocumentIcon(ctx: CanvasRenderingContext2D) {
    const { docType } = this.networkNode.metadata
    const image = new Image()
    image.src = getDocumentBoxIconByDocType(docType)

    ctx.shadowColor = getShadowColorByDocType(docType)
    ctx.shadowBlur = 15
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 5
    ctx.drawImage(
      image,
      this.networkNode.x - NetworkNode.DOCUMENT_IMAGE_SIZE / 2,
      this.networkNode.y - NetworkNode.DOCUMENT_IMAGE_SIZE / 2,
      NetworkNode.DOCUMENT_IMAGE_SIZE,
      NetworkNode.DOCUMENT_IMAGE_SIZE,
    )

    ctx.shadowBlur = 0
    ctx.shadowOffsetX = 0
    ctx.shadowOffsetY = 0
  }

  private paintDocumentName(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = 'black'
    ctx.textAlign = 'center'
    ctx.font = `bold ${NetworkNode.DOCUMENT_FONT_SIZE}px Pretendard`
    ctx.strokeStyle = 'white'

    NetworkNode.DOCUMENT_CLICKED_HIGHLIGH_PADDING,
      canvasModules.text.fillTextByNumberOfLines(
        ctx,
        this.networkNode.title,
        this.networkNode.x,
        this.networkNode.y +
          NetworkNode.DOCUMENT_IMAGE_SIZE / 2 +
          NetworkNode.DOCUMENT_FONT_SIZE +
          NetworkNode.DOCUMENT_CLICKED_HIGHLIGH_PADDING,

        NetworkNode.DOCUMENT_FONT_SIZE,
        NetworkNode.DOCUMENT_TEXT_MAX_WIDTH,
        NetworkNode.DOCUMENT_TITLE_NUMBER_OF_LINES,
        true,
      )
  }

  private paintOtherProjectName(
    ctx: CanvasRenderingContext2D,
    project: TypedProject,
  ) {
    ctx.fillStyle = colors.blue60()
    ctx.font = `${NetworkNode.DOCUMENT_OTHER_PROJECT_INFO_FONT_SIZE}px Pretendard`
    ctx.fillText(
      project.emoji + ' ' + project.name,
      this.networkNode.x,
      this.networkNode.y -
        NetworkNode.DOCUMENT_IMAGE_SIZE / 2 -
        NetworkNode.DOCUMENT_OTHER_PROJECT_INFO_FONT_SIZE / 2 -
        14,
    )
  }

  private paintResourceNode(
    ctx: CanvasRenderingContext2D,
    clickedNodeId: string,
    hoverNodeId: string,
    highlightNodeIds: string[],
  ) {
    ctx.fillStyle = 'black'
    ctx.font = `${NetworkNode.RESOURCE_FONT_SIZE}px Pretendard`
    const { str } = canvasModules.text.getFittingStringAndWidth(
      ctx,
      this.networkNode.title,
      NetworkNode.RESOURCE_TEXT_MAX_WIDTH,
    )

    this.setGlobalAlphaByNodes(
      ctx,
      hoverNodeId,
      clickedNodeId,
      highlightNodeIds,
    )

    this.paintResourceBack(
      ctx,
      this.networkNode.x -
        NetworkNode.RESOURCE_ICON_SIZE / 2 -
        NetworkNode.RESOURCE_CARD_PADDING,
      this.networkNode.y -
        NetworkNode.RESOURCE_ICON_SIZE / 2 -
        NetworkNode.RESOURCE_CARD_PADDING,
      NetworkNode.RESOURCE_ICON_SIZE + NetworkNode.RESOURCE_CARD_PADDING * 2,
      NetworkNode.RESOURCE_ICON_SIZE + NetworkNode.RESOURCE_CARD_PADDING * 2,
      clickedNodeId,
    )

    this.paintResourceIcon(
      ctx,
      this.networkNode.x - NetworkNode.RESOURCE_ICON_SIZE / 2,
      this.networkNode.y - NetworkNode.RESOURCE_ICON_SIZE / 2,
      NetworkNode.RESOURCE_ICON_SIZE,
    )

    this.paintResourceName(ctx, str)
  }

  private paintResourceBack(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    clickedNodeId: string,
  ) {
    ctx.strokeStyle = '#38a5e1'
    ctx.fillStyle = 'white'
    canvasModules.shape.fillRadiusRect(
      ctx,
      x,
      y,
      width,
      height,
      { upperLeft: 6, upperRight: 6, lowerLeft: 6, lowerRight: 6 },
      true,
      clickedNodeId === this.networkNode.id,
    )
  }

  private paintResourceIcon(
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    size: number,
  ) {
    const image = new Image()
    image.src = getXsmallBoxIconOfResource(this.networkNode)

    ctx.drawImage(image, x, y, size, size)
  }

  private paintResourceName(ctx: CanvasRenderingContext2D, str: string) {
    ctx.fillStyle = 'black'
    ctx.font = `${NetworkNode.RESOURCE_FONT_SIZE}px Pretendard`
    ctx.strokeStyle = 'white'
    ctx.strokeText(
      str,
      this.networkNode.x,
      this.networkNode.y +
        NetworkNode.RESOURCE_ICON_SIZE +
        NetworkNode.RESOURCE_CARD_PADDING +
        3 +
        NetworkNode.RESOURCE_FONT_SIZE / 2,
    )
    ctx.fillText(
      str,
      this.networkNode.x,
      this.networkNode.y +
        NetworkNode.RESOURCE_ICON_SIZE +
        NetworkNode.RESOURCE_CARD_PADDING +
        3 +
        NetworkNode.RESOURCE_FONT_SIZE / 2,
    )
  }
}
