import { QueryClient } from 'react-query'
import TypedGroup from '@/models/group'
import IBaseRepository from '@/repositories/types/baseRepository'
import { queryKeys } from '@/providers/react-query'

export default class GroupRepository implements IBaseRepository<TypedGroup> {
  constructor(readonly queryClient: QueryClient) {}

  add(item: TypedGroup): void {
    this.queryClient.setQueryData<TypedGroup[]>(
      queryKeys.groupsBySpaceId(item.spaceId),
      (prevData) => [...(prevData ?? []), item],
    )
  }

  addAll(): void {
    throw new Error('Method not implemented.')
  }

  findAll(spaceId?: string): TypedGroup[] | undefined {
    const allGroupsQueriesData = this.queryClient.getQueriesData<TypedGroup[]>(
      spaceId ? queryKeys.groupsBySpaceId(spaceId) : queryKeys.allGroups(),
    )

    return allGroupsQueriesData.reduce<TypedGroup[]>(
      (allGroups, [, groups]) => [...allGroups, ...(groups ?? [])],
      [],
    )
  }

  find(id: string): TypedGroup | undefined {
    const allGroups = this.findAll()

    if (!allGroups) {
      return
    }

    return allGroups.find((group) => group.id === id)
  }

  exist(): boolean {
    throw new Error('Method not implemented.')
  }

  update(id: string, itemToPatch: Partial<TypedGroup>): boolean {
    const group = this.find(id)

    if (!group) {
      return false
    }

    const updated = group.copyWith(itemToPatch)
    this.queryClient.setQueryData<TypedGroup[]>(
      queryKeys.groupsBySpaceId(group.spaceId),
      (prevData) =>
        prevData!.map((eachPrevData) =>
          eachPrevData.id === group.id ? updated : eachPrevData,
        ),
    )

    return true
  }

  delete(id: string): boolean {
    const group = this.find(id)

    if (!group) {
      return false
    }

    this.queryClient.setQueryData<TypedGroup[]>(
      queryKeys.groupsBySpaceId(group.spaceId),
      (prevData) => prevData!.filter((eachPrevData) => eachPrevData.id !== id),
    )

    return true
  }

  clear(): void {
    this.queryClient.removeQueries(queryKeys.allGroups())
  }

  replaceAll(spaceId: string, items: TypedGroup[]): boolean {
    const groupsInSpace = this.findAll(spaceId)

    if (!groupsInSpace) {
      return false
    }

    this.queryClient.setQueryData(queryKeys.groupsBySpaceId(spaceId), items)

    return true
  }
}
