import { AxiosInstance } from 'axios'
import { TypedDocument } from '@/models/document/TypedDocument'
import TypedProject from '@/models/project'
import autoBind from '@/utils/autoBind'
import { getDocumentInstanceByJson } from '@/factories/getDocumentInstanceByJson'

export type Favorite = TypedDocument | TypedProject

class FavoriteAPI {
  constructor(readonly axios: AxiosInstance) {}

  async getFavorites(spaceId: string): Promise<Favorite[]> {
    const response = await this.axios.get(`/api/favorite`)

    const data = response.data.map((json: any) => getInstanceBy(json, spaceId))

    return data
  }
}

const getInstanceBy = (json: any, spaceId: string) => {
  if (json.type === 'document') {
    return getDocumentInstanceByJson(json)
  } else if (json.type === 'project') {
    return TypedProject.fromJSON(json, spaceId)
  } else {
    throw new Error('Invalid json')
  }
}

export default autoBind(FavoriteAPI)
